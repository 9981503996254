import React, { useContext } from "react";
import { Select, Grid, MenuItem, FormHelperText } from "@mui/material";
import { ValidatedContext } from "../../modules/AddCases/AddLaboCase/AddLaboCase";

import "./index.scss";

export default function LabelMenu(props) {
  const {
    title,
    name,
    value,
    onChange,
    helperText,
    error,
    valuesDict,
    onSetValue,
    mandatory,
    disabled,
  } = props;
  const validated = useContext(ValidatedContext);

  let keys = Object.keys(valuesDict);
  var items = Object.keys(valuesDict).map(function (key) {
    return [key, valuesDict[key]];
  });

  // Sort the array based on the second element
  items.sort(function (first, second) {
    if (first[1] < second[1]) return -1;
    else if (first[1] > second[1]) return 1;
    else return 0;

  });
  console.log(items)
  let isDisabled = disabled === undefined ? false : disabled;
  let fontWeight = props.boldTitle === undefined ? 600 : 400;
  let redFlag = "";
  if (mandatory === true) {
    redFlag = <span style={{ color: "red" }}>&nbsp;*</span>;
  }
  return (
    <div style={{ textAlign: "right" }}>
      <Grid container spacing={0.5} direction="column">
        <Grid container spacing={0} direction="row">
          <Grid item xs={12}>
            <div>
              <label
                className="label-input-label"
                style={{ fontWeight: fontWeight }}
              >
                {title}
                {redFlag}
              </label>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: 0 }}>

          <Select
            disabled={isDisabled || validated}
            name={name}
            value={value}
            onChange={onChange}
            error={error}
            inputProps={{
              style: {
                boxSizing: "border-box",
                height: "42px",
              },
            }}
            style={{
              height: "42px",
              width: "100%",
              textAlign: "left",
              backgroundColor: "#FFFFFF",
            }}
          >
            {items.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => onSetValue && onSetValue(item[1])}
                value={item[0]}
              >
                {item[1]}
              </MenuItem>
            ))}
          </Select>

          <FormHelperText style={{ color: "#d32f2f", marginLeft: "14px" }}>
            {helperText}
          </FormHelperText>
        </Grid>
      </Grid>
    </div >
  );
}
