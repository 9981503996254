import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import '../../index.scss';
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { getTypeDeclarantDict } from "../../../../utils/case";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelDatePicker from "../../../LabelDatePicker/LabelDatePicker";
import { useFormikContext } from "formik";



export default function Declarant(props) {
      const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
      const typeDeclarantDict = getTypeDeclarantDict();
      const widthPercentage = '31%';



      return (
            <div className="case-common-container" >
                  <div className="case-common-container-title">
                        <label>Le déclarant</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>

                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"N° de dossier"}
                                                      name={"declarant.numero_dossier"}
                                                      onChange={handleChange}
                                                      error={touched?.declarant?.numero_dossier && Boolean(errors?.declarant?.numero_dossier)}
                                                      helperText={touched?.declarant?.numero_dossier && errors?.declarant?.numero_dossier}
                                                      mandatory={true} disabled />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelDatePicker title={"Date saisie"}
                                                      name={"declarant.date_saisie"}
                                                      onChange={setFieldValue}
                                                      error={touched?.declarant?.date_saisie && Boolean(errors?.declarant?.date_saisie)}
                                                      helperText={touched?.declarant?.date_saisie && errors?.declarant?.date_saisie}
                                                      mandatory={true} disabled />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelDatePicker title={"Date réception"}
                                                      name={"declarant.date_reception"}
                                                      onChange={setFieldValue}
                                                      error={touched?.declarant?.date_reception && Boolean(errors?.declarant?.date_reception)}
                                                      helperText={touched?.declarant?.date_reception && errors?.declarant?.date_reception}
                                                      mandatory={true} />
                                          </div>

                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Heure réception"}
                                                      name={"declarant.heure_reception"}
                                                      onChange={handleChange}
                                                      error={touched?.declarant?.heure_reception && Boolean(errors?.declarant?.heure_reception)}
                                                      helperText={touched?.declarant?.heure_reception && errors?.declarant?.heure_reception}
                                                      type={'time'} mandatory={true} />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Commune"}
                                                      name={"declarant.commune"}
                                                      onChange={handleChange}
                                                      error={touched?.declarant?.commune && Boolean(errors?.declarant?.commune)}
                                                      helperText={touched?.declarant?.commune && errors?.declarant?.commune}
                                                      valuesDict={typeDeclarantDict} />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Province"}
                                                      name={"declarant.province"}
                                                      onChange={handleChange}
                                                      error={touched?.declarant?.province && Boolean(errors?.declarant?.province)}
                                                      helperText={touched?.declarant?.province && errors?.declarant?.province}
                                                      valuesDict={typeDeclarantDict} />
                                          </div>
                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Région"}
                                                      name={"declarant.region"}
                                                      onChange={handleChange}
                                                      error={touched?.declarant?.region && Boolean(errors?.declarant?.region)}
                                                      helperText={touched?.declarant?.region && errors?.declarant?.region}
                                                />
                                          </div>
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}