import React from "react";
import { Stack, Button } from "@mui/material";
import { Toxi, Labo, Declarant, Patient, Produits, Repondeur, Intoxication, Scorpion, UserMgt, RoleMgt } from "../Icons/Icons";

import './index.scss';


export default function RubriqueButton(props) {
  const { type, active, onClick } = props;
  let backgroundColor = active ? "#BAD6FF" : "#FFFFFF";
  let fontWeight = active ? "bold" : "normal";
  let marginTop = "4px";
  let title, icon = null;
  switch (type) {
    case "toxicovigilance":
      title = "Toxicovigilance";
      icon = <Toxi />;
      break;
    case "declarant":
      title = "Déclarant & rapport";
      icon = <Declarant />;
      break;
    case "patient":
      title = "Patient";
      icon = <Patient />;
      break;
    case "produits":
      title = "Produits";
      icon = <Produits />;
      break;
    case "repondeur":
      title = "Répondeur";
      icon = <Repondeur />;
      break;
    case "intoxication":
      title = "Intoxication";
      icon = <Intoxication />;
      break;
    case "laboratoire":
      title = "Laboratoire";
      icon = <Labo />;
      break;
    case "scorpion":
      title = "Scorpion";
      icon = <Scorpion />;
      marginTop = "2px";
      break;
    case "users-management":
      title = "Utilisateurs";
      icon = <UserMgt />
      break;
    case "roles-management":
      title = "Rôles";
      icon = <RoleMgt />
      break;
    default:
      break;
  }



  return (
    <Button style={{ textTransform: 'none' }} onClick={onClick}>
      <div className="rubrique-button-container" style={{ background: backgroundColor }}>
        <div className="rubrique-button-body">
          <Stack direction="row" spacing={0} >
            <div className="rubrique-button-body-left">
              <div className="rubrique-button-body-icon">
                <div style={{ margin: 'auto', marginTop: marginTop }}>
                  {icon}
                </div>
              </div>
            </div>
            <div className="rubrique-button-body-label" style={{ fontWeight: fontWeight }}>
              <label>{title}</label>
            </div>
          </Stack>
        </div>
      </div>
    </Button>
  )
}