import { useQueries, useQuery } from "react-query";
import { getSecureAxiosInstance } from "../../utils/axiosInstances";
import { getTableEndPoint } from "../../utils/referentielData";


const useReferentiel = (table) => {
      return useQuery(table, () => getReferentiel(table), {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 30 * 60 * 1000,
            cacheTime: 30 * 60 * 1000,
      });
}

const useReferentiels = (tables) => {

      return useQueries(
            tables.map((table) => {
                  return {
                        queryKey: table,
                        queryFn: () => getReferentiel(table),
                        refetchOnWindowFocus: false,
                        refetchOnmount: false,
                        refetchOnReconnect: false,
                        retry: false,
                        staleTime: 30 * 60 * 1000,
                        cacheTime: 30 * 60 * 1000,
                  }
            })
      )
}

const getReferentiel = async (table) => {
      let table_end_point = getTableEndPoint(table);
      if (table === "produit_medicament") {
            table_end_point = table_end_point + "-all";
      }
      const { data } = await getSecureAxiosInstance().get(`referentiels/all-${table_end_point}`);
      return data;
}

const addReferentielElement = async (values, table) => {
      const table_end_point = getTableEndPoint(table);
      const { data } = await getSecureAxiosInstance().post(`referentiels/create-${table_end_point}`, values);
      return data;
}

const editReferentielElement = async (values, table) => {
      const table_end_point = getTableEndPoint(table);
      const { data } = await getSecureAxiosInstance().patch(`referentiels/edit-${table_end_point}`, values);
      return data;
}

const deleteReferentielElement = async (id, table) => {
      const table_end_point = getTableEndPoint(table);
      const { data } = await getSecureAxiosInstance().delete(`referentiels/delete-${table_end_point}?id=${id}`);
      return data;
}

export {
      useReferentiel,
      useReferentiels,
      addReferentielElement,
      editReferentielElement,
      deleteReferentielElement,
      getReferentiel
}