import React, { useContext } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import RoleButton from "./RoleButton";
import { getRoles } from "../../utils/rolesManagement";
import CustomButton from "../Buttons/CustomButton";
import './index.scss';
import {RolePermissionsContext} from '../../contextes/UserPermissionsContext';


export default function RoleMenu(props) {

  const { handleRoleClick, handleUpdateClick } = useContext(RolePermissionsContext);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const roles = getRoles();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    let newRole = roles[index];
    handleRoleClick(newRole);
  };

  const handlePermissionClick = () => {
    handleUpdateClick();
  }


  return (
    <Stack spacing={0} sx={{ width: '100%', maxWidth: 300 }}>
      <label className="role-label-title">
        Choix du rôle
                  </label>
      <List component="nav" >
        {
          roles.map((role, index) => (
            <React.Fragment key={index}>
              <Divider />
              <ListItemButton
                style={{
                  padding: 0
                }}
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <RoleButton type={role} />
              </ListItemButton>

            </React.Fragment>
          ))}
        <Divider />
      </List>
      <div style={{ display: 'inline-grid', marginTop: '28px' }}>
        <CustomButton type='permission' onClick={handlePermissionClick} />
      </div>
    </Stack>
  );
}


