import React, {useContext} from "react";
import { Stack } from "@mui/material";
import '../../index.scss';
import LabelInput from "../../../LabelInput/LabelInput";
import { useFormikContext } from "formik";

export default function Evolution(props) {
      const {values, touched, errors, handleChange, setFieldValue} = useFormikContext();
      const widthPercentage = '31%';

      return (
            <div className="case-common-container" >
                  <div className="case-common-container-title">
                        <label>Evolution</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Evolution E1"} 
                                                      name={"evolution.e1"} 
                                                      onChange={handleChange}
                                                      error={touched?.evolution?.e1 && Boolean(errors?.evolution?.e1)}
                                                      helperText={touched?.evolution?.e1 && errors?.evolution?.e1}
                                                      mandatory={true}  />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Evolution E2"} 
                                                name={"evolution.e2"} 
                                                onChange={handleChange}
                                                error={touched?.evolution?.e2 && Boolean(errors?.evolution?.e2)}
                                                helperText={touched?.evolution?.e2 && errors?.evolution?.e2}
                                                mandatory={true}  />
                                          </div>
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}