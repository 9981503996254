import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomMoleculeDict } from "../../../../utils/referentielData";
import { getFEFormatEchantillons, getBEFormatEchantillons } from "../../../../utils/divers/divers";
import { natureCorrepondance } from "../../../../utils/divers/divers"
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";
import _ from "lodash";

export default function Echantillon(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  const widthPercentage = "31%";

  const echantillon = selectedCase
    ? selectedCase.echantillon
    : values.echantillon;

  let echantillons = selectedCase ? getFEFormatEchantillons(selectedCase.echantillon?.echantillons_nature) : values.echantillon?.echantillons;
  echantillons = _.pickBy(echantillons, (ech) => (ech.checked));

  const echantillonsArray =
    echantillons && Object.keys(echantillons).map((key) => echantillons[key]);
  const echantillonsNatures =
    echantillons && Object.keys(echantillons).map((key) => key);

  const referentielResults = useReferentiels(["examen_demande"]);
  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const examens = flattenIdNomMoleculeDict(referentielResults[0].data);
  const examensDemandes = selectedCase
    ? selectedCase.echantillon?.echantillon_examens_demandes
    : values.echantillon?.examen_demande

  const selectedExamens = examensDemandes?.map(
    (exm) => examens[exm] + ", "
  );

  const getNature = (ech, index) => {
    let result = "";
    let nature = echantillonsNatures[index];
    if (nature === "produit" || nature === "autres") {
      result = natureCorrepondance[ech.name];
    }
    else {
      result = natureCorrepondance[nature];
    }
    return result;
  }
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>L'échantillon</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Heure prélèvement"
                  value={echantillon?.heure_prelevement}
                />
              </div>
            </Stack>
          </div>
          <div>
            {echantillonsArray
              ?.filter((ech) => ech.checked === true)
              .map((ech, index) => (
                <Stack direction="row" spacing={1}>
                  <div style={{ width: "25%" }}>
                    <LabelInfo
                      title="Nature"
                      value={getNature(ech, index)}
                    />
                  </div>
                  <div style={{ width: "25%" }}>
                    <LabelInfo
                      title="Conformité"
                      value={ech.conformite ? "Oui" : "Non"}
                    />
                  </div>
                  <div style={{ width: "25%" }}>
                    <LabelInfo title="Problème" value={ech.problem_rencontre} />
                  </div>
                  <div style={{ width: "25%" }}>
                    <LabelInfo
                      title="Pôle"
                      value={
                        (ech.gc ? "GC " : "") +
                        (ech.lc ? "LC " : "") +
                        (ech.ptrr ? "PTRR " : "") +
                        (ech.saa ? "SAA " : "")
                      }
                    />
                  </div>
                </Stack>
              ))}
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div>
                <LabelInfo title="Examen demandé" value={selectedExamens} />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
