import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Repondeur(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);
  const analyste = selectedCase ? selectedCase : values.analyste;

  const referentielResults = useReferentiels(["analyste"]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const analystes = flattenIdNomDict(referentielResults[0].data);

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Information sur l'analyste</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Analyste"
                  value={analystes[analyste.analyste_id]}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "100%" }}>
                <LabelInfo title="Observation" value={analyste.observation} />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
