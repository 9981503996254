import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Stack, Modal, Box } from "@mui/material";
import UserManagementDataGrid from "../../components/Grids/UserManagementDataGrid/UserManagementDataGrid";
import { deleteUser, useUsers } from "../../services/users/users";
import CustomButton from "../../components/Buttons/CustomButton";
import ViewUser from "../../components/ViewUser/ViewUser";
import NewUser from "../../components/NewUser/NewUser";
import { useSnackbar } from "notistack";
import PopupDelRefCas from "../../components/Popups/PopupDelRefCas/PopupDelRefCas";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

import "./index.scss";

const style = {
  position: "absolute",
  width: 621,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function UserManagement(props) {
  const { data, isLoading, isError, error, refetch } = useUsers();
  const [userModal, setUserModal] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const queryClient = useQueryClient();
  const { enqueueSnackbar: showMessage } = useSnackbar();

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setSearchText(e.target.value);
  };

  const handleCloseUserModal = () => {
    setUserModal(false);
  };

  const handleShowUserClick = (user) => {
    setSelectedUser(user);
    setUserModal(true);
  };

  const handleNewUserClick = () => {
    setNewUserModal(true);
  };

  const handleCloseNewUserModal = () => {
    setNewUserModal(false);
  };
  const updateData = () => {
    queryClient.invalidateQueries("users");
  };

  const handleNewUserSuccess = () => {
    updateData();
    showMessage("Utilisateur ajouté avec succès", { variant: "success" });
  };

  const handleNewUserError = () => {
    showMessage("Erreur lors de l'ajout de l'utilisateur", {
      variant: "error",
    });
  };

  const handleEditUserSuccess = (data) => {
    updateData();
    showMessage("Utilisateur modifié avec succès", { variant: "success" });
  };

  const handleEditUserError = () => {
    showMessage("Erreur lors de la modification de l'utilisateur", {
      variant: "error",
    });
  };

  const handleCloseDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  const handleDeleteUser = (id) => {
    setSelectedUserId(id);
    setDeleteUserModal(true);
  };

  const deleteMutation = useMutation(
    (id) => {
      deleteUser(id).then(() => {
        updateData();
        showMessage("Utilisateur supprimé avec succès", { variant: "success" });
      }).catch((error) => {
        showMessage("Erreur lors de la suppression de l'utilisateur", {
          variant: "error",
        });
      });
      // },
      // {
      //   onSuccess: () => {
      //     updateData();
      //     showMessage("Utilisateur supprimé avec succès", { variant: "success" });
      //   },
      //   onError: () => {
      //     showMessage("Erreur lors de la suppression de l'utilisateur", {
      //       variant: "error",
      //     });
      //   },
      // }
    });

  const handleModalDeleteUser = (id) => {
    deleteMutation.mutate(id);
  };
  if (isLoading) {
    return <CustomLoader isLoading={isLoading} />;
  }

  return (
    <div className="user-management-container">
      <Stack direction="column" spacing={0}>
        <div className="user-management-header">
          <Stack direction="row" spacing={0}>
            <div className="user-management-research">
              <OutlinedInput
                placeholder="Rechercher un utilisateur..."
                onChange={debounce(handleSearch, 1000)}
                endAdornment={<SearchIcon htmlColor="#aaaaaa" />}
                style={{ width: "51%" }}
              />
            </div>
            <Stack
              direction="row-reverse"
              spacing={0}
              style={{ width: "100%" }}
            >
              <div className="user-management-add-user">
                <CustomButton
                  title={"Ajouter un utilisateur"}
                  type={"add"}
                  customBackground={true}
                  onClick={handleNewUserClick}
                />
              </div>
            </Stack>
          </Stack>
        </div>
        <div className="user-management-datagrid">
          <UserManagementDataGrid
            users={data}
            filter={searchText}
            onShowUser={handleShowUserClick}
            onDeleteUser={handleDeleteUser}
          />
        </div>
      </Stack>
      <Modal
        open={userModal}
        onClose={handleCloseUserModal}
        aria-labelledby="modal-view-user"
      >
        <Box sx={{ ...style }}>
          <ViewUser
            user={selectedUser}
            onClose={handleCloseUserModal}
            onSuccess={handleEditUserSuccess}
            onError={handleEditUserError}
          />
        </Box>
      </Modal>
      <Modal
        open={newUserModal}
        onClose={handleCloseNewUserModal}
        aria-labelledby="modal-new-user"
      >
        <Box sx={{ ...style }}>
          <NewUser
            user={selectedUser}
            onClose={handleCloseNewUserModal}
            onSuccess={handleNewUserSuccess}
            onError={handleNewUserError}
          />
        </Box>
      </Modal>
      <Modal
        open={deleteUserModal}
        onClose={handleCloseDeleteUserModal}
        aria-labelledby="modal-delete-user"
      >
        <PopupDelRefCas
          selectedRow={selectedUserId}
          onClose={handleCloseDeleteUserModal}
          onDelete={handleModalDeleteUser}
          title="cet utilisateur"
        />
      </Modal>
    </div>
  );
}
