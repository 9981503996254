import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import "../index.scss";
import Medicament from "../../../components/ViewCase/Common/Toxiques/Medicament";
import { CaseContext } from "../../../components/Modal/PreviewCaseModal";
import MedicamentVeterinaire from "../../../components/ViewCase/Common/Toxiques/MedicamentVeterinaire";
import Plante from "../../../components/ViewCase/Common/Toxiques/Plante";
import Gaz from "../../../components/ViewCase/Common/Toxiques/Gaz";
import Inconnu from "../../../components/ViewCase/Common/Toxiques/Inconnu";
import ProduitIndustriel from "../../../components/ViewCase/Common/Toxiques/ProduitIndustriel";
import ProduitMenager from "../../../components/ViewCase/Common/Toxiques/ProduitMenager";
import Scorpion from "../../../components/ViewCase/Common/Toxiques/Scorpion";
import Serpent from "../../../components/ViewCase/Common/Toxiques/Serpent";
import Drogue from "../../../components/ViewCase/Common/Toxiques/Drogue";
import ProduitCosmetique from "../../../components/ViewCase/Common/Toxiques/ProduitCosmetique";
import Autre from "../../../components/ViewCase/Common/Toxiques/Autre";
import AutreAnimalVenimeux from "../../../components/ViewCase/Common/Toxiques/AutreAnimalVenimeux";
import Pesticide from "../../../components/ViewCase/Common/Toxiques/Pesticide";
import Engrais from "../../../components/ViewCase/Common/Toxiques/Engrais";

export default function Toxique(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  const toxiques = selectedCase
    ? selectedCase.toxiques
    : values.toxiques;
  const getToxiqueViewComponent = (toxique, toxiqueType) => {
    switch (toxiqueType) {
      case "medicaments":
        return <Medicament medicament={toxique} preview={selectedCase ? true : false} />
      case "toxique_medicament_veterinaire":
        return <MedicamentVeterinaire medicament={toxique} preview={selectedCase ? true : false} />
      case "plante":
        return <Plante plante={toxique} preview={selectedCase ? true : false} />
      case "gaz":
        return <Gaz gaz={toxique} preview={selectedCase ? true : false} />
      case "inconnu":
        return <Inconnu inconnu={toxique} preview={selectedCase ? true : false} />
      case "produit_industriel":
        return <ProduitIndustriel produit={toxique} preview={selectedCase ? true : false} />
      case "produit_menager":
        return <ProduitMenager produit={toxique} preview={selectedCase ? true : false} />
      case "scorpion":
        return <Scorpion scoprion={toxique} preview={selectedCase ? true : false} />
      case "serpent":
        return <Serpent serpent={toxique} preview={selectedCase ? true : false} />
      case "drogue":
        return <Drogue drogue={toxique} preview={selectedCase ? true : false} />
      case "produit_cosmetique":
        return <ProduitCosmetique produit={toxique} preview={selectedCase ? true : false} />
      case "autre":
        return <Autre autre={toxique} preview={selectedCase ? true : false} />
      case "autre_animal_venimeux":
        return <AutreAnimalVenimeux autreAnimal={toxique} preview={selectedCase ? true : false} />
      case "pesticide":
        return <Pesticide pesticide={toxique} preview={selectedCase ? true : false} />
      case "engrais":
        return <Engrais engrais={toxique} preview={selectedCase ? true : false} />
      default:
        return null //<div>Pas disponible</div>
    }
  }
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-component">
        <Stack direction="column" spacing={0}>
          <div className="view-case-common-sub-header">
            <div className="view-case-common-sub-header-title">
              Information sur les toxiques
            </div>
          </div>
          <div className="view-case-common-body">
            <Stack direction="column" spacing={3}>
              {toxiques &&
                Object.keys(toxiques)?.map((toxiqueType, idx) => (
                  toxiques[toxiqueType].length > 0 &&
                  toxiques[toxiqueType]?.map((toxique, idx2) => (
                    <div>
                      {getToxiqueViewComponent(toxique, toxiqueType)}
                    </div>
                  ))
                ))
              }
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
