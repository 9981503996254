import React from "react";
import { Stack } from "@mui/material";
import { BrouillonIcon, DoneStarIcon, HistoricIcon } from "../Icons/Icons";
import "./index.scss";

export default function SimplePageTitle(props) {
  const { type, caseNumber } = props;
  let title, icon;

  // eslint-disable-next-line default-case
  switch (type) {
    case "validation":
      icon = (
        <DoneStarIcon
          width="35"
          height="35"
          viewBox="0 0 40 40"
          fill="none"
          stroke="#367BF5"
          stroke-width="2"
        />
      );
      title = "Cas à valider";
      break;
    case "historic":
      icon = (
        <HistoricIcon
          width="40"
          height="40"
          viewBox="0 0 30 30"
          fill="#CF8210"
        />
      );
      title = "Historique";
      break;
    case "brouillon":
      icon = (
        <BrouillonIcon
          width="40"
          height="40"
          viewBox="0 0 30 30"
          fill="#7851A9"
        />
      );
      title = "Brouillon ";
      break;
    case "modifier":
      icon = (
        <BrouillonIcon
          width="40"
          height="40"
          viewBox="0 0 30 30"
          fill="#7851A9"
        />
      );
      title = "Modifier un cas ";
      break;
  }

  return (
    <div className="simple-page-title-container">
      <div className="simple-page-title-body">
        <Stack direction="row" spacing={0}>
          <div className="simple-page-title-body-left">
            <div className="simple-page-title-body-icon">
              <div style={{ margin: "auto" }}>{icon}</div>
            </div>
          </div>
          <div className="simple-page-title-body-label">
            <label>{title}</label>
          </div>
        </Stack>
      </div>
    </div>
  );
}
