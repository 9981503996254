import React, { useState } from 'react';
import { Stack } from '@mui/material';
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import Report from './Report';
import { isAllowed } from "../../services/auth";
import { visualizeDashboardPermissions } from "../../utils/permissions";

import './index.scss';

export default function DashboardModule(props) {
      const [componentIndex, setComponentIndex] = useState(-1);
      let category = null;

      const activateComponent = (newComponentIndex) => {
            setComponentIndex(newComponentIndex);
      }

      switch (componentIndex) {
            case 0:
                  category = "toxicovigilance";
                  break;
            case 1:
                  category = "laboratoire";
                  break;
            case 2:
                  category = "scorpion";
                  break;
            default:
                  break;
      }

      return (
            <div className="dashboard-module-container">
                  <Stack direction="column" spacing={0}>
                        <div className="dashboard-module-title">
                              <label>Sélectionner rubrique</label>
                        </div>
                        <div className="dashboard-module-buttons">
                              <Stack direction="row" spacing={0}>
                                    {isAllowed([visualizeDashboardPermissions[0], visualizeDashboardPermissions[1]]) &&
                                          <RubriqueButton
                                                type="toxicovigilance"
                                                active={componentIndex === 0}
                                                onClick={() => activateComponent(0)}
                                          />
                                    }
                                    {isAllowed([visualizeDashboardPermissions[2]]) &&
                                          <RubriqueButton
                                                type="laboratoire"
                                                active={componentIndex === 1}
                                                onClick={() => activateComponent(1)}
                                          />
                                    }
                                    {/* <RubriqueButton type="scorpion" active={componentIndex == 2} onClick={() => activateComponent(2)} /> */}
                              </Stack>
                        </div>
                        <div className="dashboard-module-title">
                              <label>Dashboard / Rapport</label>
                        </div>
                        <div className="dashboard-module-body">
                              {componentIndex > -1 &&
                                    (<Report category={category} />)
                              }
                        </div>
                  </Stack>
            </div>
      )
}