import React from "react";
import { ListItemButton, List } from "@mui/material";
import CustomNav from './CustomNav';
import { isAllowed } from "../../services/auth";



export default function LeftNavBar(props) {

      const { onClick, selectedIndex, categories, permissions } = props;

      return (
            <div style={{ backgroundColor: '#fff', maxWidth: '85px' }}>
                  <List component="nav">
                        {categories.map((category, index) =>

                              <ListItemButton
                                    style={{ paddingLeft: 0, marginBottom: '15px' }}
                                    key={index}
                                    onClick={(event) => onClick(index)}
                                    disabled={!isAllowed(permissions[index])}
                              >
                                    <CustomNav type={category} active={selectedIndex === index} />
                              </ListItemButton>

                        )
                        }
                  </List>
            </div>

      );

}