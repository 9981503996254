import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getPlanteDetails } from "../../../../services/cases/cases";

export default function Plante(props) {
    const { plante, preview } = props;
    const [planteDetails, setPlanteDetails] = useState(null);

    const referentielResults = useReferentiels([
        "plante_nom_vernaculaire",
        "voie_intoxication",
        "partie_plante",
        "presentation_plante",
        "mode_preparation_plante"
    ]);
    useEffect(() => {
        getPlanteDetails(plante.nom_vernaculaire_id).then((data) => {
            setPlanteDetails({
                "nom_scientifique": data.nom_scientifique,
                "nom_francais": data.nom_francais,
                "code": data.code
            });
        });
    }, [props]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading || planteDetails === null) return <CustomLoader isLoading={isLoading} />;

    const plantes = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);
    const parties = flattenIdNomDict(referentielResults[2].data);
    const presentations = flattenIdNomDict(referentielResults[3].data);
    const modes = flattenIdNomDict(referentielResults[4].data);

    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Plante</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(plante.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom commercial"
                                        value={plantes[plante.nom_vernaculaire_id]}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Nom français" value={planteDetails.nom_francais} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom scientifique"
                                        value={planteDetails.nom_scientifique}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Partie utilisée"
                                        value={parties[planteDetails.partie_utilisee_id]}
                                    />
                                </div>
                                <div style={{ width: "66%" }}>
                                    <LabelInfo
                                        title="Présentation"
                                        value={presentations[plante.presentation_id]}
                                    />
                                </div>
                                <div style={{ width: "66%" }}>
                                    <LabelInfo
                                        title="Code"
                                        value={planteDetails.code}
                                    />
                                </div>

                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Doses administrées"
                                        value={plante.dose_administree}
                                    />
                                </div>
                                <div style={{ width: "66%" }}>
                                    <LabelInfo title="Mode de préparation" value={modes[plante.mode_preparation_id]} />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[plante.voie_intoxication_id]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Conseillée dans les médias"
                                        value={
                                            plante.conseillee_media === 1 ? "Oui" : "Non"
                                        }
                                    />
                                </div>


                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Plante Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
