import React, { useContext } from "react";
import "./index.scss";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { ValidatedContext } from "../../modules/AddCases/AddLaboCase/AddLaboCase";

export default function LabelTextArea(props) {
  const {
    title,
    name,
    value,
    onChange,
    error,
    helperText,
    placeholder,
    mandatory,
    disabled
  } = props;
  const validated = useContext(ValidatedContext);

  let redFlag = "";
  if (mandatory === true) {
    redFlag = <span style={{ color: "red" }}>&nbsp;*</span>;
  }
  return (
    <>
      <Grid
        container
        spacing={0.5}
        direction="column"
        style={{ margin: 0, padding: 0, width: "100%" }}
      >
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <label className="label-textarea-label">
              {title}
              {redFlag}
            </label>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 0 }}>
          <TextField
            className="label-textarea-textarea"
            disabled={validated || disabled}
            style={{ width: "100%", fontSize: 16 }}
            name={name}
            placeholder={placeholder}
            value={value}
            error={error}
            helperText={helperText}
            onChange={onChange}
            multiline
            inputProps={{
              style: {
                boxSizing: "border-box",
                height: "100px",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
