import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Patient(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);
  const patient = selectedCase ? selectedCase.patient : values.patient;

  const referentielResults = useReferentiels([
    "groupe_age",
    "commune",
    "province",
    "region",
    "profession"
  ]);
  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const trancheAges = flattenIdNomDict(referentielResults[0].data);
  const communes = flattenIdNomDict(referentielResults[1].data);
  const provinces = flattenIdNomDict(referentielResults[2].data);
  const regions = flattenIdNomDict(referentielResults[3].data);
  const professions = flattenIdNomDict(referentielResults[4].data);

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Le patient</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Nom et prénom" value={patient?.nom_prenom} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Numéro de téléphone"
                  value={patient?.telephone}
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Sexe"
                  value={
                    parseInt(patient?.sexe) === 0
                      ? "Féminin"
                      : parseInt(patient?.sexe) === 1
                        ? "Masculin"
                        : "Non précisé"
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Milieu"
                  value={parseInt(patient?.milieu) === 0
                    ? "Rural"
                    : parseInt(patient?.milieu) === 1
                      ? "Urban"
                      : parseInt(patient?.milieu) === 2 && "Inconnu"}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Age" value={patient?.age_chiffre} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Unité d'âge" value={patient?.age_unite} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Tranche d'âge"
                  value={trancheAges[patient?.tranche_age]}
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Commune"
                  value={communes[patient?.commune_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Province"
                  value={provinces[patient?.province_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Région" value={regions[patient?.region_id]} />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Poids (Kg)" value={patient?.poids} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Grossesse"
                  value={parseInt(patient?.grossesse) === 0
                    ? "Non"
                    : parseInt(patient?.grossesse) === 1
                      ? "Oui"
                      : parseInt(patient?.grossesse) === 2 && "Inconnu"}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Nombre de semaines"
                  value={patient?.nombre_semaines}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Allaitement"
                  value={parseInt(patient?.allaitement) === 0
                    ? "Non"
                    : parseInt(patient?.allaitement) === 1
                      ? "Oui"
                      : parseInt(patient?.allaitement) === 2 && "Inconnu"}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Profession" value={professions[patient?.profession_id]} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Antécédents" value={patient?.antecedents} />
              </div>
            </Stack>
          </div>

        </Stack>
      </div>
    </div>
  );
}
