const navDiv ={
      borderLeftColor:'#EBEFF8'
}


const navButtonStyle = {
      background: '#EBEFF8',
      borderRadius: '5px',
      width: '32px',
      height: '32px',
      marginLeft:'20px',
      borderLeftColor: '#fff'
}

const navTitleStyle = {
      marginTop:'4px',
      width: '69px',
      height: '27px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: '#4F5E7B',
     
}

export {navDiv, navButtonStyle, navTitleStyle};