import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getProduitGazDetails } from "../../../../services/cases/cases";

export default function Gaz(props) {
    const { gaz, preview } = props;
    const [gazDetails, setGazDetails] = useState(null);

    const referentielResults = useReferentiels([
        "produit_gaz",
        "voie_intoxication",
        "source_gaz"
    ]);
    useEffect(() => {
        getProduitGazDetails(gaz.produit_gaz_id).then((data) => {
            setGazDetails({
                "classe_gaz": data.classe_gaz,
                "code": data.code
            });
        });
    }, [props]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading || gazDetails === null) return <CustomLoader isLoading={isLoading} />;

    const produits = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);
    const sources = flattenIdNomDict(referentielResults[2].data);

    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Gaz</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(gaz.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom"
                                        value={gaz.nom}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Produit" value={produits[gaz.produit_gaz_id]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Classe"
                                        value={gazDetails.classe_gaz}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[gaz.voie_intoxication_id]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Source"
                                        value={
                                            sources[gaz.source_id]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Si autre source"
                                        value={
                                            gaz.autre_source
                                        }
                                    />
                                </div>

                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Gaz Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
