import React from "react";
import { Stack } from "@mui/material";
import Analyste from "../../../components/Case/Labo/Analyste/Analyste";
import '../index.scss';


export default function AddLaboAnalyste(props) {

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={4}>
                        <Analyste />
                  </Stack>
            </div>

      )
}