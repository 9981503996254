import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelMenuAuto from "../../../LabelMenu/LabelMenuAuto";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelPhone from "../../../LabelPhone/LabelPhone";
import { getProvince, getProvinces, getCommunes, getRegionWithProvince } from "../../../../services/cases/cases";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import "../../index.scss";

export default function Declarant(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const [provinces, setProvinces] = useState({});
  const [communes, setCommunes] = useState({});
  const referentielResults = useReferentiels([
    "declarant_type",
    "commune",
    "province",
    "provenance",
    "region",
    "service"
  ]);
  const widthPercentage = "31%";

  const onComuneProvinceChange = (event, name) => {
    handleChange(event);
    let communeId = getIn(values, "declarant.declarant.commune_id");
    let provinceId = getIn(values, "declarant.declarant.province_id");
    if (name === "commune") {
      communeId = event.target.value;
      getProvince(communeId).then((data) => {
        setFieldValue("declarant.declarant.province_id", "" + data.province_id);
        setFieldValue("declarant.declarant.region_id", "" + data.region_id);
      })
    } else {
      provinceId = event.target.value;
      onProvinceChange(event);
    }
  };

  const onRegionChange = (event) => {
    handleChange(event);
    let regionId = event.target.value;
    if (regionId === "") return;
    getProvinces(regionId).then((data) => {
      setProvinces(flattenIdNomDict(data));
      setFieldValue("declarant.declarant.commune_id", "");
      setFieldValue("declarant.declarant.province_id", "");
    }
    )
  };

  const onProvinceChange = (event) => {

    handleChange(event);
    let provinceId = event.target.value;
    if (provinceId === "") {
      return;
    }
    getCommunes(provinceId).then((data) => {
      setCommunes(flattenIdNomDict(data));
      setFieldValue("declarant.declarant.commune_id", "");
    }
    )

    getRegionWithProvince(provinceId).then((data) => {
      setFieldValue("declarant.declarant.region_id", "" + data);
    });
  };

  const isLoading = referentielResults.some((result) => result.isLoading);
  useEffect(() => {
    setProvinces(isLoading ? {} : flattenIdNomDict(referentielResults[2].data));
    setCommunes(isLoading ? {} : flattenIdNomDict(referentielResults[1].data))
  }, [isLoading]);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const declarantTypes = flattenIdNomDict(referentielResults[0].data);
  const provenances = flattenIdNomDict(referentielResults[3].data);
  const regions = flattenIdNomDict(referentielResults[4].data);
  const services = flattenIdNomDict(referentielResults[5].data);

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Le déclarant</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Type du déclarant"}
                  name={"declarant.declarant.declarant_type_id"}
                  value={
                    getIn(values, "declarant.declarant.declarant_type_id")
                  }
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.declarant.declarant_type_id") &&
                    Boolean(
                      getIn(errors, "declarant.declarant.declarant_type_id")
                    )
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.declarant_type_id") &&
                    getIn(errors, "declarant.declarant.declarant_type_id")
                  }
                  mandatory={true}
                  valuesDict={declarantTypes}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Nom du déclarant"}
                  name={"declarant.declarant.nom"}
                  value={getIn(values, "declarant.declarant.nom")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.declarant.nom") &&
                    Boolean(getIn(errors, "declarant.declarant.nom"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.nom") &&
                    getIn(errors, "declarant.declarant.nom")
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelPhone
                  title={"Numéro de téléphone"}
                  name={"declarant.declarant.telephone"}
                  value={getIn(values, "declarant.declarant.telephone")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.declarant.telephone") &&
                    Boolean(getIn(errors, "declarant.declarant.telephone"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.telephone") &&
                    getIn(errors, "declarant.declarant.telephone")
                  }

                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Commune"}
                  name={"declarant.declarant.commune_id"}
                  value={getIn(values, "declarant.declarant.commune_id")}
                  onChange={(e) => onComuneProvinceChange(e, "commune")}
                  error={
                    getIn(touched, "declarant.declarant.commune_id") &&
                    Boolean(getIn(errors, "declarant.declarant.commune_id"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.commune_id") &&
                    getIn(errors, "declarant.declarant.commune_id")
                  }

                  valuesDict={communes}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Province"}
                  name={"declarant.declarant.province_id"}
                  value={getIn(values, "declarant.declarant.province_id")}
                  onChange={(e) => onComuneProvinceChange(e, "province")}
                  error={
                    getIn(touched, "declarant.declarant.province_id") &&
                    Boolean(getIn(errors, "declarant.declarant.province_id"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.province_id") &&
                    getIn(errors, "declarant.declarant.province_id")
                  }

                  valuesDict={provinces}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Région"}
                  name={"declarant.declarant.region_id"}
                  value={getIn(values, "declarant.declarant.region_id")}
                  onChange={onRegionChange}
                  error={
                    getIn(touched, "declarant.declarant.region_id") &&
                    Boolean(getIn(errors, "declarant.declarant.region_id"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.region_id") &&
                    getIn(errors, "declarant.declarant.region_id")
                  }
                  valuesDict={regions}

                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Provenance "}
                  name={"declarant.declarant.provenance_id"}
                  value={
                    getIn(values, "declarant.declarant.provenance_id")
                  }
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.declarant.provenance_id") &&
                    Boolean(getIn(errors, "declarant.declarant.provenance_id"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.provenance_id") &&
                    getIn(errors, "declarant.declarant.provenance_id")
                  }
                  valuesDict={provenances}
                />
              </div>
              {[1, 2, 3].includes(parseInt(getIn(values, "declarant.declarant.provenance_id"))) && (<div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Service "}
                  name={"declarant.declarant.service_id"}
                  value={
                    getIn(values, "declarant.declarant.service_id")
                  }
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.declarant.service_id") &&
                    Boolean(getIn(errors, "declarant.declarant.service_id"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.service_id") &&
                    getIn(errors, "declarant.declarant.service_id")
                  }
                  valuesDict={services}
                />
              </div>)}
              < div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Dénomination sociale "}
                  name={"declarant.declarant.denomination"}
                  value={
                    getIn(values, "declarant.declarant.denomination")
                  }
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.declarant.denomination") &&
                    Boolean(getIn(errors, "declarant.declarant.denomination"))
                  }
                  helperText={
                    getIn(touched, "declarant.declarant.denomination") &&
                    getIn(errors, "declarant.declarant.denomination")
                  }
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div >
    </div >
  );
}
