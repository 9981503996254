import React from "react";
import { Box } from "@mui/system";
import { Stack, IconButton, Button, Divider } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useFormik } from 'formik';
import LabelInput from "../../../LabelInput/LabelInput";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { UserViewCloseIcon, NewUserAddIcon } from "../../../Icons/Icons";
import { EspeceAnimauxSchema } from "../../../../utils/validationSchemas/referentiel/referentiel";
import { useReferentiel } from "../../../../services/referentiels/referentiels";
import {flattenIdNomDict} from "../../../../utils/referentielData";
import '../../index.scss';


const style = {
      position: 'absolute',
      width: 621,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'

}



export default function EspeceAnimauxModal(props) {
      const {selectedRow,onEdit,onClose}  = props;
      const { data, isLoading, isError, error } = useReferentiel('groupe_animaux'); 
     
      const inputBackground = '#FFFFFF';
      const formik = useFormik({
            initialValues: {
                  id:selectedRow?.id,
                  nom: selectedRow?.nom,
                  groupe_animaux_id:selectedRow?.groupe_animaux_id
            },
            validationSchema: EspeceAnimauxSchema,
            onSubmit: (values) => {
                  onEdit(values);
            },
      });
      if (isLoading) 
            return (<CustomLoader isLoading={isLoading} />)
      
      const transformedData = flattenIdNomDict(data)
      return (
            <Box sx={{ ...style }}>
                  <div>
                        <Stack direction="column" spacing={0} >
                              <div>
                                    <div className="referentiel-modal-container">
                                          <Stack direction="column" spacing={0}>
                                                <div className="referentiel-modal-header">
                                                      <Stack direction="row" spacing={0} style={{ alignItems: 'center', width:'100%' }}>
                                                            <div>
                                                                  <ModeEditIcon style={{color:"#367BF5"}} />
                                                            </div>
                                                            <div className="referentiel-modal-header-title">
                                                                  <label>Modifier un élément</label>
                                                            </div>
                                                            
                                                            <div className="referentiel-modal-close" >
                                                                  <IconButton style={{ padding: 0 }} onClick={onClose}>
                                                                        <UserViewCloseIcon />
                                                                  </IconButton>
                                                            </div>
                                                            
                                                      </Stack>
                                                </div>
                                                <Divider />
                                                <form onSubmit={formik.handleSubmit}>
                                                      <div className="referentiel-modal-body">
                                                            <Stack direction="row" spacing={6}>
                                                                  <div style={{ width: '100%' }} >
                                                                        <Stack direction="column" spacing={4}>
                                                                              <div>
                                                                                    <LabelInput title={'Nom:'} name={"nom"} value={formik.values.nom}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.nom && Boolean(formik.errors.nom)}
                                                                                          helperText={formik.touched.nom && formik.errors.nom}
                                                                                          inputBackground={inputBackground}  />  
                                                                              </div>
                                                                              <div>
                                                                                    <LabelMenu title='Groupe animal:' name={"groupe_animaux_id"} value={formik.values.groupe_animaux_id}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.groupe_animaux_id && Boolean(formik.errors.groupe_animaux_id)}
                                                                                          helperText={formik.touched.groupe_animaux_id && formik.errors.groupe_animaux_id}
                                                                                          valuesDict={transformedData} />
                                                                              </div>
                                                                        </Stack>
                                                                  </div>
                                                            </Stack>
                                                      </div>
                                                      <div className="referentiel-modal-footer">
                                                            <div style={{ paddingRight: '34px', paddingTop: '16px' }}>
                                                                  <Stack
                                                                        direction="row-reverse"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        spacing={2}
                                                                  >
                                                                        <Button variant="contained" type="submit" style={{
                                                                              background: '#367BF5',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Modifier</Button>

                                                                        <Button onClick={onClose} style={{
                                                                              color: '#4F566B',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Annuler</Button>

                                                                  </Stack>
                                                            </div>

                                                      </div>
                                                </form>
                                          </Stack>
                                    </div>
                              </div>
                        </Stack>
                  </div>
            </Box>
      )
}