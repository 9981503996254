import React, { useState } from "react";
import { Stack } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import {
  getImputability,
  imputabilityDict,
  idExpositionDict, idSymptomatologieDict, idChronologieDict, idCaracterisationCausaleDict, idDiagnosticDict, idLienExtrinsequeDict
} from "../../../../utils/imputability";
import "../../index.scss";

export default function Imputabilite(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();

  const widthPercentage = "31%";
  const onImputabilityParamChange = (event, name) => {
    handleChange(event);
    let E = name === "E" ? event.target.value : getIn(values, "intoxication.evolution.imputabilite.exposition");
    let S = name === "S" ? event.target.value : getIn(values, "intoxication.evolution.imputabilite.symptomatologie");
    let C = name === "C" ? event.target.value : getIn(values, "intoxication.evolution.imputabilite.chronologie");
    let L = name === "L" ? event.target.value : getIn(
      values,
      "intoxication.evolution.imputabilite.caracterisation_causale"
    );
    let D = name === "D" ? event.target.value : getIn(
      values,
      "intoxication.evolution.imputabilite.diagnostique_differentiel"
    );
    let B = name === "B" ? event.target.value : getIn(
      values,
      "intoxication.evolution.imputabilite.lien_extrinseque"
    );
    let imputability = getImputability(E, S, C, L, D, B);
    setFieldValue(
      "intoxication.evolution.imputabilite.imputabilite",
      imputability
    );
  };

  return (
    <div className="case-imputabilite">
      <div className="case-imputabilite-label">
        <label>
          Imputabilité
        </label>
      </div>
      <div className="case-imputabilite-body">
        <Stack direction="column" spacing={4} style={{ padding: "20px" }}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Exposition"}
                  name={"intoxication.evolution.imputabilite.exposition"}
                  value={

                    getIn(
                      values,
                      "intoxication.evolution.imputabilite.exposition"
                    )

                  }
                  onChange={(event) => onImputabilityParamChange(event, "E")}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.exposition"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.imputabilite.exposition"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.exposition"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.evolution.imputabilite.exposition"
                    )
                  }
                  valuesDict={idExpositionDict}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Symptomatologie"}
                  name={"intoxication.evolution.imputabilite.symptomatologie"}
                  value={

                    getIn(
                      values,
                      "intoxication.evolution.imputabilite.symptomatologie"
                    )

                  }
                  onChange={(event) => onImputabilityParamChange(event, "S")}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.symptomatologie"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.imputabilite.symptomatologie"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.symptomatologie"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.evolution.imputabilite.symptomatologie"
                    )
                  }
                  valuesDict={idSymptomatologieDict}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Chronologie"}
                  name={"intoxication.evolution.imputabilite.chronologie"}
                  value={

                    getIn(
                      values,
                      "intoxication.evolution.imputabilite.chronologie"
                    )

                  }
                  onChange={(event) => onImputabilityParamChange(event, "C")}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.chronologie"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.imputabilite.chronologie"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.chronologie"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.evolution.imputabilite.chronologie"
                    )
                  }
                  valuesDict={idChronologieDict}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Caractérisation causale"}
                  name={
                    "intoxication.evolution.imputabilite.caracterisation_causale"
                  }
                  value={

                    getIn(
                      values,
                      "intoxication.evolution.imputabilite.caracterisation_causale"
                    )

                  }
                  onChange={(event) => onImputabilityParamChange(event, "L")}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.caracterisation_causale"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.imputabilite.caracterisation_causale"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.caracterisation_causale"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.evolution.imputabilite.caracterisation_causale"
                    )
                  }
                  valuesDict={idCaracterisationCausaleDict}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Diagnostic différentiel"}
                  name={
                    "intoxication.evolution.imputabilite.diagnostique_differentiel"
                  }
                  value={

                    getIn(
                      values,
                      "intoxication.evolution.imputabilite.diagnostique_differentiel"
                    )

                  }
                  onChange={(event) => onImputabilityParamChange(event, "D")}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.diagnostique_differentiel"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.imputabilite.diagnostique_differentiel"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.diagnostique_differentiel"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.evolution.imputabilite.diagnostique_differentiel"
                    )
                  }
                  valuesDict={idDiagnosticDict}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Lien extrinsèque"}
                  name={"intoxication.evolution.imputabilite.lien_extrinseque"}
                  value={

                    getIn(
                      values,
                      "intoxication.evolution.imputabilite.lien_extrinseque"
                    )

                  }
                  onChange={(event) => onImputabilityParamChange(event, "B")}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.lien_extrinseque"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.imputabilite.lien_extrinseque"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.lien_extrinseque"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.evolution.imputabilite.lien_extrinseque"
                    )
                  }
                  valuesDict={idLienExtrinsequeDict}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Imputabilité"}
                  name={"intoxication.evolution.imputabilite.imputabilite"}
                  value={getIn(
                    values,
                    "intoxication.evolution.imputabilite.imputabilite"
                  )}
                  onChange={handleChange}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.imputabilite"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.imputabilite.imputabilite"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.imputabilite.imputabilite"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.evolution.imputabilite.imputabilite"
                    )
                  }
                  disabled={true}
                  valuesDict={imputabilityDict}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
