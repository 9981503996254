import React, { useContext } from "react";
import {
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useFormikContext, getIn } from "formik";
import { styled } from "@mui/material/styles";
import "./index.scss";
import ToggleSwitches from "../../../ToggleSwitches";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F0F0F0",
    color: theme.palette.common.white,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#25282B",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const checkBoxSX = {
  color: "#C4C4C4",
  borderRadius: "5px",
  "&.Mui-checked": {
    color: "#6BE158",
  },
};

export default function PTTRRecherche(props) {
  let componentValues = props.values;
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const validated = useContext(ValidatedContext);

  const prefix = "resultat.PTRR.resultats_recherche.";
  return (
    <div className="pttr-recherche-container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell style={{ width: "10%" }}></HeaderTableCell>
              <HeaderTableCell style={{ width: "40%" }}>
                Analyse
              </HeaderTableCell>
              <HeaderTableCell style={{ width: "50%" }}>
                Résultat (vert=positif)
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {componentValues.map((key, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={validated}
                          sx={checkBoxSX}
                          checked={getIn(values, prefix + index + ".checked")}
                          onChange={handleChange}
                          name={prefix + index + ".checked"}
                        />
                      }
                      label=""
                    />
                  </FormGroup>
                </TableCell>
                <TableCell>
                  <label className="pttr-recherche-label">{key.analyse}</label>
                </TableCell>
                <TableCell>
                  <ToggleSwitches
                    checked={getIn(values, prefix + index + ".result")}
                    handleChange={(event) =>
                      setFieldValue(
                        prefix + index + ".result",
                        event.target.checked
                      )
                    }
                    updateable={true}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
