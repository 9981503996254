import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { useGridApiContext, useGridState } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import "./index.scss";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
});

export default function CustomPagination(props) {
  const apiRef = useGridApiContext();
  const [state] = useGridState(apiRef);
  const classes = useStyles();
  return (
    <div className="pagination-rounded-wrapper">
      <Stack spacing={2}>
        <Pagination
          color="primary"
          count={state.pagination.pageCount}
          page={state.pagination.page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          shape="rounded"
        />
      </Stack>
    </div>
  );
}
