import React from "react";
import { Stack } from "@mui/material";
import CaseHeader from "../Common/CaseHeader";
import Scorpion from './Scorpion';

import '../index.scss';



export default function ViewScorpionCase(props){



      return (
            <div className="view-case-common-container">
                  <Stack direction="column" spacing={0}>
                        <CaseHeader  type='scorpion' />
                        <Scorpion />
                  </Stack>
            </div>
      )
}