import React, { useState, useContext, useEffect } from "react";
import {
  Stack,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  FormControl,
  Modal,
} from "@mui/material";
import CustomButton from "../../../Buttons/CustomButton";
import "../../index.scss";
import { useFormikContext, getIn } from "formik";
import NewElementModal from "../../Common/NewElementModal/NewElementModal";
import ProduitMoleculeDosageTable from "../../Common/ProduitMoleculeDosageTable/ProduitMoleculeDosageTable";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";
import { getLaboCategories } from "../../../../services/cases/cases";

export default function GCMS(props) {
  const middlePercentage = "50%";
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const validated = useContext(ValidatedContext);

  const prefix = "resultat.GC.";
  const produits = getIn(values, prefix + "produits_testes");
  const [newProduitModal, setNewProduitModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleProduitNewRowClick = () => {
    setNewProduitModal(true);
  };
  const handleCloseProduitModal = () => {
    setNewProduitModal(false);
  };
  useEffect(() => {
    getLaboCategories("produits_testes").then((data) => {
      setCategories(data.map((cat) => cat.nom));
    });
  }, []);

  const handleOnNewProduit = (value) => {
    let newProduit = {
      checked: false,
      produit: value,
      molecules: [""],
      dosages: [""],
    };
    let newProduits = getIn(values, prefix + "produits_testes");
    if (newProduits === undefined || newProduits === null) {
      newProduits = [];
    }
    newProduits.push(newProduit);
    setFieldValue(prefix + "produits_testes", newProduits);
    setNewProduitModal(false);
  };

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Chromatographie Gazeuse</label>
      </div>
      <div
        className="case-common-container-body"
        style={{ marginRight: "20px" }}
      >
        <Stack direction="column" spacing={4}>
          <div style={{ width: middlePercentage, height: "59px" }}>
            <FormControl
              disabled={validated}
              component="fieldset"
              style={{ height: "100%" }}
            >
              <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                <div className="case-common-container-body-label">
                  <label>
                    Recherche / Dosage <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="case-common-container-body-radio">
                  <RadioGroup
                    row
                    name={prefix + "recherche_dosage"}
                    onChange={(event) =>
                      setFieldValue(
                        prefix + "recherche_dosage",
                        event.target.value
                      )
                    }
                    value={getIn(values, prefix + "recherche_dosage")}
                    error={
                      getIn(touched, prefix + "recherche_dosage") &&
                      Boolean(getIn(errors, prefix + "recherche_dosage"))
                    }
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Recherche"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Dosage"
                    />
                  </RadioGroup>
                </div>
              </Stack>
              <FormHelperText style={{ color: "#d32f2f", marginLeft: "14px" }}>
                {getIn(touched, prefix + "resultat_analyse") &&
                  getIn(errors, prefix + "resultat_analyse")}
              </FormHelperText>
            </FormControl>
          </div>
          {parseInt(getIn(values, prefix + "recherche_dosage")) === 1 && (
            <div style={{ width: middlePercentage, height: "59px" }}>
              <FormControl
                disabled={validated}
                component="fieldset"
                style={{ height: "100%" }}
              >
                <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                  <div className="case-common-container-body-label">
                    <label>Résultat d'analyse </label>
                  </div>
                  <div className="case-common-container-body-radio">
                    <RadioGroup
                      row
                      name={prefix + "resultat_analyse"}
                      onChange={(event) =>
                        setFieldValue(
                          prefix + "resultat_analyse",
                          event.target.value
                        )
                      }
                      value={getIn(values, prefix + "resultat_analyse")}
                      error={
                        getIn(touched, prefix + "resultat_analyse") &&
                        Boolean(getIn(errors, prefix + "resultat_analyse"))
                      }
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Positive"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Négative"
                      />
                    </RadioGroup>
                  </div>
                </Stack>
                <FormHelperText
                  style={{ color: "#d32f2f", marginLeft: "14px" }}
                >
                  {getIn(touched, prefix + "resultat_analyse") &&
                    getIn(errors, prefix + "resultat_analyse")}
                </FormHelperText>
              </FormControl>
            </div>
          )}
          <div>
            <Stack direction="column" spacing={1}>
              <div className="container-middle">
                <Stack direction="row" spacing={0} style={{ width: "100%" }}>
                  <div style={{ width: "50%", alignSelf: "center" }}>
                    <div className="container-table-name">
                      <label>Produits Testés</label>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Stack
                      direction="row-reverse"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0}
                    >
                      <div>
                        <CustomButton
                          type={"add"}
                          onClick={handleProduitNewRowClick}
                          title={"Ajouter un produit"}
                          customBackground={true}
                        />
                      </div>
                    </Stack>
                  </div>
                </Stack>
              </div>
              <div>
                <ProduitMoleculeDosageTable
                  values={produits ?? []}
                  prefix={prefix + "produits_testes."}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
      <Modal open={newProduitModal} onClose={handleCloseProduitModal}>
        <NewElementModal
          onClose={handleCloseProduitModal}
          onNew={handleOnNewProduit}
          categories={categories}
        />
      </Modal>
    </div>
  );
}
