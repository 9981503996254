import React from "react";
import { Stack, TableContainer, Table, TableRow, TableHead, TableBody, Paper, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import './index.scss';
import ProduitMoleculeDosage from "../ProduitMoleculeDosage/ProduitMoleculeDosage";

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F0F0F0',
            color: theme.palette.common.white,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color: '#25282B'
      },
      [`&.${tableCellClasses.body}`]: {
            fontSize: 14,

      },
}));

const checkBoxSX = {
      color: '#C4C4C4',
      borderRadius: '5px',
      '&.Mui-checked': {
            color: '#6BE158',
      }
}

export default function ProduitMoleculeDosageTable(props) {
      let values = props.values;
            
      return (
            <div className="cl-table-container">
                  <TableContainer component={Paper}>
                        <Table >
                              <TableHead>
                                    <TableRow>
                                          <HeaderTableCell style={{ width: '10%' }}></HeaderTableCell>
                                          <HeaderTableCell style={{ width: '20%' }}>Produit</HeaderTableCell>
                                          <HeaderTableCell style={{ width: '30%' }}>Molécule</HeaderTableCell>
                                          <HeaderTableCell style={{ width: '34%' }}>Dosage</HeaderTableCell>
                                          <HeaderTableCell style={{ width: '6%' }}></HeaderTableCell>

                                    </TableRow>
                              </TableHead>
                              <TableBody>
                                    {
                                          values.map((key, index) => (
                                                <ProduitMoleculeDosage values={key} key={index} index={index} prefix={props.prefix} />
                                          ))
                                    }
                              </TableBody>
                        </Table>
                  </TableContainer>
            </div>
      )
}