import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { FastStarIcon } from "../Icons/Icons";
import SideBarButton from "../SideBarButton";
import NotificationList from "./NotificationList";
import {
  getAllNotifications,
  getTotalCases,
  checkNotification
} from "../../services/cases/cases";
import { useHistory } from "react-router-dom";
import { isAllowed } from "../../services/auth";
import { toValidateCasesPermissions, brouillonAccessPermissions } from "../../utils/permissions";

export default function NotificationSideBar(props) {
  // const [historicalCases, setHistoricalCases] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [dates, setDates] = useState([]);
  const [totalCases, setTotalCases] = useState({
    brouillon: 0,
    to_validate: 0,
  });
  const [updateSideBar, setUpdateSideBar] = useState(true);

  const history = useHistory();

  useEffect(() => {
    getTotalCases().then((data) => setTotalCases(data));
    getAllNotifications(30).then((data) => {
      setNotifications(data);
      setDates(
        data.map(
          (notification) =>
            new Date(notification.notification_date.split(" ")[0])
        )
      );
    });
  }, [updateSideBar]);

  const consultCase = (notification) => {
    checkNotification(notification.id);
    setUpdateSideBar(x => !x);
    if (
      notification.notification_type === "MODIFICATION_CAS" || notification.notification_type === "SUPPRESSION_CAS"
    ) {
      history.push({
        pathname: "/main/view-cases",
        state: { declarationId: notification.declaration_id },
        // replace with this when correct id is inserted => state: { declarationId: declarationId },
      });
    }
    if (notification.notification_type === "VALIDATION_CAS") {
      history.push({
        pathname: "/main/validation",
        state: { declarationId: notification.declaration_id },
        // replace with this when correct id is inserted => state: { declarationId: declarationId },
      });
    }
    if (notification.notification_type === "MODIFICATION_REFERENTIEL") {
      history.push({
        pathname: "/main/update-ref",
        state: { category: 0, table: 0 },
        // replace with this when correct id is inserted => state: { declarationId: declarationId },
      });
    }
  };
  return (
    <div className="notification-side-bar-container">
      <Stack direction="column" spacing={0}>
        <div className="notification-side-bar-header">
          <div className="notification-side-bar-notification-icon">
            <FastStarIcon />
          </div>
          <div className="notification-side-bar-notification-label">
            <label>Accès rapide</label>
          </div>
        </div>
        <div className="notification-side-bar-body">
          {isAllowed(toValidateCasesPermissions) && (
            <div className="notification-side-bar-body-elt">
              <SideBarButton type="case" value={totalCases.to_validate} />
            </div>
          )
          }
          {isAllowed(brouillonAccessPermissions) && (
            <div className="notification-side-bar-body-elt">
              <SideBarButton type="brouillon" value={totalCases.brouillon} />
            </div>
          )}
          {/* <div className="notification-side-bar-body-elt">
            <SideBarButton type="historique" value={100} />
          </div> */}
        </div>

        <div className="notification-side-bar-header">
          <div className="notification-side-bar-notification-icon">
            <NotificationsIcon
              color="action"
              style={{ fill: "#000000", transform: "rotate(23deg)" }}
            />
          </div>
          <div className="notification-side-bar-notification-label">
            <label>Notifications</label>
          </div>
        </div>
        <div className="notification-side-bar-body">
          <div className="notification-side-bar-body-list">
            {notifications.map((notification, index) => (
              <NotificationList
                key={index}
                index={index}
                dates={dates}
                notification={notification}
                onConsultCase={consultCase}
              />
            ))}
            <div style={{ marginTop: "10px" }}></div>
          </div>
        </div>
      </Stack >
    </div >
  );
}
