import {
  getSecureAxiosInstance,
  getSecureMultipartAxiosInstance,
} from "../utils/axiosInstances";
import { updateReferentielPermissions } from "../utils/permissions";
import _ from "lodash";
import history from "../utils/history";

const currentUserKey = "TF.current_user";
const currentUserTokenKey = "TF.current_user_token";
const currentUserPermissionsKey = "TF.current_user_permissions";

const login = async (data) => {
  const newData = { email: data.email, password: data.password };
  return getSecureAxiosInstance().post("/user/signin", newData);
};

const onForgotPassword = async (email) => {
  return getSecureAxiosInstance().post("/user/reset-password", email);
};

const editProfile = async (data) => {
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("nom_prenom", data.nom_prenom);
  formData.append("email", data.email);
  formData.append("telephone", data.telephone);
  if (data.current_password) {
    formData.append("current_password", data.current_password);
    formData.append("new_password", data.new_password);
  }
  if (data.avatar) {
    formData.append("file", data.avatar);
  }
  return getSecureMultipartAxiosInstance().patch("/user/edit-profile", formData);
};

const checkToken = async (showMessage) => {
  getSecureAxiosInstance().get("/user/check_token").then((data) => {
    if (data.data === false) {
      showMessage("Prière de s'authentifier à nouveau", { variant: "error" })
      history.push("/login");
    }
  });
}

const getUserFromStore = () => {
  if (currentUserKey in localStorage) {
    return JSON.parse(localStorage.getItem(currentUserKey));
  } else {
    history.push("/login");
  }
};

const getUserPermissionsFromStore = () => {
  if (currentUserPermissionsKey in localStorage) {
    return JSON.parse(localStorage.getItem(currentUserPermissionsKey));
  } else {
    history.push("/login");
  }
};

const updateStorage = (userData) => {
  localStorage.setItem(currentUserKey, JSON.stringify(userData));
  localStorage.setItem(currentUserTokenKey, userData.token);
  localStorage.setItem(currentUserPermissionsKey, JSON.stringify(userData.permissions));
}
const onEditProfileSuccess = (userData) => {
  updateStorage(userData);
};

const onLoginSuccess = (userData) => {
  updateStorage(userData);
  history.push("/main");
};

const isAllowed = (targetPermissions) => {
  const userPermissions = getUserPermissionsFromStore();
  return targetPermissions.reduce(
    (prevPermission, currPermission) =>
      prevPermission || userPermissions.includes(currPermission),
    false,
  );
}

const referentielIsAllowed = (vigilances) => {
  let basicAccess = isAllowed([updateReferentielPermissions]);
  let result = false;
  if (!basicAccess) {
    result = false;
  }
  let user = getUserFromStore();
  if (vigilances[0] === 0) {
    result = user.role_id === 1
  } else if (vigilances[0] === -1) {
    result = user.role_id === 1 || user.role_id === 6;
  } else {
    let intersection = _.intersection(vigilances, user.vigilances);
    result = intersection.length > 0 || user.role_id === 1
  }
  return result;
}

export {
  login,
  onForgotPassword,
  onLoginSuccess,
  getUserFromStore,
  editProfile,
  onEditProfileSuccess,
  getUserPermissionsFromStore,
  isAllowed,
  referentielIsAllowed,
  checkToken
};
