import React from "react";
import { Box } from "@mui/system";
import { Stack, IconButton, Button, Divider, RadioGroup, Radio, FormControlLabel, FormHelperText, FormControl } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useFormik } from 'formik';
import LabelInput from "../../LabelInput/LabelInput";
import CustomLoader from "../../CustomLoader/CustomLoader";
import LabelMenu from "../../LabelMenu/LabelMenu";
import { UserViewCloseIcon, NewUserAddIcon } from "../../Icons/Icons";
import { ProduitMedicamentSchema } from "../../../utils/validationSchemas/referentiel/referentiel";
import { useReferentiels } from "../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../utils/referentielData";
import '../index.scss';


const style = {
      position: 'absolute',
      width: 621,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'

}



export default function ProduitMedicamentModal(props) {
      const { selectedRow, onEdit, onClose } = props;
      const referentielResults = useReferentiels(['dci', 'groupe_anatomique', 'code_atc']);

      const inputBackground = '#FFFFFF';
      const formik = useFormik({
            initialValues: {
                  id: selectedRow?.id,
                  nom_commercial: selectedRow?.nom_commercial,
                  veterinaire: selectedRow?.veterinaire,
                  dci_id: selectedRow?.dci_id,
                  groupe_anatomique_id: selectedRow?.groupe_anatomique_id,
                  code_atc_id: selectedRow?.code_atc_id,
            },
            validationSchema: ProduitMedicamentSchema,
            onSubmit: (values) => {
                  onEdit(values);
            },
      });
      const isLoading = referentielResults.some(result => result.isLoading)
      if (isLoading)
            return (<CustomLoader isLoading={isLoading} />)

      const dci = flattenIdNomDict(referentielResults[0].data);
      const groupesAnatomiques = flattenIdNomDict(referentielResults[1].data);
      const codes_atc = flattenIdNomDict(referentielResults[2].data);
      return (
            <Box sx={{ ...style }}>
                  <div>
                        <Stack direction="column" spacing={0} >
                              <div>
                                    <div className="referentiel-modal-container">
                                          <Stack direction="column" spacing={0}>
                                                <div className="referentiel-modal-header">
                                                      <Stack direction="row" spacing={0} style={{ alignItems: 'center', width: '100%' }}>
                                                            <div>
                                                                  <ModeEditIcon style={{ color: "#367BF5" }} />
                                                            </div>
                                                            <div className="referentiel-modal-header-title">
                                                                  <label>Modifier un élément</label>
                                                            </div>

                                                            <div className="referentiel-modal-close" >
                                                                  <IconButton style={{ padding: 0 }} onClick={onClose}>
                                                                        <UserViewCloseIcon />
                                                                  </IconButton>
                                                            </div>

                                                      </Stack>
                                                </div>
                                                <Divider />
                                                <form onSubmit={formik.handleSubmit}>
                                                      <div className="referentiel-modal-body">
                                                            <Stack direction="row" spacing={6}>
                                                                  <div style={{ width: '100%' }} >
                                                                        <Stack direction="column" spacing={4}>
                                                                              <div>
                                                                                    <LabelInput title={'Nom commercial:'} name={"nom_commercial"} value={formik.values.nom_commercial}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.nom_commercial && Boolean(formik.errors.nom_commercial)}
                                                                                          helperText={formik.touched.nom_commercial && formik.errors.nom_commercial}
                                                                                          inputBackground={inputBackground} />
                                                                              </div>
                                                                              <div>
                                                                                    <FormControl component="fieldset" style={{ height: '100%' }}>
                                                                                          <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                                                                <div className="referentiel-modal-label">
                                                                                                      <label >Véterinaire:</label>
                                                                                                </div>
                                                                                                <div className="referentiel-modal-radio">
                                                                                                      <RadioGroup row name={"veterinaire"}
                                                                                                            onChange={formik.handleChange}
                                                                                                            value={formik.values.veterinaire}
                                                                                                            error={formik.touched.veterinaire && Boolean(formik.errors.veterinaire)}>
                                                                                                            <FormControlLabel value={1} control={<Radio />} label="Oui" />
                                                                                                            <FormControlLabel value={0} control={<Radio />} label="Non" />
                                                                                                      </RadioGroup>
                                                                                                </div>
                                                                                          </Stack>
                                                                                          <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{formik.touched.veterinaire && formik.errors.veterinaire}</FormHelperText>
                                                                                    </FormControl>
                                                                              </div>
                                                                              <div>
                                                                                    <LabelMenu title='DCI:' name={"dci_id"} value={formik.values.dci_id}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.dci_id && Boolean(formik.errors.dci_id)}
                                                                                          helperText={formik.touched.dci_id && formik.errors.dci_id}
                                                                                          valuesDict={dci} />
                                                                              </div>

                                                                              <div>
                                                                                    <LabelMenu title='Groupe Anatomique:' name={"groupe_anatomique_id"} value={formik.values.groupe_anatomique_id}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.groupe_anatomique_id && Boolean(formik.errors.groupe_anatomique_id)}
                                                                                          helperText={formik.touched.groupe_anatomique_id && formik.errors.groupe_anatomique_id}
                                                                                          valuesDict={groupesAnatomiques} />
                                                                              </div>
                                                                              <div>
                                                                                    <LabelMenu title='Code ATC:' name={"code_atc_id"} value={formik.values.code_atc_id}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.code_atc_id && Boolean(formik.errors.code_atc_id)}
                                                                                          helperText={formik.touched.code_atc_id && formik.errors.code_atc_id}
                                                                                          valuesDict={codes_atc} />
                                                                              </div>
                                                                        </Stack>
                                                                  </div>
                                                            </Stack>
                                                      </div>
                                                      <div className="referentiel-modal-footer">
                                                            <div style={{ paddingRight: '34px', paddingTop: '16px' }}>
                                                                  <Stack
                                                                        direction="row-reverse"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        spacing={2}
                                                                  >
                                                                        <Button variant="contained" type="submit" style={{
                                                                              background: '#367BF5',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Modifier</Button>

                                                                        <Button onClick={onClose} style={{
                                                                              color: '#4F566B',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Annuler</Button>

                                                                  </Stack>
                                                            </div>

                                                      </div>
                                                </form>
                                          </Stack>
                                    </div>
                              </div>
                        </Stack>
                  </div>
            </Box>
      )
}