import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import HistoryDataGrid from "../../components/Grids/HistoryDataGrid/HistoryDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { debounce } from "lodash";
import { getToxiCases } from "../../services/cases/cases";
import { toxicoColumnNames, toxicoFieldNames, laboColumnNames, laboFieldNames } from "../../utils/dataGrid";
import "./index.scss";
import { useSnackbar } from "notistack";

export default function HistoryCases(props) {
  const { activeTab } = props;
  const [cases, setCases] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const { enqueueSnackbar: showMessage } = useSnackbar();



  useEffect(() => {
    setLoading(true);
    getToxiCases()
      .then((data) => {
        setCases(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error.toString(), { variant: "error" });
      });
  }, []);

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setSearchText(e.target.value);
  };

  return (
    <div className="history-cases-container">
      <Stack direction="column" spacing={0} style={{ width: "100%" }}>
        <div className="history-cases-research">
          <Stack direction="row-reverse" spacing={0} style={{ width: "100%" }}>
            <OutlinedInput
              placeholder="Rechercher un cas…"
              onChange={debounce(handleSearch, 1000)}
              endAdornment={<SearchIcon htmlColor="#aaaaaa" />}
            />
          </Stack>
        </div>
        <div className="history-cases-datagrid">
          <HistoryDataGrid
            columnNames={
              activeTab.toLowerCase() === "toxicovigilance"
                ? toxicoColumnNames
                : laboColumnNames
            }
            fieldNames={
              activeTab.toLowerCase() === "toxicovigilance" ? toxicoFieldNames : laboFieldNames
            }
            cases={cases}
            loading={loading}
            filter={searchText}
            onSetCases={setCases}
          />
        </div>
      </Stack>
    </div>
  );
}
