import React from "react";
import { Stack } from "@mui/material";
import Intoxication from "../../../components/Case/Labo/Intoxication/Intoxication";
import Symptomologie from "../../../components/Case/Common/Symptomologie/Symptomologie";
import Evolution from "../../../components/Case/Common/Evolution/Evolution";

import '../index.scss';



export default function AddLaboIntoxication(props){

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={4}>
                        <Intoxication />
                        <Symptomologie />
                        <Evolution />
                  </Stack>
            </div>

      )
}