import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Header from "../../components/Header/Header";
import LeftNavBar from "../../components/LeftNavBar/LeftNavBar";
import NotificationSideBar from "../../components/NotificationSideBar/NotificationSideBar";
import HistoryCasesModule from "../../modules/HistoryCases/HistoryCasesModule";
import BrouillonCasesModule from "../../modules/BrouillonCases/BrouillonCasesModule";
import ValidateCasesModule from "../../modules/ValidateCases/ValidateCasesModule";
import UserManagementModule from "../../modules/UserManagementModule/UserManagementModule";
import AddCases from "../../modules/AddCases/AddCases";
import ViewCasesModule from "../../modules/ViewCases/ViewCasesModule";
import UserProfilePage from "../../modules/UserProfile/UserProfilePage";
import ReferentielManagementModule from "../../modules/ReferentielManagement/ReferentielManagementModule";
import ExtractDataModule from "../../modules/ExtractData/ExtractDataModule";
import DashboardModule from "../../modules/Dashboards/DashboardModule";
import history from "../../utils/history";
import {
  addCasesPermissions,
  viewCasesPermissions,
  extractDataPermissions,
  updateReferentielPermissions,
  visualizeDashboardPermissions,
  userManagementPermissions
} from "../../utils/permissions";
import { useSnackbar } from "notistack";
import { checkToken } from '../../services/auth';
import "./index.scss";

export default function Main(props) {
  const componentName = props.match.params.component;
  const { enqueueSnackbar: showMessage } = useSnackbar();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [editMode, setEditMode] = useState(false);
  const [userChanged, setUserChanged] = useState(false);


  const categories = [
    "add-case",
    "view-case",
    "update-ref",
    "extract-data",
    "dashboard",
    "permissions",
  ];
  const paths = [
    "add-cases",
    "view-cases",
    "update-ref",
    "extract-data",
    "dashboard",
    "user-management",
  ];

  const permissions = [
    addCasesPermissions,
    viewCasesPermissions,
    updateReferentielPermissions,
    extractDataPermissions,
    visualizeDashboardPermissions,
    userManagementPermissions
  ]
  const handleLeftNavBarClick = (index) => {
    setSelectedIndex(index);
    history.push("/main/" + paths[index]);
  };

  const onUserChange = () => {
    setUserChanged(!userChanged);
  }

  const updateSelectedIndex = (index) => {
    if (index !== selectedIndex) {
      setSelectedIndex(index);
    }
  };
  useEffect(() => {
    console.log(editMode);

  }, [editMode]);
  checkToken(showMessage);
  let component = null;
  switch (componentName) {
    case "historic":
      updateSelectedIndex(-1);
      component = <HistoryCasesModule />;
      break;
    case "brouillon":
      updateSelectedIndex(-1);
      component = <BrouillonCasesModule onEditMode={setEditMode} />;
      break;
    case "validation":
      updateSelectedIndex(-1);
      component = <ValidateCasesModule onEditMode={setEditMode} />;
      break;
    case "add-cases":
      editMode ? updateSelectedIndex(-1) : updateSelectedIndex(0);
      component = <AddCases onEditMode={setEditMode} editMode={editMode} />;
      break;
    case "view-cases":
      updateSelectedIndex(1);
      component = <ViewCasesModule onEditMode={setEditMode} />;
      break;
    case "update-ref":
      updateSelectedIndex(2);
      component = <ReferentielManagementModule />;
      break;
    case "extract-data":
      updateSelectedIndex(3);
      component = <ExtractDataModule />;
      break;
    case "dashboard":
      updateSelectedIndex(4);
      component = <DashboardModule />;
      break;
    case "user-management":
      updateSelectedIndex(5);
      component = <UserManagementModule />;
      break;
    case "user-profile":
      updateSelectedIndex(-1);
      component = <UserProfilePage onUserChange={onUserChange} />;
      break;
    default:
      break;
  }

  return (
    <div className="main-container">
      <Stack direction="column" spacing={0}>
        <div>
          <Header userChanged={userChanged} />
        </div>
        <div>
          <Stack direction="row" spacing={0} style={{ height: "100%" }}>
            <div className="main-container-left-side">
              <div className="main-container-left-side-menu">
                <LeftNavBar
                  categories={categories}
                  permissions={permissions}
                  onClick={handleLeftNavBarClick}
                  selectedIndex={selectedIndex}
                />
              </div>
            </div>
            <div className="main-container-middle-side">{component}</div>
            <div>
              <Stack direction="row-reverse" spacing={0}>
                <div className="main-container-right-side-menu">
                  <NotificationSideBar />
                </div>
              </Stack>
            </div>
          </Stack>
        </div>
      </Stack>
    </div>
  );
}
