const colors = ["#D3EAFF", "#EAEAF6", "#1984c5", "#22a7f0", "#63bff0", "#a7d5ed", "#e2e2e2", "#e1a692", "#de6e56", "#e14b31", "#c23728","#54bebe", "#76c8c8", "#98d1d1", "#badbdb", "#dedad2", "#e4bcad", "#df979e", "#d7658b", "#c80064"]

const addColors = (datasets) => {
    let colorsLength = colors.length;
    for(let i = 0; i < datasets.length; i++ ){
        datasets[i]['backgroundColor'] = colors[i%colorsLength];
    }
}


export {addColors};