import React from "react";
import { Stack } from "@mui/material";
import {
      Chart as ChartJS,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import './index.scss';
import { addColors } from "../../utils/dashboard";


ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
);

export default function BarChart(props) {
      const { data, title, stacked } = props;
      addColors(data.datasets);
      
      const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: false,
              },
            },
          };
      if (stacked === true){
            options.scales = {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true
                  }
                }
      }
      return (
            <div className="graph-container">
                  <Stack direction='column' spacing={0}>
                        <div className="graph-title">
                              <label>{title}</label>
                        </div>
                        {data && <div className="graph-body">
                              <Bar
                                    data={data}
                                    width={260}
                                    options={options}
                              />
                        </div>
                        }
                  </Stack>
            </div>
      )

}