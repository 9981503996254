import React from "react";
import { Stack } from "@mui/material";
import ChromatographieLiquide from "../../../components/Case/Labo/ChromatographieLiquide/ChromatographieLiquide";
import PTTR from "../../../components/Case/Labo/PTTR/PTTR";
import GCMS from "../../../components/Case/Labo/GCMS/GCMS";
import '../index.scss';
import MetauxLourds from "../../../components/Case/Labo/MetauxLourds/MetauxLourds";



export default function AddLaboResultat(props) {

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={4}>
                        <ChromatographieLiquide />
                        <GCMS />
                        <PTTR />
                        <MetauxLourds />
                  </Stack>
            </div>

      )
}