import React from "react";
import DashKPI from "./DashKPI";
import { Stack } from "@mui/material";
import BarChart from "../Graphs/BarChart";
import CaseEvolution from "../Graphs/CaseEvolution";
import './index.scss';
import RemplissageFiche from "../Graphs/RemplissageFiche";
import ExhaustiviteDonnee from "../Graphs/ExhaustiviteDonnee";
import CaseByTrancheAge from "../Graphs/CaseByTrancheAge";

export default function DashKPIS(props) {

      const { data } = props;
      return (
            <div className="dash-kpis-container" id="dash-kpis">
                  <div className="dash-kpis-body">
                        <Stack direction="column" spacing={1}>
                              <div className="dash-kpis-elt">
                                    <Stack direction="row" spacing={4}>
                                          <DashKPI type={'cases'} title='# Cas' value={data.data.KPI.declarations_count} />
                                          <DashKPI type={"blessed-person"} title='% Qualité' value={data.data.KPI.progress_average * 100} />
                                          <DashKPI type={"person-health"} title='# Morts' value={data.data.KPI.deaths_count} />
                                          <DashKPI type={"drugs"} title='% Ratio Sexe' value={data.data.KPI.sex_ratio * 100} />
                                    </Stack>
                              </div>
                              <div className="dash-kpis-elt">
                                    <BarChart data={data.data.declarations_by_type_rapport_graph} title={"Déclarations par type de rapport"} stacked={false} />
                              </div>
                              <div className="dash-kpis-elt">
                                    <BarChart data={data.data.declarations_by_sexe_graph} title={"Déclarations par sexe"} stacked={false} />
                              </div>
                              <div className="dash-kpis-elt">
                                    <BarChart data={data.data.declarations_by_groupe_age_graph} title={"Déclarations par groupe d'âge"} stacked={true} />
                              </div>
                              <div className="dash-kpis-elt">
                                    <BarChart data={data.data.declarations_by_toxique_graph} title={"Déclarations par toxique"} stacked={true} />
                              </div>
                              <div className="dash-kpis-elt">
                                    <BarChart data={data.data.declarations_by_region_graph} title={"Déclarations par région"} stacked={true} />
                              </div>
                        </Stack>

                  </div>
            </div>
      )
}