const CREATION_CAS_TOXICO = 1;
const MODIFICATION_CAS_TOXICO_BROUILLON = 2;
const SUPPRESSION_CAS_TOXICO_BROUILLON = 3;
const ENVOI_CAS_TOXICO_POUR_VALIDATION = 4;
const VALIDATION_CAS_TOXICO = 5;
const MODIFICATION_CAS_TOXICO_VALIDE = 6;
const SUPPRESSION_CAS_TOXICO_VALIDE = 7;
const CONSULTATION_CAS_TOXICO_VALIDE = 8;
const TELECHARGEMENT_RAPPORT_CAS_TOXICO = 9;
const GENERATION_RAPPORT_CAS_TOXICO = 10;
const TELECHARGEMENT_CAS_TOXICO = 11;
const CREATION_CAS_LABO = 12;
const MODIFICATION_CAS_LABO_BROUILLON = 13;
const SUPPRESSION_CAS_LABO_BROUILLON = 14;
const ENVOI_CAS_LABO_POUR_VALIDATION = 15;
const VALIDATION_CAS_LABO = 16;
const MODIFICATION_CAS_LABO_VALIDE = 17;
const SUPPRESSION_CAS_LABO_VALIDE = 18;
const CONSULTATION_CAS_LABO_VALIDE = 19;
const GENERATION_RAPPORT_CAS_LABO = 20;
const TELECHARGEMENT_CAS_LABO = 21;
const GESTION_REFERENTIEL = 22;
const GESTION_UTILISATEURS = 23;

const addCasesPermissions = [CREATION_CAS_TOXICO, CREATION_CAS_LABO, MODIFICATION_CAS_TOXICO_BROUILLON, MODIFICATION_CAS_LABO_BROUILLON, SUPPRESSION_CAS_TOXICO_BROUILLON, SUPPRESSION_CAS_LABO_BROUILLON, ENVOI_CAS_TOXICO_POUR_VALIDATION, ENVOI_CAS_LABO_POUR_VALIDATION];
const viewCasesPermissions = [VALIDATION_CAS_TOXICO, VALIDATION_CAS_LABO, MODIFICATION_CAS_TOXICO_VALIDE, MODIFICATION_CAS_LABO_VALIDE, SUPPRESSION_CAS_TOXICO_VALIDE, SUPPRESSION_CAS_LABO_VALIDE, CONSULTATION_CAS_TOXICO_VALIDE, CONSULTATION_CAS_LABO_VALIDE];
const viewToxiCasesPermissions = [VALIDATION_CAS_TOXICO, MODIFICATION_CAS_TOXICO_VALIDE, SUPPRESSION_CAS_TOXICO_VALIDE, CONSULTATION_CAS_TOXICO_VALIDE];
const viewLaboCasesPermissions = [VALIDATION_CAS_LABO, MODIFICATION_CAS_LABO_VALIDE, SUPPRESSION_CAS_LABO_VALIDE, CONSULTATION_CAS_LABO_VALIDE];
const extractDataPermissions = [TELECHARGEMENT_CAS_TOXICO, TELECHARGEMENT_CAS_LABO];
const updateReferentielPermissions = [GESTION_REFERENTIEL];
const visualizeDashboardPermissions = [TELECHARGEMENT_RAPPORT_CAS_TOXICO, GENERATION_RAPPORT_CAS_TOXICO, GENERATION_RAPPORT_CAS_LABO];
const userManagementPermissions = [GESTION_UTILISATEURS];
const toValidateCasesPermissions = [VALIDATION_CAS_TOXICO, VALIDATION_CAS_LABO];
const brouillonAccessPermissions = [MODIFICATION_CAS_TOXICO_BROUILLON, SUPPRESSION_CAS_TOXICO_BROUILLON, ENVOI_CAS_TOXICO_POUR_VALIDATION, MODIFICATION_CAS_LABO_BROUILLON, SUPPRESSION_CAS_LABO_BROUILLON, ENVOI_CAS_LABO_POUR_VALIDATION]

export {
    addCasesPermissions,
    viewCasesPermissions,
    extractDataPermissions,
    updateReferentielPermissions,
    visualizeDashboardPermissions,
    userManagementPermissions,
    toValidateCasesPermissions,
    brouillonAccessPermissions,
    viewToxiCasesPermissions,
    viewLaboCasesPermissions
}