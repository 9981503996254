import React, { useState } from "react";
// import { signIn } from "next-auth/client"
import {
  // useTheme,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import Image from "next/image";
import CustomLoader from "../CustomLoader/CustomLoader";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
// import history from '../../utils/history';
import { login, onForgotPassword, onLoginSuccess } from "../../services/auth";
import "./index.scss";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email("invalid e-mail")
    .max(255)
    .required("required"),
  password: Yup.string().nullable().required(),
});
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email("invalid e-mail")
    .max(255)
    .required("required"),
});

const Login = ({ csrfToken }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const { enqueueSnackbar: showMessage } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: forgotPassword ? forgotPasswordSchema : loginSchema,
    onSubmit: ({ email, password }) => {
      // const { callbackUrl } = history.query
      const options = {
        redirect: false,
        // callbackUrl: Array.isArray(callbackUrl) ? undefined : callbackUrl,
        email,
        password,
        csrfToken,
      };
      if (forgotPassword) {
        setIsLoading(true);
        onForgotPassword({ email })
          .then((res) => {
            setIsLoading(false);
            showMessage("Email sent", { variant: "success" })
          })
          .catch(() =>
            showMessage("adresse email inconnue. Veuillez réessayer", {
              variant: "error",
            })
          );
      } else {
        setIsLoading(true);
        login({ email, password })
          .then((res) => {
            setIsLoading(false);
            onLoginSuccess(res.data)
          })
          .then((res) =>
            showMessage("Connexion réussie", { variant: "success" })
          )
          .catch(() =>
            showMessage("Accès refusé. Veuillez réessayer", {
              variant: "error",
            })
          );
      }
    },
  });
  if (isLoading) return <CustomLoader isLoading={isLoading} />;
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          paddingTop: "20px",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          display: "grid",
          gap: 3,
          padding: 5,
          borderRadius: "1rem",
          background: "rgb(255 255 255 / 65%)",
          // boxShadow: theme.shadows[20]
        }}
      >
        <img
          src="/assets/imgs/login_left_side.png"
          width="320"
          height="380"
          alt="toxiflow_logo"
        />
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <h3 className="login-label">
            {forgotPassword
              ? "Réinitialisez votre mot de passe"
              : "Authentification"}
          </h3>
        </Box>
        {/* <form method="post" action="/api/auth/callback/credentials"> */}
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControl
            error={formik.touched.email && !!formik.errors.email}
            sx={{ m: 1 }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type={"email"}
              size="medium"
              {...formik.getFieldProps("email")}
              label="email"
              aria-describedby="component-error-email"
            />
            <FormHelperText id="component-error-email">
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""}
            </FormHelperText>
          </FormControl>
          {!forgotPassword && (
            <FormControl
              error={formik.touched.password && !!formik.errors.password}
              sx={{ m: 1 }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Mot de passe
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                size="medium"
                {...formik.getFieldProps("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Mot de passe"
                aria-describedby="component-error-password"
              />
              <FormHelperText id="component-error-passord">
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""}
              </FormHelperText>
            </FormControl>
          )}

          <Button
            type="button"
            onClick={() => {
              formik.submitForm();
            }}
            variant="contained"
          >
            {forgotPassword ? "Envoyer un e-mail de réinitialisation" : "Login"}
          </Button>
          {!forgotPassword && (
            <span
              onClick={() => setForgotPassword(true)}
              className="forgot-password"
            >
              mot de passe oublié ?
            </span>
          )}
          {forgotPassword && (
            <span
              onClick={() => setForgotPassword(false)}
              className="forgot-password"
            >
              Retour à la page de login
            </span>
          )}
        </Box>
      </Box>
      <Typography padding={2} style={{ fontSize: "14px", textAlign: "center" }}>
        NowEdge &#169; {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};

export default Login;
