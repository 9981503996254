import React from "react";
import { Stack } from "@mui/material";
import CaseHeader from "../Common/CaseHeader";
import Prescripteur from "./Prescripteur";
import Patient from "./Patient";
import Toxique from "../Common/Toxique";
import Intoxication from "./Intoxication";
import Echantillon from "./Echantillon";
import Resultat from "./Resultat";
import Analyste from "./Analyste";
import "../index.scss";

export default function ViewLaboCase(props) {
  return (
    <div id="apercu" className="view-case-common-container">
      <Stack direction="column" spacing={0}>
        <CaseHeader type="laboratoire" />
        <Prescripteur />
        <Patient />
        <Toxique />
        <Intoxication />
        <Echantillon />
        <Resultat />
        <Analyste />
      </Stack>
    </div>
  );
}
