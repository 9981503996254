import React, { useContext } from "react";
import { Stack, Divider } from "@mui/material";
import ExtractLaboMenu from "../../components/ExtractDataMenus/ExtractLaboMenu";
import ExtractionChoixDates from "../../components/ExtractionChoixDates/ExtractionChoixDates";
import { ExtractDataContext } from "../../contextes/ExtractDataContext";
import "./index.scss";

export default function ExtractLaboData(props) {
  const {
    categories,
    setCategories,
    setStartDate,
    setEndDate,
    handleExtractClick,
    isLoading,
  } = useContext(ExtractDataContext);

  const handleLaboClick = (laboType) => {
    let newLaboGroups = [...categories];
    const index = newLaboGroups.indexOf(laboType);
    if (index > -1) {
      newLaboGroups.splice(index, 1);
    } else {
      newLaboGroups.push(laboType);
    }
    setCategories(newLaboGroups);
  };

  const onStartDateChange = (name, newValue) => {
    setStartDate(newValue);
  };

  const onEndDateChange = (name, newValue) => {
    setEndDate(newValue);
  };

  return (
    <div className="extract-data-container-root">
      <div className="extract-data-container">
        <div className="extract-data-main">
          <Stack direction="row" spacing={0}>
            <div className="extract-data-menu">
              <ExtractLaboMenu onClick={handleLaboClick} />
            </div>
            <div className="extract-data-divider">
              <Divider orientation="vertical" flexItem />
            </div>
            <div className="extract-data-menu">
              <ExtractionChoixDates
                onClick={() => handleExtractClick("labo")}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
                isLoading={isLoading}
              />
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
}
