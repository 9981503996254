
const expositionDict = {
      '-': '-',
      'E0': 'E0',
      'E1': 'E1',
      'E2': 'E2'
}

const expositionIdDict = {
      '-': 1,
      'E0': 2,
      'E1': 3,
      'E2': 4
}

const idExpositionDict = {
      1: '-',
      2: 'E0',
      3: 'E1',
      4: 'E2'
}

const symptomatologieDict = {
      '-': '-',
      'S0': 'S0',
      'S1': 'S1'
}

const symptomatologieIdDict = {
      '-': 1,
      'S0': 2,
      'S1': 3
}

const idSymptomatologieDict = {
      1: '-',
      2: 'S0',
      3: 'S1'
}

const chronologieDict = {
      '-': '-',
      'C0': 'C0',
      'C1': 'C1',
      'C2': 'C2'
}

const chronologieIdDict = {
      '-': 1,
      'C0': 2,
      'C1': 3,
      'C2': 4
}

const idChronologieDict = {
      1: '-',
      2: 'C0',
      3: 'C1',
      4: 'C2'
}

const caracterisationCausaleDict = {
      '-': '-',
      'L0': 'L0',
      'L1': 'L1',
      'L2': 'L2'
}

const caracterisationCausaleIdDict = {
      '-': 1,
      'L0': 2,
      'L1': 3,
      'L2': 4
}

const idCaracterisationCausaleDict = {
      1: '-',
      2: 'L0',
      3: 'L1',
      4: 'L2'
}


const diagnosticDict = {
      '-': '-',
      'D0': 'D0',
      'D1': 'D1',
      'D2': 'D2'
}

const diagnosticIdDict = {
      '-': 1,
      'D0': 2,
      'D1': 3,
      'D2': 4
}

const idDiagnosticDict = {
      1: '-',
      2: 'D0',
      3: 'D1',
      4: 'D2'
}

const lienExtrinsequeDict = {
      '-': '-',
      'B0': 'B0',
      'B1': 'B1',
      'B2': 'B2'
}

const lienExtrinsequeIdDict = {
      '-': 1,
      'B0': 2,
      'B1': 3,
      'B2': 4
}

const idLienExtrinsequeDict = {
      1: '-',
      2: 'B0',
      3: 'B1',
      4: 'B2'
}

const imputabilityDict = {
      1: 'Très probable',
      2: 'Probable',
      3: 'Possible',
      4: 'Non exclu',
      5: 'Nulle',
      6: 'Non applicable',
      7: 'Inconnu'
}

const imputabilityFunctionDict = {
      'E1S1C1L1D2B2': 1,
      'E1S1C1L2D2B2': 1,
      'E1S1C2L1D2B2': 1,
      'E1S1C2L2D1B2': 1,
      'E1S1C2L2D2B1': 1,
      'E1S1C2L2D2B2': 1,
      'E2S1C1L1D2B2': 1,
      'E2S1C1L2D1B1': 1,
      'E2S1C1L2D1B2': 1,
      'E2S1C1L2D2B2': 1,
      'E2S1C2L1D2B2': 1,
      'E2S1C2L2D1B1': 1,
      'E2S1C2L2D1B2': 1,
      'E2S1C2L2D2B1': 1,
      'E2S1C2L2D2B2': 1,
      'E2S1C1L2D2B1': 1,
      'E1S1C1L1D2B1': 2,
      'E1S1C1L2D1B2': 2,
      'E1S1C1L2D2B0': 2,
      'E1S1C1L2D2B1': 2,
      'E1S1C2L1D1B2': 2,
      'E1S1C2L1D2B0': 2,
      'E1S1C2L1D2B1': 2,
      'E1S1C2L2D1B1': 2,
      'E1S1C2L2D2B0': 2,
      'E2S1C1L1D1B2': 2,
      'E2S1C1L1D2B1': 2,
      'E2S1C1L2D1B0': 2,
      'E2S1C1L2D2B0': 2,
      'E2S1C2L1D1B2': 2,
      'E2S1C2L1D2B1': 2,
      'E2S1C2L2D1B0': 2,
      'E2S1C2L2D2B0': 2,
      'E1S1C1L1D1B1': 3,
      'E1S1C1L1D1B2': 3,
      'E1S1C1L1D2B0': 3,
      'E1S1C1L2D1B0': 3,
      'E1S1C1L2D1B1': 3,
      'E1S1C2L1D1B1': 3,
      'E1S1C2L2D1B0': 3,
      'E2S1C1L1D1B1': 3,
      'E2S1C1L1D2B0': 3,
      'E2S1C2L1D1B1': 3,
      'E2S1C2L1D2B0': 3,
      'E1S1C1L1D1B0': 4,
      'E1S1C2L1D1B0': 4,
      'E2S1C1L1D1B0': 4,
      'E2S1C2L1D1B0': 4,
      '-S1-L2D0-': 4,
      'E0-----': 5,
      '--C0---': 5,
      '---L0--': 5,
      '----D0-': 5,
      '-S0----': 6,
      '------': 7
}

const getExpositionId = (exposition) => {
      return expositionIdDict[exposition]
}

const getSymptomatologieId = (symptomatologie) => {
      return symptomatologieIdDict[symptomatologie]
}

const getChronologieId = (chronologie) => {
      return chronologieIdDict[chronologie]
}

const getCaracterisationCausaleId = (caracterisationCausale) => {
      return caracterisationCausaleIdDict[caracterisationCausale]
}

const getDiagnosticId = (diagnostic) => {
      return diagnosticIdDict[diagnostic]
}

const getLienExtrinsequeId = (lienExtrinseque) => {
      return lienExtrinsequeIdDict[lienExtrinseque]
}



const getImputability = (E, S, C, L, D, B) => {
      let imputabilitySymbol = [idExpositionDict[E], idSymptomatologieDict[S], idChronologieDict[C], idCaracterisationCausaleDict[L], idDiagnosticDict[D], idLienExtrinsequeDict[B]].join('');
      if (imputabilitySymbol in imputabilityFunctionDict)
            return imputabilityFunctionDict[imputabilitySymbol];
      else
            return 7;
}



export { idExpositionDict, idSymptomatologieDict, idChronologieDict, idCaracterisationCausaleDict, idDiagnosticDict, idLienExtrinsequeDict, getSymptomatologieId, getChronologieId, getExpositionId, getLienExtrinsequeId, getDiagnosticId, getCaracterisationCausaleId, getImputability, imputabilityDict, expositionDict, symptomatologieDict, chronologieDict, caracterisationCausaleDict, diagnosticDict, lienExtrinsequeDict }