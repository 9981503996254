import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";

import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Dossier(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  let dossier = selectedCase ? selectedCase : values.prescripteur.dossier;

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Dossier</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Type de dossier"
                  value={parseInt(dossier.type_dossier) === 1 ? "STP" : "Toxico"}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="N° de dossier"
                  value={dossier.numero_dossier}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Date saisie" value={dossier.date_saisie} />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Date réception"
                  value={dossier.date_reception}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Heure réception"
                  value={dossier.heure_reception}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
