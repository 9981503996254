import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";

import left_logo from "../../../assets/imgs/logos.svg";
import right_logo from "../../../assets/imgs/toxiflow.svg";
import "../index.scss";
import { CaseContext } from "../../../components/Modal/PreviewCaseModal";

export default function CaseHeader(props) {
  const { type } = props;

  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  let caseInfo;

  let title = "";
  switch (type.toLowerCase()) {
    case "toxicovigilance":
      caseInfo = selectedCase
        ? selectedCase.declarant?.rapport
        : values.declarant?.rapport;
      title = " de toxicovigilance";
      break;
    case "laboratoire":
      caseInfo = selectedCase
        ? selectedCase.prescripteur?.dossier
        : values.prescripteur?.dossier;
      title = " de laboratoire";
      break;
    case "scorpion":
      title = " de scorpion";
      break;
    default:
      break;
  }

  let date = caseInfo && new Date(caseInfo.date_saisie).toLocaleDateString();

  return (
    <div className="view-case-common-container">
      <div className="view-case-common-header">
        <Stack direction="column" spacing={2}>
          <div className="view-case-common-header-logo">
            <Stack direction="row" spacing={0}>
              <img src={left_logo} alt="" />
              <Stack
                direction="row-reverse"
                spacing={0}
                style={{ width: "100%" }}
              >
                <img src={right_logo} alt="" />
              </Stack>
            </Stack>
          </div>
          {caseInfo && (
            <div className="view-case-common-header-body">
              <Stack direction="column" spacing={3}>
                <div className="view-case-common-header-body-title">
                  <label>Rapport de cas {title}</label>
                </div>
                <div className="view-case-common-header-body-subtitle">
                  <label>Numéro du cas : </label>

                  <label className="view-case-common-header-body-elt">
                    {caseInfo.numero_dossier}
                  </label>
                </div>
                <div className="view-case-common-header-body-subtitle">
                  <label>Date : </label>

                  <label className="view-case-common-header-body-elt">
                    {date}
                  </label>
                </div>
              </Stack>
            </div>
          )}
        </Stack>
      </div>
    </div>
  );
}
