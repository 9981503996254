import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import CustomButton from '../../Buttons/CustomButton';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { ThemeProvider, Modal, Snackbar, Alert } from "@mui/material";
import gridTheme from "../GridTheme";
import CustomLoader from "../../CustomLoader/CustomLoader";
import CustomPagination from '../../Pagination';
import PopupDelRefCas from "../../Popups/PopupDelRefCas/PopupDelRefCas";
import { DataGridDeleteIcon, DataGridEditIcon } from "../../Icons/DataGridIcons";
import { getTableHeader, getFieldNames, getFieldHeaderName, getReferentielComponents, getReferentielDependencies, referentielVigilances } from "../../../utils/referentielData";
import { createDataGridRows } from "../../../utils/dataGrid";
import { referentielIsAllowed } from "../../../services/auth";
import { useReferentiel, addReferentielElement, editReferentielElement, deleteReferentielElement } from "../../../services/referentiels/referentiels";
import './index.scss';
import { useSnackbar } from 'notistack';


const columnStyleClass = 'grid-header';
const cellStyleClass = 'grid-cell-style';

export default function ReferentielDataContainer(props) {
  const { table } = props;
  const tableHeader = getTableHeader(table);
  const { data, isLoading, isError, error } = useReferentiel(table);


  const queryClient = useQueryClient();
  const [EditRowModalComponent, NewRowModalComponent] = getReferentielComponents(table);
  const [editRowModal, setEditRowModal] = useState(false);
  const [newRowModal, setNewRowModal] = useState(false);
  const [deleteRowModal, setDeleteRowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const { enqueueSnackbar: showMessage } = useSnackbar();

  const addMutation = useMutation(values => {
    addReferentielElement(values, table).then(() => {
      queryClient.invalidateQueries(table);
      showMessage("Elément ajouté avec succès!", { variant: 'success' });
      setNewRowModal(false);
    }).catch((error) => {
      showMessage("Erreur lors de l'ajout de l'élément ", { variant: 'error' });
      setNewRowModal(false);
    });
  }, {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries(table);
    //     showMessage("Elément ajouté avec succès!", { variant: 'success' });
    //     setNewRowModal(false);
    //   },
    // onError: () => {
    //   showMessage("Erreur lors de l'ajout de l'élément " + error.toString(), { variant: 'error' });
    //   setNewRowModal(false);
    // },
  });
  const editMutation = useMutation(values => {
    editReferentielElement(values, table)
      .then(() => {
        queryClient.invalidateQueries(table);
        showMessage("Elément modifé avec succès!", { variant: 'success' });
        setEditRowModal(false);
      }).catch((error) => {
        showMessage("Erreur lors de la modification de l'élément ", { variant: 'error' });
        setEditRowModal(false);
      });
    // }, {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries(table);
    //     showMessage("Elément modifé avec succès!", { variant: 'success' });
    //     setNewRowModal(false);
    //   },
    //   onError: () => {
    //     showMessage("Erreur lors de la modification de l'élément " + error.toString(), { variant: 'error' });
    //     setNewRowModal(false);
    //   },
  });
  const deleteMutation = useMutation(id => {
    deleteReferentielElement(id, table).then(() => {
      queryClient.invalidateQueries(table);
      showMessage("Elément modifé avec succès!", { variant: 'success' });
      setDeleteRowModal(false);
    }).catch((error) => {
      showMessage("Erreur lors de la modification de l'élément ", { variant: 'error' });
      setDeleteRowModal(false);
    });
  });

  // }), {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(table);
  //     showMessage("Elément supprimé avec succès!", { variant: 'success' });
  //     setNewRowModal(false);
  //   },
  //     onError: () => {
  //       showMessage("Erreur lors de la suppression de l'élément " + error.toString(), { variant: 'error' });
  //       setNewRowModal(false);
  //     },
  // });
  const handleNewRowClick = () => {
    setNewRowModal(true);
  };

  const handleOnNew = (values) => {
    addMutation.mutate(values);
  }
  const handleCloseNewRowModal = () => {
    setNewRowModal(false);
  };

  const handleEditClick = React.useCallback(
    (id) => () => {
      const row = data.find(x => x.id === id);
      setSelectedRow(row);
      setEditRowModal(true);
    },
    [data],
  );

  const handleOnEdit = (values) => {
    editMutation.mutate(values)
  };

  const handleCloseEditRowModal = () => {
    setEditRowModal(false);
  };

  const handleDeleteClick = React.useCallback(
    (id) => () => {
      const row = data.find(x => x.id === id);
      setSelectedRow(row);
      setDeleteRowModal(true);
    },
    [data],
  );

  const handleOnDelete = (selectedRow) => {
    deleteMutation.mutate(selectedRow?.id);
  };

  const handleCloseDeleteRowModal = () => {
    setDeleteRowModal(false);
  };

  const createColumns = (fieldNames) => {
    let allFieldNames = [...fieldNames, 'actions'];
    let isAllowed = referentielIsAllowed(referentielVigilances[table]);
    let columns = [];
    for (let i = 0; i < allFieldNames.length; i++) {
      let fieldName = allFieldNames[i];
      if (fieldName.includes("_id")) {
        continue;
      }
      let column = {
        field: fieldName,
        headerName: getFieldHeaderName(fieldName),
        flex: 1,
        headerClassName: columnStyleClass,
        cellClassName: cellStyleClass,
      };
      if (fieldName === "id") {
        column.hide = true;
      }
      if (fieldName === "actions") {
        column.flex = 0.7;
        column.headerName = 'Actions';
        column.type = 'actions';
        column.getActions = (params) => [
          <GridActionsCellItem
            icon={<DataGridEditIcon />}
            label="Modifier"
            onClick={handleEditClick(params.id)}
            disabled={!isAllowed}
          />,
          <GridActionsCellItem
            icon={<DataGridDeleteIcon />}
            label="Supprimer"
            onClick={handleDeleteClick(params.id)}
            disabled={!isAllowed}
          />
        ]


      }
      columns.push(column);
    }

    return columns;
  }



  if (isLoading) {
    return (<CustomLoader isLoading={isLoading} />)
  } else {
    const fieldNames = getFieldNames(data);
    const rows = createDataGridRows(table, data, '');
    let columns = createColumns(fieldNames);

    return (
      <>
        <div className="referentiel-data-container">
          <Stack direction="column" spacing={0} >
            <div className="referentiel-data-container-title">
              <label>Détails</label>
            </div>
            <div className="referentiel-data-container-middle">
              <Stack direction="row" spacing={0} style={{ width: '100%' }}>
                <div style={{ width: '50%', alignSelf: 'center' }}>
                  <div className="referentiel-data-container-table-name" >
                    <label>{tableHeader}</label>
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <Stack
                    direction="row-reverse"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                  >
                    <div style={{ marginRight: '12px' }} >
                      <CustomButton type={'add'} onClick={handleNewRowClick} disabled={!referentielIsAllowed(referentielVigilances[table])} />
                    </div>
                  </Stack>
                </div>

              </Stack>
            </div>
            <div className="referentiel-data-container-grid">
              <ThemeProvider theme={gridTheme}>
                <div style={{ width: '100%', height: 495 }}>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid rows={rows} columns={columns}
                        disableColumnSelector={true}
                        pagination
                        pageSize={7}
                        rowsPerPageOptions={[7]}
                        components={{
                          Pagination: CustomPagination,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ThemeProvider>
            </div>

          </Stack>
        </div>

        <Modal open={editRowModal} onClose={handleCloseEditRowModal}>
          <EditRowModalComponent selectedRow={selectedRow} onClose={handleCloseEditRowModal} onEdit={handleOnEdit} table={table} />
        </Modal>

        <Modal open={newRowModal} onClose={handleCloseNewRowModal}>
          <NewRowModalComponent onClose={handleCloseNewRowModal} onNew={handleOnNew} table={table} />
        </Modal>

        <Modal open={deleteRowModal} onClose={handleCloseDeleteRowModal}>
          <PopupDelRefCas selectedRow={selectedRow} onClose={handleCloseDeleteRowModal} onDelete={handleOnDelete} title={'cet élément'} />
        </Modal>
      </>

    )
  }
}