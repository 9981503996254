import React, { useContext } from "react";
import {
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";
import { useFormikContext, getIn } from "formik";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelDatePicker from "../../../LabelDatePicker/LabelDatePicker";
import "../../index.scss";

export default function Intoxication(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const referentielResults = useReferentiels([
    "lieu_intoxication",
    "type_risque"
  ]);
  const widthPercentage = "31%";
  const prefix = "intoxication.intoxication.";

  const validated = useContext(ValidatedContext);
  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const lieuxIntoxication = flattenIdNomDict(referentielResults[0].data);
  const typesRisque = flattenIdNomDict(referentielResults[1].data);

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>L'intoxication</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelDatePicker
                  title={"Date d'intoxication"}
                  name={prefix + "date_intoxication"}
                  value={getIn(values, prefix + "date_intoxication")}
                  onChange={setFieldValue}
                  error={
                    getIn(touched, prefix + "date_intoxication") &&
                    Boolean(getIn(errors, prefix + "date_intoxication"))
                  }
                  helperText={
                    getIn(touched, prefix + "date_intoxication") &&
                    getIn(errors, prefix + "date_intoxication")
                  }

                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Heure d'intoxication"}
                  name={prefix + "heure"}
                  value={getIn(values, prefix + "heure")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "heure") &&
                    Boolean(getIn(errors, prefix + "heure"))
                  }
                  helperText={
                    getIn(touched, prefix + "heure") &&
                    getIn(errors, prefix + "heure")
                  }

                  type={"time"}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ display: "flex" }}>
                <span className="label-input-label">Délai post exposition</span>
              </div>
              <div >
                <Stack direction="row" spacing={1}>
                  <div style={{ width: "30%" }}>
                    <LabelInput title={"(jour)"}
                      name={"intoxication.intoxication.delai_intoxication_j"}
                      value={getIn(values, "intoxication.intoxication.delai_intoxication_j")}
                      onChange={handleChange}
                      error={getIn(touched, "intoxication.intoxication.delai_intoxication_j") && Boolean(getIn(errors, "intoxication.intoxication.delai_intoxication_j"))}
                      helperText={getIn(touched, "intoxication.intoxication.delai_intoxication_j") && getIn(errors, "intoxication.intoxication.delai_intoxication_j")}
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <LabelInput title={"(heure)"}
                      name={"intoxication.intoxication.delai_intoxication_h"}
                      value={getIn(values, "intoxication.intoxication.delai_intoxication_h")}
                      onChange={handleChange}
                      error={getIn(touched, "intoxication.intoxication.delai_intoxication_h") && Boolean(getIn(errors, "intoxication.intoxication.delai_intoxication_h"))}
                      helperText={getIn(touched, "intoxication.intoxication.delai_intoxication_h") && getIn(errors, "intoxication.intoxication.delai_intoxication_h")}
                    />
                  </div>
                  <div style={{ width: "30%" }}>
                    <LabelInput title={"(minute)"}
                      name={"intoxication.intoxication.delai_intoxication_m"}
                      value={getIn(values, "intoxication.intoxication.delai_intoxication_m")}
                      onChange={handleChange}
                      error={getIn(touched, "intoxication.intoxication.delai_intoxication_m") && Boolean(getIn(errors, "intoxication.intoxication.delai_intoxication_m"))}
                      helperText={getIn(touched, "intoxication.intoxication.delai_intoxication_m") && getIn(errors, "intoxication.intoxication.delai_intoxication_m")}
                    />
                  </div>
                </Stack>
              </div>

            </Stack>
          </div>


          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Lieu d'intoxication"}
                  name={prefix + "lieu_intoxication_id"}
                  value={getIn(values, prefix + "lieu_intoxication_id")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "lieu_intoxication_id") &&
                    Boolean(getIn(errors, prefix + "lieu_intoxication_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "lieu_intoxication_id") &&
                    getIn(errors, prefix + "lieu_intoxication_id")
                  }

                  valuesDict={lieuxIntoxication}
                />
              </div>
              {parseInt(getIn(values, prefix + "lieu_intoxication_id")) === 9 ? (
                <div style={{ width: widthPercentage }}>
                  <LabelInput title={"Si autre, précisez"}
                    name={"intoxication.intoxication.autre_lieu_intoxication"}
                    value={getIn(values, "intoxication.intoxication.autre_lieu_intoxication")}
                    onChange={handleChange}
                    error={getIn(touched, "intoxication.intoxication.autre_lieu_intoxication") && Boolean(getIn(errors, "intoxication.intoxication.autre_lieu_intoxication"))}
                    helperText={getIn(touched, "intoxication.intoxication.autre_lieu_intoxication") && getIn(errors, "intoxication.intoxication.autre_lieu_intoxication")}
                  />
                </div>
              ) : <div style={{ width: widthPercentage }} />}
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Type de risque"}
                  name={prefix + "type_risque_id"}
                  value={getIn(values, prefix + "type_risque_id")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "type_risque_id") &&
                    Boolean(getIn(errors, prefix + "type_risque_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "type_risque_id") &&
                    getIn(errors, prefix + "type_risque_id")
                  }

                  valuesDict={typesRisque}
                />
              </div>

            </Stack>
          </div>

          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "45%", height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>
                        Type d'intoxication:{" "}

                      </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={prefix + "type_intoxication"}
                        onChange={(event) =>
                          setFieldValue(
                            prefix + "type_intoxication",
                            event.target.value
                          )
                        }
                        value={getIn(values, prefix + "type_intoxication")}
                        error={
                          getIn(touched, prefix + "type_intoxication") &&
                          Boolean(getIn(errors, prefix + "type_intoxication"))
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Isolée"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Collective"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Inconnu"
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(touched, prefix + "type_intoxication") &&
                      getIn(errors, prefix + "type_intoxication")}
                  </FormHelperText>
                </FormControl>
              </div>
              {getIn(values, "intoxication.intoxication.type_intoxication") === "1" && (
                <div style={{ width: widthPercentage }}>
                  <LabelInput title={"Nombre de cas"}
                    name={"intoxication.intoxication.nombre_cas"}
                    value={getIn(values, "intoxication.intoxication.nombre_cas")}
                    onChange={handleChange}
                    error={getIn(touched, "intoxication.intoxication.nombre_cas") && Boolean(getIn(errors, "intoxication.intoxication.nombre_cas"))}
                    helperText={getIn(touched, "intoxication.intoxication.nombre_cas") && getIn(errors, "intoxication.intoxication.nombre_cas")}
                  />
                </div>)}
            </Stack>
          </div>

        </Stack>
      </div >
    </div >
  );
}
