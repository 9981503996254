import React from "react";
import { Stack } from "@mui/material";
import Toxiques from "../../components/Case/Common/Toxiques/Toxiques";

import './index.scss';



export default function AddToxiToxique(props){

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={0}>
                        <Toxiques />
                  </Stack>
            </div>

      )
}