import React from "react";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import './index.scss';



export default function SaisieNavElement(props) {
      const { active, isDone, isFirst, title } = props;
      let bgShape1 = '#d2d6dc';
      let bgShape2 = '#ffffff';
      let shape = 'polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 10% 50%, 0% 0%)';
      let textColor = "#3c4257";

      if (active) {
            bgShape1 = bgShape2 = '#5469D4';
            textColor = "#ffffff";
      }
      if(isFirst){
            shape = 'polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 0%)';
      }

      return (

            <div className="saisie-nav-elt-shape1" style={{ background: bgShape1, clipPath:shape }}>
                  <div className="saisie-nav-elt-shape2" style={{ background: bgShape2, clipPath:shape  }}>
                        <label className="saisie-nav-elt-text" style={{ color: textColor }}>{title}</label>
                        {
                              isDone ? <DoneOutlinedIcon style={{
                                    fill: '#10b351',
                                    marginRight: '15px',
                                    width:'20%', 
                              }} />: null
                        }
                  </div>
            </div>
      )
}