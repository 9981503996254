import React, { useState, useContext } from "react";
import {
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import SearchIcon from '@mui/icons-material/Search';
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import OutlinedInput from "@mui/material/OutlinedInput";
import { debounce } from "lodash";
import "../../index.scss";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelInput from "../../../LabelInput/LabelInput";
import SmallButton from "../../../Buttons/SmallButton";
import WHOAccordion from "../WHOAccordion/WHOAccordion";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";

export default function Symptomologie(props) {
  const [search, setSearch] = useState("");
  const validated = useContext(ValidatedContext);

  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const [symptomatique, SetSymptomatique] = useState(values ? parseInt(getIn(values, "intoxication.symptomatologie.symptomatique")) : 0);
  const referentielResults = useReferentiels(["gradation", "type_risque", "terme_reaction"]);

  const widthPercentage = "31%";
  const doublePercentage = "70%";
  let termes = null;
  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const gradations = flattenIdNomDict(referentielResults[0].data);
  const typeRisques = flattenIdNomDict(referentielResults[1].data);
  const termesDict = flattenIdNomDict(referentielResults[2].data);
  const handleSymptomatiqueChange = (event) => {
    setFieldValue(
      "intoxication.symptomatologie.symptomatique",
      event.target.value
    );
    SetSymptomatique(parseInt(event.target.value));
  };

  if (parseInt(symptomatique) === 1) {
    termes = (
      <Stack direction="column" spacing={4}>
        <div>
          <Stack direction="row" spacing={2}>
            <div style={{ width: "75%" }}>
              <Stack direction="row" spacing={2} style={{ alignItems: "end" }}>
                <div style={{ width: "40%" }}>
                  <LabelMenu
                    title={"Terme de la réaction"}
                    name={"intoxication.symptomatologie.terme_reaction_id"}
                    value={

                      getIn(
                        values,
                        "intoxication.symptomatologie.terme_reaction_id"
                      )

                    }
                    onChange={handleChange}
                    error={
                      getIn(
                        touched,
                        "intoxication.symptomatologie.terme_reaction_id"
                      ) &&
                      Boolean(
                        getIn(
                          errors,
                          "intoxication.symptomatologie.terme_reaction_id"
                        )
                      )
                    }
                    helperText={
                      getIn(
                        touched,
                        "intoxication.symptomatologie.terme_reaction_id"
                      ) &&
                      Boolean(
                        getIn(
                          errors,
                          "intoxication.symptomatologie.terme_reaction_id"
                        )
                      )
                    }
                    valuesDict={termesDict}
                  />
                </div>
                <div style={{ width: "40%", paddingBottom: "3px" }}>
                  <OutlinedInput style={{ width: "96%", height: "43px", background: "white" }}
                    placeholder="Recherche"
                    onChange={debounce((e) => setSearch(e.target.value), 1000)}
                    endAdornment={<SearchIcon htmlColor="#aaaaaa" />}
                  />

                </div>

                <div style={{ paddingBottom: "3px" }}>
                  <SmallButton type={"load"} onClick={() => setSearch("")} />
                </div>
              </Stack>
            </div>
          </Stack>
        </div>
        <div>
          <Stack direction="column" spacing={1}>
            <div className="container-middle">
              <Stack direction="row" spacing={0} style={{ width: "100%" }}>
                <div style={{ width: "50%", alignSelf: "center" }}>
                  <div className="container-table-name">
                    <label>
                      Sélectionner:
                    </label>
                  </div>
                </div>
              </Stack>
            </div>
            <div>
              <WHOAccordion search={search} />
            </div>
          </Stack>
        </div>
      </Stack>
    );
  } else {
    termes = null;
  }

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>La symptomatologie (MedDRA)</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: doublePercentage, height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>
                        Symptomatique <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={"intoxication.symptomatologie.symptomatique"}
                        onChange={handleSymptomatiqueChange}
                        value={getIn(
                          values,
                          "intoxication.symptomatologie.symptomatique"
                        )}
                        error={
                          getIn(
                            touched,
                            "intoxication.symptomatologie.symptomatique"
                          ) &&
                          Boolean(
                            getIn(
                              errors,
                              "intoxication.symptomatologie.symptomatique"
                            )
                          )
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non"
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(
                      touched,
                      "intoxication.symptomatologie.symptomatique"
                    ) &&
                      getIn(errors, "intoxication.symptomatologie.symptomatique")}
                  </FormHelperText>
                </FormControl>
              </div>
            </Stack>
          </div>

          <div>{termes}</div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "80%", height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>
                        Relation entre toxique et symptomatologie:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={
                          "intoxication.symptomatologie.relation_toxique_symptomologie"
                        }
                        onChange={(event) =>
                          setFieldValue(
                            "intoxication.symptomatologie.relation_toxique_symptomologie",
                            event.target.value
                          )
                        }
                        value={getIn(
                          values,
                          "intoxication.symptomatologie.relation_toxique_symptomologie"
                        )}
                        error={
                          getIn(
                            touched,
                            "intoxication.symptomatologie.relation_toxique_symptomologie"
                          ) &&
                          Boolean(
                            getIn(
                              errors,
                              "intoxication.symptomatologie.relation_toxique_symptomologie"
                            )
                          )
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value="-1"
                          control={<Radio />}
                          label="Inconnue"
                        />
                        <FormControlLabel
                          value="-2"
                          control={<Radio />}
                          label="Non précisée"
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(
                      touched,
                      "intoxication.symptomatologie.relation_toxique_symptomologie"
                    ) &&
                      getIn(
                        errors,
                        "intoxication.symptomatologie.relation_toxique_symptomologie"
                      )}
                  </FormHelperText>
                </FormControl>
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Risque à l'appel"}
                  name={"intoxication.symptomatologie.type_risque_id"}
                  value={getIn(
                    values,
                    "intoxication.symptomatologie.type_risque_id"
                  )}
                  onChange={handleChange}
                  error={
                    getIn(
                      touched,
                      "intoxication.symptomatologie.type_risque_id"
                    ) &&
                    Boolean(
                      getIn(errors, "intoxication.symptomatologie.type_risque_id")
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.symptomatologie.type_risque_id"
                    ) &&
                    Boolean(
                      getIn(errors, "intoxication.symptomatologie.type_risque_id")
                    )
                  }

                  valuesDict={typeRisques}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Gradation initiale"}
                  name={"intoxication.symptomatologie.gradation_initiale_id"}
                  value={getIn(
                    values,
                    "intoxication.symptomatologie.gradation_initiale_id"
                  )}
                  onChange={handleChange}
                  error={
                    getIn(
                      touched,
                      "intoxication.symptomatologie.gradation_initiale_id"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.symptomatologie.gradation_initiale_id"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.symptomatologie.gradation_initiale_id"
                    ) &&
                    getIn(
                      errors,
                      "intoxication.symptomatologie.gradation_initiale_id"
                    )
                  }
                  
                  valuesDict={gradations}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
