import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Prescripteur(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  const referentielResults = useReferentiels([
    "prescripteur_type",
    "commune",
    "province",
    "region",
    "provenance",
    "service"
  ]);

  const isLoading = referentielResults.some((result) => result.isLoading);

  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const prescripteurTypes = flattenIdNomDict(referentielResults[0].data);
  const communes = flattenIdNomDict(referentielResults[1].data);
  const provinces = flattenIdNomDict(referentielResults[2].data);
  const regions = flattenIdNomDict(referentielResults[3].data);
  const provenances = flattenIdNomDict(referentielResults[4].data);
  const services = flattenIdNomDict(referentielResults[5].data);

  const prescripteur = selectedCase
    ? selectedCase.prescripteur
    : values.prescripteur.prescripteur;

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Le prescripteur</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Type du prescripteur"
                  value={prescripteurTypes[prescripteur?.prescripteur_type_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Nom du prescripteur"
                  value={prescripteur?.nom}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Commune"
                  value={communes[prescripteur?.commune_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Province"
                  value={provinces[prescripteur?.province_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Région"
                  value={regions[prescripteur?.region_id]}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Provenance"
                  value={provenances[prescripteur?.provenance_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Service"
                  value={services[prescripteur?.service_id]}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
