import React from 'react';
import { Stack } from '@mui/material';
import Header from '../../components/Header/Header';
import MainMenu from '../../components/MainMenu/MainMenu'
import NotificationSideBar from '../../components/NotificationSideBar/NotificationSideBar';
import { checkToken, getUserFromStore } from '../../services/auth';
import { useSnackbar } from "notistack";
import './index.scss';



export default function Admin(props) {
    const { enqueueSnackbar: showMessage } = useSnackbar();
    const currentUser = getUserFromStore();
    checkToken(showMessage);
    return (
        <Stack direction="column" spacing={0}>
            <Header />
            <div className="admin-body">
                <Stack direction="row" spacing={0} style={{ width: '100%' }}>
                    <div className="admin-body-left">
                        <div>
                            <h1>Bonjour <br />Dr. {currentUser?.nom_prenom}</h1>
                        </div>
                        <div>
                            <h3>Bienvenue sur votre plateforme de <br />gestion des cas d'intoxication</h3>
                        </div>
                    </div>
                    <div className="admin-body-middle">
                        <MainMenu />
                    </div>
                    <div className="admin-body-right">
                        <Stack direction="row-reverse" spacing={0}>
                            <NotificationSideBar />
                        </Stack>
                    </div>
                </Stack>
            </div>
        </Stack>
    )
}
