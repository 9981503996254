import React, { useState } from "react";
import { Stack } from "@mui/material";
import ReferentielManagement from "./ReferentielManagement";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import "./index.scss";
import { useLocation } from "react-router-dom";
import { getTablesByCategory } from "../../utils/referentielData";

export default function ReferentielManagementModule(props) {
  const location = useLocation();
  const category = location.state ? location.state.category : 0;
  const table = location.state ? location.state.table : 0;

  const [categoryIndex, setCategoryIndex] = useState(category ?? 0);

  const categories = {
    0: "declarant_rapport",
    1: "patient",
    2: "produits",
    3: "repondeur",
    4: "intoxication",
    5: "laboratoire",
  };


  const handleClick = (newCategoryIndex) => {
    setCategoryIndex(newCategoryIndex);
  };
  return (
    <div className="referentiel-management-module-container">
      <Stack direction="column" spacing={0}>
        <div className="referentiel-management-module-title">
          <label>Sélectionner rubrique</label>
        </div>
        <div className="referentiel-management-module-buttons">
          <Stack direction="row" spacing={0}>

            <RubriqueButton
              type="declarant"
              active={categoryIndex == 0}
              onClick={() => handleClick(0)}
            />
            <RubriqueButton
              type="patient"
              active={categoryIndex == 1}
              onClick={() => handleClick(1)}
            />
            <RubriqueButton
              type="produits"
              active={categoryIndex == 2}
              onClick={() => handleClick(2)}
            />
            <RubriqueButton
              type="repondeur"
              active={categoryIndex == 3}
              onClick={() => handleClick(3)}
            />


            <RubriqueButton
              type="intoxication"
              active={categoryIndex == 4}
              onClick={() => handleClick(4)}
            />
            <RubriqueButton
              type="laboratoire"
              active={categoryIndex == 5}
              onClick={() => handleClick(5)}
            />

          </Stack>
        </div>
        <div className="referentiel-management-module-title">
          <label>Gestion des référentiels</label>
        </div>
        <div className="referentiel-management-module-body">
          <ReferentielManagement
            notificationTable={table}
            category={categories[categoryIndex]}
          />
        </div>
      </Stack >
    </div >
  );
}
