
const SCORPION_FORMIK_INITIAL_VALUES = {
      general: {
            province: '',
            mois: '',
            annee: '',
            label: 'chu'
      },
      declarant: {
            numero_dossier: '',
            date_saisie: '',
            date_reception: '',
            heure_reception: '',
            commune: '',
            province: '',
            region: ''
      },
      patient: {
            sexe_masculin: '',
            sexe_feminin: '',
            age_less_15: '',
            age_more_15: ''
      },
      traitement: {
            t0: '',
            t1: ''
      },
      hospitalisation: {
            mis_observation: '',
            hospitalisation: ''
      },
      reference: {
            r0: '',
            r1: '',
            r2: ''
      },
      evolution: {
            e1: '',
            e2: ''
      }
};

const TOXI_FORMIK_INITIAL_VALUES = {
      id: '',
      declarant: {
            id: '',
            rapport: {
                  numero_dossier: '',
                  date_saisie: '',
                  date_reception: '',
                  heure_reception: '',
                  type_rapport_id: '',
                  operateur: '',
            },
            declarant: {
                  declarant_type_id: '',
                  nom: '',
                  telephone: '',
                  commune_id: '',
                  province_id: '',
                  region_id: '',
                  provenance_id: '',
                  service_id: '',
                  denomination: ''
            },

      },
      patient: {
            id: '',
            nom_prenom: '',
            telephone: '',
            sexe: '2',
            age: '',
            tranche_age: '',
            region_id: '',
            commune_id: '',
            province_id: '',
            milieu: '2',
            poids: '',
            grossesse: '2',
            nombre_semaines: '0',
            allaitement: '2',
            profession_id: '',
            antecedents: '',
            historique: '',
      },
      toxiques: {
            id: '',
            medicaments: [],
            produit_menager: [],
            cosmetique: [],
            aliments: [],
            plante: [],
            gaz: [],
            drogue: [],
            pesticide: [],
            engrais: [],
            metaux_lourds: [],
            autre_animal_venimeux: [],
            medicaments_veterinaires: [],
            produit_industriel: [],
            scorpion: [],
            serpent: [],
            inconnu: [],
            autre: [],
      },
      intoxication: {
            id: '',
            intoxication: {
                  date_intoxication: '',
                  heure: '',
                  delai_intoxication_j: '',
                  delai_intoxication_h: '',
                  delai_intoxication_m: '',
                  type_intoxication: '0',
                  nombre_cas: '1',
                  lieu_intoxication_id: '',
                  autre_lieu_intoxication: '',
                  circonstance_intoxication_id: '',
                  autre_circonstance_intoxication: '',
                  erreur_medicamenteuse: '0',
                  erreur_medicamenteuse_erreur_circuit: '0',
                  erreur_medicamenteuse_type_erreur: '0',
                  erreur_medicamenteuse_cause_erreur: '0',
                  erreur_medicamenteuse_autre_information: '',
            },
            symptomatologie: {
                  symptomatique: '0',
                  terme_reaction_id: '',
                  termes: [],
                  relation_toxique_symptomologie: '0',
                  type_risque_id: '',
                  gradation_initiale_id: '',
            },
            traitement_avant: {

                  abstention_therapeutique: '0',
                  orientation: '0',

                  precise: '1',

                  decontaminations: [],
                  traitement_symptomatique_id: '',
                  epurations: [],
                  antidotes: [],

                  consultation_specialisee: '',
                  surveillance: '0',
                  bilan_radiologique: '0',
                  bilan_biologique: '0',
                  bilan_toxicologique: '0',
                  resultat_bilan_toxicologique: '',
            },

            traitement_capm: {
                  precise: '1',
                  abstention_therapeutique: '0',
                  orientation: '0',

                  decontaminations: [],
                  traitement_symptomatique_id: '',
                  epurations: [],
                  antidotes: [],

                  consultation_specialisee: '',
                  surveillance: '0',
                  bilan_radiologique: '0',
                  bilan_biologique: '0',
                  bilan_toxicologique: '0',
                  resultat_synthese: '',
            },

            traitement_effectue: {
                  precise: '1',
                  abstention_therapeutique: '0',
                  orientation: '0',

                  decontaminations: [],
                  traitement_symptomatique_id: '',
                  epurations: [],
                  antidotes: [],

                  consultation_specialisee: '',
                  surveillance: '0',
                  bilan_radiologique: '0',
                  bilan_biologique: '0',
                  bilan_toxicologique: '0',
                  nombre_cas: '',
                  resultat_synthese: ''
            },
            evolution: {
                  relance: '0',
                  date_relance: '',
                  hospitalisation: '-1',
                  duree_hospitalisation: '',
                  gradation_finale_id: '',
                  evolution_id: '',

                  sequelles: '0',
                  sequelles_details: '',
                  imputabilite: {
                        exposition: '',
                        symptomatologie: '',
                        chronologie: '',
                        caracterisation_causale: '',
                        diagnostique_differentiel: '',
                        lien_extrinseque: '',
                        imputabilite: '',
                  },
                  historique: '',
            },
      },
      repondeur: {
            id: '',
            repondeur_id: '',
            delai_reponse_id: '',
            signal: '',
      },
};

const LABO_FORMIK_INITIAL_VALUES = {
      prescripteur: {
            dossier: {
                  type_dossier: '1',
                  numero_dossier: '',
                  date_saisie: '',
                  date_reception: '',
                  heure_reception: '',
            },
            prescripteur: {
                  prescripteur_type_id: '',
                  nom: '',
                  commune_id: '',
                  province_id: '',
                  region_id: '',
                  provenance_id: '',
                  service_id: ''
            }
      },
      patient: {
            id: '',
            nom_prenom: '',
            telephone: '',
            sexe: '0',
            age: '',
            tranche_age: '',
            region_id: '',
            commune_id: '',
            province_id: '',
            milieu: '2',
            poids: '',
            grossesse: '2',
            nombre_semaines: '0',
            allaitement: '2',
            profession_id: '',
            antecedents: '',
            historique: '',
      },
      toxiques: {
            medicaments: [],
            produit_menager: [],
            cosmetique: [],
            aliments: [],
            plante: [],
            gaz: [],
            drogue: [],
            pesticide: [],
            engrais: [],
            metaux_lourds: [],
            autre_animal_venimeux: [],
            medicaments_veterinaires: [],
            produit_industriel: [],
            scorpion: [],
            serpent: []
      },
      intoxication: {
            intoxication: {
                  date_intoxication: '',
                  heure: '',
                  delai_intoxication_j: '',
                  delai_intoxication_h: '',
                  delai_intoxication_m: '',
                  lieu_intoxication_id: '',
                  autre_lieu_intoxication: '',
                  type_risque_id: '',
                  type_intoxication: '0',
                  nombre_cas: '1',
            },
            symptomatologie: {
                  symptomatique: '0',
                  terme_reaction_id: '',
                  termes: [],
                  relation_toxique_symptomologie: '1',
                  type_risque_id: '',
                  gradation_initiale_id: '',
            },

            evolution: {
                  relance: '0',
                  date_relance: '',
                  hospitalisation: '-1',
                  duree_hospitalisation: '',
                  gradation_finale_id: '',
                  evolution_id: '',

                  sequelles: '0',
                  sequelles_details: '',
                  imputabilite: {
                        exposition: '',
                        symptomatologie: '',
                        chronologie: '',
                        caracterisation_causale: '',
                        diagnostique_differentiel: '',
                        lien_extrinseque: '',
                        imputabilite: '',
                  },
                  historique: '',
            },

      },
      echantillon: {
            heure_prelevement: '',
            echantillons: {
                  sang: {
                        checked: false,
                        conformite: '',
                        problem_rencontre: '',
                        lc: false,
                        gc: false,
                        ptrr: false,
                        saa: false,
                  },
                  urines: {
                        checked: false,
                        conformite: '',
                        problem_rencontre: '',
                        lc: false,
                        gc: false,
                        ptrr: false,
                        saa: false,
                  },
                  lg: {
                        checked: false,
                        conformite: '',
                        problem_rencontre: '',
                        lc: false,
                        gc: false,
                        ptrr: false,
                        saa: false,
                  },
                  lcr: {
                        checked: false,
                        conformite: '',
                        problem_rencontre: '',
                        lc: false,
                        gc: false,
                        ptrr: false,
                        saa: false,
                  },
                  produit: {
                        checked: false,
                        name: '',
                        conformite: '',
                        problem_rencontre: '',
                        lc: false,
                        gc: false,
                        ptrr: false,
                        saa: false,
                  },
                  autres: {
                        checked: false,
                        name: '',
                        conformite: '',
                        problem_rencontre: '',
                        lc: false,
                        gc: false,
                        ptrr: false,
                        saa: false,
                  },
            },
            examen_demande: [],
      },
      resultat: {
            LC: {
                  recherche_dosage: '0',
                  resultat_analyse: '0',
                  produits_testes: [
                        {
                              checked: false,
                              produit: 'Pesticide',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Médicament',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Drogue',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Plante',
                              molecules: [''],
                              dosages: ['']
                        }
                  ],
            },
            PTRR: {
                  resultat_analyse: '0',
                  resultats_dosage: [
                        {
                              checked: false,
                              analyse: 'Activité cholinestérasique érythrocytaire',
                              dosage: ''
                        },
                        {
                              checked: false,
                              analyse: 'Activité cholinestérasique plasmatique',
                              dosage: ''
                        },
                        {
                              checked: false,
                              analyse: 'Caroboxyhémoglobine',
                              dosage: ''
                        },
                        {
                              checked: false,
                              analyse: 'Méthémoglobine',
                              dosage: ''
                        }
                  ],
                  resultats_recherche: [
                        {
                              checked: false,
                              analyse: 'Alpha-clorasole',
                              result: false
                        },
                        {
                              checked: false,
                              analyse: 'Amines aromatiques',
                              result: false
                        },
                        {
                              checked: false,
                              analyse: 'Amphétamines',
                              result: false
                        },
                        {
                              checked: false,
                              analyse: 'Atropine',
                              result: false
                        }
                  ],
            },
            SAA: {
                  resultat_analyse: '0',
                  resultats: [
                        {
                              checked: false,
                              analyse: 'Plomb',
                              dosage: ''
                        },
                        {
                              checked: false,
                              analyse: 'Cadmium',
                              dosage: ''
                        }
                  ],
            },
            GC: {
                  recherche_dosage: '0',
                  resultat_analyse: '0',
                  produits_testes: [
                        {
                              checked: false,
                              produit: 'Médicament',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Aliment',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Pr. Cosmétique',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Drogue',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Gaz',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Pesticide',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Plante',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Pr. Ménager',
                              molecules: [''],
                              dosages: ['']
                        },
                        {
                              checked: false,
                              produit: 'Pr. Industriels',
                              molecules: [''],
                              dosages: ['']
                        }
                  ],
            },
      },
      analyste: {
            analyste_id: '',
            observation: ''
      },

};


export { SCORPION_FORMIK_INITIAL_VALUES, TOXI_FORMIK_INITIAL_VALUES, LABO_FORMIK_INITIAL_VALUES }
