import React, { useState, useEffect } from "react";
import { Stack, Button, Badge, Avatar } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import logo from "./assets/logo.png";
import axios from "axios";
import { authHeaderKey } from "../../utils/axiosInstances";
import RoleName from "../RoleName/RoleName";
import AccountMenu from "./AccountMenu";
import history from "../../utils/history";
import { getUserFromStore } from "../../services/auth";
import { getNumberLastNotifications } from "../../services/cases/cases";
import defaultAvatar from "../../assets/default_avatar.svg";
import "./index.scss";
import NotificationModal from "../Modal/NotificationModal/NotificationModal";
import { getSecureAxiosInstance } from "../../utils/axiosInstances";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid `,
    padding: "0 4px",
    background: "#FF6760",
    border: "2px solid #F6F8FB",
    color: "#FFFFFF",
  },
}));

export default function Header(props) {
  const [showModal, setShowModal] = useState(false);
  const user = getUserFromStore();
  const [userImage, setUserImage] = useState(defaultAvatar);
  const [numberOfNewNotifications, setNumberOfNewNotifications] = useState(0);
  const axiosBaseConfig = {
    baseURL:
      process.env.REACT_APP_ENV === "prod"
        ? process.env.REACT_APP_BACKEND_URL_PROD
        : process.env.REACT_APP_BACKEND_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  const handleLogoClick = () => {
    history.push("/main");
  };
  const showNotifications = () => {
    setShowModal(true);
  };

  useEffect(() => {
    getNumberLastNotifications().then((data) => {
      setNumberOfNewNotifications(data);
    });

    getSecureAxiosInstance().get(`user/avatar/${user?.avatar_url}`, {
      headers: {
        ...axiosBaseConfig.headers,
        Authorization: authHeaderKey(),
      },
      responseType: "arraybuffer",
    })
      .then((res) => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setUserImage(base64);
      });

  }, [user, props.userChanged]);

  return (
    <div className="header-container">
      <Stack direction="row" spacing={0} alignItems="center" width="100%">
        <div className="header-logo">
          <Button onClick={handleLogoClick}>
            <img src={logo} alt="" />
          </Button>
        </div>
        <div className="header-right-side">
          <Stack
            direction="row-reverse"
            spacing={0}
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className="header-right-side-user">
              <Stack direction="row" spacing={0}>
                <div>
                  <div className="header-right-side-user-img">
                    <Avatar
                      sx={{ width: 40, height: 40 }}
                      src={`data:image/jpeg;charset=utf-8;base64,${userImage}`}
                    />
                  </div>
                </div>
                <div className="header-user-avatar-right">
                  <Stack direction="column" spacing={0}>
                    <div className="header-user-avatar-text">
                      <AccountMenu userName={"Dr. " + user?.nom_prenom} />
                    </div>
                    <div className="header-user-avatar-status">
                      <RoleName type={user?.role_id} small={true} />
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
            <div className="header-right-side-notifications">
              <StyledBadge
                badgeContent={numberOfNewNotifications}
                overlap="circular"
              >
                <NotificationsIcon
                  onClick={showNotifications}
                  color="action"
                  style={{
                    fill: "#CACCCF",
                    transform: "rotate(23deg)",
                    cursor: "pointer",
                  }}
                />
              </StyledBadge>
            </div>
          </Stack>
        </div>
        <NotificationModal showModal={showModal} onShowModal={setShowModal} />
      </Stack>
    </div>
  );
}
