import React from "react";
import { FirstAidIcon, BlessedPersonIcon, PersonHealthIcon, DrugsIcon } from "../Icons/Icons";
import { createTheme } from "@mui/material/styles";
import { Grid, ThemeProvider } from "@mui/material";
import './index.scss';

const dashKPITheme = createTheme();
dashKPITheme.components = {
      MuiSvgIcon: {
            styleOverrides: {
                  root: {
                        fill: '#558EFF',
                        width: '40px',
                        height: '36px',
                  }
            }
      }
}

export default function DashKPI(props) {
      let icone = null;

      switch (props.type) {
            case 'cases':
                  icone = <FirstAidIcon viewBox="0 0 40 36" fill="#558EFF" />
                  break;
            case 'blessed-person':
                  icone = <BlessedPersonIcon viewBox="0 0 40 36" fill="#558EFF" />
                  break;
            case 'person-health':
                  icone = <PersonHealthIcon viewBox="0 0 36 36" width="36" height="36" fill="#558EFF" />
                  break;
            case 'drugs':
                  icone = <DrugsIcon viewBox="0 0 36 36" fill="#558EFF" />
                  break;
            default:
                  break;
      }
      return (
            <div className="dash-kpi-container">
                  <Grid container spacing={0} direction='row'>
                        <Grid item xs={5} style={{ display: 'flex' }}>
                              <div className="icon-part">
                                    <ThemeProvider theme={dashKPITheme}>
                                          {icone}
                                    </ThemeProvider>
                              </div>
                        </Grid>
                        <Grid item xs={7}>
                              <Grid container spacing={0} direction='column'>
                                    <Grid item xs={6} >
                                          <div className="text-part">
                                                <span>{props.title}</span>
                                          </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                          <div className="value-part">
                                                <span>{Math.round(props.value)}</span>
                                          </div>
                                    </Grid>
                              </Grid>
                        </Grid>
                  </Grid>
            </div>
      )
}