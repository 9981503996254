import React from "react";
import { Stack, Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelMultipleMenu from "../../../LabelMenu/LabelMultipleMenu";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelTextArea from "../../../LabelTextArea";

import '../../index.scss';


export default function Traitement(props) {
      const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
      const referentielResults = useReferentiels(['decontamination', 'traitement_symptomatique', 'epuration', 'antidotes']);
      const widthPercentage = '31%';

      const isLoading = referentielResults.some(result => result.isLoading)
      if (isLoading)
            return (<CustomLoader isLoading={isLoading} />)

      const decontaminations = flattenIdNomDict(referentielResults[0].data);
      const traitementSymptomatiques = flattenIdNomDict(referentielResults[1].data);
      const epurations = flattenIdNomDict(referentielResults[2].data);
      const antidotes = flattenIdNomDict(referentielResults[3].data);

      return (
            <div className="case-common-container" >
                  <div className="case-common-container-title">
                        <label>Traitement avant l'appel</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: '45%', height: '59px' }}>
                                                <FormControl component="fieldset" style={{ height: '100%' }}>
                                                      <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                            <div className="case-common-container-body-label">
                                                                  <label>Précisé: </label>
                                                            </div>
                                                            <div className="case-common-container-body-radio">
                                                                  <RadioGroup row name={"intoxication.traitement_avant.precise"}
                                                                        onChange={(event) => setFieldValue("intoxication.traitement_avant.precise", event.target.value)}
                                                                        value={getIn(values, "intoxication.traitement_avant.precise")}
                                                                        error={getIn(touched, "intoxication.traitement_avant.precise") && Boolean(getIn(errors, "intoxication.traitement_avant.precise"))}>
                                                                        <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                        <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                  </RadioGroup>
                                                            </div>
                                                      </Stack>
                                                      <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_avant.precise") && getIn(errors, "intoxication.traitement_avant.precise")}</FormHelperText>
                                                </FormControl>
                                          </div>
                                    </Stack>
                              </div>
                              {
                                    parseInt(getIn(values, "intoxication.traitement_avant.precise")) === 1 &&
                                    <>
                                          <div>
                                                <Stack direction="row" spacing={3}>

                                                      <div style={{ width: '45%', height: '59px' }}>
                                                            <FormControl component="fieldset" style={{ height: '100%' }}>
                                                                  <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                                        <div className="case-common-container-body-label">
                                                                              <label>Orientation: </label>
                                                                        </div>
                                                                        <div className="case-common-container-body-radio">
                                                                              <RadioGroup row name={"intoxication.traitement_avant.orientation"}
                                                                                    onChange={(event) => setFieldValue("intoxication.traitement_avant.orientation", event.target.value)}
                                                                                    value={getIn(values, "intoxication.traitement_avant.orientation")}
                                                                                    error={getIn(touched, "intoxication.traitement_avant.orientation") && Boolean(getIn(errors, "intoxication.traitement_avant.orientation"))}>
                                                                                    <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                                    <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                              </RadioGroup>
                                                                        </div>
                                                                  </Stack>
                                                                  <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_avant.orientation") && getIn(errors, "intoxication.traitement_avant.orientation")}</FormHelperText>
                                                            </FormControl>
                                                      </div>
                                                      <div style={{ width: '45%', height: '59px' }}>
                                                            <FormControl component="fieldset" style={{ height: '100%' }}>
                                                                  <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                                        <div className="case-common-container-body-label">
                                                                              <label>Abstention thérapeutique: </label>
                                                                        </div>
                                                                        <div className="case-common-container-body-radio">
                                                                              <RadioGroup row name={"intoxication.traitement_avant.abstention_therapeutique"}
                                                                                    onChange={(event) => setFieldValue("intoxication.traitement_avant.abstention_therapeutique", event.target.value)}
                                                                                    value={getIn(values, "intoxication.traitement_avant.abstention_therapeutique")}
                                                                                    error={getIn(touched, "intoxication.traitement_avant.abstention_therapeutique") && Boolean(getIn(errors, "intoxication.traitement_avant.abstention_therapeutique"))}>
                                                                                    <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                                    <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                              </RadioGroup>
                                                                        </div>
                                                                  </Stack>
                                                                  <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_avant.abstention_therapeutique") && getIn(errors, "intoxication.traitement_avant.abstention_therapeutique")}</FormHelperText>
                                                            </FormControl>
                                                      </div>
                                                </Stack>
                                          </div>
                                          <div>
                                                <Stack direction="row" spacing={3}>
                                                      <div style={{ width: widthPercentage }}>
                                                            <LabelMultipleMenu title={"Décontamination"}
                                                                  name={"intoxication.traitement_avant.decontaminations"}
                                                                  initialValues={getIn(values, "intoxication.traitement_avant.decontaminations")}
                                                                  onChange={setFieldValue}
                                                                  error={getIn(touched, "intoxication.traitement_avant.decontaminations") && Boolean(getIn(errors, "intoxication.traitement_avant.decontaminations"))}
                                                                  helperText={getIn(touched, "intoxication.traitement_avant.decontaminations") && getIn(errors, "intoxication.traitement_avant.decontaminations")}
                                                                  valuesDict={decontaminations}
                                                                  disabled={parseInt(getIn(values, "intoxication.traitement_avant.abstention_therapeutique")) === 1} />
                                                      </div>
                                                      <div style={{ width: widthPercentage }}>
                                                            <LabelMenu title={"Traitement symptomatique"}
                                                                  name={"intoxication.traitement_avant.traitement_symptomatique_id"}
                                                                  value={getIn(values, "intoxication.traitement_avant.traitement_symptomatique_id")}
                                                                  onChange={handleChange}
                                                                  error={getIn(touched, "intoxication.traitement_avant.traitement_symptomatique_id") && Boolean(getIn(errors, "intoxication.traitement_avant.traitement_symptomatique_id"))}
                                                                  helperText={getIn(touched, "intoxication.traitement_avant.traitement_symptomatique_id") && getIn(errors, "intoxication.traitement_avant.traitement_symptomatique_id")}
                                                                  valuesDict={traitementSymptomatiques}
                                                                  disabled={parseInt(getIn(values, "intoxication.traitement_avant.abstention_therapeutique")) === 1} />
                                                      </div>
                                                      <div style={{ width: widthPercentage }}>
                                                            <LabelMultipleMenu title={"Epuration"}
                                                                  name={"intoxication.traitement_avant.epurations"}
                                                                  initialValues={getIn(values, "intoxication.traitement_avant.epurations")}
                                                                  onChange={setFieldValue}
                                                                  error={getIn(touched, "intoxication.traitement_avant.epurations") && Boolean(getIn(errors, "intoxication.traitement_avant.epurations"))}
                                                                  helperText={getIn(touched, "intoxication.traitement_avant.epurations") && getIn(errors, "intoxication.traitement_avant.epurations")}
                                                                  valuesDict={epurations}
                                                                  disabled={parseInt(getIn(values, "intoxication.traitement_avant.abstention_therapeutique")) === 1} />
                                                      </div>

                                                </Stack>
                                          </div>
                                          <div>
                                                <Stack direction="row" spacing={3}>
                                                      <div style={{ width: widthPercentage }}>
                                                            <LabelMultipleMenu title={"Antidotes"}
                                                                  name={"intoxication.traitement_avant.antidotes"}
                                                                  initialValues={getIn(values, "intoxication.traitement_avant.antidotes")}
                                                                  onChange={setFieldValue}
                                                                  error={getIn(touched, "intoxication.traitement_avant.antidotes") && Boolean(getIn(errors, "intoxication.traitement_avant.antidotes"))}
                                                                  helperText={getIn(touched, "intoxication.traitement_avant.antidotes") && getIn(errors, "intoxication.traitement_avant.antidotes")}
                                                                  valuesDict={antidotes}
                                                                  disabled={parseInt(getIn(values, "intoxication.traitement_avant.abstention_therapeutique")) === 1} />
                                                      </div>
                                                      <div style={{ width: widthPercentage }}>
                                                            <LabelInput title={"Consultation spécialisée"}
                                                                  name={"intoxication.traitement_avant.consultation_specialisee"}
                                                                  value={getIn(values, "intoxication.traitement_avant.consultation_specialisee")}
                                                                  onChange={handleChange}
                                                                  error={getIn(touched, "intoxication.traitement_avant.consultation_specialisee") && Boolean(getIn(errors, "intoxication.traitement_avant.consultation_specialisee"))}
                                                                  helperText={getIn(touched, "intoxication.traitement_avant.consultation_specialisee") && getIn(errors, "intoxication.traitement_avant.consultation_specialisee")}
                                                                  disabled={parseInt(getIn(values, "intoxication.traitement_avant.abstention_therapeutique")) === 1} />
                                                      </div>

                                                </Stack>
                                          </div>
                                          <div>
                                                <Stack direction="row" spacing={3}>
                                                      <div style={{ width: '45%', height: '59px' }}>
                                                            <FormControl component="fieldset" style={{ height: '100%' }}>
                                                                  <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                                        <div className="case-common-container-body-label">
                                                                              <label>Surveillance: </label>
                                                                        </div>
                                                                        <div className="case-common-container-body-radio">
                                                                              <RadioGroup row name={"intoxication.traitement_avant.surveillance"}
                                                                                    onChange={(event) => setFieldValue("intoxication.traitement_avant.surveillance", event.target.value)}
                                                                                    value={getIn(values, "intoxication.traitement_avant.surveillance")}
                                                                                    error={getIn(touched, "intoxication.traitement_avant.surveillance") && Boolean(getIn(errors, "intoxication.traitement_avant.surveillance"))}

                                                                              >
                                                                                    <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                                    <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                              </RadioGroup>
                                                                        </div>
                                                                  </Stack>
                                                                  <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_avant.surveillance") && getIn(errors, "intoxication.traitement_avant.surveillance")}</FormHelperText>
                                                            </FormControl>
                                                      </div>
                                                      <div style={{ width: '45%', height: '59px' }}>
                                                            <FormControl component="fieldset" style={{ height: '100%' }}>
                                                                  <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                                        <div className="case-common-container-body-label">
                                                                              <label>Bilan radiologique: </label>
                                                                        </div>
                                                                        <div className="case-common-container-body-radio">
                                                                              <RadioGroup row name={"intoxication.traitement_avant.bilan_radiologique"}
                                                                                    onChange={(event) => setFieldValue("intoxication.traitement_avant.bilan_radiologique", event.target.value)}
                                                                                    value={getIn(values, "intoxication.traitement_avant.bilan_radiologique")}
                                                                                    error={getIn(touched, "intoxication.traitement_avant.bilan_radiologique") && Boolean(getIn(errors, "intoxication.traitement_avant.bilan_radiologique"))}
                                                                              >
                                                                                    <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                                    <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                              </RadioGroup>
                                                                        </div>
                                                                  </Stack>
                                                                  <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_avant.bilan_radiologique") && getIn(errors, "intoxication.traitement_avant.bilan_radiologique")}</FormHelperText>
                                                            </FormControl>
                                                      </div>
                                                </Stack>
                                                <Stack direction="row" spacing={3}>
                                                      <div style={{ width: '45%', height: '59px' }}>
                                                            <FormControl component="fieldset" style={{ height: '100%' }}>
                                                                  <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                                        <div className="case-common-container-body-label">
                                                                              <label>Bilan biologique: </label>
                                                                        </div>
                                                                        <div className="case-common-container-body-radio">
                                                                              <RadioGroup row name={"intoxication.traitement_avant.bilan_biologique"}
                                                                                    onChange={(event) => setFieldValue("intoxication.traitement_avant.bilan_biologique", event.target.value)}
                                                                                    value={getIn(values, "intoxication.traitement_avant.bilan_biologique")}
                                                                                    error={getIn(touched, "intoxication.traitement_avant.bilan_biologique") && Boolean(getIn(errors, "intoxication.traitement_avant.bilan_biologique"))}
                                                                              >
                                                                                    <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                                    <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                              </RadioGroup>
                                                                        </div>
                                                                  </Stack>
                                                                  <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_avant.bilan_biologique") && getIn(errors, "intoxication.traitement_avant.bilan_biologique")}</FormHelperText>
                                                            </FormControl>
                                                      </div>
                                                      <div style={{ width: '45%', height: '59px' }}>
                                                            <FormControl component="fieldset" style={{ height: '100%' }}>
                                                                  <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                                        <div className="case-common-container-body-label">
                                                                              <label>Bilan toxicologique: </label>
                                                                        </div>
                                                                        <div className="case-common-container-body-radio">
                                                                              <RadioGroup row name={"intoxication.traitement_avant.bilan_toxicologique"}
                                                                                    onChange={(event) => setFieldValue("intoxication.traitement_avant.bilan_toxicologique", event.target.value)}
                                                                                    value={getIn(values, "intoxication.traitement_avant.bilan_toxicologique")}
                                                                                    error={getIn(touched, "intoxication.traitement_avant.bilan_toxicologique") && Boolean(getIn(errors, "intoxication.traitement_avant.bilan_toxicologique"))}
                                                                              >
                                                                                    <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                                    <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                              </RadioGroup>
                                                                        </div>
                                                                  </Stack>
                                                                  <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_avant.bilan_toxicologique") && getIn(errors, "intoxication.traitement_avant.bilan_toxicologique")}</FormHelperText>
                                                            </FormControl>
                                                      </div>
                                                </Stack>
                                          </div>
                                          <div>
                                                <Stack direction="row" spacing={3}>
                                                      <div
                                                            style={{
                                                                  marginRight: "12px",
                                                                  width: "100%",
                                                                  marginLeft: "-5px",
                                                            }}
                                                      >
                                                            <LabelTextArea
                                                                  title={"Résultat du bilan toxicologique"}
                                                                  name={"intoxication.traitement_avant.resultat_bilan_toxicologique"}
                                                                  value={getIn(
                                                                        values,
                                                                        "intoxication.traitement_avant.resultat_bilan_toxicologique"
                                                                  )}
                                                                  onChange={handleChange}
                                                                  error={
                                                                        getIn(
                                                                              touched,
                                                                              "intoxication.traitement_avant.resultat_bilan_toxicologique"
                                                                        ) &&
                                                                        Boolean(
                                                                              getIn(
                                                                                    errors,
                                                                                    "intoxication.traitement_avant.resultat_bilan_toxicologique"
                                                                              )
                                                                        )
                                                                  }
                                                                  helperText={
                                                                        getIn(
                                                                              touched,
                                                                              "intoxication.traitement_avant.resultat_bilan_toxicologique"
                                                                        ) &&
                                                                        getIn(
                                                                              errors,
                                                                              "intoxication.traitement_avant.resultat_bilan_toxicologique"
                                                                        )
                                                                  }

                                                            />
                                                      </div>
                                                </Stack>
                                          </div>
                                    </>
                              }
                        </Stack >
                  </div >
            </div >
      )

}