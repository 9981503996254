import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Notification from "./Notification";
import { Divider } from "@mui/material";
import "./index.scss";
import { getLatestNotifications, checkNotification } from "../../../services/cases/cases";
import { useHistory } from "react-router-dom";

export const CaseContext = React.createContext();

export default function NotificationModal(props) {

  const { showModal, onShowModal } = props;
  const [notifications, setNotifications] = useState(null);
  const [dates, setDates] = useState(null);

  const history = useHistory();

  useEffect(() => {
    getLatestNotifications().then((data) => {
      setNotifications(data);
      let dates = data.map(
        (notification) => new Date(notification.notification_date.split(" ")[0])
      );
      setDates(dates);
    });
  }, [showModal]);

  const consultCase = (notification) => {
    checkNotification(notification.id);
    if (
      notification.notification_type === "MODIFICATION_CAS" || notification.notification_type === "SUPPRESSION_CAS"
    ) {
      history.push({
        pathname: "/main/view-cases",
        state: { declarationId: notification.declaration_id },
        // replace with this when correct id is inserted => state: { declarationId: declarationId },
      });
    }
    if (notification.notification_type === "VALIDATION_CAS") {
      history.push({
        pathname: "/main/validation",
        state: { declarationId: notification.declaration_id },
        // replace with this when correct id is inserted => state: { declarationId: declarationId },
      });
    }
    if (notification.notification_type === "MODIFICATION_REFERENTIEL") {
      history.push({
        pathname: "/main/update-ref",
        state: { category: 0, table: 0 },
        // replace with this when correct id is inserted => state: { declarationId: declarationId },
      });
    }

    onShowModal(false);
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        onShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          height: "80%",
          borderRadius: 4,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <CloseIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            onShowModal(false);
          }}
        />
        <h2 className="modal-title">Notifications</h2>
        <Divider />
        <div className="notifications-container">
          {notifications && notifications.map((notification, index) => (
            <Notification
              key={index}
              index={index}
              dates={dates}
              onConsultCase={consultCase}
              declarationId={318}
              notification={notification}
            />
          ))}
        </div>

        <div className="modal-footer">
          <button onClick={() => onShowModal(false)} className="button">
            OK
          </button>
        </div>
      </Box>
    </Modal>
  );
}
