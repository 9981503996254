import React from "react";
import { Stack } from "@mui/material";
import './index.scss';


export default function LabelInfo(props) {
      const { title, value } = props;
      let newValue = value === undefined || value === '' ? <span style={{ color: 'red' }}>VIDE</span> : value;

      return (
            <div className='label-info-container'>
                  <Stack direction="row" spacing={0}>

                        <div className='label-info-title'>
                              <label>{title}&ensp;:&ensp; </label>
                        </div>
                        <div className='label-info-value'>
                              <label>{newValue}</label>
                        </div>

                  </Stack>
            </div>
      )
}