import React from "react";
import { Stack } from "@mui/material";
import "../../index.scss";
import LabelInput from "../../../LabelInput/LabelInput";
import EchantillonTable from "../EchantillonTable/EchantillonTable";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomMoleculeDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelMultipleMenu from "../../../LabelMenu/LabelMultipleMenu";

export default function Echantillon(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const referentielResults = useReferentiels(["examen_demande"]);

  const widthPercentage = "31%";
  const prefix = "echantillon.";

  const handleCheckBoxChange = (event) => {
    setFieldValue(
      "echantillon.examen_demande." + event.target.name,
      event.target.checked
    );
  };

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const examens = flattenIdNomMoleculeDict(referentielResults[0].data);
  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>L'échantillon</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Heure prélévement"}
                  name={prefix + "heure_prelevement"}
                  value={getIn(values, prefix + "heure_prelevement")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "heure_prelevement") &&
                    Boolean(getIn(errors, prefix + "heure_prelevement"))
                  }
                  helperText={
                    getIn(touched, prefix + "heure_prelevement") &&
                    getIn(errors, prefix + "heure_prelevement")
                  }
                  type={"time"}
                />
              </div>
            </Stack>
          </div>
          <div>
            <EchantillonTable />
          </div>
          <div>
            <Stack direction="column" spacing={1}>
              <div style={{ width: "98%" }}>
                <LabelMultipleMenu
                  title={"Examens demandés"}
                  name={prefix + "examen_demande"}
                  initialValues={getIn(values, prefix + "examen_demande")}
                  onChange={setFieldValue}
                  error={
                    getIn(touched, prefix + "examen_demande") &&
                    Boolean(getIn(errors, prefix + "examen_demande"))
                  }
                  helperText={
                    getIn(touched, prefix + "examen_demande") &&
                    getIn(errors, prefix + "examen_demande")
                  }
                  mandatory={true}
                  valuesDict={examens}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
