import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { Stack } from "carbon-components-react/lib/components/Stack";
import { Button } from "carbon-components-react";

export default function RejectCaseModal(props) {
  const { showModal, onShowModal, onDelete } = props;
  return (
    <Modal
      open={showModal}
      onClose={() => onShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          height: 500,
          borderRadius: 4,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CloseIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => onShowModal(false)}
        />
        
        <Typography id="modal-modal-description" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} sx={{ mt: 2 }}>
           <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <img
            src="/assets/imgs/reject.png"
            alt=""
            width={80}
            style={{margin:"10px"}}
            />
            <h1 style={{margin:"10px"}} >Etes-vous sûr?</h1>
            <p style={{margin:"10px", fontWeight:"bolder", fontSize:"20px", color:"#9EA2B4"}}>Voulez-vous vraiment rejeter ce cas ?</p>
            <Stack direction="row" spacing={10} style={{ alignItems: 'center' }}>
                <Button onClick={() => onShowModal(false)}   style={{
                        background: '#E3E5E6',
                        height: '45px',
                        width: '144px',
                        color: '#5E6366',
                        cursor:"pointer",
                        border:"none",
                        margin:"12px",
                        borderRadius:7,
                    }}>Annuler</Button>
                <Button  onClick={()=>onDelete(true)} style={{
                        background: '#EB5757',
                        color: '#FFFFFF',
                        height: '45px',
                        width: '144px',
                        cursor:"pointer",
                        border:"none",
                        borderRadius:7,
                    }}>Rejeter</Button>
            </Stack>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
}
