import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Stack, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import RoleName from "../../components/RoleName/RoleName";
import LabelInput from "../../components/LabelInput/LabelInput";
import NormalButton from "../../components/Button";
import pen from "./assets/icon-park-outline_preview-open.png";
import {
  editProfile,
  getUserFromStore,
  onEditProfileSuccess,
} from "../../services/auth";
import { editProfileValidationSchema } from "../../utils/validationSchemas/others/formValidationSchemas";
import history from "../../utils/history";
import defaultAvatar from "../../assets/default_avatar.svg";
import "./index.scss";
import { useSnackbar } from "notistack";
import axios from "axios";
import { authHeaderKey } from "../../utils/axiosInstances";
import { getSecureAxiosInstance } from "../../utils/axiosInstances";

export default function UserProfile(props) {
  const user = getUserFromStore();
  const [changePassword, setChangePassword] = useState(false);
  const [avatarFilename, setAvatarFileName] = useState(user?.avatar_url);
  const [avatar, setAvatar] = useState("");
  const { enqueueSnackbar: showMessage } = useSnackbar();

  const axiosBaseConfig = {
    baseURL:
      process.env.REACT_APP_ENV === "prod"
        ? process.env.REACT_APP_BACKEND_URL_PROD
        : process.env.REACT_APP_BACKEND_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };


  useEffect(() => {
    loadImage()
  }, []);

  const loadImage = () => {
    if (!user) return;
    getSecureAxiosInstance().get(`user/avatar/${avatarFilename}`, {
        headers: { ...axiosBaseConfig.headers, Authorization: authHeaderKey() },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setAvatar(base64);
      });
  }

  let changePasswordText = "Changer mot de passe";
  let marginPct = "76%";

  const handleChangePassClick = () => {
    setChangePassword(!changePassword);
  };

  const handleCancelClick = () => {
    history.push("/main");
  };

  const errorLoadingAvatar = () => {
    showMessage("Un problème lors du chargement de l'image", {
      variant: "error",
    });
  };

  const successLoadingAvatar = () => {
    showMessage("Image chargée avec succès", { variant: "success" });
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (files) => {
      const file = files[0];
      if (!file) return;
      console.log(file)
      setAvatar(files[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        setAvatarFileName(event.target.result);

      };
      reader.readAsDataURL(file);
    },
    accept: ".jpeg,.jpg,.png",
    multiple: false,
    minSize: 0,
    maxSize: 5242880,

    onDropRejected: () => {
      errorLoadingAvatar();
    },
  });

  const handleSubmit = (values) => {
    const payload = {
      id: values.id,
      nom_prenom: values.nom_prenom,
      email: values.email,
      telephone: values.telephone,
      avatar: avatar,
    };
    if (changePassword) {
      payload.current_password = values.current_password;
      payload.new_password = values.new_password;
    }
    editProfile(payload)
      .then((res) => {
        onEditProfileSuccess(res.data);
        props.onUserChange();
        showMessage("Profil édité avec succès", { variant: "success" });
      })
      .catch((error) => {
        showMessage(
          "Un problème lors de l'édition de votre profil " + error.toString(),
          { variant: "error" }
        );
      });
  };

  const formik = useFormik({
    initialValues: {
      id: user?.id,
      avatar_filename: user?.avatar_filename,
      nom_prenom: user?.nom_prenom,
      email: user?.email,
      telephone: user?.telephone,
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: editProfileValidationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  let newPasswordSection = null;
  if (changePassword) {
    changePasswordText = "Annuler";
    marginPct = "90%";
    newPasswordSection = (
      <Stack direction="column" spacing={3}>
        <div>
          <LabelInput
            title="Mot de passe actuel"
            type="password"
            placeholder="Votre mot de passe actuel"
            name={"current_password"}
            value={formik.values.current_password}
            onChange={formik.handleChange}
            error={
              formik.touched.current_password &&
              Boolean(formik.errors.current_password)
            }
            helperText={
              formik.touched.current_password && formik.errors.current_password
            }
          />
        </div>
        <div>
          <Stack direction="row" spacing={2}>
            <div style={{ width: "50%" }}>
              <LabelInput
                title="Nouveau mot de passe"
                type="password"
                name={"new_password"}
                value={formik.values.new_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
              />
            </div>
            <div style={{ width: "50%" }}>
              <LabelInput
                title="Répétez le nouveau mot de passe"
                type="password"
                name={"confirm_new_password"}
                value={formik.values.confirm_new_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirm_new_password &&
                  Boolean(formik.errors.confirm_new_password)
                }
                helperText={
                  formik.touched.confirm_new_password &&
                  formik.errors.confirm_new_password
                }
              />
            </div>
          </Stack>
        </div>
      </Stack>
    );
  } else {
    newPasswordSection = null;
    changePasswordText = "Changer mot de passe";
    marginPct = "76%";
  }

  return (
    <div className="user-profile-container">
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={0}>
          <div className="user-profile-header">
            <div className="user-profile-avatar">
              <Stack direction="row" spacing={2}>
                <div className="user-profile-avatar-img">
                  <img
                    src={`data:image/jpeg;charset=utf-8;base64,${avatar}`}
                    alt=""
                  />
                  <div {...getRootProps()} className="dropzone-btn">
                    <input {...getInputProps()} />
                    <div className="user-profile-avatar-img-pen">
                      <img
                        src={pen}
                        alt=""
                        style={{ width: "22px", height: "22px" }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Stack direction="column" spacing={0}>
                    <div className="user-profile-avatar-text">
                      <label>{user?.nom_prenom}</label>
                    </div>
                    <div className="user-profile-avatar-status">
                      <RoleName type={user?.role_id} />
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
          </div>
          <div className="user-profile-body">
            <div className="user-profile-body-elements">
              <Stack direction="column" spacing={3}>
                {/* <div>
                                <LabelTextArea title='Description:' placeholder='A propos de vous' value={user?.description} />
                            </div> */}
                <div>
                  <LabelInput
                    title="Nom et prénom"
                    laceholder="Votre nom et prénom"
                    name={"nom_prenom"}
                    value={formik.values.nom_prenom}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nom_prenom &&
                      Boolean(formik.errors.nom_prenom)
                    }
                    helperText={
                      formik.touched.nom_prenom && formik.errors.nom_prenom
                    }
                  />
                </div>
                <div>
                  <LabelInput
                    title="Email"
                    placeholder="Votre Email"
                    disabled={true}
                    name={"email"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div>
                  <LabelInput
                    title="Télephone"
                    placeholder="Votre numéro de télephone"
                    name={"telephone"}
                    value={formik.values.telephone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.telephone &&
                      Boolean(formik.errors.telephone)
                    }
                    helperText={
                      formik.touched.telephone && formik.errors.telephone
                    }
                  />
                </div>
                <div>
                  <Stack direction="row" spacing={2}>
                    <div style={{ width: "100%" }}>
                      <LabelInput
                        title="Mot de passe"
                        type="password"
                        placeholder="Votre mot de passe"
                        value={"********"}
                      />
                      <Button
                        variant="text"
                        style={{ textTransform: "none", marginLeft: marginPct }}
                        onClick={handleChangePassClick}
                      >
                        {changePasswordText}
                      </Button>
                    </div>
                  </Stack>
                </div>
                {newPasswordSection}
                <div className="user-profile-footer">
                  <Stack direction="row-reverse" spacing={1}>
                    <NormalButton btnType="save" type="submit" />
                    <NormalButton
                      btnType="cancel"
                      onClick={handleCancelClick}
                    />
                  </Stack>
                </div>
              </Stack>
            </div>
          </div>
        </Stack>
      </form>
    </div>
  );
}
