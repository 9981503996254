import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { getFEFormatResultat } from "../../../../utils/divers/divers";
import { getReferentiel } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function GCMS(props) {
  const value = useFormikContext();
  const [entreeNom, setEntreeNom] = useState({});
  const [gcms, setGcms] = useState({});

  const selectedCase = useContext(CaseContext);

  useEffect(() => {
    getReferentiel("entree_nom").then((data) => {
      let flattenData = flattenIdNomDict(data);
      setEntreeNom(flattenData);
      const { values } = value || {};
      const resultatLabo = selectedCase
        ? getFEFormatResultat(selectedCase?.resultats_labo, flattenData) : values.resultat;
      setGcms(resultatLabo?.GC);
    })
  }, []);
  const widthPercentage = "45%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Chromatographie Gazeuse</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Recherche / Dosage "
                  value={parseInt(gcms?.recherche_dosage) === 0 ? "Dosage" : "Recherche"}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Résultat d'analyse"
                  value={parseInt(gcms?.resultat_analyse) === 0 ? "Négatif" : "Positif"}
                />
              </div>
            </Stack>
          </div>
          <div className="view-case-common-container-body-label">
            <label>Produits Testés</label>
          </div>
          <div>
            {gcms?.produits_testes && gcms?.produits_testes
              .filter((res) => res.checked === true)
              .map((result) => (
                <Stack direction="row" spacing={1}>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo title="Produit" value={result.produit} />
                  </div>
                  <div
                    style={{
                      width: widthPercentage,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {result.molecules.map((molecule) => (
                      <LabelInfo title="Molécule" value={molecule} />
                    ))}
                  </div>
                  <div
                    style={{
                      width: widthPercentage,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {result.dosages.map((dosage) => (
                      <LabelInfo
                        title="Dosage"
                        value={dosage.sign + dosage.value + dosage.unit}
                      />
                    ))}
                  </div>
                </Stack>
              ))}
          </div>
        </Stack>
      </div>
    </div>
  );
}
