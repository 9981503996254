import { useQuery } from "react-query";
import { getSecureAxiosInstance } from "../../utils/axiosInstances";




const useUsers = () => {
  return useQuery('users', getUsers);
}

const getUsers = async () => {
  
  const { data } = await getSecureAxiosInstance().get('user-management/users');
  return data;
  
}

const addUser = (values) => {
  return getSecureAxiosInstance().post('user-management/create-user', values);
}

const editUser = (values) => {
  return getSecureAxiosInstance().patch('user-management/edit-user', values);
}

const deleteUser = (id) => {
  return getSecureAxiosInstance().delete(`user-management/delete-user?id=${id}`)
}

export {
  useUsers,
  addUser,
  editUser,
  deleteUser
}