import React, { useEffect } from "react";
import {
    Stack,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { getPlanteDetails } from "../../../../services/cases/cases";

import "./index.scss";
import "../../index.scss";

export default function ToxiqueDetailsPlante(props) {
    const { toxiqueType, toxiqueTitle, index } = props;
    const { values, setFieldValue, touched, errors, handleChange } =
        useFormikContext();
    const widthPercentage = "45%";
    const referentielResults = useReferentiels([
        "plante_nom_vernaculaire",
        "voie_intoxication",
        "partie_plante",
        "presentation_plante",
        "mode_preparation_plante"
    ]);
    const namePrefix = "toxiques." + toxiqueType + "." + index + ".";

    const handleProduitChange = (event) => {
        handleChange(event);
        let nomId = event.target.value;
        updateData(nomId)
    };

    const updateData = (nomId) => {
        getPlanteDetails(nomId).then((data) => {
            setFieldValue(namePrefix + "nom_scientifique", data.nom_scientifique);
            setFieldValue(namePrefix + "nom_francais", data.nom_francais);
            setFieldValue(namePrefix + "code", data.code);

        });
    }

    useEffect(() => {
        setFieldValue(namePrefix + "index", index + 1);
        setFieldValue(namePrefix + "title", toxiqueTitle);
        let nomId = getIn(values, namePrefix + "nom_vernaculaire_id");
        if (nomId)
            updateData(nomId)
    }, [])

    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading) return <CustomLoader isLoading={isLoading} />;

    const plantes = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);
    const parties = flattenIdNomDict(referentielResults[2].data);
    const presentations = flattenIdNomDict(referentielResults[3].data);
    const modes = flattenIdNomDict(referentielResults[4].data);

    return (
        <div className="toxique-details-container">
            <Stack direction="column" spacing={1}>
                <div className="toxique-details-header">
                    <div style={{ width: "100%", height: "59px" }}>
                        <FormControl component="fieldset" style={{ height: "100%" }}>
                            <Stack
                                direction="row"
                                spacing={0}
                                style={{ height: "100%", alignItems: "center" }}
                            >
                                <div className="toxique-details-header-title">
                                    <label>
                                        {toxiqueTitle} <span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                                <div className="case-common-container-body-radio">
                                    <RadioGroup
                                        row
                                        name={namePrefix + "connu_inconnu"}
                                        onChange={(event) =>
                                            setFieldValue(
                                                namePrefix + "connu_inconnu",
                                                event.target.value
                                            )
                                        }
                                        value={getIn(values, namePrefix + "connu_inconnu")}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="Connu"
                                        />
                                        <FormControlLabel
                                            value="0"
                                            control={<Radio />}
                                            label="Inconnu"
                                        />
                                    </RadioGroup>
                                </div>
                            </Stack>
                        </FormControl>
                    </div>
                </div>
                {parseInt(getIn(values, namePrefix + "connu_inconnu")) === 1 && (
                    <div className="toxique-details-body">
                        <div className="toxique-details-elt">
                            <Stack direction="column" spacing={4}>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Nom vernaculaire"}
                                                name={namePrefix + "nom_vernaculaire_id"}
                                                value={getIn(values, namePrefix + "nom_vernaculaire_id")}
                                                onChange={handleProduitChange}
                                                error={
                                                    getIn(errors, namePrefix + "nom_vernaculaire_id") &&
                                                    Boolean(getIn(errors, namePrefix + "nom_vernaculaire_id"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "nom_vernaculaire_id") &&
                                                    getIn(errors, namePrefix + "nom_vernaculaire_id")
                                                }
                                                mandatory={true}
                                                valuesDict={plantes}
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Nom français"}
                                                name={namePrefix + "nom_francais"}
                                                value={getIn(values, namePrefix + "nom_francais")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "nom_francais") &&
                                                    Boolean(getIn(errors, namePrefix + "nom_francais"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "nom_francais") &&
                                                    getIn(errors, namePrefix + "nom_francais")
                                                }
                                                disabled
                                            />
                                        </div>

                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Nom scientifique"}
                                                name={namePrefix + "nom_scientifique"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "nom_scientifique"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "nom_scientifique") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "nom_scientifique")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "nom_scientifique") &&
                                                    getIn(errors, namePrefix + "nom_scientifique")
                                                }
                                                disabled
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Code"}
                                                name={namePrefix + "code"}
                                                value={getIn(values, namePrefix + "code")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "code") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "code")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "code") &&
                                                    getIn(errors, namePrefix + "code")
                                                }
                                                disabled
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Partie utilisée de la plante"}
                                                name={namePrefix + "partie_utilisee_id"}
                                                value={getIn(values, namePrefix + "partie_utilisee_id")}
                                                onChange={handleProduitChange}
                                                error={
                                                    getIn(errors, namePrefix + "partie_utilisee_id") &&
                                                    Boolean(getIn(errors, namePrefix + "partie_utilisee_id"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "partie_utilisee_id") &&
                                                    getIn(errors, namePrefix + "partie_utilisee_id")
                                                }
                                                mandatory={true}
                                                valuesDict={parties}
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Présentation"}
                                                name={namePrefix + "presentation_id"}
                                                value={getIn(values, namePrefix + "presentation_id")}
                                                onChange={handleProduitChange}
                                                error={
                                                    getIn(errors, namePrefix + "presentation_id") &&
                                                    Boolean(getIn(errors, namePrefix + "presentation_id"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "presentation_id") &&
                                                    getIn(errors, namePrefix + "presentation_id")
                                                }
                                                mandatory={true}
                                                valuesDict={presentations}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Doses administrées"}
                                                name={namePrefix + "dose_administree"}
                                                value={getIn(values, namePrefix + "dose_administree")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "dose_administree") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "dose_administree")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "dose_administree") &&
                                                    getIn(errors, namePrefix + "dose_administree")
                                                }
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Mode de préparation"}
                                                name={namePrefix + "mode_preparation_id"}
                                                value={getIn(values, namePrefix + "mode_preparation_id")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "mode_preparation_id") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "mode_preparation_id")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "mode_preparation_id") &&
                                                    getIn(errors, namePrefix + "mode_preparation_id")
                                                }
                                                valuesDict={modes}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Voie d'intoxication"}
                                                name={namePrefix + "voie_intoxication_id"}
                                                value={getIn(values, namePrefix + "voie_intoxication_id")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "voie_intoxication_id") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "voie_intoxication_id")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "voie_intoxication_id") &&
                                                    getIn(errors, namePrefix + "voie_intoxication_id")
                                                }
                                                valuesDict={voix}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <div style={{ height: "59px" }}>
                                        <FormControl
                                            component="fieldset"
                                            style={{ height: "100%" }}
                                        >
                                            <Stack direction="column" spacing={0} style={{ height: "100%" }}>
                                                <div className="case-common-container-body-label">
                                                    <label style={{ fontSize: "14px", lineHeight: "17px", marginTop: "-4px" }}>Conseillée dans les médias:</label>
                                                </div>
                                                <div className="case-common-container-body-radio">
                                                    <RadioGroup
                                                        row
                                                        name={namePrefix + "conseillee_media"}
                                                        onChange={(event) =>
                                                            setFieldValue(namePrefix + "conseillee_media", event.target.value)
                                                        }
                                                        value={getIn(values, namePrefix + "conseillee_media")}
                                                    >
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio />}
                                                            label="Oui"
                                                        />
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio />}
                                                            label="Non"
                                                        />

                                                    </RadioGroup>
                                                </div>
                                            </Stack>
                                        </FormControl>
                                    </div>
                                </div>

                            </Stack>
                        </div>
                    </div>
                )}
            </Stack>
        </div>
    );
}
