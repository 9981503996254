import React, {useContext} from "react";
import { Stack } from "@mui/material";
import '../../index.scss';
import LabelInput from "../../../LabelInput/LabelInput";
import { useFormikContext } from "formik";


export default function Reference(props) {
      const {values, touched, errors, handleChange, setFieldValue} = useFormikContext();
      const widthPercentage = '31%';

      return (
            <div className="case-common-container" >
                  <div className="case-common-container-title">
                        <label>Reference</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Référence R0"} 
                                                      name={"reference.r0"} 
                                                      onChange={handleChange}
                                                      error={touched?.reference?.r0 && Boolean(errors?.reference?.r0)}
                                                      helperText={touched?.reference?.r0 && errors?.reference?.r0}
                                                      mandatory={true}  />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Référence R1"} 
                                                      name={"reference.r1"} 
                                                      onChange={handleChange}
                                                      error={touched?.reference?.r1 && Boolean(errors?.reference?.r1)}
                                                      helperText={touched?.reference?.r1 && errors?.reference?.r1}
                                                      mandatory={true}  />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Référence R2"}
                                                      name={"reference.r2"} 
                                                      onChange={handleChange}
                                                      error={touched?.reference?.r2 && Boolean(errors?.reference?.r2)}
                                                      helperText={touched?.reference?.r2 && errors?.reference?.r2}
                                                      mandatory={true}  />
                                          </div>
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}