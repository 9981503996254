import React from "react";
import { useMutation } from "react-query";
import { Stack, Button, IconButton } from "@mui/material";
import LabelInput from "../LabelInput/LabelInput";
import LabelMenu from "../LabelMenu/LabelMenu";
import LabelMultipleMenu from "../LabelMenu/LabelMultipleMenu";
import { UserViewCloseIcon } from "../Icons/Icons";
import RoleName from "../RoleName/RoleName";
import { useFormik } from "formik";

import { flattenIdNomDict } from "../../utils/referentielData";
import { useReferentiels } from "../../services/referentiels/referentiels";
import CustomLoader from "../CustomLoader/CustomLoader";
import { getRolesDict } from "../../utils/rolesManagement";
import "./index.scss";

export default function ViewUserForm(props) {
  const { onClose, formik } = props;
  const inputBackground = "#FFFFFF";
  const referentielResults = useReferentiels(['region', 'vigilance']);
  const [respVigilanceSelected, setRespVigilanceSelected] = React.useState(formik.values.role_id === 5);

  const handleRoleChanged = (e) => {
    formik.handleChange(e);
    setRespVigilanceSelected(e.target.value === "5");
  }
  const isLoading = referentielResults.some(result => result.isLoading)
  if (isLoading) {
    return (<CustomLoader isLoading={isLoading} />)
  }
  const rolesDict = getRolesDict();
  const regions = flattenIdNomDict(referentielResults[0].data);
  const vigilances = flattenIdNomDict(referentielResults[1].data);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="view-user-body">
        <Stack direction="row" spacing={6}>
          <div style={{ width: "100%" }}>
            <Stack direction="column" spacing={4}>
              <LabelInput
                title={"Nom et prénom"}
                name={"nom_prenom"}
                value={formik.values.nom_prenom}
                onChange={formik.handleChange}
                error={
                  formik.touched.nom_prenom && Boolean(formik.errors.nom_prenom)
                }
                helperText={
                  formik.touched.nom_prenom && formik.errors.nom_prenom
                }
                inputBackground={inputBackground}
              />

              <LabelInput
                title={"Téléphone"}
                name={"telephone"}
                value={formik.values.telephone}
                onChange={formik.handleChange}
                inputBackground={inputBackground}
              />
              <LabelMenu
                title="Rôle"
                name={"role_id"}
                value={formik.values.role_id}
                onChange={(e) => handleRoleChanged(e)}
                error={formik.touched.role_id && Boolean(formik.errors.role_id)}
                helperText={formik.touched.role_id && formik.errors.role_id}
                valuesDict={rolesDict}
              />
            </Stack>
          </div>
          <div style={{ width: "100%" }}>
            <Stack direction="column" spacing={4}>
              <LabelInput
                title={"Adresse email"}
                name={"email"}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                inputBackground={inputBackground}
              />
              <LabelMenu
                title="Région"
                name={"region_id"}
                value={formik.values.region_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.region_id && Boolean(formik.errors.region_id)
                }
                helperText={formik.touched.region_id && formik.errors.region_id}
                valuesDict={regions}
              />
              {respVigilanceSelected === true &&
                <LabelMultipleMenu title='Vigilances' name={"vigilances"} value={formik.values.vigilances}
                  onChange={formik.setFieldValue}
                  valuesDict={vigilances} />
              }
            </Stack>
          </div>
        </Stack>
      </div>
      <div className="view-user-footer">
        <div style={{ paddingRight: "31px", paddingTop: "17px" }}>
          <Stack
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="contained"
              type="submit"
              style={{
                background: "#367BF5",
                height: "32px",
                textTransform: "none",
              }}
            >
              Sauvegarder
            </Button>
            <Button
              style={{
                color: "#4F566B",
                height: "32px",
                textTransform: "none",
              }}
              onClick={onClose}
            >
              Annuler
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
}
