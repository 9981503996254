import React from "react"
import { Stack } from "@mui/material";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import '../../index.scss';

export default function Patient(props) {

      const widthPercentage = '31%';
      return (
            <div className="view-case-common-container">
                  <div className="view-case-common-container-title">
                        <label>Le patient</label>
                  </div>
                  <div className="view-case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInfo title='Sexe masculin' />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInfo title='Sexe féminin' />
                                          </div>
                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInfo title='Age < 15' />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInfo title='Age > 15' />
                                          </div>
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}


