import React from "react";
import { Button, ThemeProvider } from "@mui/material";
import { ExcelIcon } from "../Icons/Icons";
import { excelButtonTheme } from "./ButtonsTheme";

export default function ExcelButton(props) {
  const onClick = props.onClick;
  const isLoading = props.isLoading;
  const icon = <ExcelIcon width="30" height="30" />;
  return (
    <ThemeProvider theme={excelButtonTheme}>
      <Button
        variant="outlined"
        startIcon={icon}
        disabled={isLoading}
        onClick={onClick}
      >
        <span style={{fontFamily: "Roboto"}}>Exporter la sélection vers Excel</span>
      </Button>
    </ThemeProvider>
  );
}
