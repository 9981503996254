const roleColors = {
      1: '#29CC97',
      2: '#025E86',
      3: '#367BF5',
      4: '#EABE7D',
      5: '#929AC3',
      6: '#F0797F'
}

const roleById = {
      1: 'admin',
      2: 'operateur-saisie',
      3: 'medecin-garde',
      4: 'responsable-region',
      5: 'responsable-vigilance',
      6: 'responsable-laboratoire'
}

const roleNames = {
      1: 'Admin',
      2: 'Opérateur de Saisie',
      3: 'Médecin de garde',
      4: 'Responsable Région',
      5: 'Responsable vigilance',
      6: 'Responsable Laboratoire'
}

function getRoles() {
      return Object.keys(roleNames);
}

function getRolesDict() {
      return roleNames;
}

function getRoleColor(roleType) {
      return roleColors[roleType];
}

function getRoleName(roleType) {
      return roleNames[roleType];
}

function getRoleById(roleId) {
      return roleById[roleId];
}


export { getRoleColor, getRoleName, getRoles, getRolesDict, getRoleById };