import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { getCasebyId } from "../../services/cases/cases";
import ViewToxiCase from "../../modules/ViewCase/Toxi/ViewToxiCase";
import CustomButton from "../Buttons/CustomButton";
import { Stack } from "@mui/material";
import ViewLaboCase from "../../modules/ViewCase/Labo/ViewLaboCase";

export const CaseContext = React.createContext();

export default function PreviewCaseModal(props) {
  const { showModal, category, onShowModal, previewCase, editCase } = props;
  const [selectedCase, setSelectedCase] = useState("");

  useEffect(() => {
    if (!previewCase) return;
    getCasebyId(previewCase, category).then((data) => {
      setSelectedCase(data);
    });
  }, [previewCase]);

  return (
    <Modal
      open={showModal}
      onClose={() => {
        onShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80%",
          borderRadius: 4,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <CloseIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            onShowModal(false);
          }}
        />
        <div style={{ margin: 10, marginRight: 30 }}>
          <CustomButton
            type={"edit"}
            onClick={() => {
              editCase();
            }}
            title={"Modifier le cas"}
            customBackground={true}
          />
        </div>

        {selectedCase && (
          <CaseContext.Provider value={selectedCase}>
            <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
              {category.toLowerCase() === "toxicovigilance" ? (
                <ViewToxiCase />
              ) : (
                <ViewLaboCase />
              )}
            </div>
          </CaseContext.Provider>
        )}
      </Box>
    </Modal>
  );
}
