import React from "react";
import { Stack } from "@mui/material";

import Patient from "../../../components/Case/Common/Patient/Patient";
import '../index.scss';




export default function AddLaboPatient(props) {

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={4}>
                        <Patient />
                  </Stack>
            </div>

      )
}