import * as yup from 'yup';
import { phoneRegExp } from '../../divers/divers';

const LOGIN_VALIDATION_SCHEMA = yup.object({
      email: yup
            .string('Entrer un email')
            .email('Entrer un email valide')
            .required("L'email est obligatoire"),
      password: yup
            .string('Entrer votre mot de passe')
            .required("Le mot de passe est obligatoire"),
      remember: yup.boolean()
});

const newUserValidationSchema = yup.object({
      nom_prenom: yup
            .string('Entrer un nom et prénom')
            .required("Le nom et prénom sont obligatoires"),
      email: yup
            .string('Entrer un email')
            .email('Entrer un email valide')
            .required("L'email est obligatoire"),
      role_id: yup
            .number('Entrer un rôle')
            .required("Le rôle est obligatoire"),
      region_id: yup
            .number('Entrer une région'),
      telephone: yup.string().nullable()
            .matches(phoneRegExp, 'Numéro de téléphone invalide')
            .min(0, "Le numéro de téléphone doit avoir 10 chiffres")
            .max(10, "Le numéro de téléphone doit avoir 10 chiffres"),
      vigilances: yup.array(),
});

const editProfileValidationSchema = yup.object({
      nom_prenom: yup
            .string('Entrer un nom et prénom')
            .required("Le nom et prénom sont obligatoires"),
      email: yup
            .string('Entrer un email')
            .email('Entrer un email valide')
            .required("L'email est obligatoire"),
      telephone: yup.string().nullable()
            .matches(phoneRegExp, 'Numéro de téléphone invalide')
            .min(0, "Le numéro de téléphone doit avoir 10 chiffres")
            .max(10, "Le numéro de téléphone doit avoir 10 chiffres"),
      current_password: yup
            .string('Entrer votre mot de passe actuel'),
      new_password: yup
            .string('Entrer un mot de passe'),
      confirm_new_password: yup
            .string('Ressaisir le nouveau mot de pass')
            .oneOf([yup.ref('new_password'), null], 'Ressaisir le nouveau mot de passe')
})

const groupeAgeSchema = yup.object({
      nom: yup
            .string("Entrer un nom du groupe d'âge")
            .required("Le nom est obligatoire"),
      debut_age: yup
            .number('Entrer le début')
            .required("Le début est obligatoire"),
      fin_age: yup
            .number('Entrer la fin')
            .required("La fin est obligatoire"),
      unite_age: yup
            .string('Entrer le type')
            .required("Le type est obligatoire"),
});





export { LOGIN_VALIDATION_SCHEMA, newUserValidationSchema, groupeAgeSchema, editProfileValidationSchema };