import React, { useEffect, useState } from "react";
import {
    Stack,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdCodeDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelDatePicker from "../../../LabelDatePicker/LabelDatePicker";

import "./index.scss";
import "../../index.scss";

export default function ToxiqueDetailsSerpent(props) {
    const { toxiqueType, toxiqueTitle, index } = props;
    const { values, setFieldValue, touched, errors, handleChange } =
        useFormikContext();

    const widthPercentage = "45%";
    const referentielResults = useReferentiels([
        "espece_serpent",
        "evolution_gradation",
        "premiers_gestes",
        "serpent_traitement_symptomatique",
        "syndrome_cobraique",
        "syndrome_viperin"
    ]);
    const namePrefix = "toxiques." + toxiqueType + "." + index + ".";

    useEffect(() => {
        setFieldValue(namePrefix + "index", index + 1);
        setFieldValue(namePrefix + "title", toxiqueTitle);
        setFieldValue(namePrefix + "voie_intoxication_id", 3);
        setFieldValue(namePrefix + "code", "");
    }, []);
    const especeChange = (event) => {
        handleChange(event);
        setFieldValue(namePrefix + "code", codes[event.target.value]);

    }

    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading) return <CustomLoader isLoading={isLoading} />;

    const especes = flattenIdNomDict(referentielResults[0].data);
    const codes = flattenIdCodeDict(referentielResults[0].data);
    const evolutions = flattenIdNomDict(referentielResults[1].data);
    const premiers_gestes = flattenIdNomDict(referentielResults[2].data);
    const serpent_traitement_symptomatiques = flattenIdNomDict(referentielResults[3].data);
    const syndrome_cobraiques = flattenIdNomDict(referentielResults[4].data);
    const syndrome_viperin = flattenIdNomDict(referentielResults[5].data);

    return (
        <div className="toxique-details-container">
            <Stack direction="column" spacing={1}>
                <div className="toxique-details-header">
                    <div style={{ width: "100%", height: "59px" }}>
                        <FormControl component="fieldset" style={{ height: "100%" }}>
                            <Stack
                                direction="row"
                                spacing={0}
                                style={{ height: "100%", alignItems: "center" }}
                            >
                                <div className="toxique-details-header-title">
                                    <label>
                                        {toxiqueTitle} <span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                                <div className="case-common-container-body-radio">
                                    <RadioGroup
                                        row
                                        name={namePrefix + "connu_inconnu"}
                                        onChange={(event) =>
                                            setFieldValue(
                                                namePrefix + "connu_inconnu",
                                                event.target.value
                                            )
                                        }
                                        value={getIn(values, namePrefix + "connu_inconnu")}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="Connu"
                                        />
                                        <FormControlLabel
                                            value="0"
                                            control={<Radio />}
                                            label="Inconnu"
                                        />
                                    </RadioGroup>
                                </div>
                            </Stack>
                        </FormControl>
                    </div>
                </div>
                {parseInt(getIn(values, namePrefix + "connu_inconnu")) === 1 && (
                    <div className="toxique-details-body">
                        <div className="toxique-details-elt">
                            <Stack direction="column" spacing={4}>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: "70%" }}>
                                            <LabelMenu
                                                title={"Espèce"}
                                                name={namePrefix + "espece_serpent_id"}
                                                value={getIn(values, namePrefix + "espece_serpent_id")}
                                                onChange={especeChange}
                                                error={
                                                    getIn(errors, namePrefix + "espece_serpent_id") &&
                                                    Boolean(getIn(errors, namePrefix + "espece_serpent_id"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "espece_serpent_id") &&
                                                    getIn(errors, namePrefix + "espece_serpent_id")
                                                }
                                                mandatory={true}
                                                valuesDict={especes}
                                            />
                                        </div>
                                        <div style={{ width: "20%" }}>
                                            <LabelInput
                                                title={"Code"}
                                                name={namePrefix + "code"}
                                                value={getIn(values, namePrefix + "code")}
                                                disabled={true}
                                            />
                                        </div>

                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Voie d'intoxication"}
                                                name={namePrefix + "voie_intoxication_id"}
                                                value={3}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "voie_intoxication_id") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "voie_intoxication_id")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "voie_intoxication_id") &&
                                                    getIn(errors, namePrefix + "voie_intoxication_id")
                                                }
                                                valuesDict={{ 3: 'Cutanée' }}
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Malade référé"}
                                                name={namePrefix + "malade_refere"}
                                                value={getIn(values, namePrefix + "malade_refere")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "malade_refere") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "malade_refere")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "malade_refere") &&
                                                    getIn(errors, namePrefix + "malade_refere")
                                                }
                                                valuesDict={{ "1": 'Oui', "0": 'Non' }}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: "96%" }}>
                                            <LabelInput
                                                title={"Siège morsure"}
                                                name={namePrefix + "siege_morsure"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "siege_morsure"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "siege_morsure") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "siege_morsure")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "siege_morsure") &&
                                                    getIn(errors, namePrefix + "siege_morsure")
                                                }

                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelDatePicker
                                                title={"Date de la morsure"}
                                                name={namePrefix + "date_morsure"}
                                                value={getIn(values, namePrefix + "date_morsure")}
                                                onChange={setFieldValue}
                                                error={
                                                    getIn(touched, namePrefix + "date_morsure") &&
                                                    Boolean(getIn(errors, namePrefix + "date_morsure"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "date_morsure") &&
                                                    getIn(errors, namePrefix + "date_morsure")
                                                }
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Heure de la morsure"}
                                                name={namePrefix + "heure_morsure"}
                                                value={getIn(values, namePrefix + "heure_morsure")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(touched, namePrefix + "heure_morsure") &&
                                                    Boolean(getIn(errors, namePrefix + "heure_morsure"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "heure_morsure") &&
                                                    getIn(errors, namePrefix + "heure_morsure")
                                                }

                                                type={"time"}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelDatePicker
                                                title={"Date de l'admission"}
                                                name={namePrefix + "date_admission"}
                                                value={getIn(values, namePrefix + "date_admission")}
                                                onChange={setFieldValue}
                                                error={
                                                    getIn(touched, namePrefix + "date_admission") &&
                                                    Boolean(getIn(errors, namePrefix + "date_admission"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "date_admission") &&
                                                    getIn(errors, namePrefix + "date_admission")
                                                }
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Heure de l'admission"}
                                                name={namePrefix + "heure_admission"}
                                                value={getIn(values, namePrefix + "heure_admission")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(touched, namePrefix + "heure_admission") &&
                                                    Boolean(getIn(errors, namePrefix + "heure_admission"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "heure_admission") &&
                                                    getIn(errors, namePrefix + "heure_admission")
                                                }

                                                type={"time"}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: "96%" }}>
                                            <LabelMenu
                                                title={"Grade (syndrome vipérin)"}
                                                name={namePrefix + "grade_syndrome_viperin"}
                                                value={getIn(values, namePrefix + "grade_syndrome_viperin")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "grade_syndrome_viperin") &&
                                                    Boolean(getIn(errors, namePrefix + "grade_syndrome_viperin"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "grade_syndrome_viperin") &&
                                                    getIn(errors, namePrefix + "grade_syndrome_viperin")
                                                }

                                                valuesDict={syndrome_viperin}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: "96%" }}>
                                            <LabelMenu
                                                title={"Syndrome cobraîque"}
                                                name={namePrefix + "grade_syndrome_cobraique"}
                                                value={getIn(values, namePrefix + "grade_syndrome_cobraique")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "grade_syndrome_cobraique") &&
                                                    Boolean(getIn(errors, namePrefix + "grade_syndrome_cobraique"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "grade_syndrome_cobraique") &&
                                                    getIn(errors, namePrefix + "grade_syndrome_cobraique")
                                                }

                                                valuesDict={syndrome_cobraiques}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: "96%" }}>
                                            <LabelMenu
                                                title={"Premiers gestes"}
                                                name={namePrefix + "premiers_gestes"}
                                                value={getIn(values, namePrefix + "premiers_gestes")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "premiers_gestes") &&
                                                    Boolean(getIn(errors, namePrefix + "premiers_gestes"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "premiers_gestes") &&
                                                    getIn(errors, namePrefix + "premiers_gestes")
                                                }

                                                valuesDict={premiers_gestes}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: "96%" }}>
                                            <LabelMenu
                                                title={"Traitement symptomatique"}
                                                name={namePrefix + "traitement_symptomatique"}
                                                value={getIn(values, namePrefix + "traitement_symptomatique")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "traitement_symptomatique") &&
                                                    Boolean(getIn(errors, namePrefix + "traitement_symptomatique"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "traitement_symptomatique") &&
                                                    getIn(errors, namePrefix + "traitement_symptomatique")
                                                }

                                                valuesDict={serpent_traitement_symptomatiques}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Type de sérum administré"}
                                                name={namePrefix + "type_serum_administre"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "type_serum_administre"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "type_serum_administre") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "type_serum_administre")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "type_serum_administre") &&
                                                    getIn(errors, namePrefix + "type_serum_administre")
                                                }

                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Délai d’administration en heure "}
                                                name={namePrefix + "delai_administration"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "delai_administration"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "delai_administration") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "delai_administration")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "delai_administration") &&
                                                    getIn(errors, namePrefix + "delai_administration")
                                                }

                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>

                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Nombre d'ampoules administrées "}
                                                name={namePrefix + "nombre_ampoules_administrees"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "nombre_ampoules_administrees"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "nombre_ampoules_administrees") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "nombre_ampoules_administrees")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "nombre_ampoules_administrees") &&
                                                    getIn(errors, namePrefix + "nombre_ampoules_administrees")
                                                }

                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Durée d'hospitalisation (en jours)"}
                                                name={namePrefix + "duree_hospitalisation"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "duree_hospitalisation"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "duree_hospitalisation") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "duree_hospitalisation")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "duree_hospitalisation") &&
                                                    getIn(errors, namePrefix + "duree_hospitalisation")
                                                }

                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: "96%" }}>
                                            <LabelInput
                                                title={"Effets indésirables observés "}
                                                name={namePrefix + "effets_indesirables"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "effets_indesirables"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "effets_indesirables") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "effets_indesirables")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "effets_indesirables") &&
                                                    getIn(errors, namePrefix + "effets_indesirables")
                                                }

                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>

                                        <div style={{ width: "96%" }}>
                                            <LabelMenu
                                                title={"Evolution de la gradation clinique et de la biologie "}
                                                name={namePrefix + "evolution_gradation"}
                                                value={getIn(values, namePrefix + "evolution_gradation")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "evolution_gradation") &&
                                                    Boolean(getIn(errors, namePrefix + "evolution_gradation"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "evolution_gradation") &&
                                                    getIn(errors, namePrefix + "evolution_gradation")
                                                }

                                                valuesDict={evolutions}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                            </Stack>
                        </div>
                    </div>
                )}
            </Stack>
        </div>
    );
}
