import React, { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ThemeProvider } from "@mui/material";

import gridTheme from "../GridTheme";
import CustomPagination from "../../Pagination";

import {
  DataGridDeleteIcon,
  DataGridEditIcon,
  DataGridEyeIcon,
} from "../../Icons/DataGridIcons";
import { createDataGridRows } from "../../../utils/dataGrid";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { flattenIdNomDict } from "../../../utils/referentielData";
import { useReferentiels } from "../../../services/referentiels/referentiels";
import "../index.scss";
import { useSnackbar } from "notistack";
import { deleteCase } from "../../../services/cases/cases";
import DeleteCaseModal from "../../Modal/DeleteCaseModal";

const numeroColunmWidth = 79;
const columnStyleClass = "grid-header";
const cellStyleClass = "grid-cell-style";

export default function BrouillonDataGrid(props) {
  const {
    cases,
    filter,
    columnNames,
    fieldNames,
    activTab,
    loading,
    onSetCases,
    onPreview,
    onShowModal,
    editCase,
    onSetSkip,
    totalCases,
    onSetSort,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState();
  const [deletedCase, setDeletedCase] = useState(false);
  const { enqueueSnackbar: showMessage } = useSnackbar();
  const referentielResults = useReferentiels(["type_rapport", "repondeur", "delai_reponse", "analyste"]);
  const isLoading = referentielResults.some((result) => result.isLoading);
  const showCase = React.useCallback(
    (id) => () => {
      onPreview(id);
      onShowModal(true);
    },
    [cases]
  );

  const deleteCaseFromTable = (declarationId) => {
    const currentCase = cases.find((c) => c.id == declarationId);
    const currentCaseIndex = cases.indexOf(currentCase);
    const newCases = [...cases];
    newCases.splice(currentCaseIndex, 1);
    onSetCases(newCases);
    setShowDeleteModal(false);
    setSelectedCase(undefined);
    setDeletedCase(undefined);
  };

  useEffect(() => {
    if (deletedCase && selectedCase) {
      deleteCase(selectedCase, activTab)
        .then(() => {
          showMessage("Cas supprimé", { variant: "warning" });
          deleteCaseFromTable(selectedCase);
        })
        .catch((error) => {
          showMessage("Erreur lors du rejet du cas " + error.toString(), {
            variant: "error",
          });
        });
    }
  }, [deletedCase]);

  const createColumns = (fieldNames, columnNames) => {
    let columns = [{ field: "id", hide: true }];
    for (let i = 0; i < fieldNames.length; i++) {
      let column = {
        field: fieldNames[i],
        headerName: columnNames[i],
        flex: 1,
        headerClassName: columnStyleClass,
        cellClassName: cellStyleClass,
        sortable:
          fieldNames[i] === "numero_dossier" ||
            fieldNames[i] === "date_saisie" ||
            fieldNames[i] === "date_reception"
            ? true
            : false,
      };

      if (fieldNames[i] == "actions") {
        column.type = "actions";
        column.headerName = "Actions";
        column.width = numeroColunmWidth + 20;
        column.getActions = (params) => [
          <GridActionsCellItem
            icon={<DataGridEyeIcon />}
            label="Afficher"
            onClick={showCase(params.id)}
          />,
          <GridActionsCellItem
            icon={<DataGridEditIcon />}
            label="Editer"
            onClick={() => editCase(params.id)}
          />,
          <GridActionsCellItem
            icon={<DataGridDeleteIcon />}
            label="Supprimer"
            onClick={() => {
              setShowDeleteModal(true);
              setSelectedCase(params.id);
            }}
          />,
        ];
      }
      columns.push(column);
    }
    return columns;
  };
  if (isLoading) return <CustomLoader isLoading={isLoading} />;
  const typeRapports = flattenIdNomDict(referentielResults[0].data);
  const repondeurs = flattenIdNomDict(referentielResults[1].data);
  const delaisReponses = flattenIdNomDict(referentielResults[2].data);
  const analystes = flattenIdNomDict(referentielResults[3].data);

  const rows = createDataGridRows("cases", cases, filter, { "types_rapports": typeRapports, "repondeurs": repondeurs, "delais": delaisReponses, "analystes": analystes });
  let columns = createColumns(fieldNames, columnNames);

  const data = { rows, columns };
  return (
    <ThemeProvider theme={gridTheme}>
      <div style={{ height: 555 }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              {...data}
              disableColumnSelector={true}
              disableColumnMenu
              sortingMode="server"
              loading={loading}
              pagination
              rowCount={totalCases}
              pageSize={8}
              onSortModelChange={(e) => {
                if (e && e[0]) {
                  onSetSort({
                    sort_order: e[0].sort == "asc" ? 0 : 1,
                    sort_column: e[0].field,
                  });
                }
              }}
              paginationMode="server"
              rowsPerPageOptions={[8]}
              onPageChange={(newPage) => {
                onSetSkip(newPage * 8);
              }}
              components={{
                Pagination: CustomPagination,
              }}
            />
          </div>
        </div>
      </div>
      <DeleteCaseModal
        showModal={showDeleteModal}
        onShowModal={setShowDeleteModal}
        selectedCase={selectedCase}
        onDelete={setDeletedCase}
      />
    </ThemeProvider>
  );
}
