import React from "react";
import { Stack } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import '../../index.scss';
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelTextArea from "../../../LabelTextArea";
import {useReferentiels} from "../../../../services/referentiels/referentiels";
import {flattenIdNomDict} from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";




export default function Analyste(props) {
      const {values, touched, errors, handleChange, setFieldValue} = useFormikContext();
      const referentielResults = useReferentiels(['analyste']);
      const widthPercentage = '31%';
      const prefix = "analyste.";
      const isLoading = referentielResults.some(result => result.isLoading)
      if (isLoading) 
            return (<CustomLoader isLoading={isLoading} />)

      const analystes = flattenIdNomDict(referentielResults[0].data);
      return (
            <div className="case-common-container">
                  <div className="case-common-container-title">
                        <label>Information sur l'analyste</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Analyste"} 
                                                      name={prefix+"analyste_id"}
                                                      value={getIn(values, prefix+"analyste_id")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, prefix+"analyste_id") && Boolean(getIn(errors, prefix+"analyste_id"))}
                                                      helperText={getIn(touched, prefix+"analyste_id") && getIn(errors, prefix+"analyste_id")}
                                                      mandatory={true} valuesDict={analystes} />
                                          </div>
                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ marginRight: '12px', width:'100%' }}>
                                                <LabelTextArea title={"Observation"} 
                                                      name={prefix+"observation"}
                                                      value={getIn(values, prefix+"observation")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, prefix+"observation") && Boolean(getIn(errors, prefix+"observation"))}
                                                      helperText={getIn(touched, prefix+"observation") && getIn(errors, prefix+"observation")}
                                                />
                                          </div>
                                    </Stack>
                              </div>

                        </Stack>
                  </div>
            </div>
      )
}