import React, { useState } from "react";
import { Stack } from "@mui/material";
import CustomButton from "../../../components/Buttons/CustomButton";
import AddScorpionMain from "./AddScorpionMain";
import NormalButton from "../../../components/Button";
import ViewScorpionCase from "../../ViewCase/Scorpion/ViewScorpionCase";
import { Formik, Form } from 'formik';
import { SCORPION_FORMIK_INITIAL_VALUES } from "../../../utils/validationSchemas/cases/initialValues";
import { SCORPION_FORMIK_VALIDATION_SCHEMA } from "../../../utils/validationSchemas/cases/validationSchemas";

import '../index.scss';


export default function AddScorpionCase(props) {

  const [preview, setPreview] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(<AddScorpionMain />);
  const handleCancelClick = () => {

  }

  const handleSubmitClick = (values, actions) => {
    alert(JSON.jsonify(values));
  }

  const handlePreviewCase = () => {
    setCurrentComponent(<ViewScorpionCase />);
    setPreview(true);
  }

  const handleReturnClick = () => {
    setCurrentComponent(<AddScorpionMain />);
    setPreview(false);
  }

  return (
    <div className="add-case-container">
      <Formik
        initialValues={SCORPION_FORMIK_INITIAL_VALUES}
        validationSchema={SCORPION_FORMIK_VALIDATION_SCHEMA}
        onSubmit={(values, actions) => handleSubmitClick(values, actions)}
      >
        <Form>
          <Stack direction="column" spacing={3}>
            <div>
              <Stack direction="row" spacing={2}>
                {preview ?
                  (<CustomButton type={"return"} disabled={!preview} onClick={handleReturnClick} />) : null
                }
                {/* <CustomButton type={"save"} customBackground={true} /> */}
                <CustomButton type={"preview"} onClick={handlePreviewCase} disabled={preview} />
                <CustomButton type={"download"} disabled={!preview} />
              </Stack>
            </div>
            <div>
              {currentComponent}
            </div>
            <div className="add-case-footer">
              {!preview ? (
                <Stack direction="row" spacing={2}>
                  <div style={{ width: '100%' }}>
                    <Stack direction="row-reverse" spacing={1}>
                      <div>
                        <NormalButton btnType="validate" type="submit" />
                      </div>
                      <div>
                        <NormalButton btnType="cancel" onClick={handleCancelClick} />
                      </div>

                    </Stack>
                  </div>
                </Stack>) : null
              }
            </div>
          </Stack>
        </Form>
      </Formik>
    </div>
  )
}