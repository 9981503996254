import React from "react";
import ToxiqueDetailsContainer from "../ToxiqueDetailsContainer/ToxiqueDetailsContainer";
import '../../index.scss'


export default function Toxiques(props) {

      return (
            <div className="case-common-container">
                  <div className="case-common-container-title">
                        <label>Les toxiques</label>
                  </div>
                  <div className="case-common-container-body" style={{marginLeft:"10px"}}>
                        <ToxiqueDetailsContainer />
                  </div>
            </div>
      )
}