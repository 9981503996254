const erreurCircuitDict = {
    1: 'Prescription',
    2: 'Administration',
    3: 'Dispensation',
    4: 'Préparation'
};

const erreurTypeDict = {
    1: 'E. de dose',
    2: 'E. de la voie d’admin',
    3: 'E. de rythme d’administration',
    4: 'Médicament périmé ou détérioré',
    5: 'E. de médicament'
};

const causesErreurDict = {
    1: 'Automédication',
    2: 'Instruction de prise médicamenteuse non expliquée',
    3: 'Confusion entre emballages des médicaments',
}

export {erreurCircuitDict, erreurTypeDict, causesErreurDict}