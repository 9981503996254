import axios from 'axios';

/* Define Axios configs */
const axiosBaseConfig = {
    baseURL: process.env.REACT_APP_ENV === 'prod' ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*"
    }
};

const axiosMultipartConfig = {
    baseURL: process.env.REACT_APP_ENV === 'prod' ? process.env.REACT_APP_BACKEND_URL_PROD : process.env.REACT_APP_BACKEND_URL,
    headers: {
        Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*"
    }
};

export const authHeaderKey = () => {
    const token = localStorage.getItem("TF.current_user_token");
    return token ? `Bearer ${token}` : "";
};

// Export configured axios instances
export const getAxiosInstance = ()=>{
    axios.create(axiosBaseConfig);
}

export const getSecureAxiosInstance = () => {
    return axios.create({...axiosBaseConfig,headers: {...axiosBaseConfig.headers,Authorization: authHeaderKey()}});
};

export const getSecureMultipartAxiosInstance = () => {
    return axios.create({...axiosMultipartConfig,headers: {...axiosMultipartConfig.headers,Authorization: authHeaderKey()}});
};
