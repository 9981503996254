import React from "react";
import { Stack } from "@mui/material";

import '../index.scss';
import Echantillon from "../../../components/Case/Labo/Echantillon/Echantillon";




export default function AddLaboEchantillon(props) {

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={4}>
                        <Echantillon />
                  </Stack>
            </div>

      )
}