import React, { Component } from 'react';
import { Button } from '@mui/material';
import { BrouillonIcon, DoneStarIcon, HistoricIcon } from '../Icons/Icons';
import history from '../../utils/history';
import './index.scss';

class SideBarButton extends Component {
    render() {
        const {type, value} = this.props;
        let icon, color, title, subtitle;
        let path = null;

        if (type === 'brouillon'){
            icon = <BrouillonIcon width="24" height="24" viewBox="0 0 24 24" fill="#7851A9" />;
            color = "#EAEAF7";
            title = "Brouillon";
            subtitle = value + ' cas';
            path = "/main/brouillon";

        } else if (type === 'case') {
            icon = <DoneStarIcon  width="24" height="24" fill="none" viewBox="0 0 40 40" stroke="#367BF5" strokeWidth="2" />;
            color = "#D3EAFF";
            title = "Cas à valider";
            subtitle = value + ' cas en attente de validation';
            path = "/main/validation";
        }
        else {
            icon = <HistoricIcon width="24" height="24" viewBox="0 0 24 24" fill="#CF8210"/>
            color = "#F1E0CD";
            title="Historique";
            subtitle = value + ' cas';
            path = "/main/historic";
        }

        const handleButtonClick = () => {
            history.push(path)
        }

        return (
            <Button style={{textTransform:'none', padding:0}} onClick={handleButtonClick}>
                <div className="side-bar-button" style={{background: color}}>
                    <div className="side-bar-icon">
                        {icon}
                    </div>
                    <div className="side-bar-text">
                        <h5>{title}</h5>
                        <p>{subtitle}</p>
                    </div>
                </div>
            </Button>
        )
    }
}

export default SideBarButton;
