import React from "react";
import { Stack } from "@mui/material";
import Intoxication from "../../../components/Case/Toxi/Intoxication/Intoxication";
import Symptomologie from "../../../components/Case/Common/Symptomologie/Symptomologie";
import Traitement from "../../../components/Case/Toxi/Traitement/Traitement";
import Evolution from "../../../components/Case/Common/Evolution/Evolution";
import TraitementCAPM from "../../../components/Case/Toxi/Traitement/TraitementCAPM";
import '../index.scss';
import TraitementEffectue from "../../../components/Case/Toxi/Traitement/TraitementEffectue";




export default function AddToxiIntoxication(props){

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={4}>
                        <Intoxication />
                        <Symptomologie />
                        <Traitement />
                        <TraitementCAPM />
                        <TraitementEffectue />
                        <Evolution />
                  </Stack>
            </div>

      )
}