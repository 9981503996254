import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getClasseMenagerDetails } from "../../../../services/cases/cases";

export default function ProduitMenager(props) {
    const { produit, preview } = props;
    const [produitDetails, setProduitDetails] = useState(null);

    const referentielResults = useReferentiels([
        "classe_produit_menager",
        "presentation_produit_menager",
        "voie_intoxication",
    ]);
    useEffect(() => {
        getClasseMenagerDetails(produit.classe_id).then((data) => {
            setProduitDetails({

                "code": data.code
            });
        });
    }, [props]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading || produitDetails === null) return <CustomLoader isLoading={isLoading} />;

    const classes = flattenIdNomDict(referentielResults[0].data);
    const presentations = flattenIdNomDict(referentielResults[1].data);
    const voix = flattenIdNomDict(referentielResults[2].data);

    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Pr. Ménager</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(produit.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom"
                                        value={produit.nom}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Classe" value={classes[produit.classe_id]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Code"
                                        value={produitDetails.code}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>

                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Doses administrées"
                                        value={produit.dose_administree}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Présentation"
                                        value={presentations[produit.presentation_id]}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[produit.voie_intoxication_id]
                                        }
                                    />
                                </div>

                            </Stack>
                        </div>

                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Pr. Ménager Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
