import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomButton from "../../../components/Buttons/CustomButton";
import SaisieNavBar from "../../../components/SaisieNavBar/SaisieNavBar";
import { Formik, Form } from "formik";
import { printPdf } from "../../../utils/divers/divers";
import AddToxiDeclarant from "./AddToxiDeclarant";
import AddToxiPatient from "./AddToxiPatient";
import AddToxique from "../AddToxique";
import AddToxiIntoxication from "./AddToxiIntoxication";
import AddToxiRepondeur from "./AddToxiRepondeur";
import NormalButton from "../../../components/Button";
import ViewToxiCase from "../../ViewCase/Toxi/ViewToxiCase";

import { TOXI_FORMIK_INITIAL_VALUES } from "../../../utils/validationSchemas/cases/initialValues";
import { TOXI_FORMIK_VALIDATION_SCHEMA } from "../../../utils/validationSchemas/cases/validationSchemas";
import {
  addToxiCase,
  addToxiStep,
  getCasebyId,
  toxiCaseValidation,
} from "../../../services/cases/cases";
import "../index.scss";
import { useLocation } from "react-router-dom";
import { getUserFromStore } from "../../../services/auth";

function getCurrentComponent(componentIndex) {
  let component = null;
  switch (componentIndex) {
    case 0:
      component = <AddToxiDeclarant />;
      break;
    case 1:
      component = <AddToxiPatient />;
      break;
    case 2:
      component = <AddToxique />;
      break;
    case 3:
      component = <AddToxiIntoxication />;
      break;
    case 4:
      component = <AddToxiRepondeur />;
      break;
    case 5:
      component = <ViewToxiCase />;
      break;
    default:
      break;
  }
  return component;
}

export default function AddToxiCase(props) {
  const { onEditMode, editMode } = props;

  const steps = [
    "Déclarant",
    "Patient",
    "Toxique",
    "Intoxication",
    "Répondeur",
  ];
  const location = useLocation();
  const selectedCase = location.state ? location.state.selectedCase : "";

  const [stepsDone, setStepsDone] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [componentIndex, setComponentIndex] = useState(0);
  const [preview, setPreview] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(
    getCurrentComponent(componentIndex)
  );
  const [caseInitialData, setCaseInitialData] = useState({});
  const [pdfLoading, setPdfLoading] = useState(false);
  const [showValidateButton, setShowValidateButton] = useState(true);
  const { enqueueSnackbar: showMessage } = useSnackbar();

  const updateStepsDone = () => {
    let newStepsDone = [...stepsDone];
    newStepsDone[componentIndex] = true;
    setStepsDone(newStepsDone);
  };
  useEffect(() => {
    getCasebyId(selectedCase, "toxicovigilance").then((data) => {
      setCaseInitialData({
        id: data.id,
        declarant: {
          id: data.declaration_id,
          rapport: {
            numero_dossier: data.numero_dossier,
            date_saisie: data.date_saisie,
            date_reception: data.date_reception,
            heure_reception: data.heure_reception,
            type_rapport_id: data.type_rapport_id,
            operateur: data.operateur,
          },
          declarant: data.declarant,
        },
        patient: data.patient ?? TOXI_FORMIK_INITIAL_VALUES.patient,
        toxiques: data.toxiques,
        intoxication: data.intoxication ? {
          id: data.intoxication.id,
          intoxication: {
            date_intoxication: data.intoxication.date_intoxication,
            heure: data.intoxication.heure,
            delai_intoxication_j: data.intoxication.delai_intoxication_j,
            delai_intoxication_h: data.intoxication.delai_intoxication_h,
            delai_intoxication_m: data.intoxication.delai_intoxication_m,
            type_intoxication: data.intoxication.type_intoxication,
            nombre_cas: data.intoxication.nombre_cas,
            lieu_intoxication_id: data.intoxication.lieu_intoxication_id,
            autre_lieu_intoxication: data.intoxication.autre_lieu_intoxication,
            circonstance_intoxication_id: data.intoxication.circonstance_intoxication_id,
            autre_circonstance_intoxication: data.intoxication.autre_circonstance_intoxication,
            erreur_medicamenteuse: data.intoxication.erreur_medicamenteuse,
            erreur_medicamenteuse_erreur_circuit: data.intoxication.erreur_medicamenteuse_erreur_circuit,
            erreur_medicamenteuse_type_erreur: data.intoxication.erreur_medicamenteuse_type_erreur,
            erreur_medicamenteuse_cause_erreur: data.intoxication.erreur_medicamenteuse_cause_erreur,
            erreur_medicamenteuse_autre_information: data.intoxication.erreur_medicamenteuse_autre_information,
            
          },
          symptomatologie: {
            symptomatique: data.intoxication.symptomatique,
            terme_reaction_id: data.intoxication.terme_reaction_id,
            termes: data.intoxication.termes ?? [],
            relation_toxique_symptomologie:
              data.intoxication.relation_toxique_symptomologie,
            type_risque_id: data.intoxication.type_risque_id,
            gradation_initiale_id: data.intoxication.gradation_initiale_id,
          },
          traitement_avant: data.intoxication.traitement_avant,
          traitement_capm: data.intoxication.traitement_capm,
          traitement_effectue: data.intoxication.traitement_effectue,
          evolution: {
            relance: data.intoxication.relance,
            date_relance: data.intoxication.date_relance,
            hospitalisation: data.intoxication.hospitalisation,
            duree_hospitalisation: data.intoxication.duree_hospitalisation,
            gradation_finale_id: data.intoxication.gradation_finale_id,
            evolution_id: data.intoxication.evolution_id,
            sequelles: data.intoxication.sequelles,
            sequelles_details: data.intoxication.sequelles_details,
            imputabilite: {
              exposition: data.intoxication.imputabilite.exposition_id,
              symptomatologie: data.intoxication.imputabilite.symptomatologie_id,
              chronologie: data.intoxication.imputabilite.chronologie_id,
              caracterisation_causale:
                data.intoxication.imputabilite.caracterisation_causale_id,
              diagnostique_differentiel:
                data.intoxication.imputabilite.diagnostic_differentiel_id,
              lien_extrinseque:
                data.intoxication.imputabilite.lien_extrinseque_id,
              imputabilite:
                data.intoxication.imputabilite.imputabilite_resultat_id,
            },
            historique: data.intoxication.historique,
          },
        } : TOXI_FORMIK_INITIAL_VALUES.intoxication,
        repondeur: {
          id: data.id,
          repondeur_id: data.repondeur_id,
          delai_reponse_id: data.delai_reponse_id,
          signal: data.signal,
        },
      });
      editMode &&
        setStepsDone([
          data.brouillon.declarant,
          data.brouillon.patient,
          data.brouillon.toxique,
          data.brouillon.intoxication,
          data.brouillon.repondeur,
        ]);
    });
  }, [editMode, selectedCase, location]);

  useEffect(() => {
    if (selectedCase.length > 0) return;
    addToxiCase()
      .then((data) => {
        let initialData = { ...TOXI_FORMIK_INITIAL_VALUES };
        initialData.id = data.id;
        initialData.declarant.rapport.numero_dossier = data.numero_dossier;
        initialData.declarant.rapport.date_saisie = data.date_saisie;
        initialData.declarant.rapport.date_reception = data.date_saisie;
        initialData.declarant.rapport.operateur = getUserFromStore()?.nom_prenom
        setCaseInitialData(initialData);
        onEditMode(false);
      })
      .catch((error) => {
        showMessage(
          "Erreur lors de l'initialisation du cas " + error.toString(),
          { variant: "error" }
        );
      });
  }, []);

  const handleSaisieNavBarClick = (index) => {
    setComponentIndex(index);
    setCurrentComponent(getCurrentComponent(index));
  };

  const handlePreviousClick = () => {
    setComponentIndex((s) => s - 1);
    setCurrentComponent(getCurrentComponent(componentIndex - 1));
  };

  const handleNextClick = () => {
    setComponentIndex((s) => s + 1);
    setCurrentComponent(getCurrentComponent(componentIndex + 1));
  };

  const handleCancelClick = () => { };

  const handleSubmitClick = (values, actions) => {
    addToxiStep(componentIndex, values)
      .then((result) => {
        showMessage("Etape enregistrée avec succès", { variant: "success" });
        updateStepsDone();
      })
      .catch((error) => {
        showMessage(
          "Erreur lors de l'enregistrement de cette étape " + error.toString(),
          { variant: "error" }
        );
      });
  };

  const handlePreviewCase = () => {
    setCurrentComponent(getCurrentComponent(5));
    setPreview(true);
  };

  const handleReturnClick = () => {
    setCurrentComponent(getCurrentComponent(componentIndex));
    setPreview(false);
  };
  const handleCasesValidation = () => {
    toxiCaseValidation(caseInitialData.id)
      .then((data) => {
        showMessage("Cas envoyé pour validation !", { variant: "success" });
      })
      .catch((error) => {
        showMessage(
          "Erreur lors de la validation de cette étape " + error.toString(),
          { variant: "error" }
        );
      });
  };

  const handleExportPdf = () => {
    printPdf("apercu", "rapport de cas d'intoxication #" +
      caseInitialData.declarant.rapport.numero_dossier +
      ".pdf", setPdfLoading);
  };

  return (
    <div className="add-case-container">
      <Formik
        enableReinitialize
        initialValues={caseInitialData}
        validationSchema={TOXI_FORMIK_VALIDATION_SCHEMA[componentIndex]}
        onSubmit={(values, actions) => handleSubmitClick(values, actions)}
      >
        <Form>
          <Stack direction="column" spacing={3}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Stack direction="row" spacing={2}>
                {preview ? (
                  <CustomButton
                    type={"return"}
                    disabled={!preview}
                    onClick={handleReturnClick}
                  />
                ) : null}
                {/* <CustomButton type={"save"} customBackground={true} /> */}
                <CustomButton
                  type={"preview"}
                  onClick={handlePreviewCase}
                  disabled={preview}
                />
                <CustomButton
                  type={"download"}
                  onClick={handleExportPdf}
                  disabled={!preview || pdfLoading}
                />
              </Stack>
              {!stepsDone.includes(false) && !stepsDone.includes(0) && (
                <>
                  {showValidateButton && (
                    <CustomButton
                      type={"validation"}
                      customBackground={true}
                      onClick={() => {
                        handleCasesValidation();
                        setShowValidateButton(false);
                      }}
                      disabled={stepsDone.includes(false)}
                    />
                  )}
                </>
              )}
            </div>
            <div>
              {!preview ? (
                <SaisieNavBar
                  steps={steps}
                  stepsDone={stepsDone}
                  onClick={handleSaisieNavBarClick}
                  currentStep={componentIndex}
                />
              ) : null}
            </div>
            <div className="add-case-center">{currentComponent}</div>
            <div className="add-case-footer">
              {!preview ? (
                <Stack direction="row" spacing={2}>
                  <div>
                    {componentIndex > 0 && (
                      <NormalButton
                        btnType="previous"
                        onClick={handlePreviousClick}
                      />
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <Stack direction="row-reverse" spacing={1}>
                      <div>
                        <NormalButton btnType="validate" type="submit" />
                      </div>
                      {componentIndex < steps.length - 1 && (
                        <div>
                          <NormalButton
                            btnType="next"
                            onClick={handleNextClick}
                          />
                        </div>
                      )}
                      <div>
                        <NormalButton
                          btnType="cancel"
                          onClick={handleCancelClick}
                        />
                      </div>
                    </Stack>
                  </div>
                </Stack>
              ) : null}
            </div>
          </Stack>
        </Form>
      </Formik>
    </div>
  );
}
