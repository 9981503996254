import { createTheme } from "@mui/material/styles";


const buttonsTheme = createTheme();

buttonsTheme.components = {
      MuiButton: {
            disableElevation: true,
            styleOverrides: {
                  root: {
                        borderRadius: '5px',
                        textTransform: 'none',
                        border: '1px solid #367BF5',
                        boxSizing: 'border-box',
                        height:'31px',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#367BF5',
                        
                  },
            }
      }
}

const excelButtonTheme = createTheme();
excelButtonTheme.components = {
      MuiButton: {
            styleOverrides: {
                  root: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '6px 16px',
                        background: '#FFFFFF',
                        border: '0.5px solid #3C9B3A',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        margin: '0px 0px',
                        width: '278px',
                        height: '43px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: '#3C9B3A',
                        textTransform:'none',
                       
                        '&:hover': {
                              border: '0.5px solid #3C9B3A',
                        }
                  }
            }
      }
}
export  {buttonsTheme, excelButtonTheme};