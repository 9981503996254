import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { arrayRemove } from "../../../../utils/divers/divers";

import { getMeddraElements } from "../../../../services/cases/cases";
const meddraKey = "TF.meddra_data";


const CustomAccordion = styled((props) => <Accordion {...props} />)(
  ({ theme }) => ({
    marginBottom: "10px",
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundColor: "inherit",
  })
);

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ArrowDropDownIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: "#F6F7FC",
  fontFamily: "Roboto",
  border: "1px solid #B0B2B5",
  boxSizing: "border-box",
  height: "45px",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    fontWeight: "bold",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function WHOAccordion(props) {
  const { search } = props;
  const { values, setFieldValue } = useFormikContext();
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [meddraElements, setMeddraElements] = React.useState([]);

  React.useEffect(() => {
    let meddraData = [];
    if (meddraKey in localStorage) {
      meddraData = JSON.parse(localStorage.getItem(meddraKey));
      setMeddraElements(meddraData);
    }
    else {
      getMeddraElements().then((data) => {
        setMeddraElements(data);
        localStorage.setItem(meddraKey, JSON.stringify(data));

      });
    }
  }, [])



  // const referentielResults = useReferentiels(["who_art"]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };


  const handleOptionCheck = (event, optionId) => {
    const checked = event.target.checked;
    let newTermes = [...(values.intoxication.symptomatologie.termes ?? [])];
    if (checked) {
      newTermes.push(optionId);
    } else {
      newTermes = arrayRemove(newTermes, optionId);
    }
    setFieldValue("intoxication.symptomatologie.termes", newTermes);
  };

  return (
    <div style={{ width: "840px" }}>
      {meddraElements && meddraElements
        //filter list
        ?.filter((soc) => {
          if (
            soc.hgltOptions.find((hgltOption) =>
              hgltOption.hltOptions.find((htlOption) => htlOption.hltName.toLowerCase().includes(search?.toLowerCase()))
            )
          ) {
            return soc;
          }
        })
        .map((soc, socIndex) => (
          <CustomAccordion
            key={socIndex}
            id={"soc" + socIndex}
            expanded={!search ? expanded === "soc" + socIndex : true}
            onChange={handleChange("soc" + socIndex)}
          >
            <CustomAccordionSummary>
              <label>{soc.socName}</label>
            </CustomAccordionSummary>
            <AccordionDetails>
              <div style={{ width: "800px" }}>
                {
                  soc.hgltOptions?.filter((hlgtOption) => {
                    if (hlgtOption.hltOptions.find((htlOption) => htlOption.hltName.toLowerCase().includes(search?.toLowerCase()))) {
                      return hlgtOption
                    }
                  }).map((hlgtOption, hlgtIndex) => (
                    <CustomAccordion
                      key={hlgtIndex}
                      id={"hlgt" + hlgtIndex}
                      expanded={!search ? (expanded === "soc" + socIndex && expanded2 === "hlgt" + hlgtIndex) : true}
                      onChange={handleChange2("hlgt" + hlgtIndex)}
                    >
                      <CustomAccordionSummary>
                        <label>{hlgtOption.hlgtName}</label>
                      </CustomAccordionSummary>
                      <AccordionDetails>
                        <FormGroup>
                          {hlgtOption.hltOptions
                            //filter sublist
                            ?.filter((hltOption) => {
                              if (
                                hltOption.hltName.toLowerCase().includes(search?.toLowerCase())
                              ) {
                                return hltOption;
                              }
                            })
                            .map((hltOption, hltOptionIndex) => (
                              <FormControlLabel
                                key={hltOptionIndex}
                                label={hltOption.hltName}
                                control={
                                  <Checkbox
                                    onChange={(event) =>
                                      handleOptionCheck(event, hltOption.hltCode)
                                    }
                                    checked={getIn(
                                      values,
                                      "intoxication.symptomatologie.termes"
                                    ).includes(hltOption.hltCode)}
                                  />
                                }
                              />
                            ))}
                        </FormGroup>
                      </AccordionDetails>
                    </CustomAccordion>
                  ))
                }
              </div>

            </AccordionDetails>
          </CustomAccordion>
        ))}
    </div>
  );
}
