import { Stack } from "@mui/material";
import React from "react";
import { getRoleName, getRoleColor } from "../../utils/rolesManagement";
import './index.scss'


const RoleIcon = (props) => {

  return (
    <div className="role-icon" style={{
      background: props.backgroundColor,
    }}>

    </div>
  )
}

export default function RoleButton(props) {
  const roleType = props.type;
  let roleName = getRoleName(roleType);
  let backgroundColor = getRoleColor(roleType);
  return (
    <Stack direction='row' className="role-button-container">
      <div className="role-button-left-side">
        {roleName}
      </div>
      <div className="role-button-right-side">
        <RoleIcon backgroundColor={backgroundColor} />
      </div>
    </Stack>
  )
}

