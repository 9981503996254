import React from "react";
import { Stack } from "@mui/material";
import MainMenuButton from "./MainMenuButton";
import {
      addCasesPermissions,
      viewCasesPermissions,
      extractDataPermissions,
      updateReferentielPermissions,
      visualizeDashboardPermissions,
      userManagementPermissions
} from "../../utils/permissions";
import { isAllowed } from "../../services/auth";



export default function MainMenu(props) {
      const menuTypes = ['add-cases', 'view-cases', 'extract-data', 'update-ref', 'dashboard', 'user-management'];
      const permissions = [addCasesPermissions, viewCasesPermissions, extractDataPermissions, updateReferentielPermissions, visualizeDashboardPermissions, userManagementPermissions];

      return (
            <Stack direction="column" spacing={0}>
                  {
                        menuTypes.map((type, index) => (
                              isAllowed(permissions[index]) && (
                                    <div style={{ paddingBottom: '12px' }} key={index}>
                                          <MainMenuButton type={type} />
                                    </div>
                              )))
                  }
            </Stack>
      )
}