import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { getTypeDeclarantDict } from "../../../../utils/case";

import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import { flattenIdNomDict } from "../../../../utils/referentielData";

export default function Intoxication(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  const intoxication = selectedCase
    ? selectedCase.intoxication
    : values.intoxication?.intoxication;
  const referentielResults = useReferentiels([
    "lieu_intoxication",
    "type_risque"
  ]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const lieu_intoxication = flattenIdNomDict(referentielResults[0].data);
  const type_risque = flattenIdNomDict(referentielResults[1].data);


  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>L'intoxication</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Date d'intoxication"
                  value={intoxication?.date_intoxication}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Heure d'intoxication"
                  value={intoxication?.heure}
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "60%" }}>
                <Stack direction="row" spacing={1}>
                  <LabelInfo
                    title="Délai d'intoxication (jour)"
                    value={intoxication?.delai_intoxication_j}
                  />
                  <LabelInfo
                    title="(heure)"
                    value={intoxication?.delai_intoxication_h}
                  />
                  <LabelInfo
                    title="(minute)"
                    value={intoxication?.delai_intoxication_m}
                  />
                </Stack>
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Lieu d'intoxication"
                  value={lieu_intoxication[intoxication?.lieu_intoxication_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Si autre, précisez"
                  value={intoxication?.autre_lieu_intoxication}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Type de risque"
                  value={type_risque[intoxication?.type_risque_id]}
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Type d'intoxication"
                  value={
                    parseInt(intoxication?.type_intoxication) === 0
                      ? "Isolée"
                      : parseInt(intoxication?.type_intoxication) === 1 ? "Collective" : "Inconnu"
                  }
                />
              </div>
              {parseInt(intoxication?.type_intoxication) === 1 && (
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Nombre de cas"
                    value={
                      intoxication?.nombre_cas
                    }
                  />
                </div>
              )}

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "100%" }}>
                <LabelInfo
                  title="Relation entre toxique et symptomatologie:"
                  value={
                    intoxication?.relation_toxique_symptomatologie === 0
                      ? "Non"
                      : intoxication?.relation_toxique_symptomatologie === 1
                        ? "Oui"
                        : "Inconnu"
                  }
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
