import React, { useState, useEffect } from "react";

import { Stack } from "@mui/material";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import CustomButton from "../../../components/Buttons/CustomButton";
import SaisieNavBar from "../../../components/SaisieNavBar/SaisieNavBar";
import AddLaboPrescripteur from "./AddLaboPrescripteur";
import AddLaboPatient from "./AddLaboPatient";
import AddToxique from "../AddToxique";
import AddLaboIntoxication from "./AddLaboIntoxication";
import AddLaboEchantillon from "./AddLaboEchantillon";
import AddLaboResultat from "./AddLaboResultat";
import AddLaboAnalyste from "./AddLaboAnalyste";
import NormalButton from "../../../components/Button";
import ViewLaboCase from "../../ViewCase/Labo/ViewLaboCase";
import { printPdf } from "../../../utils/divers/divers";
import {
  addLaboCase,
  addLaboStep,
  getCasebyId,
  laboCaseValidation,
} from "../../../services/cases/cases";
import { getReferentiel } from "../../../services/referentiels/referentiels";
import { getFEFormatEchantillons, getFEFormatResultat } from "../../../utils/divers/divers"
import { LABO_FORMIK_INITIAL_VALUES } from "../../../utils/validationSchemas/cases/initialValues";
import { LABO_FORMIK_VALIDATION_SCHEMA } from "../../../utils/validationSchemas/cases/validationSchemas";
import "../index.scss";
import { useLocation } from "react-router-dom";
import { flattenIdNomDict } from "../../../utils/referentielData";

export const ValidatedContext = React.createContext();

function getCurrentComponent(componentIndex, laboCasesChecked) {
  let component = null;
  switch (componentIndex) {
    case 0:
      component = <AddLaboPrescripteur />;
      break;
    case 1:
      component = <AddLaboPatient />;
      break;
    case 2:
      component = <AddToxique />;
      break;
    case 3:
      component = <AddLaboIntoxication />;
      break;
    case 4:
      component = <AddLaboEchantillon />;
      break;
    case 5:
      component = <AddLaboResultat />;
      break;
    case 6:
      component = <AddLaboAnalyste />;
      break;
    case 7:
      component = <ViewLaboCase />;
      break;
    default:
      break;
  }
  return (
    <ValidatedContext.Provider value={laboCasesChecked}>
      {component}
    </ValidatedContext.Provider>
  );
}

export default function AddLaboCase(props) {
  const { onEditMode, editMode } = props;
  const steps = [
    "Prescripteur",
    "Patient",
    "Toxique",
    "Intoxication",
    "Echantillon",
    "Résultat",
    "Analyste",
  ];
  const location = useLocation();
  const selectedCase = location.state ? location.state.selectedCase : "";

  const [stepsDone, setStepsDone] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [componentIndex, setComponentIndex] = useState(0);
  const [caseInitialData, setCaseInitialData] = useState({});
  const [preview, setPreview] = useState(false);
  const [laboCasesChecked, setLaboCasesChecked] = useState(
    // stepsDone.includes(false) ? false : true
    false
  );
  const [entreeNom, setEntreeNom] = useState({});
  const [currentComponent, setCurrentComponent] = useState(
    getCurrentComponent(componentIndex, laboCasesChecked)
  );
  const [pdfLoading, setPdfLoading] = useState(false);
  const [showValidateButton, setShowValidateButton] = useState(true);
  const { enqueueSnackbar: showMessage } = useSnackbar();

  const handleSaisieNavBarClick = (index) => {
    setComponentIndex(index);
    setCurrentComponent(getCurrentComponent(index, laboCasesChecked));
  };
  const handlePreviousClick = () => {
    setComponentIndex((s) => s - 1);
    setCurrentComponent(
      getCurrentComponent(componentIndex - 1, laboCasesChecked)
    );
  };
  const handleNextClick = () => {
    setComponentIndex((s) => s + 1);
    setCurrentComponent(
      getCurrentComponent(componentIndex + 1, laboCasesChecked)
    );
  };

  const handleCancelClick = () => { };

  const updateStepsDone = () => {
    let newStepsDone = [...stepsDone];
    newStepsDone[componentIndex] = true;
    setStepsDone(newStepsDone);
  };

  const handleSubmitClick = (values, actions) => {
    addLaboStep(componentIndex, values)
      .then((result) => {
        showMessage("Etape enregistrée avec succès", { variant: "success" });
        updateStepsDone();
      })
      .catch((error) => {
        showMessage(
          "Erreur lors de l'enregistrement de cette étape " + error.toString(),
          { variant: "error" }
        );
      });
  };

  const handlePreviewCase = () => {
    setCurrentComponent(getCurrentComponent(7, laboCasesChecked));
    setPreview(true);
  };

  const handleReturnClick = () => {
    setCurrentComponent(getCurrentComponent(componentIndex, laboCasesChecked));
    setPreview(false);
  };
  const handleCasesValidation = () => {
    laboCaseValidation(caseInitialData.id)
      .then((data) => {
        showMessage("Cas envoyé pour validation !", { variant: "success" });
      })
      .catch((error) => {
        showMessage("Erreur lors de validation des cas " + error.toString(), {
          variant: "error",
        });
      });
  };

  const handleExportPdf = () => {
    printPdf("apercu", "Rapport Cas Labo #" +
      caseInitialData.prescripteur.dossier.numero_dossier +
      ".pdf", setPdfLoading);

  };
  useEffect(() => {
    let initialData = { ...LABO_FORMIK_INITIAL_VALUES };
    let entreeNomFlatten = null;
    getReferentiel("entree_nom").then((data) => {
      entreeNomFlatten = flattenIdNomDict(data);
      setEntreeNom(entreeNomFlatten);
    }).then(() => {
      getCasebyId(selectedCase, "laboratoire").then((data) => {

        setCaseInitialData({
          id: data.id,
          prescripteur: {
            dossier: {
              type_dossier: data.type_dossier,
              numero_dossier: data.numero_dossier,
              date_saisie: data.date_saisie,
              date_reception: data.date_reception,
              heure_reception: data.heure_reception,
            },
            prescripteur: data.prescripteur,
          },
          patient: data.patient,
          toxiques: data.toxiques,
          intoxication: data.intoxication
            ? {
              intoxication: {
                date_intoxication: data.intoxication.date_intoxication,
                heure: data.intoxication.heure,
                delai_intoxication_j: data.intoxication.delai_intoxication_j,
                delai_intoxication_h: data.intoxication.delai_intoxication_h,
                delai_intoxication_m: data.intoxication.delai_intoxication_m,
                lieu_intoxication_id: data.intoxication.lieu_intoxication_id,
                autre_lieu_intoxication: data.intoxication.autre_lieu_intoxication,
                type_risque_id: data.intoxication.type_risque_id,
                gradation_initiale_id: data.intoxication.gradation_initiale_id,
                type_intoxication: data.intoxication.type_intoxication,
                nombre_cas: data.intoxication.nombre_cas
              },
              symptomatologie: {
                symptomatique: data.intoxication.symptomatique ?? "",
                terme_reaction_id: data.intoxication.terme_reaction_id,
                termes: data.intoxication.termes ?? [],
                relation_toxique_symptomologie:
                  data.intoxication.relation_toxique_symptomologie,
                type_risque_id: data.intoxication.type_risque_id,
                gradation_initiale_id: data.intoxication.gradation_initiale_id,
              },

              evolution: {
                relance: data.intoxication.relance,
                date_relance: data.intoxication.date_relance,
                hospitalisation: data.intoxication.hospitalisation,
                duree_hospitalisation: data.intoxication.duree_hospitalisation,
                gradation_finale_id: data.intoxication.gradation_finale_id,
                evolution_id: data.intoxication.evolution_id,

                sequelles: data.intoxication.sequelles,
                sequelles_details: data.intoxication.sequelles_details,
                imputabilite: {
                  exposition: data.intoxication.imputabilite.exposition_id,
                  symptomatologie:
                    data.intoxication.imputabilite.symptomatologie_id,
                  chronologie: data.intoxication.imputabilite.chronologie_id,
                  caracterisation_causale:
                    data.intoxication.imputabilite.caracterisation_causale_id,
                  diagnostique_differentiel:
                    data.intoxication.imputabilite.diagnostic_differentiel_id,
                  lien_extrinseque:
                    data.intoxication.imputabilite.lien_extrinseque_id,
                  imputabilite:
                    data.intoxication.imputabilite.imputabilite_resultat_id,
                },
                historique: data.intoxication.historique,
              },
            }
            : initialData.intoxication,
          echantillon: data.echantillon && {
            heure_prelevement: data.echantillon.heure_prelevement,
            echantillons: getFEFormatEchantillons(data.echantillon.echantillons_nature),
            examen_demande: data.echantillon.echantillon_examens_demandes,
          },
          resultat: data.resultats_labo && getFEFormatResultat(data.resultats_labo, entreeNomFlatten),
          analyste: {
            analyste_id: data.analyste_id,
            observation: data.observation,
          },
        });
        editMode &&
          setStepsDone([
            data.brouillon.prescripteur,
            data.brouillon.patient,
            data.brouillon.toxique,
            data.brouillon.intoxication,
            data.brouillon.echantillon,
            data.brouillon.resultat,
            data.brouillon.analyste,
          ]);
      })
    });
  }, [editMode, selectedCase, location]);

  useEffect(() => {
    if (selectedCase.length > 0) return;
    addLaboCase()
      .then((data) => {
        let initialData = { ...LABO_FORMIK_INITIAL_VALUES };
        initialData.id = data.id;
        initialData.prescripteur.dossier.numero_dossier = data.numero_dossier;
        initialData.prescripteur.dossier.date_saisie = data.date_saisie;
        initialData.prescripteur.dossier.date_reception = data.date_saisie;
        setCaseInitialData(initialData);
      })
      .catch((error) => {
        showMessage(
          "Erreur lors de l'initialisation du cas " + error.toString(),
          { variant: "error" }
        );
      });
  }, []);

  return (
    <div className="add-case-container">
      <Formik
        enableReinitialize
        initialValues={caseInitialData}
        validationSchema={LABO_FORMIK_VALIDATION_SCHEMA[componentIndex]}
        onSubmit={(values, actions) => handleSubmitClick(values, actions)}
      >
        <Form>
          <Stack direction="column" spacing={3}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Stack direction="row" spacing={2}>
                {preview ? (
                  <CustomButton
                    type={"return"}
                    disabled={!preview}
                    onClick={handleReturnClick}
                  />
                ) : null}
                <CustomButton
                  type={"preview"}
                  onClick={handlePreviewCase}
                  disabled={preview}
                />
                <CustomButton
                  type={"download"}
                  onClick={handleExportPdf}
                  disabled={!preview || pdfLoading}
                />
              </Stack>
              {!stepsDone.includes(false) && (
                <>
                  {showValidateButton && (
                    <CustomButton
                      type={"validation"}
                      customBackground={true}
                      onClick={() => {
                        handleCasesValidation();
                        setShowValidateButton(false);
                      }}
                      disabled={stepsDone.includes(false)}
                    />
                  )}
                </>
              )}
            </div>
            <div>
              {preview !== true ? (
                <SaisieNavBar
                  steps={steps}
                  stepsDone={stepsDone}
                  onClick={handleSaisieNavBarClick}
                  currentStep={componentIndex}
                />
              ) : null}
            </div>
            <div className="add-case-center">{currentComponent}</div>
            <div className="add-case-footer">
              {!preview ? (
                <Stack direction="row" spacing={2}>
                  <div>
                    {componentIndex > 0 ? (
                      <NormalButton
                        btnType="previous"
                        onClick={handlePreviousClick}
                      />
                    ) : null}
                  </div>
                  <div style={{ width: "100%" }}>
                    <Stack direction="row-reverse" spacing={1}>
                      <div>
                        <NormalButton btnType="validate" type="submit" />
                      </div>
                      {componentIndex < steps.length - 1 && (
                        <div>
                          <NormalButton
                            btnType="next"
                            onClick={handleNextClick}
                          />
                        </div>
                      )}
                      <div>
                        <NormalButton
                          btnType="cancel"
                          onClick={handleCancelClick}
                        />
                      </div>
                    </Stack>
                  </div>
                </Stack>
              ) : null}
            </div>
          </Stack>
        </Form>
      </Formik>
    </div>
  );
}
