import React, { useEffect, useState } from "react";
import {
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";
import { debounce } from "lodash";
import { getProduitMedicamentList } from "../../../../services/cases/cases";
import { useSnackbar } from "notistack";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdNomCommercialDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { getProduitMedicamentDetails } from "../../../../services/cases/cases";

import "./index.scss";
import "../../index.scss";

export default function ToxiqueDetailsMedicament(props) {
  const { toxiqueType, toxiqueTitle, index } = props;
  const { values, setFieldValue, touched, errors, handleChange } = useFormikContext();

  const [produitsMedicaments, setProduitsMedicaments] = useState({});
  const { enqueueSnackbar: showMessage } = useSnackbar();
  const widthPercentage = "45%";
  const referentielResults = useReferentiels([
    // "produit_medicament",
    "voie_intoxication",
    "forme"
  ]);
  const namePrefix = "toxiques." + toxiqueType + "." + index + ".";
  const handleProduitMedicamentChange = (event) => {
    handleChange(event);
    let produitId = event.target.value;
    updateData(produitId);
  };

  const updateData = (produitId) => {
    getProduitMedicamentDetails(produitId).then((data) => {
      setFieldValue(namePrefix + "dci", data.dci);
      //setFieldValue(namePrefix + "forme", data.forme);
      setFieldValue(namePrefix + "groupe_anatomique", data.groupe_anatomique);
      setFieldValue(namePrefix + "code_atc", data.code_atc);

    });
  }

  useEffect(() => {
    setFieldValue(namePrefix + "index", index + 1);
    setFieldValue(namePrefix + "title", toxiqueTitle);
    let produitId = getIn(values, namePrefix + "produit_medicament_id");
    if (produitId) {
      updateData(produitId);
    }
  }, [])

  const handleSearch = (e) => {
    let nomProduit = e.target.value;
    if (nomProduit === "") {
      setProduitsMedicaments({});
      return
    }
    getProduitMedicamentList(nomProduit)
      .then((data) => {
        let medicamentsDict = flattenIdNomCommercialDict(data);
        setProduitsMedicaments(medicamentsDict);
        setFieldValue(namePrefix + "dci", "");
        //setFieldValue(namePrefix + "forme", "");
        setFieldValue(namePrefix + "groupe_anatomique", "");
        setFieldValue(namePrefix + "code_atc", "");
      })
      .catch((error) => {
        showMessage(error.toString(), { variant: "error" });
      });

  };

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  //const produitsMedicaments = flattenIdNomCommercialDict(referentielResults[0].data);
  const voix = flattenIdNomDict(referentielResults[0].data);
  const formes = flattenIdNomDict(referentielResults[1].data);

  return (
    <div className="toxique-details-container">
      <Stack direction="column" spacing={1}>
        <div className="toxique-details-header">
          <div style={{ width: "100%", height: "59px" }}>
            <FormControl component="fieldset" style={{ height: "100%" }}>
              <Stack
                direction="row"
                spacing={0}
                style={{ height: "100%", alignItems: "center" }}
              >
                <div className="toxique-details-header-title">
                  <label>
                    {toxiqueTitle} <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="case-common-container-body-radio">
                  <RadioGroup
                    row
                    name={namePrefix + "connu_inconnu"}
                    onChange={(event) =>
                      setFieldValue(
                        namePrefix + "connu_inconnu",
                        event.target.value
                      )
                    }
                    value={getIn(values, namePrefix + "connu_inconnu")}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Connu"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Inconnu"
                    />
                  </RadioGroup>
                </div>
              </Stack>
            </FormControl>
          </div>
        </div>
        {parseInt(getIn(values, namePrefix + "connu_inconnu")) === 1 && (
          <div className="toxique-details-body">
            <div className="toxique-details-elt">
              <Stack direction="column" spacing={4}>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: "99%" }}>
                      <OutlinedInput style={{ width: "96%", background: "white" }}
                        placeholder="Saisir un nom commercial ou DCI"
                        onChange={debounce(handleSearch, 1000)}
                      // endAdornment={<SearchIcon htmlColor="#aaaaaa" />}
                      />
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: "96%" }}>

                      <LabelMenu
                        title={"Produit"}
                        name={namePrefix + "produit_medicament_id"}
                        value={getIn(values, namePrefix + "produit_medicament_id")}
                        onChange={handleProduitMedicamentChange}
                        error={
                          getIn(errors, namePrefix + "produit_medicament_id") &&
                          Boolean(getIn(errors, namePrefix + "produit_medicament_id"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "produit_medicament_id") &&
                          getIn(errors, namePrefix + "produit_medicament_id")
                        }
                        mandatory={true}
                        valuesDict={produitsMedicaments}
                      />
                    </div>

                  </Stack>
                </div>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: "96%" }}>
                      <LabelInput
                        title={"DCI"}
                        name={namePrefix + "dci"}
                        value={getIn(values, namePrefix + "dci")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "dci") &&
                          Boolean(getIn(errors, namePrefix + "dci"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "dci") &&
                          getIn(errors, namePrefix + "dci")
                        }
                        mandatory={true}
                        disabled
                      />
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Groupe Anatomique"}
                        name={namePrefix + "groupe_anatomique"}
                        value={getIn(values, namePrefix + "groupe_anatomique")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "groupe_anatomique") &&
                          Boolean(
                            getIn(errors, namePrefix + "groupe_anatomique")
                          )
                        }
                        helperText={
                          getIn(touched, namePrefix + "groupe_anatomique") &&
                          getIn(errors, namePrefix + "groupe_anatomique")
                        }
                        disabled
                      />
                    </div>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Code ATC"}
                        name={namePrefix + "code_atc"}
                        value={getIn(values, namePrefix + "code_atc")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "code_atc") &&
                          Boolean(getIn(errors, namePrefix + "code_atc"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "code_atc") &&
                          getIn(errors, namePrefix + "code_atc")
                        }
                        disabled
                      />
                    </div>
                  </Stack>
                </div>

                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: widthPercentage }}>
                      <LabelMenu
                        title={"Forme médicament"}
                        name={namePrefix + "forme_id"}
                        value={getIn(values, namePrefix + "forme_id")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "forme_id") &&
                          Boolean(
                            getIn(errors, namePrefix + "forme_id")
                          )
                        }
                        helperText={
                          getIn(touched, namePrefix + "forme_id") &&
                          getIn(errors, namePrefix + "forme_id")
                        }
                        valuesDict={formes}
                      />
                    </div>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Dosage"}
                        name={namePrefix + "posologie"}
                        value={getIn(values, namePrefix + "posologie")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "posologie") &&
                          Boolean(getIn(errors, namePrefix + "posologie"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "posologie") &&
                          getIn(errors, namePrefix + "posologie")
                        }
                      />
                    </div>
                  </Stack>
                </div>

                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Doses administrées"}
                        name={namePrefix + "quantite"}
                        value={getIn(values, namePrefix + "quantite")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "quantite") &&
                          Boolean(getIn(errors, namePrefix + "quantite"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "quantite") &&
                          getIn(errors, namePrefix + "quantite")
                        }
                      />
                    </div>
                    <div style={{ width: widthPercentage }}>
                      <LabelMenu
                        title={"Voie d'intoxication"}
                        name={namePrefix + "voie_intoxication_id"}
                        value={getIn(values, namePrefix + "voie_intoxication_id")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "voie_intoxication_id") &&
                          Boolean(
                            getIn(errors, namePrefix + "voie_intoxication_id")
                          )
                        }
                        helperText={
                          getIn(touched, namePrefix + "voie_intoxication_id") &&
                          getIn(errors, namePrefix + "voie_intoxication_id")
                        }
                        valuesDict={voix}
                      />
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
          </div >
        )
        }
      </Stack >
    </div >
  );
}
