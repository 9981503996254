import React, { useEffect } from "react";
import {
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdNomAlimentDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelTextArea from "../../../LabelTextArea";
import { getProduitAlimentDetails } from "../../../../services/cases/cases";

import "./index.scss";
import "../../index.scss";

export default function ToxiqueDetailsAliment(props) {
  const { toxiqueType, toxiqueTitle, index } = props;
  const { values, setFieldValue, touched, errors, handleChange } =
    useFormikContext();
  const widthPercentage = "45%";
  const referentielResults = useReferentiels([
    "produit_aliment",
    "voie_intoxication",
  ]);
  const namePrefix = "toxiques." + toxiqueType + "." + index + ".";

  const handleProduitChange = (event) => {
    handleChange(event);
    let produitId = event.target.value;
    updateData(produitId)
  };

  const updateData = (produitId) => {
    getProduitAlimentDetails(produitId).then((data) => {
      setFieldValue(namePrefix + "groupe_aliment", data.groupe_aliment);
      setFieldValue(namePrefix + "categorie_aliment", data.categorie_aliment);
      setFieldValue(namePrefix + "classe_aliment", data.classe_aliment);
      setFieldValue(namePrefix + "code_aliment", data.classe_aliment);
    });
  }

  useEffect(() => {
    setFieldValue(namePrefix + "index", index + 1);
    setFieldValue(namePrefix + "title", toxiqueTitle);
    let produitId = getIn(values, namePrefix + "produit_aliment_id");
    if (produitId)
      updateData(produitId)
  }, [])

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const produits = flattenIdNomAlimentDict(referentielResults[0].data);
  const voix = flattenIdNomDict(referentielResults[1].data);

  return (
    <div className="toxique-details-container">
      <Stack direction="column" spacing={1}>
        <div className="toxique-details-header">
          <div style={{ width: "100%", height: "59px" }}>
            <FormControl component="fieldset" style={{ height: "100%" }}>
              <Stack
                direction="row"
                spacing={0}
                style={{ height: "100%", alignItems: "center" }}
              >
                <div className="toxique-details-header-title">
                  <label>
                    {toxiqueTitle} <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="case-common-container-body-radio">
                  <RadioGroup
                    row
                    name={namePrefix + "connu_inconnu"}
                    onChange={(event) =>
                      setFieldValue(
                        namePrefix + "connu_inconnu",
                        event.target.value
                      )
                    }
                    value={getIn(values, namePrefix + "connu_inconnu")}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Connu"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Inconnu"
                    />
                  </RadioGroup>
                </div>
              </Stack>
            </FormControl>
          </div>
        </div>
        {parseInt(getIn(values, namePrefix + "connu_inconnu")) === 1 && (
          <div className="toxique-details-body">
            <div className="toxique-details-elt">
              <Stack direction="column" spacing={4}>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Nom commercial"}
                        name={namePrefix + "nom_commercial"}
                        value={getIn(values, namePrefix + "nom_commercial")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "nom_commercial") &&
                          Boolean(getIn(errors, namePrefix + "nom_commercial"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "nom_commercial") &&
                          getIn(errors, namePrefix + "nom_commercial")
                        }
                        mandatory={true}
                      />
                    </div>
                    <div style={{ width: widthPercentage }}>
                      <LabelMenu
                        title={"Produit"}
                        name={namePrefix + "produit_aliment_id"}
                        value={getIn(values, namePrefix + "produit_aliment_id")}
                        onChange={handleProduitChange}
                        error={
                          getIn(errors, namePrefix + "produit_aliment_id") &&
                          Boolean(getIn(errors, namePrefix + "produit_aliment_id"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "produit_aliment_id") &&
                          getIn(errors, namePrefix + "produit_aliment_id")
                        }
                        mandatory={true}
                        valuesDict={produits}
                      />
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Groupe"}
                        name={namePrefix + "groupe_aliment"}
                        value={getIn(
                          values,
                          namePrefix + "groupe_aliment"
                        )}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "groupe_aliment") &&
                          Boolean(
                            getIn(errors, namePrefix + "groupe_aliment")
                          )
                        }
                        helperText={
                          getIn(touched, namePrefix + "groupe_aliment") &&
                          getIn(errors, namePrefix + "groupe_aliment")
                        }
                        disabled
                      />
                    </div>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Catégorie"}
                        name={namePrefix + "categorie_aliment"}
                        value={getIn(values, namePrefix + "categorie_aliment")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "categorie_aliment") &&
                          Boolean(
                            getIn(errors, namePrefix + "categorie_aliment")
                          )
                        }
                        helperText={
                          getIn(touched, namePrefix + "categorie_aliment") &&
                          getIn(errors, namePrefix + "categorie_aliment")
                        }
                        disabled
                      />
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Classe"}
                        name={namePrefix + "classe_aliment"}
                        value={getIn(values, namePrefix + "classe_aliment")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "classe_aliment") &&
                          Boolean(getIn(errors, namePrefix + "classe_aliment"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "classe_aliment") &&
                          getIn(errors, namePrefix + "classe_aliment")
                        }
                        disabled
                      />
                    </div>
                    <div style={{ width: widthPercentage }}>
                      <LabelInput
                        title={"Code"}
                        name={namePrefix + "code_aliment"}
                        value={getIn(values, namePrefix + "code_aliment")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "code_aliment") &&
                          Boolean(getIn(errors, namePrefix + "code_aliment"))
                        }
                        helperText={
                          getIn(touched, namePrefix + "code_aliment") &&
                          getIn(errors, namePrefix + "code_aliment")
                        }
                        disabled
                      />
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack direction="row" spacing={4}>
                    <div style={{ width: widthPercentage }}>
                      <LabelMenu
                        title={"Voie d'intoxication"}
                        name={namePrefix + "voie_intoxication_id"}
                        value={getIn(values, namePrefix + "voie_intoxication_id")}
                        onChange={handleChange}
                        error={
                          getIn(errors, namePrefix + "voie_intoxication_id") &&
                          Boolean(
                            getIn(errors, namePrefix + "voie_intoxication_id")
                          )
                        }
                        helperText={
                          getIn(touched, namePrefix + "voie_intoxication_id") &&
                          getIn(errors, namePrefix + "voie_intoxication_id")
                        }
                        valuesDict={voix}
                      />
                    </div>
                    <div>
                      <div style={{ height: "59px" }}>
                        <FormControl
                          component="fieldset"
                          style={{ height: "100%" }}
                        >
                          <Stack direction="column" spacing={0} style={{ height: "100%" }}>
                            <div className="case-common-container-body-label">
                              <label style={{ fontSize: "14px", lineHeight: "17px", marginTop: "-4px" }}>Prélèvement:</label>
                            </div>
                            <div className="case-common-container-body-radio">
                              <RadioGroup
                                row
                                name={namePrefix + "prelevement"}
                                onChange={(event) =>
                                  setFieldValue(namePrefix + "prelevement", event.target.value)
                                }
                                value={getIn(values, namePrefix + "prelevement")}
                              >
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label="Humain"
                                />
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Aliment"
                                />

                              </RadioGroup>
                            </div>
                          </Stack>
                        </FormControl>
                      </div>
                    </div>
                  </Stack>
                </div>
                <div>
                  <div
                    style={{
                      paddingRight: "12px",
                      width: "100%",
                      marginLeft: "-5px",

                    }}
                  >
                    <LabelTextArea
                      title={"Résultat de Prélèvement"}
                      name={namePrefix + "resultat_prelevement"}
                      value={getIn(values, namePrefix + "resultat_prelevement")}
                      onChange={handleChange}
                      error={
                        getIn(touched, namePrefix + "resultat_prelevement") &&
                        Boolean(getIn(errors, namePrefix + "resultat_prelevement"))
                      }
                      helperText={
                        getIn(touched, namePrefix + "resultat_prelevement") &&
                        getIn(errors, namePrefix + "resultat_prelevement")
                      }
                    />
                  </div>
                </div>

              </Stack>
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
}
