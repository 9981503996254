import React from "react";
import { Stack } from "@mui/material";
import RepondeurComponent from "../../../components/ViewCase/Toxi/Repondeur/Repondeur";

import "../index.scss";

export default function Repondeur(props) {
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-component">
        <Stack direction="column" spacing={0}>
          <div className="view-case-common-sub-header">
            <div className="view-case-common-sub-header-title">
              information sur le répondeur
            </div>
          </div>
          <div className="view-case-common-body">
            <Stack direction="column" spacing={3}>
              <RepondeurComponent />
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
