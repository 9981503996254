import React, { useEffect } from "react";
import { Stack, RadioGroup, Radio, FormControlLabel, FormControl } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdNomCommercialDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import { getProduitPesticideDetails } from "../../../../services/cases/cases";

import './index.scss';
import '../../index.scss';



export default function ToxiqueDetailsPesticide(props) {
      const { toxiqueType, toxiqueTitle, index } = props;
      const { values, setFieldValue, touched, errors, handleChange } = useFormikContext();
      const namePrefix = "toxiques." + toxiqueType + "." + index + ".";
      const widthPercentage = '45%';

      const referentielResults = useReferentiels([
            "matiere_active",
            "voie_intoxication",
            "forme",
            "classe_chimique",
            "classe_dangerosite",
            "usage_pesticide",
            "produit_pesticide"
      ]);
      const handleNomCommercialChange = (event) => {
            handleChange(event);
            let produitPesticideId = event.target.value;
            updateData(produitPesticideId);
      };

      const updateData = (produitPesticideId) => {
            getProduitPesticideDetails(produitPesticideId).then((data) => {
                  setFieldValue(namePrefix + "matiere_active", data.matiere_active);
                  setFieldValue(namePrefix + "classe_chimique", data.classe_chimique);
                  setFieldValue(namePrefix + "classe_dangerosite", data.classe_dangerosite);
                  setFieldValue(namePrefix + "forme_id", data.forme_id);
                  setFieldValue(namePrefix + "usage_official", data.usage_official);
                  setFieldValue(namePrefix + "type_pesticide", data.type_pesticide);
            });
      }

      useEffect(() => {
            setFieldValue(namePrefix + "index", index + 1);
            setFieldValue(namePrefix + "title", toxiqueTitle);
            let produitPesticideId = getIn(values, namePrefix + "produit_pesticide_id");
            if (produitPesticideId)
                  updateData(produitPesticideId)
      }, [])

      const isLoading = referentielResults.some((result) => result.isLoading);
      if (isLoading) return <CustomLoader isLoading={isLoading} />;
      const matieresActives = flattenIdNomDict(referentielResults[0].data);
      const voix = flattenIdNomDict(referentielResults[1].data);
      const formes = flattenIdNomDict(referentielResults[2].data);
      const classesChimiques = flattenIdNomDict(referentielResults[3].data);
      const classesDangerosites = flattenIdNomDict(referentielResults[4].data);
      const usagesPesticides = flattenIdNomDict(referentielResults[5].data);
      const produitsPesticides = flattenIdNomCommercialDict(referentielResults[6].data);

      return (
            <div className="toxique-details-container">
                  <Stack direction="column" spacing={1}>
                        <div className="toxique-details-header">
                              <div style={{ width: '100%', height: '59px' }}>
                                    <FormControl component="fieldset" style={{ height: '100%' }}>
                                          <Stack direction="row" spacing={0} style={{ height: '100%', alignItems: 'center' }}>
                                                <div className="toxique-details-header-title">
                                                      <label>{toxiqueTitle}</label>
                                                </div>
                                                <div className="case-common-container-body-radio">
                                                      <RadioGroup row name={namePrefix + "connu_inconnu"}
                                                            onChange={(event) => setFieldValue(namePrefix + "connu_inconnu", event.target.value)}
                                                            value={getIn(values, namePrefix + "connu_inconnu")}
                                                      >
                                                            <FormControlLabel value="1" control={<Radio />} label="Connu" />
                                                            <FormControlLabel value="0" control={<Radio />} label="Inconnu" />
                                                      </RadioGroup>
                                                </div>
                                          </Stack>
                                    </FormControl>
                              </div>
                        </div>
                        {parseInt(getIn(values, namePrefix + "connu_inconnu")) === 1 && (
                              <div className="toxique-details-body">
                                    <div className="toxique-details-elt">
                                          <Stack direction="column" spacing={4}>
                                                <div>
                                                      <Stack direction="row" spacing={4}>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelMenu title={"Nom commercial"}
                                                                        name={namePrefix + "produit_pesticide_id"}
                                                                        value={getIn(values, namePrefix + "produit_pesticide_id")}
                                                                        onChange={handleNomCommercialChange}
                                                                        error={getIn(errors, namePrefix + "produit_pesticide_id") && Boolean(getIn(errors, namePrefix + "produit_pesticide_id"))}
                                                                        helperText={getIn(touched, namePrefix + "produit_pesticide_id") && getIn(errors, namePrefix + "produit_pesticide_id")}
                                                                        mandatory={true} valuesDict={produitsPesticides} />
                                                            </div>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelMenu title={"Matière active"}
                                                                        name={namePrefix + "matiere_active"}
                                                                        value={getIn(values, namePrefix + "matiere_active")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "matiere_active") && Boolean(getIn(errors, namePrefix + "matiere_active"))}
                                                                        helperText={getIn(touched, namePrefix + "matiere_active") && getIn(errors, namePrefix + "matiere_active")}
                                                                        valuesDict={matieresActives} disabled />
                                                            </div>
                                                      </Stack>
                                                </div>
                                                <div>
                                                      <Stack direction="row" spacing={4}>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelMenu title={"Classe chimique"}
                                                                        name={namePrefix + "classe_chimique"}
                                                                        value={getIn(values, namePrefix + "classe_chimique")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "classe_chimique") && Boolean(getIn(errors, namePrefix + "classe_chimique"))}
                                                                        helperText={getIn(touched, namePrefix + "classe_chimique") && getIn(errors, namePrefix + "classe_chimique")}
                                                                        valuesDict={classesChimiques} disabled />
                                                            </div>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelMenu title={"Classe dangérosité"}
                                                                        name={namePrefix + "classe_dangerosite"}
                                                                        value={getIn(values, namePrefix + "classe_dangerosite")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "classe_dangerosite") && Boolean(getIn(errors, namePrefix + "classe_dangerosite"))}
                                                                        helperText={getIn(touched, namePrefix + "classe_dangerosite") && getIn(errors, namePrefix + "classe_dangerosite")}
                                                                        valuesDict={classesDangerosites} disabled />
                                                            </div>
                                                      </Stack>
                                                </div>
                                                <div>
                                                      <Stack direction="row" spacing={4}>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelMenu title={"Forme"}
                                                                        name={namePrefix + "forme_id"}
                                                                        value={getIn(values, namePrefix + "forme_id")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "forme_id") && Boolean(getIn(errors, namePrefix + "forme_id"))}
                                                                        helperText={getIn(touched, namePrefix + "forme_id") && getIn(errors, namePrefix + "forme_id")}
                                                                        valuesDict={formes} disabled />
                                                            </div>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelInput title={"Usage officiel"}
                                                                        name={namePrefix + "usage_official"}
                                                                        value={getIn(values, namePrefix + "usage_official")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "usage_official") && Boolean(getIn(errors, namePrefix + "usage_official"))}
                                                                        helperText={getIn(touched, namePrefix + "usage_official") && getIn(errors, namePrefix + "usage_official")}
                                                                        disabled />
                                                            </div>

                                                      </Stack>
                                                </div>
                                                <div>
                                                      <Stack direction="row" spacing={4}>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelInput title={"Type"}
                                                                        name={namePrefix + "type_pesticide"}
                                                                        value={getIn(values, namePrefix + "type_pesticide")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "type_pesticide") && Boolean(getIn(errors, namePrefix + "type_pesticide"))}
                                                                        helperText={getIn(touched, namePrefix + "type_pesticide") && getIn(errors, namePrefix + "type_pesticide")}
                                                                  />
                                                            </div>
                                                      </Stack>
                                                </div>
                                                <div>
                                                      <Stack direction="row" spacing={4}>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelInput title={"Dosage"}
                                                                        name={namePrefix + "dosage"}
                                                                        value={getIn(values, namePrefix + "dosage")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "dosage") && Boolean(getIn(errors, namePrefix + "dosage"))}
                                                                        helperText={getIn(touched, namePrefix + "dosage") && getIn(errors, namePrefix + "dosage")}
                                                                  />

                                                            </div>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelMenu title={"Usage selon notificateur"}
                                                                        name={namePrefix + "usage_notificateur_id"}
                                                                        value={getIn(values, namePrefix + "usage_notificateur_id")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "usage_notificateur_id") && Boolean(getIn(errors, namePrefix + "usage_notificateur_id"))}
                                                                        helperText={getIn(touched, namePrefix + "usage_notificateur_id") && getIn(errors, namePrefix + "usage_notificateur_id")}
                                                                        valuesDict={usagesPesticides} />
                                                            </div>
                                                      </Stack>
                                                </div>
                                                <div>
                                                      <Stack direction="row" spacing={4}>
                                                            <div style={{ width: '96%' }}>
                                                                  <LabelInput title={"Usage (Autre)"}
                                                                        name={namePrefix + "usage_notificateur_autre"}
                                                                        value={getIn(values, namePrefix + "usage_notificateur_autre")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "usage_notificateur_autre") && Boolean(getIn(errors, namePrefix + "usage_notificateur_autre"))}
                                                                        helperText={getIn(touched, namePrefix + "usage_notificateur_autre") && getIn(errors, namePrefix + "usage_notificateur_autre")}
                                                                  />
                                                            </div>
                                                      </Stack>
                                                </div>
                                                <div>
                                                      <Stack direction="row" spacing={4}>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelInput title={"Quantité prise"}
                                                                        name={namePrefix + "quantite"}
                                                                        value={getIn(values, namePrefix + "quantite")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "quantite") && Boolean(getIn(errors, namePrefix + "quantite"))}
                                                                        helperText={getIn(touched, namePrefix + "quantite") && getIn(errors, namePrefix + "quantite")}
                                                                  />
                                                            </div>
                                                            <div style={{ width: widthPercentage }}>
                                                                  <LabelMenu title={"Voie d'intoxication"}
                                                                        name={namePrefix + "voie_intoxication_id"}
                                                                        value={getIn(values, namePrefix + "voie_intoxication_id")}
                                                                        onChange={handleChange}
                                                                        error={getIn(errors, namePrefix + "voie_intoxication_id") && Boolean(getIn(errors, namePrefix + "voie_intoxication_id"))}
                                                                        helperText={getIn(touched, namePrefix + "voie_intoxication_id") && getIn(errors, namePrefix + "voie_intoxication_id")}
                                                                        valuesDict={voix}
                                                                  />
                                                            </div>
                                                      </Stack>
                                                </div>
                                          </Stack>
                                    </div>
                              </div>
                        )}
                  </Stack>
            </div>
      )
}