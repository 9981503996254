import React, { useState } from "react";
import { Stack, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import LabelDatePicker from "../LabelDatePicker/LabelDatePicker";
import './index.scss';
import CustomButton from "../Buttons/CustomButton";



export default function DashboardFilter(props) {
      const { onStartDateChange, onEndDateChange, onDateTypeChange, startDate, endDate, dateType, onPreview, onDownload, disabled } = props;
      const handleDateTypeChange = (event) => {
            onDateTypeChange(event.target.value);
      }
      return (
            <div className="dashboard-filter-container">
                  <Stack spacing={3} style={{ width: '100%' }}>
                        <div>
                              <label className="dash-label-title">
                                    Choisir les dates
                              </label>
                        </div>
                        <div style={{ display: 'flex' }}>
                              <Stack direction='row' spacing={1.5} style={{ width: '100%' }}>
                                    <div className="dashboard-filter-elt">
                                          <div style={{ display: 'flex' }}>
                                                <label className="dash-label">
                                                      Type de Date:
                                                </label>
                                          </div>
                                          <div className="dashboard-filter-elt-radios">
                                                <RadioGroup row name="dateType"
                                                      onChange={handleDateTypeChange}
                                                      value={dateType}
                                                      disabled={disabled}
                                                >
                                                      <FormControlLabel value="date_reception" control={<Radio />} label="Date de réception" />
                                                      <FormControlLabel value="date_intoxication" control={<Radio />} label="Date d'intoxication" />
                                                </RadioGroup>
                                          </div>
                                    </div>
                                    <div className="dashboard-filter-elt">
                                          <Stack direction="row-reverse" spacing={2} style={{ width: '100%' }}>
                                                <div style={{ marginTop: '-15px' }}>
                                                      <LabelDatePicker name="endDate" title={"Date de fin"} onChange={onEndDateChange} value={endDate} />
                                                </div>
                                                <div style={{ marginTop: '-15px' }}>
                                                      <LabelDatePicker name="startDate" title={"Date de début"} onChange={onStartDateChange} value={startDate} />
                                                </div>
                                          </Stack>
                                    </div>
                              </Stack>
                        </div>
                        <div style={{ marginRight: '4px' }}>
                              <Stack direction="row-reverse" spacing={2}>
                                    <CustomButton type="download" onClick={onDownload} disabled={disabled} />
                                    <CustomButton type="preview" onClick={onPreview} disabled={disabled} />
                              </Stack>
                        </div>
                  </Stack>
            </div>
      )
}