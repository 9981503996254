import React from "react";
import { Stack } from "@mui/material";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import "./index.scss";

const notification_headers_dict = {
  "MODIFICATION_CAS": "Modification d'un cas",
  "VALIDATION_CAS": "Demande validation",
  "SUPPRESSION_CAS": "Suppression d'un cas",
  "MODIFICATION_REFERENTIEL": "Modification référentiel"
}
export default function NotificationList(props) {
  const { dates, notification, index, onConsultCase } = props;

  // if notification has same date as the one before it won't show it's date
  const sameDate = dates[index]?.getTime() == dates[index - 1]?.getTime();

  const date = notification.notification_date.split(" ")[0];
  const hour = notification.notification_date.split(" ")[1].split(":")[0];
  const minute = notification.notification_date.split(" ")[1].split(":")[0];

  return (
    <div className="notification-list-container">
      <Stack direction="column" spacing={0}>
        {!sameDate && (
          <div className="notification-list-header">
            <div className="notification-list-date-icon">
              <DateRangeOutlinedIcon
                style={{ width: 14, height: 14, color: "#4F4F4F" }}
              />
            </div>
            <div className="notification-list-notification-label">
              <label>{date}</label>
            </div>
          </div>
        )}
        <div
          style={{ marginLeft: "18px" }}
          className="notification-list-content"
        >
          <div
            onClick={() => {
              if (notification.status === 0)
                onConsultCase(notification);
            }}
            className="notification-list-content-title"
          >
            {notification_headers_dict[notification.notification_type]}
            <span className="notification-time">
              {hour}:{minute} {parseInt(hour) > 12 ? " PM" : " AM"}
            </span>
          </div>
          <ul>{notification.message}</ul>
        </div>
      </Stack>
    </div>
  );
}
