import React, { useEffect } from "react";
import { Stack, Divider, Grid } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import ToxiqueDetailsGroup from "../ToxiqueDetailsGroup/ToxiqueDetailsGroup";
import _ from "lodash";
import "./index.scss";
import ToxiqueCard from "../../../ToxiqueCard/ToxiqueCard";
import {
  toxiqueTypes,
  toxiqueTitles,
  toxiquePrefixes,
} from "../../../../utils/toxiques";

export default function ToxiqueDetailsContainer(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const initToxiqueGroups = (toxiques) => {

    let initialToxiqueGroups = {}
    if (toxiques === null || toxiques === undefined) {
      return initialToxiqueGroups;
    }
    Object.keys(toxiques)?.map((toxiqueType) => (
      initialToxiqueGroups[toxiqueType] = {
        type: toxiqueType,
        title: toxiqueTitles[toxiqueType],
        prefix: toxiquePrefixes[toxiqueType],
        data: toxiques[toxiqueType] && toxiques[toxiqueType].length > 0 ? toxiques[toxiqueType] : null,
      }
    ))
    return initialToxiqueGroups;
  }

  useEffect(() => {
    setToxiqueGroups(initToxiqueGroups(values?.toxiques));
  }, [])
  const [toxiqueGroups, setToxiqueGroups] = React.useState(null);
  //const [toxiqueGroups, setToxiqueGroups] = React.useState(values.toxiques);

  const handleToxiqueClick = (toxiqueType, checked) => {
    let newToxiqueGroups = { ...toxiqueGroups };
    if (!checked) {
      newToxiqueGroups[toxiqueType].data = null;
      setToxiqueGroups(newToxiqueGroups);
      setFieldValue("toxiques." + toxiqueType, []);
    } else {
      newToxiqueGroups[toxiqueType].data = [];
      setToxiqueGroups(newToxiqueGroups);
      setFieldValue("toxiques." + toxiqueType, []);
    }
  };

  return (
    <div className="toxique-details-container-container">
      <Stack direction="row" spacing={0}>
        <div className="toxique-details-container-left">
          <Stack direction="column" spacing={2}>
            <div className="toxique-details-container-title">
              <label>Type de produit</label>
            </div>
            <div className="toxique-details-container-left-elts">
              <Grid container spacing={1}>
                {toxiqueGroups && toxiqueTypes.map((toxiqueType, index) => (
                  <Grid key={index} item xs={4}>
                    <ToxiqueCard
                      type={toxiqueType}
                      onChangeHandler={(checked) => handleToxiqueClick(toxiqueType, checked)}
                      selected={toxiqueGroups[toxiqueType] && toxiqueGroups[toxiqueType]?.data?.length > 0}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </Stack>
        </div>
        <div className="toxique-details-container-divider">
          <Divider
            orientation="vertical"
            flexItem
            style={{ borderColor: "#E0E0E0" }}
          />
        </div>
        <div className="toxique-details-container-right">
          <Stack
            direction="column"
            spacing={2}
            style={{ width: "fit-content" }}
          >
            <div className="toxique-details-container-title">
              <label>Détails par toxique</label>
            </div>

            <div className="toxique-details-container-right-details">
              <Stack direction="column" spacing={2}>
                {toxiqueGroups && Object.keys(toxiqueGroups).map((toxiqueType, index) =>
                  toxiqueGroups[toxiqueType]?.data && (
                    <div key={index}>
                      <ToxiqueDetailsGroup toxiqueGroup={toxiqueGroups[toxiqueType]} />
                    </div>
                  ))}
              </Stack>
            </div>
          </Stack>
        </div>
      </Stack>
    </div>
  );
}
