import React, { useState } from "react";
import { Stack } from "@mui/material";
import SimplePageTitle from "../../components/PageTitle/SimplePageTitle";
import BrouillonCases from "./BrouillonCases";
import "./index.scss";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import { isAllowed } from "../../services/auth";
import { addCasesPermissions } from "../../utils/permissions";

export default function BrouillonCasesModule(props) {
  const { onEditMode } = props;
  const [activeTab, setActiveTab] = useState("");
  const tabs = ["toxicovigilance", "laboratoire"];

  return (
    <div className="brouillon-cases-module-container">
      <div className="add-cases-container">
        <Stack direction="column" spacing={0}>
          <div className="brouillon-cases-module-container-title">
            <SimplePageTitle type="brouillon" />
          </div>
          <div className="add-cases-title">
            <label>Sélectionner rubrique</label>
          </div>
          <div className="add-cases-buttons">
            <Stack direction="row" spacing={1}>
              {tabs.map((tab, index) => (
                isAllowed([addCasesPermissions[index]]) && (
                  <RubriqueButton
                    type={tab}
                    active={activeTab === tab}
                    onClick={() => setActiveTab(tab)}
                  />)
              ))}
            </Stack>
          </div>
        </Stack>
      </div>
      <Stack direction="column" spacing={0}>
        <div className="brouillon-cases-module-container-body">
          <BrouillonCases activeTab={activeTab} onEditMode={onEditMode} />
        </div>
      </Stack>
    </div>
  );
}
