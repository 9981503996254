import React, { useEffect } from "react";
import {
    Stack,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelTextArea from "../../../LabelTextArea";
import { getProduitGazDetails } from "../../../../services/cases/cases";

import "./index.scss";
import "../../index.scss";

export default function ToxiqueDetailsGaz(props) {
    const { toxiqueType, toxiqueTitle, index } = props;
    const { values, setFieldValue, touched, errors, handleChange } =
        useFormikContext();
    const widthPercentage = "45%";
    const referentielResults = useReferentiels([
        "produit_gaz",
        "voie_intoxication",
        "source_gaz"
    ]);
    const namePrefix = "toxiques." + toxiqueType + "." + index + ".";

    const handleProduitChange = (event) => {
        handleChange(event);
        let produitId = event.target.value;
        updateData(produitId);
    };

    const updateData = (produitId) => {
        getProduitGazDetails(produitId).then((data) => {
            setFieldValue(namePrefix + "classe_gaz", data.classe_gaz);
            setFieldValue(namePrefix + "code", data.code);

        });
    }

    useEffect(() => {
        setFieldValue(namePrefix + "index", index + 1);
        setFieldValue(namePrefix + "title", toxiqueTitle);
        let produitId = getIn(values, namePrefix + "produit_gaz_id");
        if (produitId)
            updateData(produitId)
    }, [])

    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading) return <CustomLoader isLoading={isLoading} />;

    const produits = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);
    const sources = flattenIdNomDict(referentielResults[2].data);

    return (
        <div className="toxique-details-container">
            <Stack direction="column" spacing={1}>
                <div className="toxique-details-header">
                    <div style={{ width: "100%", height: "59px" }}>
                        <FormControl component="fieldset" style={{ height: "100%" }}>
                            <Stack
                                direction="row"
                                spacing={0}
                                style={{ height: "100%", alignItems: "center" }}
                            >
                                <div className="toxique-details-header-title">
                                    <label>
                                        {toxiqueTitle} <span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                                <div className="case-common-container-body-radio">
                                    <RadioGroup
                                        row
                                        name={namePrefix + "connu_inconnu"}
                                        onChange={(event) =>
                                            setFieldValue(
                                                namePrefix + "connu_inconnu",
                                                event.target.value
                                            )
                                        }
                                        value={getIn(values, namePrefix + "connu_inconnu")}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="Connu"
                                        />
                                        <FormControlLabel
                                            value="0"
                                            control={<Radio />}
                                            label="Inconnu"
                                        />
                                    </RadioGroup>
                                </div>
                            </Stack>
                        </FormControl>
                    </div>
                </div>
                {parseInt(getIn(values, namePrefix + "connu_inconnu")) === 1 && (
                    <div className="toxique-details-body">
                        <div className="toxique-details-elt">
                            <Stack direction="column" spacing={4}>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Nom"}
                                                name={namePrefix + "nom"}
                                                value={getIn(values, namePrefix + "nom")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "nom") &&
                                                    Boolean(getIn(errors, namePrefix + "nom"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "nom") &&
                                                    getIn(errors, namePrefix + "nom")
                                                }
                                                mandatory={true}
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Produit"}
                                                name={namePrefix + "produit_gaz_id"}
                                                value={getIn(values, namePrefix + "produit_gaz_id")}
                                                onChange={handleProduitChange}
                                                error={
                                                    getIn(errors, namePrefix + "produit_gaz_id") &&
                                                    Boolean(getIn(errors, namePrefix + "produit_gaz_id"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "produit_gaz_id") &&
                                                    getIn(errors, namePrefix + "produit_gaz_id")
                                                }
                                                mandatory={true}
                                                valuesDict={produits}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Classe"}
                                                name={namePrefix + "classe_gaz"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "classe_gaz"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "classe_gaz") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "classe_gaz")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "classe_gaz") &&
                                                    getIn(errors, namePrefix + "classe_gaz")
                                                }
                                                disabled
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Code"}
                                                name={namePrefix + "code"}
                                                value={getIn(values, namePrefix + "code")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "code") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "code")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "code") &&
                                                    getIn(errors, namePrefix + "code")
                                                }
                                                disabled
                                            />
                                        </div>
                                    </Stack>
                                </div>

                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Voie d'intoxication"}
                                                name={namePrefix + "voie_intoxication_id"}
                                                value={getIn(values, namePrefix + "voie_intoxication_id")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "voie_intoxication_id") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "voie_intoxication_id")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "voie_intoxication_id") &&
                                                    getIn(errors, namePrefix + "voie_intoxication_id")
                                                }
                                                valuesDict={voix}
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Source"}
                                                name={namePrefix + "source_id"}
                                                value={getIn(values, namePrefix + "source_id")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "source_id") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "source_id")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "source_id") &&
                                                    getIn(errors, namePrefix + "source_id")
                                                }
                                                valuesDict={sources}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <div
                                        style={{
                                            paddingRight: "12px",
                                            width: "100%",
                                            marginLeft: "-5px",

                                        }}
                                    >
                                        <LabelTextArea
                                            title={"Si autre, spécifiez"}
                                            name={namePrefix + "autre_source"}
                                            value={getIn(values, namePrefix + "autre_source")}
                                            onChange={handleChange}
                                            error={
                                                getIn(touched, namePrefix + "autre_source") &&
                                                Boolean(getIn(errors, namePrefix + "autre_source"))
                                            }
                                            helperText={
                                                getIn(touched, namePrefix + "autre_source") &&
                                                getIn(errors, namePrefix + "autre_source")
                                            }
                                        />
                                    </div>
                                </div>

                            </Stack>
                        </div>
                    </div>
                )}
            </Stack>
        </div>
    );
}
