import React, { useContext } from "react";
import {
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useFormikContext, getIn } from "formik";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { styled } from "@mui/material/styles";
import "./index.scss";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";
import { laboDosageUnits } from "../../../../utils/divers/divers";

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F0F0F0",
    color: theme.palette.common.white,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#25282B",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const checkBoxSX = {
  color: "#C4C4C4",
  borderRadius: "5px",
  "&.Mui-checked": {
    color: "#6BE158",
  },
};

export default function PTTRDosage(props) {
  let componentValues = props.values;
  const validated = useContext(ValidatedContext);

  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const prefix = props.prefix;

  return (
    <div className="pttr-dosage-container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell style={{ width: "10%" }}></HeaderTableCell>
              <HeaderTableCell style={{ width: "40%" }}>
                Analyse
              </HeaderTableCell>
              <HeaderTableCell style={{ width: "50%" }}>Dosage</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {componentValues.map((key, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={validated}
                          sx={checkBoxSX}
                          checked={getIn(values, prefix + index + ".checked")}
                          onChange={handleChange}
                          name={prefix + index + ".checked"}
                        />
                      }
                      label=""
                    />
                  </FormGroup>
                </TableCell>
                <TableCell>
                  <label className="pttr-dosage-label">{key.analyse}</label>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <div style={{ width: "20%" }}>
                      <LabelMenu
                        name={prefix + index + ".dosage.sign"}
                        onChange={handleChange}
                        error={
                          getIn(errors, prefix + index + ".dosage.sign") &&
                          Boolean(
                            getIn(errors, prefix + index + ".dosage.sign")
                          )
                        }
                        helperText={
                          getIn(touched, prefix + index + ".dosage.sign") &&
                          getIn(errors, prefix + index + ".dosage.sign")
                        }
                        value={getIn(values, prefix + index + ".dosage.sign")}
                        valuesDict={{ "=": "=", ">=": ">=", "<=": "<=" }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <LabelInput
                        name={prefix + index + ".dosage.value"}
                        onChange={handleChange}
                        error={
                          getIn(errors, prefix + index + ".dosage.value") &&
                          Boolean(
                            getIn(errors, prefix + index + ".dosage.value")
                          )
                        }
                        helperText={
                          getIn(touched, prefix + index + ".dosage.value") &&
                          getIn(errors, prefix + index + ".dosage.value")
                        }
                        value={getIn(values, prefix + index + ".dosage.value")}
                        placeholder={"Dosage"}
                      />
                    </div>
                    <div style={{ width: "20%" }}>
                      <LabelMenu
                        name={prefix + index + ".dosage.unit"}
                        onChange={handleChange}
                        error={
                          getIn(errors, prefix + index + ".dosage.unit") &&
                          Boolean(
                            getIn(errors, prefix + index + ".dosage.unit")
                          )
                        }
                        helperText={
                          getIn(touched, prefix + index + ".dosage.unit") &&
                          getIn(errors, prefix + index + ".dosage.unit")
                        }
                        value={getIn(values, prefix + index + ".dosage.unit")}
                        valuesDict={laboDosageUnits}
                      />
                    </div>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
