import { Divider, Stack } from "@mui/material";
import React from "react";
import RoleMenu from "../../components/RoleMenu/RoleMenu";
import UserPermission from "../../components/UserPermission/UserPermission";
import {RolePermissionsContext} from '../../contextes/UserPermissionsContext';
import './index.scss'



export default function RolePermissions(props) {
  const [updateable, setUpdateable] = React.useState(false);
  const [role, setRole] = React.useState(1);

  const handleRoleClick = (newRole) => {
    setRole(newRole);
    setUpdateable(false);
  }

  const handleUpdateClick = () => {
    setUpdateable(true);
  }

  return (
    <div className="role-permissions-container-root">
      <div className="role-permissions-container">
        <RolePermissionsContext.Provider value={{ updateable, role, handleRoleClick, handleUpdateClick }}>
          <div className="role-permissions-main">
            <Stack direction="row" spacing={0}>
              <div className="role-permissions-role-menu">
                <RoleMenu />
              </div>
              <div className="role-permissions-divider">
                <Divider orientation="vertical" flexItem />
              </div>
              <div className="role-permissions-user-permission">
                <UserPermission />
              </div>
            </Stack>
          </div>
        </RolePermissionsContext.Provider>
      </div>
    </div>
  )

}