import React, { useState, useContext, useEffect } from "react";
import {
  Stack,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  FormControl,
  Modal,
} from "@mui/material";
import CustomButton from "../../../Buttons/CustomButton";
import { useFormikContext, getIn } from "formik";
import "../../index.scss";
import PTTRDosage from "../PTTRDosage/PTTRDosage";
import PTTRRecherche from "../PTTRRecherche/PTTRRecherche";
import NewElementModal from "../../Common/NewElementModal/NewElementModal";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import { getLaboCategories } from "../../../../services/cases/cases";

export default function PTTR(props) {
  const middlePercentage = "50%";
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const validated = useContext(ValidatedContext);
  // get referentiel table name
  // const referentielResults = useReferentiels(["pttr"]);
  // const analyses = flattenIdNomDict(referentielResults[0].data);

  const prefix = "resultat.PTRR.";
  const dosages = getIn(values, prefix + "resultats_dosage");
  const recherches = getIn(values, prefix + "resultats_recherche");
  const [newDosageModal, setNewDosageModal] = useState(false);
  const [newRechercheModal, setNewRechercheModal] = useState(false);
  const [categoriesRecherche, setCategoriesRecherche] = useState([]);
  const [categoriesDosage, setCategoriesDosage] = useState([]);

  const handleDosageNewRowClick = () => {
    setNewDosageModal(true);
  };
  const handleCloseDosageModal = () => {
    setNewDosageModal(false);
  };

  const handleRechercheNewRowClick = () => {
    setNewRechercheModal(true);
  };
  const handleCloseRechercheModal = () => {
    setNewRechercheModal(false);
  };

  const handleOnNewDosage = (value) => {
    let newDosage = { checked: false, analyse: value, dosage: "" };
    let newDosages = getIn(values, prefix + "resultats_dosage");
    if (newDosages === undefined || newDosages === null) {
      newDosages = [];
    }
    newDosages.push(newDosage);
    setFieldValue(prefix + "resultats_dosage", newDosages);
    setNewDosageModal(false);
  };

  const handleOnNewRecherche = (value) => {
    let newRecherche = { checked: false, analyse: value, result: "" };
    let newRecherches = getIn(values, prefix + "resultats_recherche");
    if (newRecherches === undefined || newRecherches === null) {
      newRecherches = [];
    }
    newRecherches.push(newRecherche);
    setFieldValue(prefix + "resultats_recherche", newRecherches);
    setNewRechercheModal(false);
  };
  useEffect(() => {
    getLaboCategories("resultats_dosage").then((data) => {
      setCategoriesDosage(data.map((cat) => cat.nom));
    });
    getLaboCategories("resultats_recherche").then((data) => {
      setCategoriesRecherche(data.map((cat) => cat.nom));
    });
  }, []);

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>PTRR</label>
      </div>
      <div
        className="case-common-container-body"
        style={{ marginRight: "20px" }}
      >
        <Stack direction="column" spacing={4}>
          {/* <div style={{ width: middlePercentage, height: "59px" }}>
            <FormControl
              disabled={validated}
              component="fieldset"
              style={{ height: "100%" }}
            >
              <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                <div className="case-common-container-body-label">
                  <label>
                    Résultat d'analyse <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="case-common-container-body-radio">
                  <RadioGroup
                    row
                    name={prefix + "resultat_analyse"}
                    onChange={(event) =>
                      setFieldValue(
                        prefix + "resultat_analyse",
                        event.target.value
                      )
                    }
                    value={getIn(values, prefix + "resultat_analyse")}
                    error={
                      getIn(touched, prefix + "resultat_analyse") &&
                      Boolean(getIn(errors, prefix + "resultat_analyse"))
                    }
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Positive"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Négative"
                    />
                  </RadioGroup>
                </div>
              </Stack>
              <FormHelperText style={{ color: "#d32f2f", marginLeft: "14px" }}>
                {getIn(touched, prefix + "resultat_analyse") &&
                  getIn(errors, prefix + "resultat_analyse")}
              </FormHelperText>
            </FormControl>
          </div> */}
          <div>
            <Stack direction="column" spacing={1}>
              <div className="container-middle">
                <Stack direction="row" spacing={0} style={{ width: "100%" }}>
                  <div style={{ width: "50%", alignSelf: "center" }}>
                    <div className="container-table-name">
                      <label>Résultats de dosage</label>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Stack
                      direction="row-reverse"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0}
                    >
                      <div>
                        <CustomButton
                          type={"add"}
                          onClick={handleDosageNewRowClick}
                          title={"Ajouter analyse"}
                          customBackground={true}
                        />
                      </div>
                    </Stack>
                  </div>
                </Stack>
              </div>
              <div>
                <PTTRDosage
                  values={dosages ?? []}
                  prefix={prefix + "resultats_dosage."}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="column" spacing={1}>
              <div className="container-middle">
                <Stack direction="row" spacing={0} style={{ width: "100%" }}>
                  <div style={{ width: "50%", alignSelf: "center" }}>
                    <div className="container-table-name">
                      <label>Résultats de recherche</label>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Stack
                      direction="row-reverse"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0}
                    >
                      <div>
                        <CustomButton
                          type={"add"}
                          onClick={handleRechercheNewRowClick}
                          title={"Ajouter analyse"}
                          customBackground={true}
                        />
                      </div>
                    </Stack>
                  </div>
                </Stack>
              </div>
              <div>
                <PTTRRecherche values={recherches ?? []} />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
      <Modal open={newDosageModal} onClose={handleCloseDosageModal}>
        <NewElementModal
          onClose={handleCloseDosageModal}
          onNew={handleOnNewDosage}
          categories={categoriesDosage}
        />
      </Modal>

      <Modal open={newRechercheModal} onClose={handleCloseRechercheModal}>
        <NewElementModal
          onClose={handleCloseRechercheModal}
          onNew={handleOnNewRecherche}
          categories={categoriesRecherche}
        />
      </Modal>
    </div>
  );
}
