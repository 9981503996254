import React, { Component } from 'react';
import { Button, Stack } from '@mui/material';
import history from '../../utils/history';
import './index.scss'
import a from "./assets/1.png"
import b from "./assets/2.png"
import c from "./assets/3.png"
import d from "./assets/4.png"
import f from "./assets/5.png"
import e from "./assets/6.png"

export default function MainMenuButton(props) {
    const { type } = props;
    const handleOnClick = () => {
        history.push('/main/'+ type);
    }
    let img, title;

    switch (type) {
        case 'add-cases':
            img = a;
            title = 'Ajouter un cas';
            break;
        case 'view-cases':
            img = b;
            title = 'Consulter un cas';
            break;
        case 'extract-data':
            img = c;
            title = 'Extraction des données';
            break;
        case 'update-ref':
            img = d;
            title = 'Mise à jour des référentiels';
            break;
        case 'dashboard':
            img = e;
            title = 'Dashboard / Rapport';
            break;
        case 'user-management':
            img = f;
            title = 'Gestion des utilisateurs';
            break;
        default:
            break;
    }

    return (
        <Button onClick={handleOnClick} style={{ textTransform: 'none' }}>
            <div className="main-menu-button">
                <Stack direction="row" spacing={0} style={{ alignItems: 'center' }}>
                    <div className="main-menu-left-box" >
                        <img src={img} alt="" />
                    </div>
                    <div className="main-menu-right-box">
                        <label>{title}</label>
                    </div>
                </Stack>
            </div>
        </Button>

    )
}


