import React, {useContext} from "react";
import { Stack } from "@mui/material";
import '../../index.scss';
import LabelInput from "../../../LabelInput/LabelInput";
import { useFormikContext } from "formik";


export default function Traitement(props) {
      const {values, touched, errors, handleChange, setFieldValue} = useFormikContext();
      const widthPercentage = '31%';

      return (
            <div className="case-common-container" >
                  <div className="case-common-container-title">
                        <label>Traitement</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Traitement T0"} 
                                                      name={"traitement.t0"} 
                                                      onChange={handleChange}
                                                      error={touched?.traitement?.t0 && Boolean(errors?.traitement?.t0)}
                                                      helperText={touched?.traitement?.t0 && errors?.traitement?.t0}
                                                      mandatory={true}  />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Traitement T1"} 
                                                      name={"traitement.t1"} 
                                                      onChange={handleChange}
                                                      error={touched?.traitement?.t1 && Boolean(errors?.traitement?.t1)}
                                                      helperText={touched?.traitement?.t1 && errors?.traitement?.t1}
                                                      mandatory={true}  />
                                          </div>
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}