import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import ExtractToxiData from "./ExtractToxiData";
import ExtractLaboData from "./ExtractLaboData";
import ExtractScorpionData from "./ExtractScorpionData";
import { ExtractDataContext } from "../../contextes/ExtractDataContext";
import { isAllowed } from "../../services/auth";
import { extractDataPermissions } from "../../utils/permissions";
import "./index.scss";
import { extractData } from "../../services/cases/cases";

export default function ExtractDataModule(props) {
  const [componentIndex, setComponentIndex] = useState(-1);
  const [categories, setCategories] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [isLoading, setisLoading] = useState(false);

  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  let component = null;

  const activateComponent = (newComponentIndex) => {
    if (newComponentIndex !== componentIndex) {
      setCategories([]);
    }
    setComponentIndex(newComponentIndex);
  };

  const handleExtractClick = async (type) => {
    setisLoading(true);
    await extractData(startDate, endDate, String(categories), type);
    setisLoading(false);
  };

  switch (componentIndex) {
    case 0:
      component = <ExtractToxiData />;
      break;
    case 1:
      component = <ExtractLaboData />;
      break;
    case 2:
      component = <ExtractScorpionData />;
      break;
    default:
      break;
  }

  return (
    <ExtractDataContext.Provider
      value={{
        categories,
        setCategories,
        setStartDate,
        setEndDate,
        handleExtractClick,
        isLoading,
      }}
    >
      <div className="extract-data-module-container">
        <Stack direction="column" spacing={0}>
          <div className="extract-data-module-title">
            <label>Sélectionner rubrique</label>
          </div>
          <div className="extract-data-module-buttons">
            <Stack direction="row" spacing={0}>
              {isAllowed([extractDataPermissions[0]]) &&
                < RubriqueButton
                  type="toxicovigilance"
                  active={componentIndex === 0}
                  onClick={() => activateComponent(0)}
                />
              }
              {isAllowed([extractDataPermissions[1]]) &&
                <RubriqueButton
                  type="laboratoire"
                  active={componentIndex === 1}
                  onClick={() => activateComponent(1)}
                />
              }
              {/* <RubriqueButton
                type="scorpion"
                active={componentIndex === 2}
                onClick={() => activateComponent(2)}
              /> */}
            </Stack>
          </div>
          <div className="extract-data-module-title">
            <label>Extraction des données</label>
          </div>
          <div className="extract-data-module-body">{component}</div>
        </Stack>
      </div>
    </ExtractDataContext.Provider>
  );
}
