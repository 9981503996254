import React from "react"
import { Stack } from "@mui/material";
import CL from "../../../components/ViewCase/Labo/CL/CL";
import PTTR from "../../../components/ViewCase/Labo/PTTR/PTTR";
import AbsorptionAtomique from "../../../components/ViewCase/Labo/AbsorptionAtomique/AbsorptionAtomique";
import GCMS from "../../../components/ViewCase/Labo/GCMS/GCMS";
import '../index.scss';

export default function Resultat(props) {


      return (
            <div className='view-case-common-container'>
                  <div className='view-case-common-component'>
                        <Stack direction="column" spacing={0}>
                              <div className='view-case-common-sub-header'>
                                    <div className='view-case-common-sub-header-title'>
                                          information sur les résultats
                                    </div>
                              </div>
                              <div className='view-case-common-body'>
                                    <Stack direction="column" spacing={3}>
                                          <CL />
                                          <GCMS />
                                          <PTTR />
                                          <AbsorptionAtomique />

                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}