import { createTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';



const gridTheme = createTheme(
  locales['frFR']
);

gridTheme.components = {
  ...gridTheme.components,
  MuiDataGrid: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',

        '& .grid-header': {
          background: '#F0F0F0',
        },
        '& .MuiDataGrid-columnsContainer': {
          background: '#F0F0F0',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '14px',
          letterSpacing: '0.1px',
          color: '#25282B',


        },
        '& .MuiDataGrid-selectedRowCount': {
          color: '#FFFFFF'
        },
        '& .MuiDataGrid-actionsCell': {
          gridGap: '0px'
        }


      },
    }
  }
}

export default gridTheme;