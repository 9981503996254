import React, {useContext} from "react";
import { Stack } from "@mui/material";
import '../../index.scss';
import LabelInput from "../../../LabelInput/LabelInput";
import { useFormikContext } from "formik";

export default function Hospitalisation(props) {
      const {values, touched, errors, handleChange, setFieldValue} = useFormikContext();
      const widthPercentage = '31%';

      return (
            <div className="case-common-container" >
                  <div className="case-common-container-title">
                        <label>Hospitalisation</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Uniquement mis en observation"} 
                                                      name={"hospitalisation.mis_observation"} 
                                                      onChange={handleChange}
                                                      error={touched?.hospitalisation?.mis_observation && Boolean(errors?.hospitalisation?.mis_observation)}
                                                      helperText={touched?.hospitalisation?.mis_observation && errors?.hospitalisation?.mis_observation}
                                                      mandatory={true}  />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Hospitalisation"} 
                                                      name={"hospitalisation.hospitalisation"} 
                                                      onChange={handleChange}
                                                      error={touched?.hospitalisation?.hospitalisation && Boolean(errors?.hospitalisation?.hospitalisation)}
                                                      helperText={touched?.hospitalisation?.hospitalisation && errors?.hospitalisation?.hospitalisation}
                                                      mandatory={true}  />
                                          </div>
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}