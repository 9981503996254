import React from "react";
import { Button } from "@mui/material";

export default function NormalButton(props) {
  const { btnType, disabled, ...otherProps } = props;
  let background = null;
  let title = null;
  let variant = "contained";
  let border = "none";
  let color = "inherit";
  switch (btnType) {
    case "cancel":
      title = "Annuler";
      background = "transparent";
      variant = "outlined";
      break;
    case "next":
      title = "Suivant";
      background = "#5469D4";
      color = "#FFFFFF";
      break;
    case "validate":
      title = "Valider";
      background = "#4DC14B";
      color = "#FFFFFF";
      break;
    case "previous":
      title = "Précédent";
      background = "transparent";
      variant = "outlined";
      border = "1px solid #C1C9D2";
      break;
    case "delete":
      title = "Supprimer";
      background = "#EB5757";
      variant = "outlined";
      border = "1px solid #C1C9D2";
      color = "white"
      break;      
    case "save":
      title = "Sauvegarder";
      background = "#367BF5";
      color = "#FFFFFF";
    default:
      break;
  }

  return (
    <Button
      variant={variant}
      disabled={disabled}
      style={{
        background: disabled ? "#B2B2B2" : background,
        borderRadius: "4px",
        height: "32px",
        textTransform: "none",
        border: border,
        color: color,
      }}
      {...otherProps}
    >
      {title}
    </Button>
  );
}
