import React, { useEffect, useState } from "react";

import { Divider, Stack } from "@mui/material";
import "./index.scss";
import { useUsers } from "../../../services/users/users";
import axios from "axios";
import { authHeaderKey } from "../../../utils/axiosInstances";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";

export const CaseContext = React.createContext();

export default function Notification(props) {
  const { dates, index, notification, onConsultCase } = props;

  const [userImage, setUserImage] = useState("");

  const { data, isLoading } = useUsers();

  const notificationTime = notification.notification_date.split(" ")[1];
  const notificationHour = notificationTime.split(":")[0];
  const notificationMinute = notificationTime.split(":")[1];

  const axiosBaseConfig = {
    baseURL:
      process.env.REACT_APP_ENV === "prod"
        ? process.env.REACT_APP_BACKEND_URL_PROD
        : process.env.REACT_APP_BACKEND_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  const getPreviousDay = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  };

  const today = new Date().setHours(1, 0, 0, 0);

  const sameDate = dates[index]?.getTime() == dates[index - 1]?.getTime();
  const isNotificationToday = dates[index]?.getTime() == today; // check if notification date is today
  const isNotificationYesterday =
    dates[index]?.getTime() == getPreviousDay().setHours(1, 0, 0, 0); // check if notification date is today

  useEffect(() => {
    if (!notification.creator_user) return;
    axios
      .get(`${notification.creator_user.avatar_url}`, {
        headers: { ...axiosBaseConfig.headers, Authorization: authHeaderKey() },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setUserImage(base64);
      });
  }, [notification]);

  return (
    <div className="container">
      {!sameDate && (
        <div className="notification-modal-list-header">
          <div className="notification-modal-list-date-icon">
            <DateRangeOutlinedIcon
              style={{ width: 14, height: 14, color: "#4F4F4F" }}
            />
          </div>
          <div className="notification-modal-list-notification-label">
            <label>
              {new Date(
                notification.notification_date.split(" ")[0]
              ).toLocaleDateString("fr-FR", {
                weekday: "long",
                month: "numeric",
                year: "numeric",
                formatMatcher: "best fit",
              })}{" "}
            </label>
          </div>
        </div>
      )}
      <Stack direction="column" spacing={1}>
        <Stack
          marginY={3}
          marginX={4}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          {notification.creator_user && (
            <img
              alt=""
              className="profile-picture"
              src={`data:image/jpeg;charset=utf-8;base64,${userImage}`}
            />
          )}
          <Stack direction="column" spacing={1}>
            <div className="notification-message">
              {notification.creator_user && (
                <span className="sender">
                  {notification.creator_user.nom_prenom}
                </span>
              )}
              <span className="action">
                {notification.notification_type === "MODIFICATION_CAS" &&
                  " a modifié le cas (cas validé) : Numéro du cas "}
                {notification.notification_type ===
                  "demande de validation d'un cas" &&
                  " a demandé la validation d'un cas : "}
                {notification.notification_type === "VALIDATION_CAS" &&
                  " a demandé la validation du cas numéro : "}
                {notification.notification_type === "SUPPRESSION_CAS" &&
                  " a supprimé le cas numéro : "}
                {notification.notification_type === "MODIFICATION_REFERENTIEL" &&
                  " a modifié le référentiel : "}
              </span>
              <span className="case">{notification.message}.</span>
            </div>
            <div className="notification-date">
              <span>
                {isNotificationToday && "Aujourd'hui"}
                {isNotificationYesterday && "Hier"} à {notificationHour}:
                {notificationMinute}
                {parseInt(notificationHour) > 12 ? " PM" : " AM"}
              </span>
            </div>
            {notification.notification_type !== "SUPPRESSION_CAS" && (
              <button
                onClick={() => {
                  if (notification.status === 0) {
                    onConsultCase(notification)
                  }
                }
                }
                className="consult-button"
              >
                consulter
              </button>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Divider className="divider" />
    </div>
  );
}
