import React from "react";
import { Stack } from "@mui/material";
import UserProfile from "./UserProfile";
import symbol from "./assets/Union.png";
import './index.scss';



export default function UserProfilePage(props) {


      return (
            <div className="user-profile-page-container">
                  <Stack direction="column" spacing={0}>
                        <div className="user-profile-page-header">
                              <Stack direction="row" spacing={2}>
                                    <div>
                                          <img src={symbol} alt="" />
                                    </div>
                                    <div>
                                          <label>Paramètres du compte</label>
                                    </div>
                              </Stack>
                        </div>
                        <div className="user-profile-page-body">
                              <UserProfile onUserChange={props.onUserChange} />
                        </div>
                  </Stack>
            </div>
      )
}