import React, { useState, useEffect } from "react";

import { Stack } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import { getProvince, getProvinces, getCommunes, getRegionWithProvince } from "../../../../services/cases/cases";

import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import "../../index.scss";

export default function Prescripteur(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const [provinces, setProvinces] = useState({});
  const [communes, setCommunes] = useState({});
  const widthPercentage = "31%";
  const prefix = "prescripteur.prescripteur.";
  const onComuneProvinceChange = (event, name) => {
    handleChange(event);
    let communeId = getIn(values, prefix + "commune_id");
    let provinceId = getIn(values, prefix + "province_id");
    if (name === "commune") {
      communeId = event.target.value;
      getProvince(communeId).then((data) => {
        setFieldValue(prefix + "province_id", "" + data.province_id);
        setFieldValue(prefix + "region_id", "" + data.region_id);
      })
    } else {
      provinceId = event.target.value;
      onProvinceChange(event);
    }
  };

  const onRegionChange = (event) => {
    handleChange(event);
    let regionId = event.target.value;
    if (regionId === "") return;
    getProvinces(regionId).then((data) => {
      setProvinces(flattenIdNomDict(data));
      setFieldValue(prefix + "commune_id", "");
      setFieldValue(prefix + "province_id", "");
    }
    )
  };

  const onProvinceChange = (event) => {

    handleChange(event);
    let provinceId = event.target.value;
    if (provinceId === "") {
      return;
    }
    getCommunes(provinceId).then((data) => {
      setCommunes(flattenIdNomDict(data));
      setFieldValue(prefix + "commune_id", "");
    }
    )

    getRegionWithProvince(provinceId).then((data) => {
      setFieldValue(prefix + "region_id", "" + data);
    });
  };

  const referentielResults = useReferentiels([
    "prescripteur_type",
    "commune",
    "province",
    "region",
    "provenance",
    "service"
  ]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  useEffect(() => {
    setProvinces(isLoading ? {} : flattenIdNomDict(referentielResults[2].data));
    setCommunes(isLoading ? {} : flattenIdNomDict(referentielResults[1].data))
  }, [isLoading]);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const prescripteurTypes = flattenIdNomDict(referentielResults[0].data);
  const regions = flattenIdNomDict(referentielResults[3].data);
  const provenances = flattenIdNomDict(referentielResults[4].data);
  const services = flattenIdNomDict(referentielResults[5].data);

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Le prescripteur</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={5}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Type du prescripteur"}
                  name={prefix + "prescripteur_type_id"}
                  value={getIn(values, prefix + "prescripteur_type_id")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "prescripteur_type_id") &&
                    Boolean(getIn(errors, prefix + "prescripteur_type_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "prescripteur_type_id") &&
                    getIn(errors, prefix + "prescripteur_type_id")
                  }
                  mandatory={true}
                  valuesDict={prescripteurTypes}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Nom du prescripteur"}
                  name={prefix + "nom"}
                  value={getIn(values, prefix + "nom")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "nom") &&
                    Boolean(getIn(errors, prefix + "nom"))
                  }
                  helperText={
                    getIn(touched, prefix + "nom") &&
                    getIn(errors, prefix + "nom")
                  }

                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Commune"}
                  name={prefix + "commune_id"}
                  value={getIn(values, prefix + "commune_id")}
                  onChange={(e) => onComuneProvinceChange(e, "commune")}
                  error={
                    getIn(touched, prefix + "commune_id") &&
                    Boolean(getIn(errors, prefix + "commune_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "commune_id") &&
                    getIn(errors, prefix + "commune_id")
                  }

                  valuesDict={communes}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Province"}
                  name={prefix + "province_id"}
                  value={getIn(values, prefix + "province_id")}
                  onChange={(e) => onComuneProvinceChange(e, "province")}
                  error={
                    getIn(touched, prefix + "province_id") &&
                    Boolean(getIn(errors, prefix + "province_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "province_id") &&
                    getIn(errors, prefix + "province_id")
                  }

                  valuesDict={provinces}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Région"}
                  name={prefix + "region_id"}
                  value={getIn(values, prefix + "region_id")}
                  error={
                    getIn(touched, prefix + "region_id") &&
                    Boolean(getIn(errors, prefix + "region_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "region_id") &&
                    getIn(errors, prefix + "region_id")
                  }
                  onChange={onRegionChange}
                  valuesDict={regions}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Provenance"}
                  name={prefix + "provenance_id"}
                  value={getIn(values, prefix + "provenance_id")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "provenance_id") &&
                    Boolean(getIn(errors, prefix + "provenance_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "provenance_id") &&
                    getIn(errors, prefix + "provenance_id")
                  }
                  valuesDict={provenances}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Service"}
                  name={prefix + "service_id"}
                  value={getIn(values, prefix + "service_id")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "service_id") &&
                    Boolean(getIn(errors, prefix + "service_id"))
                  }
                  helperText={
                    getIn(touched, prefix + "service_id") &&
                    getIn(errors, prefix + "service_id")
                  }
                  valuesDict={services}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
