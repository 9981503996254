import React from "react";
import { Stack } from "@mui/material";
import { useFormikContext, FieldArray } from "formik";
import CustomButton from "../../../Buttons/CustomButton";
import ToxiqueDetailsPesticide from "../ToxiqueDetails/ToxiqueDetailsPesticide";
import ToxiqueDetailsEngrais from "../ToxiqueDetails/ToxiqueDetailsEngrais";
import ToxiqueDetailsMedicamentVeterinaire from "../ToxiqueDetails/ToxiqueDetailsMedicamentVeterinaire";
import ToxiqueDetailsMedicament from "../ToxiqueDetails/ToxiqueDetailsMedicament";
import ToxiqueDetailsAliment from "../ToxiqueDetails/ToxiqueDetailsAlimentaire";
import ToxiqueDetailsDrogue from "../ToxiqueDetails/ToxiqueDetailsDrogue";
import ToxiqueDetailsGaz from "../ToxiqueDetails/ToxiqueDetailsGaz";
import ToxiqueDetailsProduitIndustriel from "../ToxiqueDetails/ToxiqueDetailsProduitIndustriel";
import ToxiqueDetailsAutreAnimalVenimeu from "../ToxiqueDetails/ToxiqueDetailsAutreAnimalVenimeu";
import ToxiqueDetailsAutre from "../ToxiqueDetails/ToxiqueDetailsAutre";
import ToxiqueDetailsProduitCosmetique from "../ToxiqueDetails/ToxiqueDetailsProduitCosmetique";
import ToxiqueDetailsInconnu from "../ToxiqueDetails/ToxiqueDetailsInconnu";
import ToxiqueDetailsScorpion from "../ToxiqueDetails/ToxiqueDetailsScorpion";
import ToxiqueDetailsPlante from "../ToxiqueDetails/ToxiqueDetailsPlante";
import ToxiqueDetailsProduitMenager from "../ToxiqueDetails/ToxiqueDetailsProduitMenager";
import ToxiqueDetailsSerpent from "../ToxiqueDetails/ToxiqueDetailsSerpent";

export default function ToxiqueDetailsGroup(props) {
  const { toxiqueGroup } = props;
  const { values } = useFormikContext();
  const getCorrectToxiqueComponent = (toxiqueType, toxiqueTitle, index) => {
    switch (toxiqueType) {
      case "medicaments":
        return (
          <ToxiqueDetailsMedicament
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      case "pesticide":
        return (
          <ToxiqueDetailsPesticide
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      case "engrais":
        return (
          <ToxiqueDetailsEngrais key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "medicaments_veterinaires":
        return (
          <ToxiqueDetailsMedicamentVeterinaire
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      case "drogue":
        return (
          <ToxiqueDetailsDrogue key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "gaz":
        return (
          <ToxiqueDetailsGaz key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "aliments":
        return (
          <ToxiqueDetailsAliment key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "produit_industriel":
        return (
          <ToxiqueDetailsProduitIndustriel
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      case "autre_animal_venimeux":
        return (
          <ToxiqueDetailsAutreAnimalVenimeu
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      case "autre":
        return (
          <ToxiqueDetailsAutre key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "inconnu":
        return (
          <ToxiqueDetailsInconnu key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "cosmetique":
        return (
          <ToxiqueDetailsProduitCosmetique
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      case "scorpion":
        return (
          <ToxiqueDetailsScorpion key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "plante":
        return (
          <ToxiqueDetailsPlante key={index} toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle} index={index} />
        );
      case "produit_menager":
        return (
          <ToxiqueDetailsProduitMenager
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      case "serpent":
        return (
          <ToxiqueDetailsSerpent
            key={index}
            toxiqueType={toxiqueType}
            toxiqueTitle={toxiqueTitle}
            index={index}
          />
        );
      default:
        return <div>Non disponible</div>;
    }
  };

  const handleAddToxique = (arrayHelpers, index) => {
    let toxiqueIndex = values?.toxiques[toxiqueGroup.type]?.length + 1;
    let newTitle = toxiqueGroup?.prefix + " " + toxiqueIndex;
    let newToxique = {
      type: toxiqueGroup.type,
      index: toxiqueIndex ?? index,
      title: newTitle ?? "",
      connu_inconnu: 0,

      voie_intoxication_id: "",
    };
    switch (toxiqueGroup.type) {
      case "medicaments":
        newToxique.produit_medicament_id = "";
        newToxique.dci = "";
        newToxique.forme_id = "";
        newToxique.code_atc = "";
        newToxique.posologie = "";
        newToxique.quantite = "";
        break;
      case "aliments":
        newToxique.nom_commercial = "";
        newToxique.produit_aliment_id = "";
        newToxique.groupe_aliment = "";
        newToxique.categorie_aliment = "";
        newToxique.classe_aliment = "";
        newToxique.code_aliment = "";
        newToxique.prelevement = "";
        newToxique.resultat_prelevement = "";
        break;
      case "plante":
        newToxique.nom_vernaculaire_id = "";
        newToxique.nom_francais = "";
        newToxique.nom_scientifique = "";
        newToxique.code = "";
        newToxique.partie_utilisee_id = "";
        newToxique.presentation_id = "";
        newToxique.dose_administree = "";
        newToxique.mode_preparation_id = "";
        newToxique.conseillee_media = "";
        break;
      case "produit_menager":
        newToxique.nom = "";
        newToxique.classe_id = "";
        newToxique.code = "";
        newToxique.presentation_id = "";
        newToxique.dose_administree = "";
        break;
      case "autre":
        newToxique.nom = "";
        newToxique.classe_id = "";
        newToxique.composition = "";
        newToxique.presentation = "";
        newToxique.dose_administree = "";
        break;
      case "autre_animal_venimeux":
        newToxique.nom = "";
        newToxique.espece_id = "";
        newToxique.groupe = "";
        newToxique.code = "";
        break;
      case "gaz":
        newToxique.nom = "";
        newToxique.produit_gaz_id = "";
        newToxique.classe_gaz = "";
        newToxique.code = "";
        newToxique.source_id = "";
        newToxique.autre_source = "";
        break;
      case "drogue":
        newToxique.nom = "";
        newToxique.famille_drogue_id = "";
        newToxique.code = "";
        newToxique.dose_administree = "";
        newToxique.dose_toxique = "";
        break;
      case "produit_industriel":
        newToxique.nom_commercial = "";
        newToxique.produit_industriel_id = "";
        newToxique.sous_classe = "";
        newToxique.classe = "";
        newToxique.code = "";
        newToxique.dose_administree = "";
        newToxique.presentation_id = "";
        break;
      case "inconnu":
        newToxique.nom = "";
        newToxique.classe_id = "";
        newToxique.composition = "";
        newToxique.presentation = "";
        newToxique.dose_administree = "";
        break;
      case "scorpion":
        newToxique.couleur_id = "";
        newToxique.site_piqure = "";
        newToxique.classe = "";
        break;
      case "serpent":
        newToxique.espece_serpent_id = "";
        newToxique.code = "";
        newToxique.voie_intoxication_id = 3;
        newToxique.malade_refere = "";
        newToxique.siege_morsure = "";
        newToxique.date_morsure = "";
        newToxique.heure_morsure = "";
        newToxique.date_admission = "";
        newToxique.heure_admission = "";
        newToxique.grade_syndrome_viperin = "";
        newToxique.grade_syndrome_cobraique = "";
        newToxique.premiers_gestes = "";
        newToxique.traitement_symptomatique = "";
        newToxique.type_serum_administre = "";
        newToxique.delai_administration = "";
        newToxique.nombre_ampoules_administrees = "";
        newToxique.effets_indesirables = "";
        newToxique.duree_hospitalisation = "";
        newToxique.evolution_gradation = "";
        break;
      case "cosmetique":
        newToxique.nom_commercial = "";
        newToxique.produit_cosmetique_id = "";
        newToxique.sous_classe = "";
        newToxique.classe = "";
        newToxique.code = "";
        newToxique.marque = "";
        newToxique.lieu_achat = "";
        newToxique.dose_administree = "";
        newToxique.presentation_id = "";
        break;
      case "pesticide":
        newToxique.produit_pesticide_id = "";
        newToxique.matiere_active = "";
        newToxique.classe_chimique = "";
        newToxique.classe_dangerosite = "";
        newToxique.forme_id = "";
        newToxique.dosage = "";
        newToxique.usage_official = "";
        newToxique.usage_notificateur_id = "";
        newToxique.usage_notificateur_autre = "";
        newToxique.quantite = "";
        break;
      case "engrais":
        newToxique.produit_engrais_id = "";
        newToxique.matiere_active = "";
        newToxique.classe_chimique = "";
        newToxique.classe_engrais = "";
        newToxique.forme_id = "";
        newToxique.dosage = "";
        newToxique.usage_official = "";
        newToxique.usage_notificateur_id = "";
        newToxique.usage_notificateur_autre = "";
        break;
      case "medicaments_veterinaires":
        newToxique.produit_medicament_id = "";
        newToxique.dci = "";
        newToxique.forme_id = "";
        newToxique.code_atc = "";
        newToxique.posologie = "";
        newToxique.quantite = "";
        break;
      default:
        break;
    }
    arrayHelpers.push(newToxique);
  };
  return (
    values && (
      <div className="toxique-details-container-container">
        <FieldArray
          name={`toxiques.${toxiqueGroup.type}`}
          render={(arrayHelpers, index) => {
            const toxiques = values.toxiques[toxiqueGroup.type];
            return (
              <Stack direction="column" spacing={2} key={index}>
                <div>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ height: "100%", alignItems: "center" }}
                  >
                    <div className="toxique-details-container-header">
                      <label>{toxiqueGroup.title}</label>
                    </div>
                    <div className="toxique-details-container-button">
                      <CustomButton
                        title={"Ajouter"}
                        type={"add"}
                        customBackground={true}
                        onClick={() => handleAddToxique(arrayHelpers, index)}
                      />
                    </div>
                  </Stack>
                </div>
                <div>
                  <div>
                    {toxiques?.map((toxique, idx) =>
                      getCorrectToxiqueComponent(toxique.type, toxiqueGroup.prefix + " " + (idx + 1), idx)
                    )}
                  </div>
                </div>
              </Stack>
            );
          }}
        />
      </div>
    )
  );
}
