import React from "react";
import { Stack } from "@mui/material";
import SimplePageTitle from "../../components/PageTitle/SimplePageTitle";
import ValidateCases from "./ValidateCases";
import "./index.scss";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import { toValidateCasesPermissions } from "../../utils/permissions";
import { isAllowed } from "../../services/auth";
import { useState } from "react";

export default function ValidateCasesModule(props) {
  const { onEditMode } = props;

  const [activeTab, setActiveTab] = useState("");
  const tabs = ["toxicovigilance", "laboratoire"];

  return (
    <div className="validate-cases-module-container">
      <div className="add-cases-container">
        <Stack direction="column" spacing={0}>
          <div className="validate-cases-module-container-title">
            <SimplePageTitle type="validation" />
          </div>
          <div className="add-cases-title">
            <label>Sélectionner rubrique</label>
          </div>
          <div className="add-cases-buttons">
            <Stack direction="row" spacing={1}>
              {isAllowed([toValidateCasesPermissions[0]]) && (
                <RubriqueButton
                  type={"toxicovigilance"}
                  active={activeTab === "toxicovigilance"}
                  onClick={() => setActiveTab("toxicovigilance")}
                />)
              }
              {isAllowed([toValidateCasesPermissions[1]]) && (
                <RubriqueButton
                  type={"laboratoire"}
                  active={activeTab === "laboratoire"}
                  onClick={() => setActiveTab("laboratoire")}
                />)
              }

            </Stack>
          </div>
        </Stack>
      </div>
      <Stack direction="column" spacing={0}>
        <div className="validate-cases-module-container-body">
          <ValidateCases onEditMode={onEditMode} activeTab={activeTab} />
        </div>
      </Stack>
    </div>
  );
}
