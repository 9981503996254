import React from "react";
import './index.scss';


export default function CaseKPI(props) {
      const value = props.value;
      const fontSizeValue = value > 0.2 ? '10px' : '8px';
      const labelValue = (value * 100) + "%";
      const typoWidth = Math.round(value * 115) + 'px';

      return (
            <div className="case-kpi-container" >
                  <div className="second-container" style={{
                        width: typoWidth
                  }}>
                        <span className="span-style" style={{
                              fontSize: fontSizeValue
                        }}>
                              {labelValue}
                        </span>
                  </div>

            </div>
      )
}