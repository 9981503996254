import React, { useContext, useEffect } from "react";
import { Stack, Divider } from "@mui/material";
import ExtractToxiMenu from "../../components/ExtractDataMenus/ExtractToxiMenu";
import ExtractionChoixDates from "../../components/ExtractionChoixDates/ExtractionChoixDates";
import { ExtractDataContext } from "../../contextes/ExtractDataContext";
import "./index.scss";

export default function ExtractToxiData(props) {
  const {
    categories,
    setCategories,
    setStartDate,
    setEndDate,
    handleExtractClick,
    isLoading,
  } = useContext(ExtractDataContext);

  const handleToxiqueClick = (toxiqueType) => {
    let newToxiqueGroups = [...categories];
    const index = newToxiqueGroups.indexOf(toxiqueType);
    if (index > -1) {
      newToxiqueGroups.splice(index, 1);
    } else {
      newToxiqueGroups.push(toxiqueType);
    }
    setCategories(newToxiqueGroups);
  };

  const onStartDateChange = (name, newValue) => {
    setStartDate(newValue);
  };

  const onEndDateChange = (name, newValue) => {
    setEndDate(newValue);
  };

  return (
    <div className="extract-data-container-root">
      <div className="extract-data-container">
        <div className="extract-data-main">
          <Stack direction="row" spacing={0}>
            <div className="extract-data-menu">
              <ExtractToxiMenu onClick={handleToxiqueClick} />
            </div>
            <div className="extract-data-divider">
              <Divider orientation="vertical" flexItem />
            </div>
            <div className="extract-data-menu">
              <ExtractionChoixDates
                onClick={() => handleExtractClick("toxi")}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
                isLoading={isLoading}
              />
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
}
