import React, { useState, useContext } from "react";
import {
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelDatePicker from "../../../LabelDatePicker/LabelDatePicker";
import Imputabilite from "../Imputabilite/Imputabilite";
import LabelTextArea from "../../../LabelTextArea";

import "../../index.scss";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";

export default function Evolution(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();

  const validated = useContext(ValidatedContext);

  const referentielResults = useReferentiels(["gradation", "evolution"]);
  const isLoading = referentielResults.some((result) => result.isLoading);
  const [relance, setRelance] = useState(getIn(values, "intoxication.evolution.relance"));
  const [hospitalisation, setHospitalisation] = useState(getIn(values, "intoxication.evolution.hospitalisation"));
  const [sequelles, setSequelles] = useState(parseInt(getIn(values, "intoxication.evolution.evolution_id")) === 3 ? "1" : "0");
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const gradations = flattenIdNomDict(referentielResults[0].data);
  const evolutions = flattenIdNomDict(referentielResults[1].data);

  const widthPercentage = "31%";
  const middlePercentage = "50%";
  const handleRelanceChange = (event) => {
    setFieldValue("intoxication.evolution.relance", event.target.value);
    setRelance(event.target.value);
  };
  const handleHospitalisationChange = (event) => {
    setFieldValue("intoxication.evolution.hospitalisation", event.target.value);
    setHospitalisation(event.target.value);
  };
  const handleSequellesChange = (event) => {
    setFieldValue("intoxication.evolution.evolution_id", event.target.value);
    setSequelles(parseInt(event.target.value) === 3 ? "1" : "0");
  };

  let relanceInput = null;
  let sequellesInput = null;
  let dureeHospitalisation = null;
  if (relance === "1") {
    relanceInput = (
      <div style={{ width: widthPercentage }}>
        <LabelDatePicker
          title={"Date de relance"}
          name={"intoxication.evolution.date_relance"}
          value={getIn(values, "intoxication.evolution.date_relance")}
          onChange={setFieldValue}
          error={
            getIn(touched, "intoxication.evolution.date_relance") &&
            Boolean(getIn(errors, "intoxication.evolution.date_relance"))
          }
          helperText={
            getIn(touched, "intoxication.evolution.date_relance") &&
            getIn(errors, "intoxication.evolution.date_relance")
          }
        />
      </div>
    );
  }
  if (hospitalisation === "1") {
    dureeHospitalisation = (
      <div style={{ width: widthPercentage }}>
        <LabelInput
          title={"Durée hospitalisation (en jour)"}
          name={"intoxication.evolution.duree_hospitalisation"}
          value={getIn(values, "intoxication.evolution.duree_hospitalisation")}
          onChange={handleChange}
          error={
            getIn(touched, "intoxication.evolution.duree_hospitalisation") &&
            Boolean(
              getIn(errors, "intoxication.evolution.duree_hospitalisation")
            )
          }
          helperText={
            getIn(touched, "intoxication.evolution.duree_hospitalisation") &&
            getIn(errors, "intoxication.evolution.duree_hospitalisation")
          }
        />
      </div>
    );
  }
  if (sequelles === "1") {
    sequellesInput = (
      <div style={{ width: widthPercentage }}>
        <LabelInput
          title={"Détails sequelles"}
          name={"intoxication.evolution.sequelles_details"}
          value={getIn(values, "intoxication.evolution.sequelles_details")}
          onChange={handleChange}
          error={
            getIn(touched, "intoxication.evolution.sequelles_details") &&
            Boolean(getIn(errors, "intoxication.evolution.sequelles_details"))
          }
          helperText={
            getIn(touched, "intoxication.evolution.sequelles_details") &&
            getIn(errors, "intoxication.evolution.sequelles_details")
          }
        />
      </div>
    );
  }

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>L'évolution</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: middlePercentage, height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>
                        Relance
                      </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={"intoxication.evolution.relance"}
                        onChange={handleRelanceChange}
                        value={getIn(values, "intoxication.evolution.relance")}
                        error={
                          getIn(touched, "intoxication.evolution.relance") &&
                          Boolean(
                            getIn(errors, "intoxication.evolution.relance")
                          )
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Faite"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non faite"
                        />
                        <FormControlLabel
                          value="-1"
                          control={<Radio />}
                          label="Impossible"
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(touched, "intoxication.evolution.relance") &&
                      getIn(errors, "intoxication.evolution.relance")}
                  </FormHelperText>
                </FormControl>
              </div>
              {relanceInput}
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: middlePercentage, height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>
                        Hospitalisation
                      </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={"intoxication.evolution.hospitalisation"}
                        onChange={handleHospitalisationChange}
                        value={getIn(
                          values,
                          "intoxication.evolution.hospitalisation"
                        )}
                        error={
                          getIn(
                            touched,
                            "intoxication.evolution.hospitalisation"
                          ) &&
                          Boolean(
                            getIn(
                              errors,
                              "intoxication.evolution.hospitalisation"
                            )
                          )
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value="-1"
                          control={<Radio />}
                          label="Non précisée"
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(touched, "intoxication.evolution.hospitalisation") &&
                      getIn(errors, "intoxication.evolution.hospitalisation")}
                  </FormHelperText>
                </FormControl>
              </div>
              {dureeHospitalisation}
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "50%" }}>
                <LabelMenu
                  title={"Gradation finale"}
                  name={"intoxication.evolution.gradation_finale_id"}
                  value={getIn(
                    values,
                    "intoxication.evolution.gradation_finale_id"
                  )}
                  onChange={handleChange}
                  error={
                    getIn(
                      touched,
                      "intoxication.evolution.gradation_finale_id"
                    ) &&
                    Boolean(
                      getIn(
                        errors,
                        "intoxication.evolution.gradation_finale_id"
                      )
                    )
                  }
                  helperText={
                    getIn(
                      touched,
                      "intoxication.evolution.gradation_finale_id"
                    ) &&
                    getIn(errors, "intoxication.evolution.gradation_finale_id")
                  }

                  valuesDict={gradations}
                />
              </div>


            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "50%" }}>
                <LabelMenu
                  title={"Evolution"}
                  name={"intoxication.evolution.evolution_id"}
                  value={getIn(values, "intoxication.evolution.evolution_id")}
                  onChange={handleSequellesChange}
                  error={
                    getIn(touched, "intoxication.evolution.evolution_id") &&
                    Boolean(
                      getIn(errors, "intoxication.evolution.evolution_id")
                    )
                  }
                  helperText={
                    getIn(touched, "intoxication.evolution.evolution_id") &&
                    getIn(errors, "intoxication.evolution.evolution_id")
                  }

                  valuesDict={evolutions}
                />
              </div>
              {sequellesInput}
            </Stack>
          </div>
          <div>
            <Imputabilite />
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div
                style={{
                  marginRight: "12px",
                  width: "100%",
                  marginLeft: "-5px",
                }}
              >
                <LabelTextArea
                  title={"Narratif du cas"}
                  name={"intoxication.evolution.historique"}
                  value={getIn(values, "intoxication.evolution.historique")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "intoxication.evolution.historique") &&
                    Boolean(getIn(errors, "intoxication.evolution.historique"))
                  }
                  helperText={
                    getIn(touched, "intoxication.evolution.historique") &&
                    getIn(errors, "intoxication.evolution.historique")
                  }
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}