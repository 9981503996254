import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import { getEspeceAnimaleDetails } from "../../../../services/cases/cases";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";

export default function AutreAnimalVenimeux(props) {
    const { autreAnimal, preview } = props;
    const [autreAnimalDetails, setAutreAnimalDetails] = useState(null);

    const referentielResults = useReferentiels([
        "espece_animaux",
        "voie_intoxication",
    ]);

    useEffect(() => {
        getEspeceAnimaleDetails(autreAnimal.espece_id).then((data) => {
            setAutreAnimalDetails({
                "groupe": data.sous_classe,
                "code": data.code
            });
        });
    }, [props]);

    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading) return <CustomLoader isLoading={isLoading} />;

    const especes = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);

    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Autre animal venimeux</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(autreAnimal.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom"
                                        value={autreAnimal.nom}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Espèce" value={especes[autreAnimal.espece_id]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Groupe"
                                        value={autreAnimalDetails.groupe}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Code"
                                        value={
                                            autreAnimalDetails.code
                                        }
                                    />
                                </div>

                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[autreAnimal.voie_intoxication_id]
                                        }
                                    />
                                </div>

                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Autre animal venimeux Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
