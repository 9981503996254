import { getSecureAxiosInstance } from "../../utils/axiosInstances";


const getDashboardData = async (category, dateType, startDate, endDate) => {
    if (category.toLowerCase() === "laboratoire") {
        return getSecureAxiosInstance().get(`labo-case-management/dashboard?min_date=${startDate}&max_date=${endDate}&type_date=${dateType}`)
    }
    else if (category.toLowerCase() === "toxicovigilance") {
        return getSecureAxiosInstance().get(`toxico-case-management/dashboard?min_date=${startDate}&max_date=${endDate}&type_date=${dateType}`)
    }
}


export {
    getDashboardData,
}