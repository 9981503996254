import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import AddToxiCase from "./AddToxiCase/AddToxiCase";
import AddLaboCase from "./AddLaboCase/AddLaboCase";
import AddScorpionCase from "./AddScorpionCase/AddScorpionCase";
import "./index.scss";
import SimplePageTitle from "../../components/PageTitle/SimplePageTitle";
import { useLocation } from "react-router-dom";
import { useRolesAndPermissions } from "../../services/roles/roles";
import { isAllowed } from "../../services/auth";
import { addCasesPermissions } from "../../utils/permissions";

export default function AddCases(props) {
  const { onEditMode, editMode } = props;
  const location = useLocation();
  const category = location.state ? location.state.category : "";

  const [componentIndex, setComponentIndex] = useState(
    editMode ? (category.toLowerCase() === "toxicovigilance" ? 0 : 1) : null // if in edit mode check category if not then default to "toxicovigilance"
  );
  let component = <div></div>;
  let subtitle = "Ajouter un cas -";

  switch (componentIndex) {
    case 0:
      subtitle = subtitle + " Toxicovigilance";
      component = <AddToxiCase onEditMode={onEditMode} editMode={editMode} />;
      break;
    case 1:
      subtitle = subtitle + " Laboratoire";
      component = <AddLaboCase onEditMode={onEditMode} editMode={editMode} />;
      break;
    case 2:
      subtitle = subtitle + " Scorpion";
      component = <AddScorpionCase />;
      break;
    default:
      break;
  }

  const activateComponent = (newComponentIndex) => {
    setComponentIndex(newComponentIndex);
  };

  return (
    <div className="add-cases-container">
      <Stack direction="column">
        {editMode && (
          <div className="brouillon-cases-module-container-title">
            <SimplePageTitle type="modifier" />
          </div>
        )}
        {!editMode && (
          <>
            <div className="add-cases-title">
              <label>Sélectionner rubrique</label>
            </div>
            <div className="add-cases-buttons">
              <Stack direction="row" spacing={1}>
                {isAllowed([addCasesPermissions[0]]) && (
                  < RubriqueButton
                    type="toxicovigilance"
                    active={componentIndex === 0}
                    onClick={() => activateComponent(0)}
                  />)
                }
                {isAllowed([addCasesPermissions[1]]) && (
                  <RubriqueButton
                    type="laboratoire"
                    active={componentIndex === 1}
                    onClick={() => activateComponent(1)}
                  />)
                }
                {/* <RubriqueButton
                  type="scorpion"
                  active={componentIndex == 2}
                  onClick={() => activateComponent(2)}
                /> */}
              </Stack>
            </div>
            <div className="add-cases-title">{subtitle}</div>
          </>
        )}

        <div className="add-cases-body">{component}</div>
      </Stack>
    </div>
  );
}
