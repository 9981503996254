import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { imputabilityDict, idExpositionDict, idSymptomatologieDict, idChronologieDict, idCaracterisationCausaleDict, idDiagnosticDict, idLienExtrinsequeDict } from "../../../../utils/imputability";
import { CaseContext } from "../../../Modal/PreviewCaseModal";
import { relanceDict } from "../../../../utils/case";

export default function Evolution(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  const evolution = selectedCase
    ? selectedCase.intoxication
    : values.intoxication?.evolution;

  const referentielResults = useReferentiels(["gradation", "evolution"]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const gradations = flattenIdNomDict(referentielResults[0].data);
  const evolutions = flattenIdNomDict(referentielResults[1].data);

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>L'évolution</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Relance" value={evolution && relanceDict[evolution?.relance]} />
              </div>
              {parseInt(evolution?.relance) === 1 && (
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Date relance"
                    value={evolution && evolution?.date_relance}
                  />
                </div>
              )}
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Hospitalisation"
                  value={evolution && parseInt(evolution?.hospitalisation) === 1 ? "Oui" : "Non"}
                />
              </div>
              {evolution && parseInt(evolution?.hospitalisation) === 1 && (
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Durée hospitalisation"
                    value={evolution && evolution?.duree_hospitalisation}
                  />
                </div>
              )}
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>

              <div style={{ width: "64%" }}>
                <LabelInfo
                  title="Gradation finale"
                  value={evolution && gradations[evolution?.gradation_finale_id]}
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Evolution"
                  value={evolution && evolutions[evolution?.evolution_id]}
                />
              </div>
              {
                evolution && parseInt(evolution.evolution_id) === 3 && (
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Details sequelles"
                      value={evolution && evolution?.sequelles_details}
                    />
                  </div>
                )
              }
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Exposition"
                  value={
                    evolution &&
                      !selectedCase
                      ? idExpositionDict[evolution?.imputabilite.exposition]
                      : idExpositionDict[evolution?.imputabilite.exposition_id]
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Symptomatologie"
                  value={
                    evolution &&
                      !selectedCase
                      ? idSymptomatologieDict[evolution?.imputabilite.symptomatologie]
                      : idSymptomatologieDict[evolution?.imputabilite.symptomatologie_id]
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Chronologie"
                  value={
                    evolution &&
                      !selectedCase
                      ? idChronologieDict[evolution?.imputabilite.chronologie]
                      : idChronologieDict[evolution?.imputabilite.chronologie_id]
                  }
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Caractérisation causale"
                  value={
                    evolution &&
                      !selectedCase
                      ? idCaracterisationCausaleDict[evolution?.imputabilite.caracterisation_causale]
                      : idCaracterisationCausaleDict[evolution?.imputabilite.caracterisation_causale_id]
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Diagnostic différentiel"
                  value={
                    evolution &&
                      !selectedCase
                      ? idDiagnosticDict[evolution?.imputabilite.diagnostique_differentiel]
                      : idDiagnosticDict[evolution?.imputabilite.diagnostic_differentiel_id]
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Lien extrinsèque"
                  value={
                    evolution &&
                      !selectedCase
                      ? idLienExtrinsequeDict[evolution?.imputabilite.lien_extrinseque]
                      : idLienExtrinsequeDict[evolution?.imputabilite.lien_extrinseque_id]
                  }
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Imputabilité"
                  value={
                    evolution &&
                    imputabilityDict[
                    !selectedCase
                      ? evolution?.imputabilite.imputabilite
                      : evolution?.imputabilite.imputabilite_resultat_id
                    ]
                  }
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "100%" }}>
                <LabelInfo title="Historique" value={evolution?.historique} />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
