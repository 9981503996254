import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";
import { typeIntoxication } from "../../../../utils/case";
import { erreurCircuitDict, causesErreurDict, erreurTypeDict } from "../../../../utils/erreurMedicamenteuse";

export default function Intoxication(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);
  const intoxication = selectedCase
    ? selectedCase.intoxication
    : values.intoxication?.intoxication;

  const referentielResults = useReferentiels([
    "lieu_intoxication",
    "circonstance_intoxication"
  ]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;
  const lieuxIntoxications = flattenIdNomDict(referentielResults[0].data);
  const circonstancesIntoxication = flattenIdNomDict(referentielResults[1].data);
  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>L'Intoxication</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Date d'intoxication"
                  value={intoxication?.date_intoxication}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Heure d'intoxication"
                  value={intoxication?.heure}
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "60%" }}>
                <Stack direction="row" spacing={1}>
                  <LabelInfo
                    title="Délai d'intoxication (jour)"
                    value={intoxication?.delai_intoxication_j}
                  />
                  <LabelInfo
                    title="(heure)"
                    value={intoxication?.delai_intoxication_h}
                  />
                  <LabelInfo
                    title="(minute)"
                    value={intoxication?.delai_intoxication_m}
                  />
                </Stack>
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Type d'intoxication"
                  value={typeIntoxication[intoxication?.type_intoxication]}
                />
              </div>
              {parseInt(intoxication?.type_intoxication) === 1 && (
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Nombre de cas"
                    value={intoxication?.nombre_cas}
                  />
                </div>)
              }
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Erreur médicamenteuse"
                  value={
                    parseInt(intoxication?.erreur_medicamenteuse) === 1 ? "Oui" : "Non"
                  }
                />
              </div>

            </Stack>
          </div>
          {parseInt(intoxication?.erreur_medicamenteuse) === 1 && (
            <div>
              <Stack direction="row" spacing={3}>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Erreur de circuit"
                    value={erreurCircuitDict[intoxication?.erreur_medicamenteuse_erreur_circuit]}
                  />
                </div>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Type d'erreur"
                    value={erreurTypeDict[intoxication?.erreur_medicamenteuse_type_erreur]}
                  />
                </div>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Causes d'erreur"
                    value={causesErreurDict[intoxication?.erreur_medicamenteuse_cause_erreur]}
                  />
                </div>
              </Stack>
            </div>
          )}
          {parseInt(intoxication?.erreur_medicamenteuse) === 1 && (
            <div>
              <Stack direction="row" spacing={3}>
                <div style={{ width: "80%" }}>
                  <LabelInfo
                    title="Autre information"
                    value={intoxication?.erreur_medicamenteuse_autre_information}
                  />
                </div>
              </Stack>
            </div>
          )}
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Lieu d'intoxication"
                  value={lieuxIntoxications[intoxication?.lieu_intoxication_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Si autre, précisez"
                  value={intoxication?.autre_lieu_intoxication}
                />
              </div>


            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Circonstance"
                  value={circonstancesIntoxication[intoxication?.circonstance_intoxication_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Si autre, précisez"
                  value={intoxication?.autre_circonstance_intoxication}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
