import React, { useState, useEffect, Fragment } from "react";
import { Stack } from "@mui/material";
import BrouillonDataGrid from "../../components/Grids/BrouillonDataGrid/BrouillonDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { debounce } from "lodash";
import {
  getCaseDrafts,
  getLaboDraftCases,
  getToxiDraftCases,
} from "../../services/cases/cases";
import "./index.scss";
import { useSnackbar } from "notistack";
import PreviewCaseModal from "../../components/Modal/PreviewCaseModal";
import { toxicoColumnNames, toxicoFieldNames, laboColumnNames, laboFieldNames } from "../../utils/dataGrid";
import { useHistory } from "react-router-dom";

export default function BrouillonCases(props) {
  const { activeTab, onEditMode } = props;
  const [cases, setCases] = useState([]);
  const [totalCases, setTotalCases] = useState(0);
  const [limit, setLimit] = useState(8);
  const [sort, setSort] = useState({
    sort_order: 0,
    sort_column: "date_saisie",
  });
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [previewCase, setPreviewCase] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { enqueueSnackbar: showMessage } = useSnackbar();

  const history = useHistory();



  useEffect(() => {
    setLoading(true);
    if (activeTab.toLowerCase() === "toxicovigilance") {
      getToxiDraftCases(limit, skip, query, sort)
        .then((data) => {
          setCases(data.declarations);
          setTotalCases(data.nombre);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.toString(), { variant: "error" });
        });
    } else if (activeTab.toLowerCase() === "laboratoire") {
      getLaboDraftCases(limit, skip, query, sort)
        .then((data) => {

          setCases(data.declarations);
          setTotalCases(data.nombre);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.toString(), { variant: "error" });
        });
    }
  }, [activeTab, skip, limit, query, sort]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setSearchText(e.target.value);
  };
  const editCase = (id) => {
    onEditMode(true);
    history.push({
      pathname: "/main/add-cases",
      state: { selectedCase: id.toString(), category: activeTab },
    });
  };

  return (
    <Fragment>
      {activeTab !== "" && (
        <div className="brouillon-cases-container">
          <Stack direction="column" spacing={0} style={{ width: "100%" }}>
            <div className="brouillon-cases-research">
              <Stack direction="row-reverse" spacing={0} style={{ width: "100%" }}>
                <OutlinedInput
                  placeholder="Rechercher un cas…"
                  onChange={debounce(handleSearch, 1000)}
                  endAdornment={<SearchIcon htmlColor="#aaaaaa" />}
                />
              </Stack>
            </div>
            <div className="brouillon-cases-datagrid">
              <BrouillonDataGrid
                columnNames={
                  activeTab === "toxicovigilance"
                    ? toxicoColumnNames
                    : laboColumnNames
                }
                fieldNames={
                  activeTab === "toxicovigilance" ? toxicoFieldNames : laboFieldNames
                }
                onSetSkip={setSkip}
                activTab={activeTab}
                cases={cases}
                totalCases={totalCases}
                filter={searchText}
                loading={loading}
                onSetSort={setSort}
                onSetCases={setCases}
                preview={previewCase}
                onPreview={setPreviewCase}
                onShowModal={setShowModal}
                editCase={editCase}
              />
            </div>
            <PreviewCaseModal
              category={activeTab}
              showModal={showModal}
              onShowModal={setShowModal}
              previewCase={previewCase}
              editCase={() => editCase(previewCase.toString())}
            />
          </Stack>
        </div>)}
    </Fragment>
  );
}
