import React from "react";
import { Stack } from "@mui/material";
import LabelDatePicker from "../LabelDatePicker/LabelDatePicker";
import "./index.scss";
import ExcelButton from "../Buttons/ExcelButton";
import Loader from "react-spinners/ClipLoader";

export default function ExtractionChoixDates(props) {
  const { onClick, onStartDateChange, onEndDateChange, isLoading } = props;
  return (
    <div>
      <div style={{ marginLeft: "32px" }}>
        <Stack spacing={5}>
          <label className="extraction-choix-dates-label">
            Choisir les dates
          </label>

          <div className="extraction-choix-dates-date">
            <LabelDatePicker
              name="startDate"
              title={"Date de début"}
              onChange={onStartDateChange}
            />
          </div>
          <div className="extraction-choix-dates-date">
            <LabelDatePicker
              name="endDate"
              title={"Date de fin"}
              onChange={onEndDateChange}
            />
          </div>
          <div
            style={{ marginLeft: "auto", marginRight: "auto", display: "flex" }}
          >
            {isLoading && <Loader />}
            <ExcelButton onClick={onClick} isLoading={isLoading} />
          </div>
        </Stack>
      </div>
    </div>
  );
}
