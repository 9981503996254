import React from "react"
import { Stack } from "@mui/material";
import Patient from "../../../components/ViewCase/Toxi/Patient/Patient";

import '../index.scss';

export default function Declarant(props) {
      return (
            <div className='view-case-common-container'>
                  <div className='view-case-common-component'>
                        <Stack direction="column" spacing={0}>
                              <div className='view-case-common-sub-header'>
                                    <div className='view-case-common-sub-header-title'>
                                          information sur le patient
                                    </div>
                              </div>
                              <div className='view-case-common-body'>
                                    <Stack direction="column" spacing={3}>
                                          <Patient />
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}