import React, { useContext } from "react";
import {
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import LabelInput from "../../../LabelInput/LabelInput";
import "./index.scss";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { useFormikContext, getIn } from "formik";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";
import { useLocation } from "react-router-dom";
import { autreDict, produitDict } from "../../../../utils/case";

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F0F0F0",
    color: theme.palette.common.white,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#25282B",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const checkBoxSX = {
  color: "#C4C4C4",
  borderRadius: "5px",
  "&.Mui-checked": {
    color: "#6BE158",
  },
};



const CheckBoxLabelMenu = (props) => {
  const { title, checked, onChange, name, touched, errors, value, valuesDict } = props;

  const validated = useContext(ValidatedContext);

  return (
    <div style={{ marginLeft: "-11px" }}>
      <Stack direction="row" spacing={0} style={{ width: "100%" }}>
        <div>
          <Checkbox
            disabled={validated}
            checked={checked}
            onChange={onChange}
            name={name + ".checked"}
            sx={checkBoxSX}
          />
        </div>
        <div style={{ width: "inherit" }}>
          <LabelMenu
            title={title}
            name={name + ".name"}
            value={value}
            onChange={onChange}
            error={
              getIn(touched, name + ".name") &&
              Boolean(getIn(errors, name + ".name"))
            }
            helperText={
              getIn(touched, name + ".name") && getIn(errors, name + ".name")
            }
            valuesDict={valuesDict}
          />
        </div>
      </Stack>
    </div>
  );
};

export default function EchantillonTable(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const prefix = "echantillon.echantillons.";
  const validated = useContext(ValidatedContext);

  const location = useLocation();
  const selectedCase = location.state ? location.state.selectedCase : "";

  return (
    <div className="echantillon-table-container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell>Nature</HeaderTableCell>
              <HeaderTableCell style={{ width: "17%", paddingLeft: "5px" }}>
                Confirmité
              </HeaderTableCell>
              <HeaderTableCell style={{ width: "27%" }}>
                Problème rencontré
              </HeaderTableCell>
              <HeaderTableCell style={{ width: "32%" }}>Pôle</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={validated}
                        checked={getIn(values, prefix + "sang.checked")}
                        onChange={handleChange}
                        name={prefix + "sang.checked"}
                        sx={checkBoxSX}
                      />
                    }
                    label="Sang"
                  />
                </FormGroup>
              </TableCell>
              <TableCell style={{ padding: 5 }}>
                <RadioGroup
                  row
                  name={prefix + "sang.conformite"}
                  onChange={(event) => {
                    setFieldValue(
                      prefix + "sang.conformite",
                      parseInt(event.target.value)
                    );
                  }}
                  value={getIn(values, prefix + "sang.conformite")}
                  error={
                    getIn(touched, prefix + "sang.conformite") &&
                    Boolean(getIn(errors, prefix + "sang.conformite"))
                  }
                >
                  <FormControlLabel
                    disabled={validated}
                    value={1}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Oui"
                  />
                  <FormControlLabel
                    disabled={validated}
                    value={0}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Non"
                  />
                </RadioGroup>
              </TableCell>
              <TableCell>
                <LabelInput
                  name={prefix + "sang.problem_rencontre"}
                  onChange={handleChange}
                  value={getIn(values, prefix + "sang.problem_rencontre")}
                  error={
                    getIn(touched, prefix + "sang.problem_rencontre") &&
                    Boolean(getIn(errors, prefix + "sang.problem_rencontre"))
                  }
                  helperText={
                    getIn(touched, prefix + "sang.problem_rencontre") &&
                    getIn(errors, prefix + "sang.problem_rencontre")
                  }
                />
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "sang.lc")}
                      onChange={handleChange}
                      name={prefix + "sang.lc"}
                    />
                  }
                  label="LC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "sang.gc")}
                      onChange={handleChange}
                      name={prefix + "sang.gc"}
                    />
                  }
                  label="GC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "sang.ptrr")}
                      onChange={handleChange}
                      name={prefix + "sang.ptrr"}
                    />
                  }
                  label="PTRR"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "sang.saa")}
                      onChange={handleChange}
                      name={prefix + "sang.saa"}
                    />
                  }
                  label="SAA"
                />
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={validated}
                        checked={values &&
                          values.echantillon?.echantillons?.urines
                          ? values.echantillon?.echantillons?.urines?.checked
                          : false
                        }
                        onChange={handleChange}
                        name={prefix + "urines.checked"}
                        sx={checkBoxSX}
                      />
                    }
                    label="Urines"
                  />
                </FormGroup>
              </TableCell>
              <TableCell style={{ padding: 5 }}>
                <RadioGroup
                  row
                  name={prefix + "urines.conformite"}
                  onChange={(event) =>
                    setFieldValue(
                      prefix + "urines.conformite",
                      parseInt(event.target.value)
                    )
                  }
                  value={getIn(values, prefix + "urines.conformite")}
                  error={
                    getIn(touched, prefix + "urines.conformite") &&
                    Boolean(getIn(errors, prefix + "urines.conformite"))
                  }
                >
                  <FormControlLabel
                    disabled={validated}
                    value={1}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Oui"
                  />
                  <FormControlLabel
                    disabled={validated}
                    value={0}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Non"
                  />
                </RadioGroup>
              </TableCell>
              <TableCell>
                <LabelInput
                  name={prefix + "urines.problem_rencontre"}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "urines.problem_rencontre") &&
                    Boolean(getIn(errors, prefix + "urines.problem_rencontre"))
                  }
                  helperText={
                    getIn(touched, prefix + "urines.problem_rencontre") &&
                    getIn(errors, prefix + "urines.problem_rencontre")
                  }
                />
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "urines.lc")}
                      onChange={handleChange}
                      name={prefix + "urines.lc"}
                    />
                  }
                  label="LC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "urines.gc")}
                      onChange={handleChange}
                      name={prefix + "urines.gc"}
                    />
                  }
                  label="GC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "urines.ptrr")}
                      onChange={handleChange}
                      name={prefix + "urines.ptrr"}
                    />
                  }
                  label="PTRR"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "urines.saa")}
                      onChange={handleChange}
                      name={prefix + "urines.saa"}
                    />
                  }
                  label="SAA"
                />
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={validated}
                        checked={values &&
                          values.echantillon?.echantillons?.lg
                          ? values.echantillon?.echantillons?.lg?.checked
                          : false
                        }
                        onChange={handleChange}
                        name={prefix + "lg.checked"}
                        sx={checkBoxSX}
                      />
                    }
                    label="LG"
                  />
                </FormGroup>
              </TableCell>
              <TableCell style={{ padding: 5 }}>
                <RadioGroup
                  row
                  name={prefix + "lg.conformite"}
                  onChange={(event) =>
                    setFieldValue(
                      prefix + "lg.conformite",
                      parseInt(event.target.value)
                    )
                  }
                  value={getIn(values, prefix + "lg.conformite")}
                  error={
                    getIn(touched, prefix + "lg.conformite") &&
                    Boolean(getIn(errors, prefix + "lg.conformite"))
                  }
                >
                  <FormControlLabel
                    disabled={validated}
                    value={1}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Oui"
                  />
                  <FormControlLabel
                    disabled={validated}
                    value={0}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Non"
                  />
                </RadioGroup>
              </TableCell>
              <TableCell>
                <LabelInput
                  name={prefix + "lg.problem_rencontre"}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "lg.problem_rencontre") &&
                    Boolean(getIn(errors, prefix + "lg.problem_rencontre"))
                  }
                  helperText={
                    getIn(touched, prefix + "lg.problem_rencontre") &&
                    getIn(errors, prefix + "lg.problem_rencontre")
                  }
                />
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lg.lc")}
                      onChange={handleChange}
                      name={prefix + "lg.lc"}
                    />
                  }
                  label="LC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lg.gc")}
                      onChange={handleChange}
                      name={prefix + "lg.gc"}
                    />
                  }
                  label="GC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lg.ptrr")}
                      onChange={handleChange}
                      name={prefix + "lg.ptrr"}
                    />
                  }
                  label="PTRR"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={validated}
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lg.saa")}
                      onChange={handleChange}
                      name={prefix + "lg.saa"}
                    />
                  }
                  label="SAA"
                />
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={validated}
                        checked={values &&
                          values.echantillon?.echantillons?.lcr
                          ? values.echantillon?.echantillons?.lcr?.checked
                          : false
                        }
                        onChange={handleChange}
                        name={prefix + "lcr.checked"}
                        sx={checkBoxSX}
                      />
                    }
                    label="LCR"
                  />
                </FormGroup>
              </TableCell>
              <TableCell style={{ padding: 5 }}>
                <RadioGroup
                  row
                  name={prefix + "lcr.conformite"}
                  onChange={(event) =>
                    setFieldValue(
                      prefix + "lcr.conformite",
                      parseInt(event.target.value)
                    )
                  }
                  value={getIn(values, prefix + "lcr.conformite")}
                  error={
                    getIn(touched, prefix + "lcr.conformite") &&
                    Boolean(getIn(errors, prefix + "lcr.conformite"))
                  }
                >
                  <FormControlLabel
                    disabled={validated}
                    value={1}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Oui"
                  />
                  <FormControlLabel
                    disabled={validated}
                    value={0}
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Non"
                  />
                </RadioGroup>
              </TableCell>
              <TableCell>
                <LabelInput
                  name={prefix + "lcr.problem_rencontre"}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "lcr.problem_rencontre") &&
                    Boolean(getIn(errors, prefix + "lcr.problem_rencontre"))
                  }
                  helperText={
                    getIn(touched, prefix + "lcr.problem_rencontre") &&
                    getIn(errors, prefix + "lcr.problem_rencontre")
                  }
                />
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lcr.lc")}
                      onChange={handleChange}
                      name={prefix + "lcr.lc"}
                    />
                  }
                  label="LC"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lcr.gc")}
                      onChange={handleChange}
                      name={prefix + "lcr.gc"}
                    />
                  }
                  label="GC"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lcr.ptrr")}
                      onChange={handleChange}
                      name={prefix + "lcr.ptrr"}
                    />
                  }
                  label="PTRR"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "lcr.saa")}
                      onChange={handleChange}
                      name={prefix + "lcr.saa"}
                    />
                  }
                  label="SAA"
                />
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <CheckBoxLabelMenu
                  title={"Produit"}
                  checked={values &&
                    values.echantillon?.echantillons?.produit
                    ? values.echantillon?.echantillons?.produit?.checked
                    : false
                  }
                  value={values.echantillon?.echantillons?.produit?.name}
                  onChange={handleChange}
                  name={prefix + "produit"}
                  touched={touched}
                  errors={errors}
                  valuesDict={produitDict}
                />
              </TableCell>
              <TableCell style={{ padding: 5 }}>
                <RadioGroup
                  row
                  name={prefix + "produit.conformite"}
                  onChange={(event) =>
                    setFieldValue(
                      prefix + "produit.conformite",
                      parseInt(event.target.value)
                    )
                  }
                  value={getIn(values, prefix + "produit.conformite")}
                  error={
                    getIn(touched, prefix + "produit.conformite") &&
                    Boolean(getIn(errors, prefix + "produit.conformite"))
                  }
                >
                  <FormControlLabel
                    disabled={validated}
                    value="1"
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Oui"
                  />
                  <FormControlLabel
                    disabled={validated}
                    value="0"
                    control={<Radio size="small" sx={checkBoxSX} />}
                    label="Non"
                  />
                </RadioGroup>
              </TableCell>
              <TableCell>
                <LabelInput
                  name={prefix + "produit.problem_rencontre"}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "produit.problem_rencontre") &&
                    Boolean(getIn(errors, prefix + "produit.problem_rencontre"))
                  }
                  helperText={
                    getIn(touched, prefix + "produit.problem_rencontre") &&
                    getIn(errors, prefix + "produit.problem_rencontre")
                  }
                />
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "produit.lc")}
                      onChange={handleChange}
                      name={prefix + "produit.lc"}
                    />
                  }
                  label="LC"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "produit.gc")}
                      onChange={handleChange}
                      name={prefix + "produit.gc"}
                    />
                  }
                  label="GC"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "produit.ptrr")}
                      onChange={handleChange}
                      name={prefix + "produit.ptrr"}
                    />
                  }
                  label="PTRR"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "produit.saa")}
                      onChange={handleChange}
                      name={prefix + "produit.saa"}
                    />
                  }
                  label="SAA"
                />
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <CheckBoxLabelMenu
                  title={"Autres"}
                  checked={values &&
                    values.echantillon?.echantillons?.autres
                    ? values.echantillon?.echantillons?.autres?.checked
                    : false
                  }
                  value={values.echantillon?.echantillons?.autres?.name}
                  onChange={handleChange}
                  name={prefix + "autres"}
                  touched={touched}
                  errors={errors}
                  valuesDict={autreDict}
                />
              </TableCell>
              <TableCell style={{ padding: 5 }}>
                <RadioGroup
                  row
                  name={prefix + "autres.conformite"}
                  onChange={(event) =>
                    setFieldValue(
                      prefix + "autres.conformite",
                      parseInt(event.target.value)
                    )
                  }
                  value={getIn(values, prefix + "autres.conformite")}
                  error={
                    getIn(touched, prefix + "autres.conformite") &&
                    Boolean(getIn(errors, prefix + "autres.conformite"))
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        disabled={validated}
                        size="small"
                        sx={checkBoxSX}
                      />
                    }
                    label="Oui"
                  />
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        disabled={validated}
                        size="small"
                        sx={checkBoxSX}
                      />
                    }
                    label="Non"
                  />
                </RadioGroup>
              </TableCell>
              <TableCell>
                <LabelInput
                  name={prefix + "autres.problem_rencontre"}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "autres.problem_rencontre") &&
                    Boolean(getIn(errors, prefix + "autres.problem_rencontre"))
                  }
                  helperText={
                    getIn(touched, prefix + "autres.problem_rencontre") &&
                    getIn(errors, prefix + "autres.problem_rencontre")
                  }
                />
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "autres.lc")}
                      onChange={handleChange}
                      name={prefix + "autres.lc"}
                    />
                  }
                  label="LC"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "autres.gc")}
                      onChange={handleChange}
                      name={prefix + "autres.gc"}
                    />
                  }
                  label="GC"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "autres.ptrr")}
                      onChange={handleChange}
                      name={prefix + "autres.ptrr"}
                    />
                  }
                  label="PTRR"
                />
                <FormControlLabel
                  disabled={validated}
                  control={
                    <Checkbox
                      size="small"
                      sx={checkBoxSX}
                      checked={getIn(values, prefix + "autres.saa")}
                      onChange={handleChange}
                      name={prefix + "autres.saa"}
                    />
                  }
                  label="SAA"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
