import React, { useEffect } from "react";
import {
    Stack,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";

import "./index.scss";
import "../../index.scss";

export default function ToxiqueDetailsAutre(props) {
    const { toxiqueType, toxiqueTitle, index } = props;
    const { values, setFieldValue, touched, errors, handleChange } =
        useFormikContext();
    const widthPercentage = "45%";
    const referentielResults = useReferentiels([
        "classe_chimique",
        "voie_intoxication",
    ]);
    const namePrefix = "toxiques." + toxiqueType + "." + index + ".";
    useEffect(() => {
        setFieldValue(namePrefix + "index", index + 1);
        setFieldValue(namePrefix + "title", toxiqueTitle);
    }, []);


    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading) return <CustomLoader isLoading={isLoading} />;

    const classes = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);

    return (
        <div className="toxique-details-container">
            <Stack direction="column" spacing={1}>
                <div className="toxique-details-header">
                    <div style={{ width: "100%", height: "59px" }}>
                        <FormControl component="fieldset" style={{ height: "100%" }}>
                            <Stack
                                direction="row"
                                spacing={0}
                                style={{ height: "100%", alignItems: "center" }}
                            >
                                <div className="toxique-details-header-title">
                                    <label>
                                        {toxiqueTitle} <span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                                <div className="case-common-container-body-radio">
                                    <RadioGroup
                                        row
                                        name={namePrefix + "connu_inconnu"}
                                        onChange={(event) =>
                                            setFieldValue(
                                                namePrefix + "connu_inconnu",
                                                event.target.value
                                            )
                                        }
                                        value={getIn(values, namePrefix + "connu_inconnu")}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="Connu"
                                        />
                                        <FormControlLabel
                                            value="0"
                                            control={<Radio />}
                                            label="Inconnu"
                                        />
                                    </RadioGroup>
                                </div>
                            </Stack>
                        </FormControl>
                    </div>
                </div>
                {parseInt(getIn(values, namePrefix + "connu_inconnu")) === 1 && (
                    <div className="toxique-details-body">
                        <div className="toxique-details-elt">
                            <Stack direction="column" spacing={4}>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Nom"}
                                                name={namePrefix + "nom"}
                                                value={getIn(values, namePrefix + "nom")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "nom") &&
                                                    Boolean(getIn(errors, namePrefix + "nom"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "nom") &&
                                                    getIn(errors, namePrefix + "nom")
                                                }
                                                mandatory={true}
                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Classse"}
                                                name={namePrefix + "classe_id"}
                                                value={getIn(values, namePrefix + "classe_id")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "classe_id") &&
                                                    Boolean(getIn(errors, namePrefix + "classe_id"))
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "classe_id") &&
                                                    getIn(errors, namePrefix + "classe_id")
                                                }
                                                mandatory={true}
                                                valuesDict={classes}
                                            />
                                        </div>
                                    </Stack>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Composition"}
                                                name={namePrefix + "composition"}
                                                value={getIn(
                                                    values,
                                                    namePrefix + "composition"
                                                )}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "composition") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "composition")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "composition") &&
                                                    getIn(errors, namePrefix + "composition")
                                                }

                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Présentation"}
                                                name={namePrefix + "presentation"}
                                                value={getIn(values, namePrefix + "presentation")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "presentation") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "presentation")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "presentation") &&
                                                    getIn(errors, namePrefix + "presentation")
                                                }

                                            />
                                        </div>
                                    </Stack>
                                </div>

                                <div>
                                    <Stack direction="row" spacing={4}>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelInput
                                                title={"Doses administrées"}
                                                name={namePrefix + "dose_administree"}
                                                value={getIn(values, namePrefix + "dose_administree")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "dose_administree") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "dose_administree")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "dose_administree") &&
                                                    getIn(errors, namePrefix + "dose_administree")
                                                }

                                            />
                                        </div>
                                        <div style={{ width: widthPercentage }}>
                                            <LabelMenu
                                                title={"Voie d'intoxication"}
                                                name={namePrefix + "voie_intoxication_id"}
                                                value={getIn(values, namePrefix + "voie_intoxication_id")}
                                                onChange={handleChange}
                                                error={
                                                    getIn(errors, namePrefix + "voie_intoxication_id") &&
                                                    Boolean(
                                                        getIn(errors, namePrefix + "voie_intoxication_id")
                                                    )
                                                }
                                                helperText={
                                                    getIn(touched, namePrefix + "voie_intoxication_id") &&
                                                    getIn(errors, namePrefix + "voie_intoxication_id")
                                                }
                                                valuesDict={voix}
                                            />
                                        </div>

                                    </Stack>
                                </div>


                            </Stack>
                        </div>
                    </div>
                )}
            </Stack>
        </div>
    );
}
