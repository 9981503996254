import React from "react";
import { Stack } from "@mui/material";

import IntoxicationComponent from "../../../components/ViewCase/Toxi/Intoxication/Intoxication";
import Traitement from "../../../components/ViewCase/Toxi/Traitement/Traitement";
import Evolution from "../../../components/ViewCase/Common/Evolution/Evolution";

import "../index.scss";
import Symptomologie from "../../../components/ViewCase/Common/Symptomologie/Symptomologie";

export default function Intoxication(props) {
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-component">
        <Stack direction="column" spacing={0}>
          <div className="view-case-common-sub-header">
            <div className="view-case-common-sub-header-title">
              information sur l'intoxication
            </div>
          </div>
          <div className="view-case-common-body">
            <Stack direction="column" spacing={3}>
              <IntoxicationComponent />
              <Symptomologie />
              <Traitement type="avant" />
              <Traitement type="capm" />
              <Traitement type="effectue" />
              <Evolution />
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
