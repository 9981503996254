import React, { useState, useEffect, Fragment } from "react";
import { Stack } from "@mui/material";
import ValidateCaseDataGrid from "../../components/Grids/ValidateCaseDataGrid/ValidateCaseDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { debounce } from "lodash";
import { getToValidateToxiCases } from "../../services/cases/cases";
import { getToValidateLaboCases } from "../../services/cases/cases";
import "./index.scss";
import { useSnackbar } from "notistack";
import { toxicoColumnNames, toxicoFieldNames, laboColumnNames, laboFieldNames } from "../../utils/dataGrid";
import PreviewCaseModal from "../../components/Modal/PreviewCaseModal";
import { useHistory, useLocation } from "react-router-dom";

export default function ValidateCases(props) {
  const { activeTab, onEditMode } = props;

  const location = useLocation();
  const declarationId = location.state ? location.state.declarationId : "";

  const [cases, setCases] = useState([]);
  const [searchText, setSearchText] = useState(declarationId.toString() ?? "");
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [previewCase, setPreviewCase] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [limit, setLimit] = useState(8);
  const [sort, setSort] = useState({
    sort_order: 0,
    sort_column: "date_saisie",
  });
  const [skip, setSkip] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const { enqueueSnackbar: showMessage } = useSnackbar();



  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    if (activeTab === "toxicovigilance") {
      getToValidateToxiCases(limit, skip, query, sort)
        .then((data) => {
          setCases(data.declarations);
          setTotalCases(data.nombre);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.toString(), { variant: "error" });
        });
    } else if (activeTab === "laboratoire") {
      getToValidateLaboCases(limit, skip, query, sort)
        .then((data) => {
          setCases(data.declarations);
          setTotalCases(data.nombre);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.toString(), { variant: "error" });
        });
    }
  }, [activeTab, skip, limit, totalCases, query, sort]);

  useEffect(() => {
    setQuery(declarationId.toString());
    setSearchText(declarationId.toString());
  }, [declarationId]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setSearchText(e.target.value);
  };

  const editCase = (id) => {
    onEditMode(true);
    history.push({
      pathname: "/main/add-cases",
      state: { selectedCase: id.toString(), category: activeTab },
    });

  };

  return (
    <Fragment>
      {activeTab !== "" &&
        <div className="validate-cases-container">
          <Stack direction="column" spacing={0} style={{ width: "100%" }}>
            <div className="validate-cases-research">
              <Stack direction="row-reverse" spacing={0} style={{ width: "100%" }}>
                <OutlinedInput
                  placeholder="Rechercher un cas…"
                  onChange={debounce(handleSearch, 1000)}
                  endAdornment={<SearchIcon htmlColor="#aaaaaa" />}
                />
              </Stack>
            </div>
            <div className="validate-cases-datagrid">
              <ValidateCaseDataGrid
                columnNames={
                  activeTab.toLowerCase() === "toxicovigilance"
                    ? toxicoColumnNames
                    : laboColumnNames
                }
                fieldNames={
                  activeTab.toLowerCase() === "toxicovigilance" ? toxicoFieldNames : laboFieldNames
                }
                activeTab={activeTab}
                onSetSkip={setSkip}
                cases={cases}
                totalCases={totalCases}
                filter={searchText}
                loading={loading}
                onSetSort={setSort}
                onSetCases={setCases}
                preview={previewCase}
                onPreview={setPreviewCase}
                onShowModal={setShowModal}
              />
            </div>

            <PreviewCaseModal
              showModal={showModal}
              onShowModal={setShowModal}
              editCase={() => editCase(previewCase.toString())}
              previewCase={previewCase}
              category={activeTab}
            />
          </Stack>
        </div>}
    </Fragment>
  );
}
