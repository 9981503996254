import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getProduitIndustrielDetails } from "../../../../services/cases/cases";

export default function ProduitIndustriel(props) {
    const { produit, preview } = props;
    const [produitDetails, setProduitDetails] = useState(null);

    const referentielResults = useReferentiels([
        "produit_industriel",
        "voie_intoxication",
        "presentation_produit_industriel"
    ]);
    useEffect(() => {
        getProduitIndustrielDetails(produit.produit_industriel_id).then((data) => {
            setProduitDetails({
                "sous_classe": data.sous_classe,
                "calsse": data.calsse,
                "code": data.code
            });
        });
    }, [props]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading || produitDetails === null) return <CustomLoader isLoading={isLoading} />;

    const produits = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);
    const presentations = flattenIdNomDict(referentielResults[2].data);

    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Pr. Industriel</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(produit.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom"
                                        value={produit.nom_commercial}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Produit" value={produits[produit.produit_industriel_id]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Sous classe"
                                        value={produitDetails.sous_classse}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Classe"
                                        value={produitDetails.classe}
                                    />
                                </div>

                                <div style={{ width: "66%" }}>
                                    <LabelInfo
                                        title="Code"
                                        value={produitDetails.code}
                                    />
                                </div>
                                <div style={{ width: "66%" }}>
                                    <LabelInfo
                                        title="Doses administrées"
                                        value={produit.dose_administree}
                                    />
                                </div>

                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Présentation"
                                        value={presentations[produit.presentation_id]}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[produit.voie_intoxication_id]
                                        }
                                    />
                                </div>

                            </Stack>
                        </div>

                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Pr. Industriel Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
