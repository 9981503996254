import React, { useContext } from "react";
import {
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import GridButton from "../../../Buttons/GridButton";
import "./index.scss";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";
import { laboDosageUnits } from "../../../../utils/divers/divers";

const checkBoxSX = {
  color: "#C4C4C4",
  borderRadius: "5px",
  "&.Mui-checked": {
    color: "#6BE158",
  },
};

export default function ProduitMoleculeDosage(props) {
  const validated = useContext(ValidatedContext);

  const globalIndex = props.index;
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();

  const prefixWithoutPoint = props.prefix + globalIndex;
  const prefix = prefixWithoutPoint + ".";
  const componentValues = getIn(values, prefixWithoutPoint);
  const addMolecule = () => {
    let newValues = getIn(values, prefixWithoutPoint);
    newValues.molecules.push("");
    newValues.dosages.push("");
    setFieldValue(prefixWithoutPoint, newValues);
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        <FormGroup>
          <FormControlLabel
            disabled={validated}
            control={
              <Checkbox
                sx={checkBoxSX}
                checked={getIn(values, prefix + "checked")}
                onChange={handleChange}
                name={prefix + "checked"}
              />
            }
            label=""
          />
        </FormGroup>
      </TableCell>
      <TableCell>
        <label className="produit-molecule-dosage-row-label">
          {componentValues?.produit}
        </label>
      </TableCell>

      <TableCell>
        <Stack direction="column" spacing={1}>
          {componentValues?.molecules?.map((molecule, index) => (
            <div key={index}>
              <LabelInput
                name={prefix + "molecules." + index}
                onChange={handleChange}
                error={
                  getIn(errors, prefix + "molecules." + index) &&
                  Boolean(getIn(errors, prefix + "molecules." + index))
                }
                helperText={
                  getIn(touched, prefix + "molecules." + index) &&
                  getIn(errors, prefix + "molecules." + index)
                }
                placeholder={"Molécule"}
                value={getIn(values, prefix + "molecules." + index)}
              />
            </div>
          ))}
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="column" spacing={1}>
          {componentValues?.dosages?.map((dosage, index) => (
            <div key={index}>
              <Stack direction="row" spacing={1}>
                <div style={{ width: "20%" }}>
                  <LabelMenu
                    name={prefix + "dosages." + index + ".sign"}
                    onChange={handleChange}
                    error={
                      getIn(errors, prefix + "dosages." + index + ".sign") &&
                      Boolean(
                        getIn(errors, prefix + "dosages." + index + ".sign")
                      )
                    }
                    helperText={
                      getIn(touched, prefix + "dosages." + index + ".sign") &&
                      getIn(errors, prefix + "dosages." + index + ".sign")
                    }
                    value={getIn(values, prefix + "dosages." + index + ".sign")}
                    valuesDict={{ "=": "=", ">": ">", "<": "<" }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <LabelInput
                    name={prefix + "dosages." + index + ".value"}
                    onChange={handleChange}
                    error={
                      getIn(errors, prefix + "dosages." + index + ".value") &&
                      Boolean(
                        getIn(errors, prefix + "dosages." + index + ".value")
                      )
                    }
                    helperText={
                      getIn(touched, prefix + "dosages." + index + ".value") &&
                      getIn(errors, prefix + "dosages." + index + ".value")
                    }
                    value={getIn(
                      values,
                      prefix + "dosages." + index + ".value"
                    )}
                    type={"number"}
                    placeholder={"Dosage"}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <LabelMenu
                    name={prefix + "dosages." + index + ".unit"}
                    onChange={handleChange}
                    error={
                      getIn(errors, prefix + "dosages." + index + ".unit") &&
                      Boolean(
                        getIn(errors, prefix + "dosages." + index + ".unit")
                      )
                    }
                    helperText={
                      getIn(touched, prefix + "dosages." + index + ".unit") &&
                      getIn(errors, prefix + "dosages." + index + ".unit")
                    }
                    value={getIn(values, prefix + "dosages." + index + ".unit")}
                    valuesDict={laboDosageUnits}
                  />
                </div>
              </Stack>
            </div>
          ))}
        </Stack>
      </TableCell>
      <TableCell>
        <GridButton onClick={addMolecule} />
      </TableCell>
    </TableRow>
  );
}
