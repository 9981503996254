import React from "react";
import { Box } from "@mui/system";
import { Stack, IconButton, Button, Divider } from "@mui/material";
import { UserViewCloseIcon, NewUserAddIcon } from "../../../Icons/Icons";
import "./index.scss";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import { useState } from "react";

const style = {
  position: "absolute",
  width: 621,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function NewElementModal(props) {
  const { onClose, onNew, categories } = props;
  const [value, setValue] = useState("");

  const handleOnNew = () => {
    onNew(value);
  };
  return (
    <Box sx={{ ...style }}>
      <div>
        <Stack direction="column" spacing={0}>
          <div>
            <div className="case-new-element-modal-container">
              <Stack direction="column" spacing={0}>
                <div className="case-new-element-modal-header">
                  <Stack
                    direction="row"
                    spacing={0}
                    style={{ alignItems: "center" }}
                  >
                    <div>
                      <NewUserAddIcon />
                    </div>
                    <div className="case-new-element-modal-header-title">
                      <label>Ajouter un élément</label>
                    </div>
                    <div
                      className="case-new-element-modal-close"
                      style={{ marginLeft: "360px" }}
                    >
                      <IconButton style={{ padding: 0 }} onClick={onClose}>
                        <UserViewCloseIcon />
                      </IconButton>
                    </div>
                  </Stack>
                </div>
                <Divider />
                <div className="case-new-element-modal-body">
                  <LabelMenu
                    title={"Selectionner un élément"}
                    name={"new_element"}
                    onSetValue={setValue}
                    valuesDict={categories}
                  />
                </div>
                <div className="case-new-element-modal-footer">
                  <div className="case-new-element-modal-footer-buttons">
                    <Stack
                      direction="row-reverse"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        onClick={handleOnNew}
                        variant="contained"
                        style={{
                          background: "#367BF5",
                          height: "32px",
                          textTransform: "none",
                        }}
                      >
                        Ajouter
                      </Button>

                      <Button
                        onClick={onClose}
                        style={{
                          color: "#4F566B",
                          height: "32px",
                          textTransform: "none",
                        }}
                      >
                        Annuler
                      </Button>
                    </Stack>
                  </div>
                </div>
              </Stack>
            </div>
          </div>
        </Stack>
      </div>
    </Box>
  );
}
