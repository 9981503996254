import React from "react";
import { useMutation } from "react-query";
import { Stack, IconButton } from "@mui/material";

import { UserViewCloseIcon } from "../Icons/Icons";
import RoleName from "../RoleName/RoleName";
import { useFormik } from "formik";

import { editUser } from "../../services/users/users";

import { newUserValidationSchema } from "../../utils/validationSchemas/others/formValidationSchemas";
import { getAvatar } from "../../utils/divers/divers";
import "./index.scss";
import ViewUserForm from "./ViewUserForm";

export default function ViewUser(props) {
  const { user, onClose, onSuccess, onError } = props;
  const avatar = getAvatar(user.avatar_filename);

  const mutation = useMutation(
    (values) => {
      editUser(values).then(() => {
        onSuccess();
        onClose();
      }).catch((error) => {
        onError();
        onClose();
      });
      // },
      // {
      //   onSuccess: () => {
      //     onSuccess();
      //     onClose();
      //   },
      //   onError: () => {
      //     onError();
      //     onClose();
      //   },
      // }
    });

  const formik = useFormik({
    initialValues: {
      id: user?.id,
      nom_prenom: user?.nom_prenom,
      email: user?.email,
      telephone: user?.telephone,
      region_id: user?.region_id,
      role_id: user?.role_id,
      vigilances: user?.vigilances
    },
    validationSchema: newUserValidationSchema,
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  return (
    <div className="view-user-container">
      <Stack direction="column" spacing={0}>
        <div className="view-user-header">
          <Stack direction="column" spacing={0}>
            <div className="view-user-close">
              <IconButton style={{ padding: 0 }} onClick={onClose}>
                <UserViewCloseIcon />
              </IconButton>
            </div>
            <div>
              <div className="view-user-avatar">
                <Stack direction="row" spacing={2}>
                  <div className="view-user-avatar-img">
                    <img src={avatar} alt="" />
                  </div>
                  <div>
                    <Stack direction="column" spacing={0}>
                      <div className="view-user-avatar-text">
                        <label>{user?.nom_prenom}</label>
                      </div>
                      <div className="view-user-avatar-status">
                        <RoleName type={user?.role_id} />
                      </div>
                    </Stack>
                  </div>
                </Stack>
              </div>
            </div>
          </Stack>
        </div>
        <ViewUserForm formik={formik} onClose={onClose} />
      </Stack>
    </div>
  );
}
