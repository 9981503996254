import React from "react";
import { Stack } from "@mui/material";
import Repondeur from "../../../components/Case/Toxi/Repondeur/Repondeur";

import '../index.scss';



export default function AddToxiRepondeur(props){

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={0}>
                        <Repondeur />
                  </Stack>
            </div>

      )
}