import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdCodeDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";

export default function Serpent(props) {
    const { serpent, preview } = props;
    const referentielResults = useReferentiels([
        "espece_serpent",
        "evolution_gradation",
        "premiers_gestes",
        "serpent_traitement_symptomatique",
        "syndrome_cobraique",
        "syndrome_viperin"
    ]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading === null) return <CustomLoader isLoading={isLoading} />;
    const especes = flattenIdNomDict(referentielResults[0].data);
    const codes = flattenIdCodeDict(referentielResults[0].data);
    const evolutions = flattenIdNomDict(referentielResults[1].data);
    const premiers_gestes = flattenIdNomDict(referentielResults[2].data);
    const serpent_traitement_symptomatiques = flattenIdNomDict(referentielResults[3].data);
    const syndrome_cobraiques = flattenIdNomDict(referentielResults[4].data);
    const syndrome_viperin = flattenIdNomDict(referentielResults[5].data);
    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Serpent</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(serpent.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Espèce"
                                        value={especes[serpent.espece_serpent_id]}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Code"
                                        value={
                                            codes[serpent.espece_serpent_id]
                                        }
                                    />
                                </div>

                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Voie d'intoxixation" value={"Cutanée"} />
                                </div>

                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Malade référé"
                                        value={
                                            parseInt(serpent.malade_refere) === 1 ? "Oui" : "Non"
                                        }
                                    />
                                </div>
                                <div style={{ width: "62%" }}>
                                    <LabelInfo
                                        title="Siège morsure"
                                        value={
                                            serpent.siege_morsure
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Date de la morsure"
                                        value={
                                            serpent.date_morsure
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Heure de la morsure"
                                        value={
                                            serpent.heure_morsure
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Date de l'admission"
                                        value={
                                            serpent.date_admission
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Heure de l'admission'"
                                        value={
                                            serpent.heure_admission
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: "96%" }}>
                                    <LabelInfo
                                        title="Grade (syndrome vipérin)"
                                        value={
                                            syndrome_viperin[parseInt(serpent.grade_syndrome_viperin)]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: "96%" }}>
                                    <LabelInfo
                                        title="Syndrome cobraîque"
                                        value={
                                            syndrome_cobraiques[parseInt(serpent.grade_syndrome_cobraique)]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Délai d'administration en heure"
                                        value={
                                            serpent.delai_administration
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: "96%" }}>
                                    <LabelInfo
                                        title="Premiers gestes"
                                        value={
                                            premiers_gestes[serpent.premiers_gestes]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: "96%" }}>
                                    <LabelInfo
                                        title="Traitement symptomatique"
                                        value={
                                            serpent_traitement_symptomatiques[serpent.traitement_symptomatique]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Type de sérum administré"
                                        value={
                                            serpent.type_serum_administre
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>

                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nombre d'ampoules administrées"
                                        value={
                                            serpent.nombre_ampoules_administrees
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Durée d'hospitalisation (en jours)"
                                        value={
                                            serpent.duree_hospitalisation
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Effets indésirables observés"
                                        value={
                                            serpent.effets_indesirables
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Evolution de la gradation clinique et de la biologie"
                                        value={
                                            evolutions[serpent.evolution_gradation]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Scorpion Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
