import React, { useState } from "react";
import { Stack } from "@mui/material";
import UserManagement from "./UserManagement";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import RolePermissions from "../RolePermissions/RolePermissions";
import './index.scss';



export default function UserManagementModule(props) {

      const [roleActive, setRoleActive] = useState(false);
      let subtitle = roleActive ? "Gestion des rôles" : "Gestion des utilisateurs";
      let component = roleActive ? <RolePermissions /> : <UserManagement />;

      const activateComponent = (name) => {
            if (name === "users") {
                  setRoleActive(false);
            } else {
                  setRoleActive(true);
            }
      }
      return (
            <div className="user-management-module-container">
                  <Stack direction="column" spacing={0}>
                        <div className="user-management-module-title">
                              <label>Sélectionner rubrique</label>
                        </div>
                        <div className="user-management-module-buttons">
                              <Stack direction="row" spacing={1}>
                                    <RubriqueButton type="users-management" active={!roleActive} onClick={() => activateComponent("users")} />
                                    <RubriqueButton type="roles-management" active={roleActive} onClick={() => activateComponent("roles")} />
                              </Stack>
                        </div>
                        <div className="user-management-module-title">
                              {subtitle}
                        </div>
                        <div className="user-management-module-grid">
                              {component}
                        </div>
                  </Stack>
            </div>
      )
}