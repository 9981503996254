import React from "react";
import { Stack } from "@mui/material";
import Rapport from "../../../components/Case/Toxi/Rapport/Rapport";
import Declarant from "../../../components/Case/Toxi/Declarant/Declarant";

import '../index.scss';



export default function AddToxiDeclarant(props){

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={4}>
                        <Rapport />
                        <Declarant />
                  </Stack>
            </div>

      )
}