import React, { useContext } from "react";
import {
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelDatePicker from "../../../LabelDatePicker/LabelDatePicker";
import "../../index.scss";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";

export default function Dossier(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const validated = useContext(ValidatedContext);

  const widthPercentage = "31%";
  const prefix = "prescripteur.dossier.";

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Dossier</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={5}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage, height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  {getIn(values, prefix + "type_dossier") != null && (
                    <Stack
                      direction="row"
                      spacing={0}
                      style={{ height: "100%" }}
                    >
                      <div className="case-common-container-body-label">
                        <label>
                          Type de dossier{" "}
                          <span style={{ color: "red" }}>*</span>:
                        </label>
                      </div>
                      <div className="case-common-container-body-radio">
                        <RadioGroup
                          row
                          name={prefix + "type_dossier"}
                          onChange={(event) =>
                            setFieldValue(
                              prefix + "type_dossier",
                              event.target.value
                            )
                          }
                          value={getIn(
                            values,
                            prefix + "type_dossier"
                          ).toString()}
                          error={
                            getIn(touched, prefix + "type_dossier") &&
                            Boolean(getIn(errors, prefix + "type_dossier"))
                          }
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="STP"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Toxico"
                          />
                        </RadioGroup>
                      </div>
                    </Stack>
                  )}
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(touched, prefix + "type_dossier") &&
                      getIn(errors, prefix + "type_dossier")}
                  </FormHelperText>
                </FormControl>
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"N° de dossier"}
                  name={prefix + "numero_dossier"}
                  value={getIn(values, prefix + "numero_dossier")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "numero_dossier") &&
                    Boolean(getIn(errors, prefix + "numero_dossier"))
                  }
                  helperText={
                    getIn(touched, prefix + "numero_dossier") &&
                    getIn(errors, prefix + "numero_dossier")
                  }
                  disabled
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelDatePicker
                  title={"Date de saisie"}
                  name={prefix + "date_saisie"}
                  value={getIn(values, prefix + "date_saisie")}
                  onChange={setFieldValue}
                  error={
                    getIn(touched, prefix + "date_saisie") &&
                    Boolean(getIn(errors, prefix + "date_saisie"))
                  }
                  helperText={
                    getIn(touched, prefix + "date_saisie") &&
                    getIn(errors, prefix + "date_saisie")
                  }
                  disabled
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelDatePicker
                  title={"Date de réception"}
                  name={prefix + "date_reception"}
                  value={getIn(values, prefix + "date_reception")}
                  onChange={setFieldValue}
                  error={
                    getIn(touched, prefix + "date_reception") &&
                    Boolean(getIn(errors, prefix + "date_reception"))
                  }
                  helperText={
                    getIn(touched, prefix + "date_reception") &&
                    getIn(errors, prefix + "date_reception")
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Heure de réception"}
                  type="time"
                  name={prefix + "heure_reception"}
                  value={getIn(values, prefix + "heure_reception")}
                  onChange={handleChange}
                  error={
                    getIn(touched, prefix + "heure_reception") &&
                    Boolean(getIn(errors, prefix + "heure_reception"))
                  }
                  helperText={
                    getIn(touched, prefix + "heure_reception") &&
                    getIn(errors, prefix + "heure_reception")
                  }
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
