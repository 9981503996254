import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdNomCommercialDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getProduitPesticideDetails } from "../../../../services/cases/cases";

export default function Engrais(props) {
    const { engrais, preview } = props;
    const [engraisDetails, setEngraisDetails] = useState(null);

    const referentielResults = useReferentiels([
        "matiere_active",
        "voie_intoxication",
        "forme",
        "classe_chimique",
        "classe_engrais",
        'usage_engrais',
        "produit_engrais"
    ]);
    useEffect(() => {
        getProduitPesticideDetails(engrais.produit_pesticide_id).then((data) => {
            setEngraisDetails({
                "matiere_active": data.matiere_active,
                "classe_chimique": data.classe_chimique,
                "classe_engrais": data.classe_engrais,
                "forme_id": data.forme_id,
                "usage_official": data.usage_official,
            });
        });
    }, [props]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading || engraisDetails === null) return <CustomLoader isLoading={isLoading} />;
    const matieresActives = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);
    const formes = flattenIdNomDict(referentielResults[2].data);
    const classesChimiques = flattenIdNomDict(referentielResults[3].data);
    const classesEngrais = flattenIdNomDict(referentielResults[4].data);
    const usagesEngrais = flattenIdNomDict(referentielResults[5].data);
    const produitsEngrais = flattenIdNomCommercialDict(referentielResults[6].data);


    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Engrais</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(engrais.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom commercial"
                                        value={produitsEngrais[engrais.produit_engrais_id]}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Matière active" value={matieresActives[engraisDetails.matiere_active]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Classe chimique"
                                        value={classesChimiques[engraisDetails.classe_chimique]}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Classe engrais"
                                        value={
                                            classesEngrais[engraisDetails.classe_dangerosite]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Forme"
                                        value={
                                            formes[engraisDetails.forme_id]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Usage officiel"
                                        value={
                                            engraisDetails.usage_official
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Dosage"
                                        value={
                                            engrais.dosage
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Usage selon notificateur"
                                        value={
                                            usagesEngrais[engrais.usage_notificateur_id]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Usage (Autre)"
                                        value={
                                            engrais.usage_notificateur_autre
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Quantité prise"
                                        value={
                                            engrais.quantite
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[engrais.voie_intoxication_id]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Engrais Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
