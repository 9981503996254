import React from "react";
import { Stack, Grid } from "@mui/material";
import ToxiqueCard from "../../components/ToxiqueCard/ToxiqueCard";
import { toxiqueTypes } from "../../utils/toxiques";
import './index.scss';


export default function ExtractToxiMenu(props) {

      const onClick = props.onClick;

      return (
            <Stack spacing={1} sx={{ width: '100%', maxWidth: 600 }}>
                  <label className="extract-menu-title">
                        Catégories
                  </label>
                  <div className="extract-menu-container">
                        <div className="extract-menu-sub-menu">
                              <Stack spacing={1} sx={{ width: '100%' }}>
                                    <div style={{ width: '50%' }}>
                                          <div className="extract-menu-sub-title" >
                                                <label>BD globale</label>
                                          </div>
                                    </div>

                                    <div >
                                          <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                      <ToxiqueCard type='global' onChangeHandler={() => onClick('global')} />
                                                </Grid>

                                          </Grid>
                                    </div>

                              </Stack>
                        </div>
                        <div className="extract-menu-sub-menu">
                              <Stack spacing={1} sx={{ width: '100%' }}>
                                    <div style={{ width: '50%' }}>
                                          <div className="extract-menu-sub-title" >
                                                <label>BD spécifique</label>
                                          </div>
                                    </div>
                                    <div >
                                          <Grid container spacing={1}>
                                                {toxiqueTypes.map((toxiqueType, index) => (
                                                      <Grid key={index} item xs={4}>
                                                            <ToxiqueCard type={toxiqueType} onChangeHandler={() => onClick(toxiqueType)} />
                                                      </Grid>
                                                ))}
                                          </Grid>
                                    </div>

                              </Stack>
                        </div>
                  </div>
            </Stack>
      )
}