import React from "react";
import { useState } from "react";
import { Stack } from "@mui/material";
import SimplePageTitle from "../../components/PageTitle/SimplePageTitle";
import HistoryCases from "./HistoryCases";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import './index.scss';



export default function HistoryCasesModule(props) {
  const [activeTab, setActiveTab] = useState("toxicovigilance");
  const tabs = ["toxicovigilance", "laboratoire"];

  return (
    <div className="history-cases-module-container">
      <div className="add-cases-container">
        <div className="history-cases-module-container-title">
          <SimplePageTitle type='historic' />
        </div>
        <Stack direction="column" spacing={0}>
          <div className="add-cases-title">
            <label>Sélectionner rubrique</label>
          </div>
          <div className="add-cases-buttons">
            <Stack direction="row" spacing={1}>
              {tabs.map((tab) => (
                <RubriqueButton
                  type={tab}
                  active={activeTab == tab}
                  onClick={() => setActiveTab(tab)}
                />
              ))}
            </Stack>
          </div>
        </Stack>
      </div>
      <Stack direction="column" spacing={0}>
        <div className="history-cases-module-container-body">
          <HistoryCases activeTab={activeTab} />
        </div>
      </Stack>
    </div>
  )
}