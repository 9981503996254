import React, { useContext } from "react";
import { Checkbox, ThemeProvider, Typography } from "@mui/material";
import alimentImg from "../../assets/imgs/toxiques/small/aliment_small.jpg";
import cosmetiqueImg from "../../assets/imgs/toxiques/small/cosmetique_small.jpg";
import autreAnimalVenimeuImg from "../../assets/imgs/toxiques/small/autre_animal_venimeu_small.jpg";
import drogueImg from "../../assets/imgs/toxiques/small/drogue_small.jpg";
import gazImg from "../../assets/imgs/toxiques/small/gaz_small.jpg";
import medicamentImg from "../../assets/imgs/toxiques/small/medicament_small.jpg";
import medicamentVeterinaireImg from "../../assets/imgs/toxiques/small/medicament_veterinaire_small.jpg";
import metauxLourdsImg from "../../assets/imgs/toxiques/small/metaux_lourds_small.jpg";
import engraisImg from "../../assets/imgs/toxiques/small/pesticide_engrais_small.jpg";
import planteImg from "../../assets/imgs/toxiques/small/plante_small.jpg";
import produitIndustrielImg from "../../assets/imgs/toxiques/small/produit_industriel_small.jpg";
import produitMenagerImg from "../../assets/imgs/toxiques/small/produit_menager_small.jpg";
import scorpionImg from "../../assets/imgs/toxiques/small/scorpion_small.jpg";
import serpentImg from "../../assets/imgs/toxiques/small/serpent_small.jpg";
import globalImg from "../../assets/imgs/toxiques/small/global_small.jpg";
import LCImg from "../../assets/imgs/toxiques/small/LC_small.jpg";
import PTRRImg from "../../assets/imgs/toxiques/small/PTRR_small.jpg";
import SAAImg from "../../assets/imgs/toxiques/small/SAA_small.jpg";
import InconnuImg from "../../assets/imgs/toxiques/small/inconnu_small.jpg";
import PesticideImg from "../../assets/imgs/toxiques/small/pesticide_small.jpg";
import AutreImg from "../../assets/imgs/toxiques/small/autre_small.jpg";
import "./index.scss";
import { ValidatedContext } from "../../modules/AddCases/AddLaboCase/AddLaboCase";

export default function ToxiqueCard(props) {
  let icone = null;
  let text = null;
  let fontSize = "12px";
  const [checked, setChecked] = React.useState(props.selected);
  const validated = useContext(ValidatedContext);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onChangeHandler(event.target.checked);
  };

  switch (props.type) {
    case "aliments":
      icone = alimentImg;
      text = "Aliments";
      break;
    case "global":
      icone = globalImg;
      text = "BD tv";
      break;
    case "autre_animal_venimeux":
      icone = autreAnimalVenimeuImg;
      text = "Autre Animal Venimeu";
      break;
    case "cosmetique":
      icone = cosmetiqueImg;
      text = "Prod. Cosmétique";
      break;
    case "drogue":
      icone = drogueImg;
      text = "Drogue";
      break;
    case "gaz":
      icone = gazImg;
      text = "Gaz";
      break;
    case "medicaments":
      icone = medicamentImg;
      text = "Médicaments";
      break;
    case "medicaments_veterinaires":
      icone = medicamentVeterinaireImg;
      text = "Médicaments Vétérinaires";
      break;
    case "metaux_lourds":
      icone = metauxLourdsImg;
      text = "Métaux Lourds";
      break;
    case "engrais":
      icone = engraisImg;
      text = "Engrais";
      break;
    case "pesticide":
      icone = PesticideImg;
      text = "Pesticides";
      break;
    case "plante":
      icone = planteImg;
      text = "Plante";
      break;
    case "produit_industriel":
      icone = produitIndustrielImg;
      text = "Prod. Industriel";
      break;
    case "produit_menager":
      icone = produitMenagerImg;
      text = "Prod. Ménager";
      break;
    case "scorpion":
      icone = scorpionImg;
      text = "Scorpion";
      break;
    case "serpent":
      icone = serpentImg;
      text = "Serpent";
      break;
    case "LC":
      icone = LCImg;
      text = "LC";
      break;
    case "GC":
      icone = gazImg;
      text = "GC";
      break;
    case "PTRR":
      icone = PTRRImg;
      text = "PTRR";
      break;
    case "SAA":
      icone = SAAImg;
      text = "SAA";
      break;
    case "inconnu":
      icone = InconnuImg;
      text = "Inconnu";
      break;
    case "autre":
      icone = AutreImg;
      text = "Autre";
      break;
    default:
      break;
  }
  return (
    <div
      className={"toxique-card-container"}
      style={{
        backgroundImage: `url(${icone})`,
      }}
    >
      <Checkbox
        disabled={validated}
        className={"toxique-card-container checkbox"}
        style={{
          color: "#6BE158",
          background: "white",
        }}
        checked={checked}
        onChange={handleChange}
      />

      <div className={"second-container"}>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: fontSize,
            margin: "auto",
            color: "#9B9FA4",
            textTransform: "none",
          }}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
}
