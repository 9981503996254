import {
  Redirect,
  Route,
  Switch,
  Router as BrowserRouter,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import history from "./utils/history";
import Login from "./pages/Login/Login";
import Admin from "./pages/Admin/Admin";
import Main from "./pages/Main/Main";
import NotFound from "./pages/NotFound/NotFound";
import { SnackbarProvider } from "notistack";
import PermissionsProvider from "./contextes/UserPermissionsContext";
import "./app.css";

const queryClient = new QueryClient();

const snackAnchor = {
  horizontal: "center",
  vertical: "top",
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <PermissionsProvider permissions={permissions}> */}
      <SnackbarProvider
        anchorOrigin={snackAnchor}
        maxSnack={1}
        preventDuplicate
      >
        <BrowserRouter history={history}>
          <Switch>
            {/*  Routes definition ... */}
            <Route
              path="/"
              exact
              render={() => {
                return <Redirect to="/login" />;
              }}
            />
            <Route path="/login" exact component={Login} />
            <Route path="/main" exact component={Admin} />
            <Route path="/main/:component" exact component={Main} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </SnackbarProvider>
      {/* </PermissionsProvider> */}
    </QueryClientProvider>
  );
}

export default App;
