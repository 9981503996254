import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import history from "../../utils/history";
import PopupLogout from '../Popups/PopupLogout/PopupLogout';


const accountMenuStyle = {
      textTransform: 'none',
      padding: 0,
      height: '17px',
      color: '#333333',
      fontFamily: 'Roboto',
      fontWeight: '600'
}

export default function AccountMenu(props) {

      const [logoutModal, setLogoutModal] = useState(false);

      const handleCloseLogoutModal = () => {
            setLogoutModal(false);
      }
      const profileHandleClick = (popupState) => {
            history.push("/main/user-profile");
            popupState.close();
      }

      const logoutHandleClick = (popupState) => {
            setLogoutModal(true);
            popupState.close();
      }

      const handleLogout = () => {
            localStorage.clear();
            history.push('/login');
      }

      return (
            <>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                              <React.Fragment>
                                    <Button variant="text" {...bindTrigger(popupState)} style={accountMenuStyle}>
                                          {props.userName} <ArrowDropDownIcon />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                          <MenuItem onClick={() => profileHandleClick(popupState)}>Votre Profil</MenuItem>
                                          <MenuItem onClick={() => logoutHandleClick(popupState)}>Se déconnecter</MenuItem>
                                    </Menu>
                              </React.Fragment>
                        )}
                  </PopupState>
                  <Modal
                        open={logoutModal}
                        onClose={handleCloseLogoutModal}
                        aria-labelledby="modal-logout"
                  >
                        <PopupLogout onClose={handleCloseLogoutModal} onLogout={handleLogout} />
                  </Modal>
            </>
      );
}