import GroupeAgeModal from "../components/ReferentielModals/GroupeAge/GroupeAgeModal";
import NewGroupeAgeModal from "../components/ReferentielModals/GroupeAge/NewGroupeAgeModal";
import SOCModal from "../components/ReferentielModals/SOC/SOCModal";
import NewSOCModal from "../components/ReferentielModals/SOC/NewSOCModal";
import NomOnlyModal from "../components/ReferentielModals/NomOnly/NomOnlyModal";
import NewNomOnlyModal from "../components/ReferentielModals/NomOnly/NewNomOnlyModal";
import ITModal from "../components/ReferentielModals/OneForeignKey/IT/ITModal";
import NewITModal from "../components/ReferentielModals/OneForeignKey/IT/NewITModal";
import CategorieAlimentModal from "../components/ReferentielModals/OneForeignKey/CategorieAliment/CategorieAlimentModal";
import NewCategorieAlimentModal from "../components/ReferentielModals/OneForeignKey/CategorieAliment/NewCategorieAliment";
import DCIModal from "../components/ReferentielModals/OneForeignKey/DCI/DCIModal";
import NewDCIModal from "../components/ReferentielModals/OneForeignKey/DCI/NewDCIModal";
import NewEspeceAnimauxModal from "../components/ReferentielModals/OneForeignKey/EspeceAnimaux/NewEspeceAnimaux";
import EspeceAnimauxModal from "../components/ReferentielModals/OneForeignKey/EspeceAnimaux/EspeceAnimauxModal";
import GroupeAlimentModal from "../components/ReferentielModals/OneForeignKey/GroupeAliment/GroupeAlimentModal";
import NewGroupeAlimentModal from "../components/ReferentielModals/OneForeignKey/GroupeAliment/NewGroupeAliment";
import GroupeChimiqueModal from "../components/ReferentielModals/OneForeignKey/GroupeChimique/GroupeChimiqueModal";
import NewGroupeChimiqueModal from "../components/ReferentielModals/OneForeignKey/GroupeChimique/NewGroupeChimiqueModal";
import GroupeTherapeutiqueModal from "../components/ReferentielModals/OneForeignKey/GroupeTherapeutique/GroupeTherapeutiqueModal";
import NewGroupeTherapeutiqueModal from "../components/ReferentielModals/OneForeignKey/GroupeTherapeutique/NewGroupeTherapeutiqueModal";
import MatiereActiveModal from "../components/ReferentielModals/OneForeignKey/MatiereActive/MatiereActiveModal";
import NewMatiereActiveModal from "../components/ReferentielModals/OneForeignKey/MatiereActive/NewMatiereActiveModal";
import PlanteNomFrancaisModal from "../components/ReferentielModals/OneForeignKey/PlanteNomFrancais/PlanteNomFrancaisModal";
import NewPlanteNomFrancaisModal from "../components/ReferentielModals/OneForeignKey/PlanteNomFrancais/NewPlanteNomFrancaisModal";
import PlanteNomScientifiqueModal from "../components/ReferentielModals/OneForeignKey/PlanteNomScientifique/PlanteNomScientifiqueModal";
import NewPlanteNomScientifiqueModal from "../components/ReferentielModals/OneForeignKey/PlanteNomScientifique/NewPlanteNomScientifiqueModal";
import PlanteNomVernaculaireModal from "../components/ReferentielModals/OneForeignKey/PlanteNomVernaculaire/PlanteNomVernaculaireModal";
import NewPlanteNomVernaculaireModal from "../components/ReferentielModals/OneForeignKey/PlanteNomVernaculaire/NewPlanteNomVernaculaireModal";
import ProduitAlimentModal from "../components/ReferentielModals/OneForeignKey/ProduitAliment/ProduitAlimentModal";
import NewProduitAlimentModal from "../components/ReferentielModals/OneForeignKey/ProduitAliment/NewProduitAlimentModal";
import ProduitCosmetiqueModal from "../components/ReferentielModals/OneForeignKey/ProduitCosmetique/ProduitCosmetiqueModal";
import NewProduitCosmetiqueModal from "../components/ReferentielModals/OneForeignKey/ProduitCosmetique/NewProduitCosmetiqueModal";
import ProduitGazModal from "../components/ReferentielModals/OneForeignKey/ProduitGaz/ProduitGazModal";
import NewProduitGazModal from "../components/ReferentielModals/OneForeignKey/ProduitGaz/NewProduitGazModal";
import ProduitIndustrielModal from "../components/ReferentielModals/OneForeignKey/ProduitIndustriel/ProduitIndustrielModal";
import NewProduitIndustrielModal from "../components/ReferentielModals/OneForeignKey/ProduitIndustriel/NewProduitIndustrielModal";
import SousClasseProduitCosmetiqueModal from "../components/ReferentielModals/OneForeignKey/SousClasseProduitCosmetique/SousClasseProduitCosmetiqueModal";
import NewSousClasseProduitCosmetiqueModal from "../components/ReferentielModals/OneForeignKey/SousClasseProduitCosmetique/NewSousClasseProduitCosmetiqueModal";
import ClasseProduitCosmetiqueModal from "../components/ReferentielModals/OneForeignKey/ClasseProduitCosmetique/ClasseProduitCosmetiqueModal";
import NewClasseProduitCosmetiqueModal from "../components/ReferentielModals/OneForeignKey/ClasseProduitCosmetique/NewClasseProduitCosmetiqueModal";
import SousClasseProduitIndustrielModal from "../components/ReferentielModals/OneForeignKey/SousClasseProduitIndustriel/SousClasseProduitIndustrielModal";
import NewSousClasseProduitIndustrielModal from "../components/ReferentielModals/OneForeignKey/SousClasseProduitIndustriel/NewSousClasseProduitIndustrielModal";
import SubstanceChimiqueModal from "../components/ReferentielModals/OneForeignKey/SubstanceChimique/SubstanceChimiqueModal";
import NewSubstanceChimiqueModal from "../components/ReferentielModals/OneForeignKey/SubstanceChimique/NewSubstanceChimiqueModal";
import ProduitEngraisModal from "../components/ReferentielModals/ProduitEngrais/ProduitEngraisModal";
import NewProduitEngraisModal from "../components/ReferentielModals/ProduitEngrais/NewProduitEngraisModal";
import ProduitMedicamentModal from "../components/ReferentielModals/ProduitMedicament/ProduitMedicamentModal";
import NewProduitMedicamentModal from "../components/ReferentielModals/ProduitMedicament/NewProduitMedicamentModal";
import ProduitPesticideModal from "../components/ReferentielModals/ProduitPesticide/ProduitPesticideModal";
import NewProduitPesticideModal from "../components/ReferentielModals/ProduitPesticide/NewProduitPesticideModal";
import ScorpionCouleurModal from "../components/ReferentielModals/ScorpionCouleur/ScorpionCouleurModal";
import NewScorpionCouleurModal from "../components/ReferentielModals/ScorpionCouleur/NewScorpionCouleurModal";
import NewExamenDemandeModal from "../components/ReferentielModals/ExamenDemande/NewExamenDemandeModal";
import ExamenDemandeModal from "../components/ReferentielModals/ExamenDemande/ExamenDemandeModal";
import WHOArtModal from "../components/ReferentielModals/WHOArt/WHOArtModal";
import NewWHOArtModal from "../components/ReferentielModals/WHOArt/NewWHOArtModal";
import EntreeNomModal from "../components/ReferentielModals/OneForeignKey/EntreeNom/EntreeNomModal";
import NewEntreeNomModal from "../components/ReferentielModals/OneForeignKey/EntreeNom/NewEntreeNomModal";

const tableHeaders = {
  code_atc: "Code ATC",
  groupe_age: "Groupe d'age",
  examen_demande: "Examen demandé",
  repondeur: "Répondeur",
  delai_reponse: "Délai de réponse",
  type_rapport: "Type rapport",
  provenance: "Provenance",
  service: "Service",
  declarant_type: "Type déclarant",
  analyste: "Analyste",
  nature: "Nature",
  prescripteur_type: "Type prescripteur",
  evolution: "Evolution",
  gradation: "Gradation",
  it: "IT",
  lieu_intoxication: "Lieu intoxication",
  circonstance_intoxication: "Circonstance intoxication",
  soc: "SOC",
  type_risque: "Type de risque",
  decontamination: "Décontamination",
  traitement_symptomatique: "Traitement symptomatique",
  epuration: "Epuration",
  antidotes: "Antidotes",
  categorie_aliment: "Catégories aliment",
  classe_aliment: "Classes aliment",
  classe_chimique: "Classes chimique",
  classe_dangerosite: "Classes dangérosité",
  classe_engrais: "Classes engrais",
  classe_gaz: "Classes gaz",
  classe_plante: "Classes plante",
  classe_produit_cosmetique: "Classes produit cosmétique",
  classe_produit_industriel: "Classes produit industriel",
  classe_produit_menager: "Classes produit ménager",
  dci: "DCI",
  voie_intoxication: "Voie intoxication",
  espece_animaux: "Espèces animaux",
  espece_serpent: "Espèces serpent",
  famille_drogues: "Familles drogues",
  forme: "Forme",
  groupe_aliment: "Groupe aliment",
  groupe_anatomique: "Groupe anatomique",
  groupe_animaux: "Groupe animaux",
  groupe_chimique: "Groupe chimiques",
  groupe_therapeutique: "Groupe thérapeutiques",
  matiere_active: "Matière active",
  partie_plante: "Partie plante",
  plante_nom_francais: "Plante nom français",
  plante_nom_scientifique: "Plante nom scientifique",
  plante_nom_vernaculaire: "Plante nom vernaculaire",
  presentation_plante: "Présentation plante",
  presentation_produit_cosmetique: " Présentation produit cosmétique",
  presentation_produit_industriel: "Présentation produit industriel",
  produit_aliment: "Produit alimentaire",
  produit_cosmetique: "Produit cosmétique",
  produit_engrais: "Produit engrais",
  produit_gaz: "Produit gaz",
  produit_industriel: "Produit industriel",
  produit_medicament: "Produit médicament",
  produit_pesticide: "Produit pesticide",
  scorpion_couleur: "Scorpion couleur",
  siege_morsure: "Siege morsure",
  source_gaz: "Source gaz",
  sous_classe_produit_cosmetique: "Sous classe produit cosmétique",
  sous_classe_produit_industriel: "Sous classe produit industriel",
  sous_presentation_produit_cosmetique: "Sous présentation produit cosmétique",
  substance_chimique: "Substance chimique",
  syndrome_viperin: "Syndrome viperin",
  syndrome_cobraique: "Syndrome cobraîque",
  usage_pesticide: "Usage pesticide",
  usage_engrais: "Usage engrais",
  who_art: "WHO ART",
  entree_nom: "Produits et résultats",
  presentation_produit_menager: "Présentation pr. Ménager",
  mode_preparation_plante: "Mode préparation plante",
  usage_medicament: "Usage médicament",
  terme_reaction: "Terme réaction",
  profession: "Profession"
};

const fieldHeaderNamesDict = {
  id: "id",
  actions: "Actions",
  nom_aliment: "Nom Aliment",
  nom_commercial: "Nom Commercial",
  intitule: "Intitulé du groupe",
  debut_age: "Début",
  fin_age: "Fin",
  unite_age: "Unité",
  type: "Type",
  nom: "Nom",
  nom_molecule: "Nom Molécule",
  classe: "Classe",
  categorie: "Catégorie",
  veterinaire: "Vétérinaire",
  couleur: "Couleur",
  type_pesticide: "Type"
};

const tablesByCategory = {
  patient: ["groupe_age", "profession"],
  repondeur: ["delai_reponse", "repondeur"],
  declarant_rapport: ["declarant_type", "provenance", "service"],
  laboratoire: ["analyste", "entree_nom", "examen_demande", "nature", "prescripteur_type"],
  intoxication: [
    "antidotes",
    "circonstance_intoxication",
    "decontamination",
    "epuration",
    "evolution",
    "gradation",
    "it",
    "lieu_intoxication",
    //"soc",
    "terme_reaction",
    "traitement_symptomatique",
    "type_risque",
    "voie_intoxication",
    //"who_art"
  ],
  produits: [
    "categorie_aliment",
    "classe_aliment",
    "classe_chimique",
    "classe_dangerosite",
    "classe_engrais",
    "classe_gaz",
    "classe_plante",
    "classe_produit_cosmetique",
    "classe_produit_industriel",
    "classe_produit_menager",
    "code_atc",
    "dci",
    "espece_animaux",
    "espece_serpent",
    "famille_drogues",
    "forme",
    "groupe_aliment",
    "groupe_anatomique",
    "groupe_animaux",
    "groupe_chimique",
    "groupe_therapeutique",
    "matiere_active",
    "mode_preparation_plante",
    "partie_plante",
    "plante_nom_francais",
    "plante_nom_scientifique",
    "plante_nom_vernaculaire",
    "presentation_plante",
    "presentation_produit_menager",
    "presentation_produit_cosmetique",
    "presentation_produit_industriel",
    "produit_aliment",
    "produit_cosmetique",
    "produit_engrais",
    "produit_gaz",
    "produit_industriel",
    "produit_medicament",
    "produit_pesticide",
    "scorpion_couleur",
    "siege_morsure",
    "source_gaz",
    "sous_classe_produit_cosmetique",
    "sous_classe_produit_industriel",
    "sous_presentation_produit_cosmetique",
    "substance_chimique",
    "syndrome_cobraique",
    "syndrome_viperin",
    "usage_engrais",
    "usage_medicament",
    "usage_pesticide",
  ],
};

const referentielVigilances = {
  commune: [0],
  profession: [0],
  vigilance: [0],
  province: [0],
  region: [0],
  groupe_age: [0],
  examen_demande: [-1],
  repondeur: [0],
  type_rapport: [0],
  provenance: [0],
  service: [0],
  declarant_type: [0],
  delai_reponse: [0],
  analyste: [-1],
  nature: [-1],
  prescripteur_type: [-1],
  evolution: [0],
  gradation: [0],
  it: [0],
  lieu_intoxication: [0],
  circonstance_intoxication: [0],
  soc: [0],
  type_risque: [0],
  decontamination: [0],
  traitement_symptomatique: [0],
  epuration: [0],
  antidotes: [0],
  categorie_aliment: [16],
  classe_aliment: [16],
  classe_chimique: [5, 6, 7, 14, 15],
  classe_dangerosite: [0],
  classe_engrais: [15],
  classe_gaz: [3],
  classe_plante: [2],
  classe_produit_cosmetique: [6],
  classe_produit_industriel: [5],
  classe_produit_menager: [7],
  dci: [0],
  entree_nom: [-1],
  espece_animaux: [13],
  espece_serpent: [11],
  famille_drogues: [4],
  forme: [0],
  groupe_aliment: [16],
  groupe_anatomique: [0],
  groupe_animaux: [13],
  groupe_chimique: [5, 6, 7, 14, 15],
  groupe_therapeutique: [0],
  matiere_active: [0],
  partie_plante: [2],
  plante_nom_francais: [2],
  plante_nom_scientifique: [2],
  plante_nom_vernaculaire: [2],
  presentation_plante: [2],
  presentation_produit_cosmetique: [6],
  presentation_produit_industriel: [5],
  produit_aliment: [16],
  produit_cosmetique: [6],
  produit_engrais: [15],
  produit_gaz: [3],
  produit_industriel: [5],
  produit_medicament: [1],
  code_atc: [1],
  produit_medicament_veterinaire: [1],
  produit_pesticide: [14],
  scorpion_couleur: [10],
  siege_morsure: [10],
  source_gaz: [3],
  sous_classe_produit_cosmetique: [6],
  sous_classe_produit_industriel: [5],
  sous_presentation_produit_cosmetique: [6],
  substance_chimique: [0],
  syndrome_viperin: [11],
  syndrome_cobraique: [11],
  usage_pesticide: [14],
  usage_engrais: [15],
  usage_medicament: [1],
  voie_intoxication: [0],
  who_art: [0],
  presentation_produit_menager: [7],
  mode_preparation_plante: [2],
  terme_reaction: [0],
}
const referentielEndPoints = {
  commune: "commune",
  vigilance: "vigilance",
  province: "province",
  region: "region",
  groupe_age: "groupe-age",
  examen_demande: "examen-demande",
  repondeur: "repondeur",
  type_rapport: "type-rapport",
  provenance: "provenance",
  service: "service",
  declarant_type: "declarant-type",
  delai_reponse: "delai-reponse",
  analyste: "analyste",
  nature: "nature",
  prescripteur_type: "prescripteur-type",
  evolution: "evolution",
  gradation: "gradation",
  it: "it",
  lieu_intoxication: "lieu-intoxication",
  circonstance_intoxication: "circonstance-intoxication",
  soc: "soc",
  type_risque: "type-risque",
  decontamination: "decontamination",
  traitement_symptomatique: "traitement-symptomatique",
  epuration: "epuration",
  antidotes: "antidote",
  categorie_aliment: "categorie-aliment",
  classe_aliment: "classe-aliment",
  classe_chimique: "classe-chimique",
  classe_dangerosite: "classe-dangerosite",
  classe_engrais: "classe-engrais",
  classe_gaz: "classe-gaz",
  classe_plante: "classe-plante",
  classe_produit_cosmetique: "classe-produit-cosmetique",
  code_produit_cosmetique: "code-produit-cosmetique",
  classe_produit_industriel: "classe-produit-industriel",
  classe_produit_menager: "classe-produit-menager",
  dci: "dci",
  espece_animaux: "espece-animaux",
  espece_serpent: "espece-serpent",
  famille_drogues: "famille-drogues",
  forme: "forme",
  groupe_aliment: "groupe-aliment",
  groupe_anatomique: "groupe-anatomique",
  groupe_animaux: "groupe-animaux",
  groupe_chimique: "groupe-chimique",
  groupe_therapeutique: "groupe-therapeutique",
  matiere_active: "matiere-active",
  partie_plante: "partie-plante",
  plante_nom_francais: "plante-nom-francais",
  plante_nom_scientifique: "plante-nom-scientifique",
  plante_nom_vernaculaire: "plante-nom-vernaculaire",
  presentation_plante: "presentation-plante",
  presentation_produit_cosmetique: "presentation-produit-cosmetique",
  presentation_produit_industriel: "presentation-produit-industriel",
  produit_aliment: "produit-aliment",
  produit_cosmetique: "produit-cosmetique",
  produit_engrais: "produit-engrais",
  produit_gaz: "produit-gaz",
  produit_industriel: "produit-industriel",
  produit_medicament: "produit-medicament",
  code_atc: "code-atc",
  produit_medicament_veterinaire: "produit-medicament-veterinaire",
  produit_pesticide: "produit-pesticide",
  scorpion_couleur: "scorpion-couleur",
  siege_morsure: "siege-morsure",
  source_gaz: "source-gaz",
  sous_classe_produit_cosmetique: "sous-classe-produit-cosmetique",
  sous_classe_produit_industriel: "sous-classe-produit-industriel",
  sous_presentation_produit_cosmetique: "sous-presentation-produit-cosmetique",
  substance_chimique: "substance-chimique",
  syndrome_viperin: "syndrome-viperin",
  usage_pesticide: "usage-pesticide",
  usage_engrais: "usage-engrais",
  usage_medicament: "usage-medicament",
  voie_intoxication: "voie-intoxication",
  who_art: "who-art",
  entree_nom: "entree-nom",
  presentation_produit_menager: "presentation-produit-menager",
  mode_preparation_plante: "mode-preparation-plante",
  terme_reaction: "terme-reaction",
  profession: "profession",
  evolution_gradation: "evolution-gradation",
  premiers_gestes: "premiers-gestes",
  serpent_traitement_symptomatique: "serpent-traitement-symptomatique",
  syndrome_cobraique: "syndrome-cobraique",

};


const referentielComponents = {
  groupe_age: [GroupeAgeModal, NewGroupeAgeModal],
  soc: [SOCModal, NewSOCModal],
  it: [ITModal, NewITModal],
  categorie_aliment: [CategorieAlimentModal, NewCategorieAlimentModal],
  dci: [DCIModal, NewDCIModal],
  espece_animaux: [EspeceAnimauxModal, NewEspeceAnimauxModal],
  groupe_aliment: [GroupeAlimentModal, NewGroupeAlimentModal],
  groupe_chimique: [GroupeChimiqueModal, NewGroupeChimiqueModal],
  groupe_therapeutique: [GroupeTherapeutiqueModal, NewGroupeTherapeutiqueModal],
  matiere_active: [MatiereActiveModal, NewMatiereActiveModal],
  plante_nom_francais: [PlanteNomFrancaisModal, NewPlanteNomFrancaisModal],
  plante_nom_scientifique: [
    PlanteNomScientifiqueModal,
    NewPlanteNomScientifiqueModal,
  ],
  plante_nom_vernaculaire: [
    PlanteNomVernaculaireModal,
    NewPlanteNomVernaculaireModal,
  ],
  produit_aliment: [ProduitAlimentModal, NewProduitAlimentModal],
  produit_cosmetique: [ProduitCosmetiqueModal, NewProduitCosmetiqueModal],
  produit_gaz: [ProduitGazModal, NewProduitGazModal],
  produit_industriel: [ProduitIndustrielModal, NewProduitIndustrielModal],
  sous_classe_produit_cosmetique: [
    SousClasseProduitCosmetiqueModal,
    NewSousClasseProduitCosmetiqueModal,
  ],
  classe_produit_cosmetique: [
    ClasseProduitCosmetiqueModal,
    NewClasseProduitCosmetiqueModal,
  ],
  sous_classe_produit_industriel: [
    SousClasseProduitIndustrielModal,
    NewSousClasseProduitIndustrielModal,
  ],
  substance_chimique: [SubstanceChimiqueModal, NewSubstanceChimiqueModal],
  produit_engrais: [ProduitEngraisModal, NewProduitEngraisModal],
  produit_medicament: [ProduitMedicamentModal, NewProduitMedicamentModal],
  produit_pesticide: [ProduitPesticideModal, NewProduitPesticideModal],
  scorpion_couleur: [ScorpionCouleurModal, NewScorpionCouleurModal],
  examen_demande: [ExamenDemandeModal, NewExamenDemandeModal],
  who_art: [WHOArtModal, NewWHOArtModal],
  entree_nom: [EntreeNomModal, NewEntreeNomModal],
  default: [NomOnlyModal, NewNomOnlyModal],
};

const referentielDependencies = {
  categories_aliment: ["classe_aliment"],
  dci: ["substance_chimique"],
  espece_animaux: ["groupe_animaux"],
  groupe_aliment: ["categorie_aliment"],
  groupe_chimique: ["groupe_therapeutique"],
  groupe_therapeutique: ["groupe_anatomique"],
  it: ["soc"],
  matiere_active: ["classe_chimique"],
  plante_nom_francais: ["plante_nom_scientifique"],
  plante_nom_scientifique: ["classe_plante"],
  plante_nom_vernaculaire: ["plante_nom_francais"],
  produit_aliment: ["groupe_aliment"],
  produit_cosmetique: ["sous_classe_produit_cosmetique"],
  produit_gaz: ["classe_gaz"],
  produit_industriel: ["sous_classe_produit_industriel"],
  sous_classe_produit_cosmetique: ["classe_produit_cosmetique"],
  classe_produit_cosmetique: ["code_produit_cosmetique"],
  sous_classe_produit_industriel: ["classe_produit_industriel"],
  substance_chimique: ["groupe_chimique"],
  produits_engrais: ["matiere_active", "classe_engrais", "usage_engrais"],
  produit_medicament: ["dci", "forme", "code_atc"],
  produit_pesticide: [
    "matiere_active",
    "usage_pesticide",
    "forme",
    "classe_dangerosite",
  ],
};

function sortNomAlphabeticallyAscending(a, b) {
  if (a.nom < b.nom) return -1;
  else if (a.nom > b.nom) return 1;
  else return 0;
}
function sortCodeAlphabeticallyAscending(a, b) {
  if (a.code < b.code) return -1;
  else if (a.code > b.code) return 1;
  else return 0;
}



const getReferentielDependencies = (table) => {
  if (table in referentielDependencies) {
    return referentielDependencies[table];
  } else {
    return [];
  }
};
const getTablesByCategory = (category) => {
  return tablesByCategory[category];
};
const getTableEndPoint = (table) => {
  if (table in referentielEndPoints) {
    return referentielEndPoints[table];
  } else {
    new Error("Endpoint not identified");
  }
};

const flattenIdNomDict = (arrayOfDicts) => {
  let result = {};
  arrayOfDicts.map((elt) => (result[elt.id] = elt.nom.trim()));
  return result;
};

const flattenIdCodeDict = (arrayOfDicts) => {
  let result = {};
  arrayOfDicts.map((elt) => (result[elt.id] = elt.code.trim()));
  return result;
};


const flattenIdCouleurDict = (arrayOfDicts) => {
  let result = {};
  arrayOfDicts.map((elt) => (result[elt.id] = elt.couleur.trim()));
  return result;
};

const flattenIdNomMoleculeDict = (arrayOfDicts) => {
  let result = {};
  arrayOfDicts.map((elt) => (result[elt.id] = elt.nom_molecule.trim()));
  return result;
};

const flattenIdNomAlimentDict = (arrayOfDicts) => {
  let result = {};
  arrayOfDicts.map((elt) => (result[elt.id] = elt.nom_aliment.trim()));
  return result;
};

const flattenIdNomCommercialDict = (arrayOfDicts) => {
  let result = {};
  arrayOfDicts.map((elt) => (result[elt.id] = elt.nom_commercial.trim()));
  return result;
};

const getReferentielComponents = (table) => {
  if (table in referentielComponents) {
    return referentielComponents[table];
  } else {
    return referentielComponents["default"];
  }
};

const getFieldNames = (data) => {
  let singleObject = data[0];
  let fieldNames = [];
  for (const property in singleObject) {
    fieldNames.push(property);
  }
  return fieldNames;
};

const getFieldHeaderName = (fieldName) => {
  if (fieldName in fieldHeaderNamesDict) {
    return fieldHeaderNamesDict[fieldName];
  }
  return fieldName;
};

const getTableHeader = (table) => {
  if (table in tableHeaders) {
    return tableHeaders[table];
  }
  return table;
};

export {
  flattenIdNomMoleculeDict,
  flattenIdNomAlimentDict,
  flattenIdNomCommercialDict,
  getReferentielDependencies,
  getFieldNames,
  flattenIdNomDict,
  flattenIdCodeDict,
  getFieldHeaderName,
  getTableEndPoint,
  getReferentielComponents,
  getTableHeader,
  getTablesByCategory,
  flattenIdCouleurDict,
  referentielVigilances
};
