import React from "react"
import { Stack } from "@mui/material";
import AnalysteComponent from "../../../components/ViewCase/Labo/Analyste/Analyste";
import '../index.scss';

export default function Analyste(props) {


      return (
            <div className='view-case-common-container'>
                  <div className='view-case-common-component'>
                        <Stack direction="column" spacing={0}>
                              <div className='view-case-common-sub-header'>
                                    <div className='view-case-common-sub-header-title'>
                                          information sur l'analyste
                                    </div>
                              </div>
                              <div className='view-case-common-body'>
                                    <Stack direction="column" spacing={3}>
                                          <AnalysteComponent />
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}