import React, { useState } from "react";
import { Stack } from "@mui/material";
import RubriqueButton from "../../components/RubriqueMenu/RubriqueButton";
import ViewCases from "./ViewCases";
import { viewToxiCasesPermissions, viewLaboCasesPermissions } from "../../utils/permissions";
import { isAllowed } from "../../services/auth";
import "./index.scss";

export default function ViewCasesModule(props) {
  const { onEditMode } = props;
  const [categoryIndex, setCategoryIndex] = useState(-1);
  const categories = { 0: "Toxicovigilance", 1: "Laboratoire", 2: "Scorpion" };

  const handleClick = (newCategoryIndex) => {
    setCategoryIndex(newCategoryIndex);
  };

  return (
    <div className="view-cases-module-container">
      <Stack direction="column" spacing={0}>
        <div className="view-cases-module-title">
          <label>Sélectionner rubrique</label>
        </div>
        <div className="view-cases-module-buttons">
          <Stack direction="row" spacing={1}>
            {isAllowed(viewToxiCasesPermissions) && (
              <RubriqueButton
                type="toxicovigilance"
                active={categoryIndex === 0}
                onClick={() => handleClick(0)}
              />)
            }
            {isAllowed(viewLaboCasesPermissions) && (
              <RubriqueButton
                type="laboratoire"
                active={categoryIndex === 1}
                onClick={() => handleClick(1)}
              />)
            }
            {/* <RubriqueButton
              type="scorpion"
              active={categoryIndex == 2}
              onClick={() => handleClick(2)}
            /> */}
          </Stack>
        </div>
        <div className="view-cases-module-title">
          <label>Consulter un cas - {categories[categoryIndex]}</label>
        </div>
        <div className="view-cases-module-body">
          {categoryIndex > -1 &&
            (<ViewCases
              category={categories[categoryIndex]}
              onEditMode={onEditMode}
            />)
          }
        </div>
      </Stack>
    </div>
  );
}
