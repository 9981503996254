import React, { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ThemeProvider } from "@mui/material";

import gridTheme from "../GridTheme";
import CustomPagination from "../../Pagination";
import {
  rejectDeclaration,
  validateDeclaration,
} from "../../../services/cases/cases";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { flattenIdNomDict } from "../../../utils/referentielData";
import { useReferentiels } from "../../../services/referentiels/referentiels";
import {
  DataGridEyeIcon,
  DataGridValideIcon,
  DataGridCancelIcon,
} from "../../Icons/DataGridIcons";
import { createDataGridRows } from "../../../utils/dataGrid";
import "../index.scss";
import { useSnackbar } from "notistack";
import RejectCaseModal from "../../Modal/RejectCaseModal";

const columnWidth = 148;
const numeroColunmWidth = 79;
const columnStyleClass = "grid-header";
const cellStyleClass = "grid-cell-style";

export default function ValidateCaseDataGrid(props) {
  const {
    cases,
    onSetCases,
    filter,
    columnNames,
    fieldNames,
    loading,
    activeTab,
    preview,
    onSetSort,
    onPreview,
    onShowModal,
    onSetSkip,
    totalCases,
  } = props;

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState();
  const [rejectedCase, setRejectedCase] = useState(false);
  const { enqueueSnackbar: showMessage } = useSnackbar();
  const referentielResults = useReferentiels(["type_rapport", "repondeur", "delai_reponse", "analyste"]);
  const isLoading = referentielResults.some((result) => result.isLoading);

  const showCase = React.useCallback(
    (id) => () => {
      onPreview(id);
      onShowModal(true);
    },
    [cases]
  );

  const deleteCaseFromTable = (declarationId) => {
    const currentCase = cases.find((c) => c.id === declarationId);
    const currentCaseIndex = cases.indexOf(currentCase);
    const newCases = [...cases];

    newCases.splice(currentCaseIndex, 1);

    onSetCases(newCases);
    setShowRejectModal(false);
    setSelectedCase(undefined);
    setRejectedCase(undefined);
  };

  const validateCase = React.useCallback(
    (declarationId) => () => {
      validateDeclaration(declarationId, activeTab)
        .then(() => {
          deleteCaseFromTable(declarationId);
          showMessage("Cas validé avec succès", { variant: "success" });
        })
        .catch((error) => {
          showMessage(
            "Erreur lors de la validation du cas " + error.toString(),
            { variant: "error" }
          );
        });
    },
    [cases]
  );

  useEffect(() => {
    if (rejectedCase && selectedCase) {
      rejectDeclaration(selectedCase, activeTab)
        .then(() => {
          showMessage("Cas supprimé", { variant: "warning" });
          deleteCaseFromTable(selectedCase);
        })
        .catch((error) => {
          showMessage("Erreur lors du rejet du cas " + error.toString(), {
            variant: "error",
          });
        });
    }
  }, [rejectedCase]);

  const createColumns = (fieldNames, columnNames) => {
    let columns = [{ field: "id", hide: true }];
    for (let i = 0; i < fieldNames.length; i++) {
      let width = fieldNames[i] === "numero" ? numeroColunmWidth : columnWidth;
      let column = {
        field: fieldNames[i] === "repondeur_id" ? "test" : fieldNames[i],
        headerName: columnNames[i],
        flex: 1,
        headerClassName: columnStyleClass,
        cellClassName: cellStyleClass,
        sortable:
          fieldNames[i] === "numero_dossier" ||
            fieldNames[i] === "date_saisie" ||
            fieldNames[i] === "date_reception"
            ? true
            : false,
      };
      if (fieldNames[i] === "actions") {
        column.type = "actions";
        column.headerName = "Actions";
        column.width = numeroColunmWidth + 20;
        column.getActions = (params) => [
          <GridActionsCellItem
            icon={<DataGridEyeIcon />}
            label="Afficher"
            onClick={showCase(params.id)}
          />,
          <GridActionsCellItem
            icon={<DataGridValideIcon />}
            label="Valider"
            onClick={validateCase(params.id)}
          />,
          <GridActionsCellItem
            icon={<DataGridCancelIcon />}
            label="Annuler"
            onClick={() => {
              setShowRejectModal(true);
              setSelectedCase(params.id);
            }}
          />,
        ];
      }
      columns.push(column);
    }
    return columns;
  };
  if (isLoading) return <CustomLoader isLoading={isLoading} />;
  const typeRapports = flattenIdNomDict(referentielResults[0].data);
  const repondeurs = flattenIdNomDict(referentielResults[1].data);
  const delaisReponses = flattenIdNomDict(referentielResults[2].data);
  const analystes = flattenIdNomDict(referentielResults[3].data);

  let columns = createColumns(fieldNames, columnNames);
  const rows = createDataGridRows("cases", cases, filter, { "types_rapports": typeRapports, "repondeurs": repondeurs, "delais": delaisReponses, "analystes": analystes });
  const data = { rows, columns };
  return (
    <ThemeProvider theme={gridTheme}>
      <div style={{ height: 555 }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              {...data}
              disableColumnSelector={true}
              disableColumnMenu
              loading={loading}
              pagination
              pageSize={8}
              rowsPerPageOptions={[8]}
              rowCount={totalCases}
              paginationMode="server"
              onSortModelChange={(e) => {
                if (e && e[0]) {
                  onSetSort({
                    sort_order: e[0].sort === "asc" ? 0 : 1,
                    sort_column: e[0].field,
                  });
                }
              }}
              onPageChange={(newPage) => {
                onSetSkip(newPage * 8);
              }}
              components={{
                Pagination: CustomPagination,
              }}
            />
          </div>
        </div>
      </div>
      <RejectCaseModal
        showModal={showRejectModal}
        onShowModal={setShowRejectModal}
        selectedCase={selectedCase}
        onDelete={setRejectedCase}
      />
    </ThemeProvider>
  );
}
