import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getFamilleDrogueDetails } from "../../../../services/cases/cases";

export default function Drogue(props) {
    const { drogue, preview } = props;
    const [drogueDetails, setDrogueDetails] = useState(null);

    const referentielResults = useReferentiels([
        "famille_drogues",
        "voie_intoxication"
    ]);
    useEffect(() => {
        getFamilleDrogueDetails(drogue.famille_drogue_id).then((data) => {
            setDrogueDetails({
                "code": data.code
            });
        });
    }, [props]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading || drogueDetails === null) return <CustomLoader isLoading={isLoading} />;

    const produits = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);

    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Drogue</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(drogue.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom"
                                        value={drogue.nom}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Famille" value={produits[drogue.famille_drogue_id]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Code"
                                        value={drogueDetails.code}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Dose Administrée"
                                        value={
                                            drogue.dose_administree
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Dose toxique"
                                        value={
                                            drogue.dose_toxique
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[drogue.voie_intoxication_id]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Drogue Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
