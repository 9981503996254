import React from "react";
import { Stack, Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import '../../index.scss';
import LabelMenu from "../../../LabelMenu/LabelMenu";




export default function Repondeur(props) {
      const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
      const referentielResults = useReferentiels(['repondeur', 'delai_reponse']);
      const widthPercentage = '31%';
      const doubleWidth = '62%';
      const isLoading = referentielResults.some(result => result.isLoading)
      if (isLoading)
            return (<CustomLoader isLoading={isLoading} />)

      const repondeurs = flattenIdNomDict(referentielResults[0].data);
      const delaiReponses = flattenIdNomDict(referentielResults[1].data);
      return (
            <div className="case-common-container" >
                  <div className="case-common-container-title">
                        <label>Information sur le répondeur</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          {(parseInt(getIn(values, "declarant.rapport.type_rapport_id")) === 6 || parseInt(getIn(values, "declarant.rapport.type_rapport_id")) === 11) && (<div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Répondeur"}
                                                      name={"repondeur.repondeur_id"}
                                                      value={getIn(values, "repondeur.repondeur_id")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "repondeur.repondeur_id") && Boolean(getIn(errors, "repondeur.repondeur_id"))}
                                                      helperText={getIn(touched, "repondeur.repondeur_id") && getIn(errors, "repondeur.repondeur_id")}
                                                      valuesDict={repondeurs} />
                                          </div>
                                          )}
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Délai de réponse"}
                                                      name={"repondeur.delai_reponse_id"}
                                                      value={getIn(values, "repondeur.delai_reponse_id")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "repondeur.delai_reponse_id") && Boolean(getIn(errors, "repondeur.delai_reponse_id"))}
                                                      helperText={getIn(touched, "repondeur.delai_reponse_id") && getIn(errors, "repondeur.delai_reponse_id")}
                                                      valuesDict={delaiReponses} />
                                          </div>
                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: doubleWidth, height: '59px' }}>
                                                <FormControl component="fieldset" style={{ height: '100%' }}>
                                                      <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                            <div className="case-common-container-body-label">
                                                                  <label>Considérez-vous ce toxique comme un signal? </label>
                                                            </div>
                                                            <div className="case-common-container-body-radio">
                                                                  <RadioGroup row name={"repondeur.signal"}
                                                                        onChange={(event) => setFieldValue("repondeur.signal", event.target.value)}
                                                                        value={getIn(values, "repondeur.signal")}
                                                                        error={getIn(touched, "repondeur.signal") && Boolean(getIn(errors, "repondeur.signal"))}>
                                                                        <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                        <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                  </RadioGroup>
                                                            </div>
                                                      </Stack>
                                                      <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "repondeur.signal") && getIn(errors, "repondeur.signal")}</FormHelperText>
                                                </FormControl>
                                          </div>
                                    </Stack>
                              </div>

                        </Stack>
                  </div>
            </div>
      )
}