const typeDeclarantDict = {
  "": "",
  declarant1: "Déclarant 1",
  declarant2: "Déclarant 2",
  declarant3: "Déclarant 3",
};

const typeIntoxication = {
  0: "Isolée",
  1: "Collective",
  2: "Inconnu"
};

const relanceDict = {
  "1": "Fait",
  "0": "Non fait",
  "-1": "Impossible"
};

const typeRapportDict = {
  Actif: {
    1: "Autre",
    2: "Etudes",
    3: "Littérature",
    4: "Média",
  },
  Spontané: {
    5: "Autre",
    6: "Consultation sur place",
    7: "Courrier",
    8: "Dossier d'hospitalisation",
    9: "Fiche de déclaration d'un cas overdose",
    10: "Registre",
    11: "Réponse téléphonique",
    12: "Site Web",
  },
};

const typeRapportFlatDict = {
  1: "Autre",
  2: "Etudes",
  3: "Littérature",
  4: "Média",
  5: "Autre",
  6: "Consultation sur place",
  7: "Courrier",
  8: "Dossier d'hospitalisation",
  9: "Fiche de déclaration d'un cas overdose",
  10: "Registre",
  11: "Réponse téléphonique",
  12: "Site Web",
}

const produitDict = {
  "produit": "Produit",
  "plantes": "Plantes",
  "mineraux": "Minéraux",
  "cosmetique": "Consmétique",
  "pesticide": "Pesticide",
  "medicament": "Médicament",
  "phytotherapie": "Produit de  Phytothérapie",
  "inconnu": "Inconnu",
};

const autreDict = {
  "salive": "Salive",
  "cheveux": "Cheveux",
  "ongles": "Ongles"
};

const getYearsDict = () => {
  let yearsDict = {};
  let startYear = new Date().getFullYear() - 10;
  let endYear = startYear + 15;
  for (let year = startYear; year < endYear; year++) {
    yearsDict[year] = year;
  }
  return yearsDict;
};

const getMonthsDict = () => {
  let monthsDict = {};
  for (let month = 1; month < 13; month++) {
    monthsDict[month] = month;
  }
  return monthsDict;
};

const getTypeDeclarantDict = () => {
  return typeDeclarantDict;
};

const getTypeRapportDict = () => {
  return typeRapportDict;
};

export {
  getTypeDeclarantDict,
  getYearsDict,
  getMonthsDict,
  getTypeRapportDict,
  produitDict,
  autreDict,
  typeIntoxication,
  relanceDict,
  typeRapportFlatDict
};
