import React from "react";
import './index.scss';
import { getRoleColor, getRoleName } from "../../utils/rolesManagement";



export default function RoleName(props) {

      const roleType = props.type;
      let className="role-name";
      if(props.small ===true){
            className="role-name-small";
      }
      let roleName = getRoleName(roleType);
      let backgroundColor = getRoleColor(roleType);
      return (
            <div className={className} style={{ background: backgroundColor }}>
                  <label className={className + "-label"}>{roleName}</label>
            </div>
      )
}