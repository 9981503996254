import React, { useEffect } from "react";
import {
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelMultipleMenu from "../../../LabelMenu/LabelMultipleMenu";
import LabelTextArea from "../../../LabelTextArea";

import "../../index.scss";

export default function TraitementEffectue(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();

  const referentielResults = useReferentiels([
    "decontamination",
    "traitement_symptomatique",
    "epuration",
    "antidotes",
  ]);
  const widthPercentage = "31%";

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const decontaminations = flattenIdNomDict(referentielResults[0].data);
  const traitementSymptomatiques = flattenIdNomDict(referentielResults[1].data);
  const epurations = flattenIdNomDict(referentielResults[2].data);
  const antidotes = flattenIdNomDict(referentielResults[3].data);


  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Traitement effectué</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: '45%', height: '59px' }}>
                <FormControl component="fieldset" style={{ height: '100%' }}>
                  <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                    <div className="case-common-container-body-label">
                      <label>Précisé: </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup row name={"intoxication.traitement_effectue.precise"}
                        onChange={(event) => setFieldValue("intoxication.traitement_effectue.precise", event.target.value)}
                        value={getIn(values, "intoxication.traitement_effectue.precise")}
                        error={getIn(touched, "intoxication.traitement_effectue.precise") && Boolean(getIn(errors, "intoxication.traitement_effectue.precise"))}>
                        <FormControlLabel value="1" control={<Radio />} label="Oui" />
                        <FormControlLabel value="0" control={<Radio />} label="Non" />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.traitement_effectue.precise") && getIn(errors, "intoxication.traitement_effectue.precise")}</FormHelperText>
                </FormControl>
              </div>
            </Stack>
          </div>
          {
            parseInt(getIn(values, "intoxication.traitement_effectue.precise")) === 1 &&
            <>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: "45%", height: "59px" }}>
                    <FormControl component="fieldset" style={{ height: "100%" }}>
                      <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                        <div className="case-common-container-body-label">
                          <label>
                            Orientation:
                          </label>
                        </div>
                        <div className="case-common-container-body-radio">
                          <RadioGroup
                            row
                            name={"intoxication.traitement_effectue.orientation"}
                            onChange={(event) =>
                              setFieldValue(
                                "intoxication.traitement_effectue.orientation",
                                event.target.value
                              )
                            }
                            value={getIn(
                              values,
                              "intoxication.traitement_effectue.orientation"
                            )}
                            error={
                              getIn(
                                touched,
                                "intoxication.traitement_effectue.orientation"
                              ) &&
                              Boolean(
                                getIn(
                                  errors,
                                  "intoxication.traitement_effectue.orientation"
                                )
                              )
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Non"
                            />
                          </RadioGroup>
                        </div>
                      </Stack>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "14px" }}
                      >
                        {getIn(
                          touched,
                          "intoxication.traitement_effectue.orientation"
                        ) &&
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.orientation"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div style={{ width: "45%", height: "59px" }}>
                    <FormControl component="fieldset" style={{ height: "100%" }}>
                      <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                        <div className="case-common-container-body-label">
                          <label>
                            Abstention thérapeutique:
                          </label>
                        </div>
                        <div className="case-common-container-body-radio">
                          <RadioGroup
                            row
                            name={
                              "intoxication.traitement_effectue.abstention_therapeutique"
                            }
                            onChange={(event) =>
                              setFieldValue(
                                "intoxication.traitement_effectue.abstention_therapeutique",
                                event.target.value
                              )
                            }
                            value={getIn(
                              values,
                              "intoxication.traitement_effectue.abstention_therapeutique"
                            )}
                            error={
                              getIn(
                                touched,
                                "intoxication.traitement_effectue.abstention_therapeutique"
                              ) &&
                              Boolean(
                                getIn(
                                  errors,
                                  "intoxication.traitement_effectue.abstention_therapeutique"
                                )
                              )
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Non"
                            />
                          </RadioGroup>
                        </div>
                      </Stack>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "14px" }}
                      >
                        {getIn(
                          touched,
                          "intoxication.traitement_effectue.abstention_therapeutique"
                        ) &&
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.abstention_therapeutique"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: widthPercentage }}>
                    <LabelMultipleMenu
                      title={"Décontamination"}
                      name={"intoxication.traitement_effectue.decontaminations"}
                      initialValues={getIn(
                        values,
                        "intoxication.traitement_effectue.decontaminations"
                      )}
                      onChange={setFieldValue}
                      error={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.decontaminations"
                        ) &&
                        Boolean(
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.decontaminations"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.decontaminations"
                        ) &&
                        getIn(
                          errors,
                          "intoxication.traitement_effectue.decontaminations"
                        )
                      }
                      disabled={parseInt(getIn(values, "intoxication.traitement_effectue.abstention_therapeutique")) === 1}
                      valuesDict={decontaminations}
                    />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelMenu
                      title={"Traitement symptomatique"}
                      name={
                        "intoxication.traitement_effectue.traitement_symptomatique_id"
                      }
                      value={getIn(
                        values,
                        "intoxication.traitement_effectue.traitement_symptomatique_id"
                      )}
                      disabled={parseInt(getIn(values, "intoxication.traitement_effectue.abstention_therapeutique")) === 1}
                      onChange={handleChange}
                      error={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.traitement_symptomatique_id"
                        ) &&
                        Boolean(
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.traitement_symptomatique_id"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.traitement_symptomatique_id"
                        ) &&
                        getIn(
                          errors,
                          "intoxication.traitement_effectue.traitement_symptomatique_id"
                        )
                      }
                      valuesDict={traitementSymptomatiques}
                    />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelMultipleMenu
                      title={"Epuration"}
                      name={"intoxication.traitement_effectue.epurations"}
                      initialValues={getIn(
                        values,
                        "intoxication.traitement_effectue.epurations"
                      )}
                      onChange={setFieldValue}
                      error={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.epurations"
                        ) &&
                        Boolean(
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.epurations"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.epurations"
                        ) &&
                        getIn(errors, "intoxication.traitement_effectue.epurations")
                      }
                      disabled={parseInt(getIn(values, "intoxication.traitement_effectue.abstention_therapeutique")) === 1}
                      valuesDict={epurations}
                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: widthPercentage }}>
                    <LabelMultipleMenu
                      title={"Antidotes"}
                      name={"intoxication.traitement_effectue.antidotes"}
                      initialValues={getIn(
                        values,
                        "intoxication.traitement_effectue.antidotes"
                      )}
                      onChange={setFieldValue}
                      error={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.antidotes"
                        ) &&
                        Boolean(
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.antidotes"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.antidotes"
                        ) &&
                        getIn(errors, "intoxication.traitement_effectue.antidotes")
                      }
                      disabled={parseInt(getIn(values, "intoxication.traitement_effectue.abstention_therapeutique")) === 1}
                      valuesDict={antidotes}
                    />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelInput
                      title={"Consultation spécialisée"}
                      name={
                        "intoxication.traitement_effectue.consultation_specialisee"
                      }
                      value={getIn(
                        values,
                        "intoxication.traitement_effectue.consultation_specialisee"
                      )}
                      onChange={handleChange}
                      error={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.consultation_specialisee"
                        ) &&
                        Boolean(
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.consultation_specialisee"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.consultation_specialisee"
                        ) &&
                        getIn(
                          errors,
                          "intoxication.traitement_effectue.consultation_specialisee"
                        )
                      }
                      disabled={parseInt(getIn(values, "intoxication.traitement_effectue.abstention_therapeutique")) === 1}
                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: "45%", height: "59px" }}>
                    <FormControl component="fieldset" style={{ height: "100%" }}>
                      <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                        <div className="case-common-container-body-label">
                          <label>
                            Surveillance:
                          </label>
                        </div>
                        <div className="case-common-container-body-radio">
                          <RadioGroup
                            row
                            name={"intoxication.traitement_effectue.surveillance"}
                            onChange={(event) =>
                              setFieldValue(
                                "intoxication.traitement_effectue.surveillance",
                                event.target.value
                              )
                            }
                            value={getIn(
                              values,
                              "intoxication.traitement_effectue.surveillance"
                            )}
                            error={
                              getIn(
                                touched,
                                "intoxication.traitement_effectue.surveillance"
                              ) &&
                              Boolean(
                                getIn(
                                  errors,
                                  "intoxication.traitement_effectue.surveillance"
                                )
                              )
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Non"
                            />
                          </RadioGroup>
                        </div>
                      </Stack>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "14px" }}
                      >
                        {getIn(
                          touched,
                          "intoxication.traitement_effectue.surveillance"
                        ) &&
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.surveillance"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div style={{ width: "45%", height: "59px" }}>
                    <FormControl component="fieldset" style={{ height: "100%" }}>
                      <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                        <div className="case-common-container-body-label">
                          <label>
                            Bilan radiologique:
                          </label>
                        </div>
                        <div className="case-common-container-body-radio">
                          <RadioGroup
                            row
                            name={
                              "intoxication.traitement_effectue.bilan_radiologique"
                            }
                            onChange={(event) =>
                              setFieldValue(
                                "intoxication.traitement_effectue.bilan_radiologique",
                                event.target.value
                              )
                            }
                            value={getIn(
                              values,
                              "intoxication.traitement_effectue.bilan_radiologique"
                            )}
                            error={
                              getIn(
                                touched,
                                "intoxication.traitement_effectue.bilan_radiologique"
                              ) &&
                              Boolean(
                                getIn(
                                  errors,
                                  "intoxication.traitement_effectue.bilan_radiologique"
                                )
                              )
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Non"
                            />
                          </RadioGroup>
                        </div>
                      </Stack>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "14px" }}
                      >
                        {getIn(
                          touched,
                          "intoxication.traitement_effectue.bilan_radiologique"
                        ) &&
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.bilan_radiologique"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: "45%", height: "59px" }}>
                    <FormControl component="fieldset" style={{ height: "100%" }}>
                      <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                        <div className="case-common-container-body-label">
                          <label>
                            Bilan biologique:
                          </label>
                        </div>
                        <div className="case-common-container-body-radio">
                          <RadioGroup
                            row
                            name={
                              "intoxication.traitement_effectue.bilan_biologique"
                            }
                            onChange={(event) =>
                              setFieldValue(
                                "intoxication.traitement_effectue.bilan_biologique",
                                event.target.value
                              )
                            }

                            value={getIn(
                              values,
                              "intoxication.traitement_effectue.bilan_biologique"
                            )}
                            error={
                              getIn(
                                touched,
                                "intoxication.traitement_effectue.bilan_biologique"
                              ) &&
                              Boolean(
                                getIn(
                                  errors,
                                  "intoxication.traitement_effectue.bilan_biologique"
                                )
                              )
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Non"
                            />
                          </RadioGroup>
                        </div>
                      </Stack>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "14px" }}
                      >
                        {getIn(
                          touched,
                          "intoxication.traitement_effectue.bilan_biologique"
                        ) &&
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.bilan_biologique"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div style={{ width: "45%", height: "59px" }}>
                    <FormControl component="fieldset" style={{ height: "100%" }}>
                      <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                        <div className="case-common-container-body-label">
                          <label>
                            Bilan toxicologique:
                          </label>
                        </div>
                        <div className="case-common-container-body-radio">
                          <RadioGroup
                            row
                            name={
                              "intoxication.traitement_effectue.bilan_toxicologique"
                            }
                            onChange={(event) =>
                              setFieldValue(
                                "intoxication.traitement_effectue.bilan_toxicologique",
                                event.target.value
                              )
                            }
                            value={getIn(
                              values,
                              "intoxication.traitement_effectue.bilan_toxicologique"
                            )}
                            error={
                              getIn(
                                touched,
                                "intoxication.traitement_effectue.bilan_toxicologique"
                              ) &&
                              Boolean(
                                getIn(
                                  errors,
                                  "intoxication.traitement_effectue.bilan_toxicologique"
                                )
                              )
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Non"
                            />
                          </RadioGroup>
                        </div>
                      </Stack>
                      <FormHelperText
                        style={{ color: "#d32f2f", marginLeft: "14px" }}
                      >
                        {getIn(
                          touched,
                          "intoxication.traitement_effectue.bilan_toxicologique"
                        ) &&
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.bilan_toxicologique"
                          )}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: widthPercentage }}>
                    <LabelInput
                      title={"Numéro du cas laboratoire"}
                      name={"intoxication.traitement_effectue.nombre_cas"}
                      value={getIn(
                        values,
                        "intoxication.traitement_effectue.nombre_cas"
                      )}
                      onChange={handleChange}
                      error={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.nombre_cas"
                        ) &&
                        Boolean(
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.nombre_cas"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.nombre_cas"
                        ) &&
                        getIn(errors, "intoxication.traitement_effectue.nombre_cas")
                      }

                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div
                    style={{
                      marginRight: "12px",
                      width: "100%",
                      marginLeft: "-5px",
                    }}
                  >
                    <LabelTextArea
                      title={"Commentaires sur le traitement et le bilan effectué"}
                      name={"intoxication.traitement_effectue.resultat_synthese"}
                      value={getIn(
                        values,
                        "intoxication.traitement_effectue.resultat_synthese"
                      )}
                      onChange={handleChange}
                      error={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.resultat_synthese"
                        ) &&
                        Boolean(
                          getIn(
                            errors,
                            "intoxication.traitement_effectue.resultat_synthese"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          touched,
                          "intoxication.traitement_effectue.resultat_synthese"
                        ) &&
                        getIn(
                          errors,
                          "intoxication.traitement_effectue.resultat_synthese"
                        )
                      }

                    />
                  </div>
                </Stack>
              </div>
            </>}
        </Stack>
      </div>
    </div >
  );
}
