
import React, { useContext, useState } from "react";
import { useFormikContext } from "formik";
import { CaseContext } from "../../../Modal/PreviewCaseModal";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { Fragment } from "react";
import { useEffect } from "react";
import { getMedDRASymptomes } from "../../../../services/cases/cases";



export default function Symptomologie(props) {
    const value = useFormikContext();
    const { values } = value || {};
    const selectedCase = useContext(CaseContext);
    const referentielResults = useReferentiels(["gradation", "type_risque", "terme_reaction"]);
    const [hltNames, setHltNames] = useState([]);

    const symptomatologie = selectedCase
        ? selectedCase.intoxication
        : values.intoxication?.symptomatologie;

    useEffect(() => {
        getMedDRASymptomes(symptomatologie?.termes).then((data) => {
            setHltNames(data);
        })
    }, []);

    const relationDict = { "1": "Oui", "0": "Non", "-1": "Inconnue", "-2": "Non précisée" }
    const widthPercentage = "31%";
    const isLoading = referentielResults.some((result) => result.isLoading);

    if (isLoading) return <CustomLoader isLoading={isLoading} />;

    const gradations = flattenIdNomDict(referentielResults[0].data);
    const typeRisques = flattenIdNomDict(referentielResults[1].data);
    const termesDict = flattenIdNomDict(referentielResults[2].data);

    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>La Symptomatologie (MedDRA)</label>
            </div>
            <div className="view-case-common-container-body">
                <Stack direction="column" spacing={4}>
                    <div>
                        <Stack direction="row" spacing={3}>
                            <div style={{ width: widthPercentage }}>
                                <LabelInfo
                                    title="Symptomatique"
                                    value={symptomatologie && parseInt(symptomatologie?.symptomatique) === 1 ? "Oui" : "Non"}
                                />
                            </div>
                        </Stack>
                    </div>
                    {symptomatologie && parseInt(symptomatologie?.symptomatique) === 1 && (
                        <Fragment>
                            <div>
                                <Stack direction="row" spacing={3}>
                                    <div style={{ width: widthPercentage }}>
                                        <LabelInfo
                                            title="Terme de la réaction"
                                            value={symptomatologie && termesDict[parseInt(symptomatologie?.terme_reaction_id)]}
                                        />
                                    </div>
                                </Stack>
                            </div>
                            <div>
                                <Stack direction="row" spacing={3}>
                                    <div style={{ width: "90%" }}>
                                        <LabelInfo
                                            title="Symptômes"
                                            value={symptomatologie && hltNames.join(', ')}
                                        />
                                    </div>
                                </Stack>
                            </div>
                        </Fragment>
                    )}
                    <div>
                        <Stack direction="row" spacing={3}>
                            <div style={{ width: widthPercentage }}>
                                <LabelInfo
                                    title="Relation entre toxique et symptomatologie"
                                    value={symptomatologie && relationDict[String(symptomatologie?.relation_toxique_symptomologie)]}
                                />
                            </div>
                            <div style={{ width: widthPercentage }}>
                                <LabelInfo
                                    title="Risque à l'appel"
                                    value={symptomatologie && typeRisques[parseInt(symptomatologie?.type_risque_id)]}
                                />
                            </div>
                            <div style={{ width: widthPercentage }}>
                                <LabelInfo
                                    title="Gradation initiale"
                                    value={symptomatologie && gradations[parseInt(symptomatologie?.gradation_initiale_id)]}
                                />
                            </div>
                        </Stack>
                    </div>



                </Stack>
            </div>
        </div>
    );
}