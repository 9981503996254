import { id } from "date-fns/locale";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import defaultAvatar from "../../assets/default_avatar.svg";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


function arrayRemove(array, value) {

    return array.filter(function (ele) {
        return ele !== value;
    });
}

function arrayFilter(array, type) {
    return array.filter(function (ele) {
        return ele.type === type
    });
}

function getAvatar(avatarFileName) {
    if (avatarFileName === null || avatarFileName === "default.jpg") {
        return defaultAvatar
    }
    return avatarFileName
}

const natureCorrepondance = {
    "sang": "Sang",
    "urines": "Urines",
    "lg": "LG (Lavage Gastrique)",
    "lcr": "LCR (Liquide Céphalo-Rachidien)",
    "produit": "Produit",
    "plantes": "Plantes",
    "mineraux": "Minéraux",
    "cosmetique": "Consmétique",
    "pesticide": "Pesticide",
    "medicament": "Médicament",
    "phytotherapie": "Produit de  Phytothérapie",
    "inconnu": "Inconnu",
    "salive": "Salive",
    "cheveux": "Cheveux",
    "ongles": "Ongles"
}

const natureIds = {
    1: 'sang',
    2: 'urines',
    3: 'lg',
    4: 'lcr',
    5: 'produit',
    101: 'plantes',
    102: 'mineraux',
    103: 'cosmetique',
    104: 'pesticide',
    105: 'medicament',
    106: 'phytotherapie',
    107: 'inconnu',
    108: 'salive',
    109: 'cheveux',
    110: 'ongles'
}

const laboDosageUnits = {
    "%": "%",
    "U/g": "U/g",
    "U/L": "U/L",
    "g/L": "g/L",
    "mg/L": "mg/L",
    "mg/g": "mg/g",
    "μg/L": "μg/L",
    "μg/g": "μg/g",
    "ng/L": "ng/L",
    "pg/L": "pg/L",
    "UI": "UI"
}

const scorpionClasses = {
    "Classe 1": "Classe 1",
    "Classe 2": "Classe 2",
    "Classe 3": "Classe 3",
}

function getFEFormatResultat(beResultats, entreeNoms) {
    let resultat = {
        LC: {
            recherche_dosage: beResultats?.LC?.recherche_dosage,
            resultat_analyse: beResultats?.LC?.resultat_analyse,
            produits_testes: beResultats?.LC?.produits_testes?.map((produit_teste) => ({
                checked: true,
                produit: entreeNoms[produit_teste.entree_nom_id],
                molecules: produit_teste.molecules_dosages?.map((molecule_dosage) => (molecule_dosage?.molecule)),
                dosages: produit_teste.molecules_dosages?.map((molecule_dosage) => ({
                    sign: molecule_dosage?.sign,
                    unit: molecule_dosage?.unit,
                    value: molecule_dosage?.value
                })),
            }))
        },
        PTRR: {
            resultat_analyse: beResultats?.PTRR?.resultat_analyse,
            resultats_dosage: beResultats?.PTRR?.resultats_dosage?.map((resultat_dosage) => ({
                checked: true,
                analyse: entreeNoms[resultat_dosage?.entree_nom_id],
                dosage: {
                    sign: resultat_dosage?.sign,
                    unit: resultat_dosage?.unit,
                    value: resultat_dosage?.value
                }
            })),
            resultats_recherche: beResultats?.PTRR?.resultats_recherche?.map((resultat_recherche) => ({
                checked: true,
                analyse: entreeNoms[resultat_recherche?.entree_nom_id],
                result: resultat_recherche?.is_positive
            })),
        },
        SAA: {
            resultat_analyse: beResultats?.SAA?.resultat_analyse,
            resultats: beResultats?.SAA?.resultats?.map((resultat) => ({
                checked: true,
                analyse: entreeNoms[resultat?.entree_nom_id],
                dosage: {
                    sign: resultat?.sign,
                    unit: resultat?.unit,
                    value: resultat?.value
                }
            })),
        },
        GC: {
            recherche_dosage: beResultats?.GC?.recherche_dosage,
            resultat_analyse: beResultats?.GC?.resultat_analyse,
            produits_testes: beResultats?.GC?.produits_testes?.map((produit_teste) => ({
                checked: true,
                produit: entreeNoms[produit_teste?.entree_nom_id],
                molecules: produit_teste?.molecules_dosages?.map((molecule_dosage) => (molecule_dosage?.molecule)),
                dosages: produit_teste?.molecules_dosages?.map((molecule_dosage) => ({
                    sign: molecule_dosage?.sign,
                    unit: molecule_dosage?.unit,
                    value: molecule_dosage?.value
                })),
            }))
        }
    }
    return resultat;
}

function getFEFormatEchantillons(echantillons) {
    const echantillonObj = {
        checked: false,
        problem_rencontre: "",
        conformite: "",
        lc: false,
        gc: false,
        ptrr: false,
        saa: false
    }
    let result = {
        sang: { ...echantillonObj },
        urines: { ...echantillonObj },
        lg: { ...echantillonObj },
        lcr: { ...echantillonObj },
        produit: { ...echantillonObj, name: "" },
        autres: { ...echantillonObj, name: "" }
    }
    for (let echantillonIdx in echantillons) {
        let echantillon = echantillons[echantillonIdx];
        let resultKey = null;
        if (echantillon.nature_id <= 4) {
            resultKey = natureIds[echantillon.nature_id];
        } else if (echantillon.nature_id > 5 && echantillon.nature_id <= 107) {
            resultKey = "produit";
        } else if (echantillon.nature_id > 107 && echantillon.nature_id <= 110) {
            resultKey = "autres";
        }
        result[resultKey].checked = true;
        result[resultKey].problem_rencontre = echantillon.problem_rencontre;
        result[resultKey].conformite = echantillon.conformite;
        result[resultKey].lc = echantillon.lc;
        result[resultKey].gc = echantillon.gc;
        result[resultKey].ptrr = echantillon.ptrr;
        result[resultKey].saa = echantillon.saa;
        if (resultKey === "produit" || resultKey === "autres") {
            result[resultKey].name = natureIds[echantillon.nature_id];
        }
    }
    return result;
}

function getBEFormatEchantillons(echantillons) {
    let filtredEchantillons = [];
    for (let ech in echantillons) {

        if (echantillons[ech].checked === true) {
            let obj = {
                "nature": natureCorrepondance[ech],
                "problem_rencontre": echantillons[ech].problem_rencontre,
                "conformite": echantillons[ech].conformite,
                "lc": echantillons[ech].lc === false ? 0 : 1,
                "gc": echantillons[ech].gc === false ? 0 : 1,
                "ptrr": echantillons[ech].ptrr === false ? 0 : 1,
                "saa": echantillons[ech].saa === false ? 0 : 1,
            }
            if (ech === "produit" || ech === "autres") {
                obj.nature = natureCorrepondance[echantillons[ech].name]
            }
            filtredEchantillons.push(obj);
        }
    }

    return filtredEchantillons;
}




function getBEFormatProduitTeste(produitsTestes) {

    let filtredProduits = [];
    for (let idxProduit in produitsTestes) {
        let produitTeste = produitsTestes[idxProduit];

        if (produitTeste.checked === true) {
            let molecules_dosages = [];
            for (let i = 0; i < produitTeste.molecules.length; i++) {
                molecules_dosages.push({
                    "molecule": produitTeste.molecules[i],
                    "sign": produitTeste.dosages[i].sign,
                    "value": produitTeste.dosages[i].value.toString().replace(',', '.'),
                    "unit": produitTeste.dosages[i].unit,
                })
            }
            filtredProduits.push({
                "entree_nom": produitTeste.produit,
                "molecules_dosages": molecules_dosages
            })
        }
    }
    return filtredProduits;
}

function getBEFormatProduitDosage(produitsDosages) {
    let filtredDosages = [];
    for (let idxDosage in produitsDosages) {
        let produitDosage = produitsDosages[idxDosage];

        if (produitDosage.checked === true) {
            filtredDosages.push({
                "entree_nom": produitDosage.analyse,
                "sign": produitDosage.dosage.sign,
                "value": produitDosage.dosage.value.replace(',', '.'),
                "unit": produitDosage.dosage.unit,
            })
        }
    }
    return filtredDosages;
}

function getBEFormatResultatRecherche(produitsRecherche) {
    let filtredRecherches = [];
    for (let idxRecherche in produitsRecherche) {
        let produitRecherche = produitsRecherche[idxRecherche];

        if (produitRecherche.checked === true) {
            filtredRecherches.push({
                "entree_nom": produitRecherche.analyse,
                "is_positive": produitRecherche.result
            })
        }
    }
    return filtredRecherches;
}

function printPdf(sectionName, fileName, setPdfLoading) {
    setPdfLoading(true);
    const section = document.getElementById(sectionName);
    html2canvas(section, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        scrollY: 0,
    }).then((canvas) => {
        const image = { type: "jpeg", quality: 0.98 };
        const margin = [0.2, 0.2];

        var imgWidth = 8.5;
        var pageHeight = 10.6;

        var innerPageWidth = imgWidth - margin[0] * 2;
        var innerPageHeight = pageHeight - margin[1] * 2;

        // Calculate the number of pages.
        var pxFullHeight = canvas.height;
        var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        var nPages = Math.ceil(pxFullHeight / pxPageHeight);

        // Define pageHeight separately so it can be trimmed on the final page.
        var pageHeight = innerPageHeight;

        // Create a one-page canvas to split up the full image.
        var pageCanvas = document.createElement("canvas");
        var pageCtx = pageCanvas.getContext("2d");
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        // Initialize the PDF.
        var pdf = new jsPDF("p", "in", [8.5, 10.6]);

        for (var page = 0; page < nPages; page++) {
            // Trim the final page to reduce file size.
            if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                pageCanvas.height = pxFullHeight % pxPageHeight;
                pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            }

            // Display the page.
            var w = pageCanvas.width;
            var h = pageCanvas.height;
            pageCtx.fillStyle = "white";
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

            // Add the page to the PDF.
            if (page > 0) pdf.addPage();
            debugger;
            var imgData = pageCanvas.toDataURL(
                "image/" + image.type,
                image.quality
            );

            pdf.addImage(
                imgData,
                image.type,
                margin[1],
                margin[0],
                innerPageWidth,
                pageHeight
            );
        }

        setPdfLoading(false);
        pdf.save(fileName);
    });
}


export { scorpionClasses, phoneRegExp, laboDosageUnits, printPdf, arrayRemove, natureCorrepondance, arrayFilter, getAvatar, getFEFormatResultat, getBEFormatEchantillons, getBEFormatProduitTeste, getBEFormatProduitDosage, getBEFormatResultatRecherche, getFEFormatEchantillons }