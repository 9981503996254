import React from "react";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { ThemeProvider } from "@mui/material";
import gridTheme from "../GridTheme";
import CustomPagination from '../../Pagination';
import { DataGridEyeIcon, DataGridDeleteIcon } from "../../Icons/DataGridIcons";
import { createDataGridRows } from "../../../utils/dataGrid";
import RoleName from "../../RoleName/RoleName";
import NomPrenomAvatar from "./NomPrenomAvatar";
import '../index.scss';
import { useReferentiel } from "../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../utils/referentielData";

import CustomLoader from "../../CustomLoader/CustomLoader";


const columnWidths = { 'nom_prenom': 250, 'email': 250, 'region_id': 200, 'role_id': 250, 'actions': 106 };

const columnStyleClass = 'grid-header';
const cellStyleClass = 'grid-cell-style';
const fieldNames = ['nom_prenom', 'email', 'region_id', 'role_id', 'actions'];
const columnNames = ['Nom et prénom', 'Email', 'Région', 'Rôle', 'Actions'];



export default function UserManagementDataGrid(props) {
  const { users, filter, onShowUser, onDeleteUser } = props;
  const { data, isLoading, isError, error, refetch } = useReferentiel('region');

  const deleteUser = React.useCallback(
    (id) => () => {
      onDeleteUser(id);
    },
    [users],
  )

  const showUser = React.useCallback(
    (id) => () => {
      const currentUser = users.find(x => x.id === id);
      onShowUser(currentUser);
    },
    [users],
  );
  const createColumns = (fieldNames, columnNames) => {
    let columns = [{ field: 'id', hide: true }, { field: 'avatar_filename', hide: true }];
    for (let i = 0; i < fieldNames.length; i++) {
      let width = fieldNames[i] === 'numero' ? 80 : columnWidths[fieldNames[i]];
      let column = {
        field: fieldNames[i],
        headerName: columnNames[i],
        width: width, headerClassName: columnStyleClass, cellClassName: cellStyleClass
      }

      if (fieldNames[i] === 'nom_prenom') {
        column.renderCell = (params) => (
          <NomPrenomAvatar nom_prenom={params.getValue(params.id, 'nom_prenom')} avatar={params.getValue(params.id, 'avatar_filename')} />
        );
      }
      if (fieldNames[i] === 'role_id') {
        column.renderCell = (params) => (
          <RoleName type={params.value} />
        );
      }
      if (fieldNames[i] === 'region_id') {
        column.renderCell = (params) => {
          return <label>{regions[params.value]}</label>
        };
      }
      if (fieldNames[i] === 'actions') {
        column.type = 'actions';
        column.headerName = 'Actions';
        column.getActions = (params) => [
          <GridActionsCellItem
            icon={<DataGridEyeIcon />}
            label="Afficher"
            onClick={showUser(params.id)}
          />,
          <GridActionsCellItem
            icon={<DataGridDeleteIcon />}
            label="Supprimer"
            onClick={deleteUser(params.id)}
          />
        ]
      }
      columns.push(column);
    }
    return columns;
  };
  if (isLoading) {
    return (<CustomLoader isLoading={isLoading} />)
  }
  const regions = flattenIdNomDict(data);
  const rows = createDataGridRows("NA", users, filter);
  let columns = createColumns(fieldNames, columnNames);
  const data2 = { rows, columns }
  return (
    <ThemeProvider theme={gridTheme}>
      <div style={{ height: 550 }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }} >
            <DataGrid {...data2}
              disableColumnSelector={true}
              pagination
              pageSize={8}
              rowsPerPageOptions={[8]}
              components={{
                Pagination: CustomPagination,
              }}
            />

          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

