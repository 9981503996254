import React, {useContext} from "react";
import { Stack } from "@mui/material";
import ExtractionChoixDates from "../../components/ExtractionChoixDates/ExtractionChoixDates";
import { ExtractDataContext } from "../../contextes/ExtractDataContext";
import './index.scss';

export default function ExtractScorpionData(props) {
      const {setStartDate, setEndDate, handleExtractClick} = useContext(ExtractDataContext);

      const onStartDateChange = (name, newValue) => {
            setStartDate(newValue);
      }

      const onEndDateChange = (name, newValue) => {
            setEndDate(newValue);
      }

      return (
            <div className="extract-data-container-root">
                  <div className="extract-data-container">
                        <div className="extract-data-main">
                              <Stack direction="row" spacing={0}>
                                    <div className="extract-data-menu" style={{maxWidth:'400px'}}>
                                          <ExtractionChoixDates 
                                                onClick={handleExtractClick}
                                                onStartDateChange={onStartDateChange} 
                                                onEndDateChange={onEndDateChange} 
                                          />
                                    </div>
                              </Stack>
                        </div>
                  </div>
            </div>
      )
}