import React from "react";
import { Box } from "@mui/system";
import { Stack, IconButton, Button, Divider } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useFormik } from 'formik';
import LabelInput from "../../LabelInput/LabelInput";
import LabelMenu from "../../LabelMenu/LabelMenu";
import { UserViewCloseIcon } from "../../Icons/Icons";
import { groupeAgeSchema } from "../../../utils/validationSchemas/others/formValidationSchemas";
import '../index.scss';

const style = {
      position: 'absolute',
      width: 621,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'

}

const typeDict = {
      'Ans': 'Ans',
      'Jours': 'Jours',
      'Mois': 'Mois',
}

export default function GroupeAgeModal(props) {
      const groupeAge = props.selectedRow;
      const onEdit = props.onEdit;
      const onClose = props.onClose;
      const inputBackground = '#FFFFFF';
      const formik = useFormik({
            initialValues: {
                  id: groupeAge?.id,
                  nom: groupeAge?.nom,
                  debut_age: groupeAge?.debut_age,
                  fin_age: groupeAge?.fin_age,
                  unite_age: groupeAge?.unite_age,

            },
            validationSchema: groupeAgeSchema,
            onSubmit: (values) => {
                  onEdit(values);
            },
      });

      return (
            <Box sx={{ ...style }}>
                  <div>
                        <Stack direction="column" spacing={0} >
                              <div>
                                    <div className="referentiel-modal-container">
                                          <Stack direction="column" spacing={0}>
                                                <div className="referentiel-modal-header">
                                                      <Stack direction="row" spacing={0} style={{ alignItems: 'center', width: '100%' }}>
                                                            <div>
                                                                  <ModeEditIcon style={{ color: "#367BF5" }} />
                                                            </div>
                                                            <div className="referentiel-modal-header-title">
                                                                  <label>Modifier Groupe d'Age</label>
                                                            </div>
                                                            <div className="referentiel-modal-close" >
                                                                  <IconButton style={{ padding: 0 }} onClick={onClose}>
                                                                        <UserViewCloseIcon />
                                                                  </IconButton>
                                                            </div>
                                                      </Stack>
                                                </div>
                                                <Divider />
                                                <form onSubmit={formik.handleSubmit}>
                                                      <div className="referentiel-modal-body">
                                                            <Stack direction="column" spacing={6}>
                                                                  <div style={{ width: '100%' }}>
                                                                        <Stack direction="row" spacing={4} style={{ width: '100%' }}>
                                                                              <div style={{ width: '50%' }}>
                                                                                    <LabelInput title={'Nom:'} name={"nom"} value={formik.values.nom}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.nom && Boolean(formik.errors.nom)}
                                                                                          helperText={formik.touched.nom && formik.errors.nom}
                                                                                          inputBackground={inputBackground} />
                                                                              </div>
                                                                              <div style={{ width: '50%' }}>
                                                                                    <LabelMenu title='Unité:' name={"unite_age"} value={formik.values.unite_age}
                                                                                          onChange={formik.handleChange}
                                                                                          valuesDict={typeDict} />
                                                                              </div>
                                                                        </Stack>
                                                                  </div>
                                                                  <div style={{ width: '100%' }}>
                                                                        <Stack direction="row" spacing={4} style={{ width: '100%' }}>
                                                                              <div style={{ width: '50%' }}>
                                                                                    <LabelInput title={'Début:'} name={"debut_age"} value={formik.values.debut_age}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.debut_age && Boolean(formik.errors.debut_age)}
                                                                                          helperText={formik.touched.debut_age && formik.errors.debut_age}
                                                                                          inputBackground={inputBackground} />
                                                                              </div>
                                                                              <div style={{ width: '50%' }}>
                                                                                    <LabelInput title={'Fin:'} name={"fin_age"} value={formik.values.fin_age}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.fin_age && Boolean(formik.errors.fin_age)}
                                                                                          helperText={formik.touched.fin_age && formik.errors.fin_age}
                                                                                          inputBackground={inputBackground} />
                                                                              </div>
                                                                        </Stack>
                                                                  </div>
                                                            </Stack>
                                                      </div>
                                                      <div className="referentiel-modal-footer">
                                                            <div style={{ paddingRight: '34px', paddingTop: '16px' }}>
                                                                  <Stack
                                                                        direction="row-reverse"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        spacing={2}
                                                                  >
                                                                        <Button variant="contained" type="submit" style={{
                                                                              background: '#367BF5',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Modifier</Button>

                                                                        <Button onClick={onClose} style={{
                                                                              color: '#4F566B',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Annuler</Button>

                                                                  </Stack>
                                                            </div>

                                                      </div>
                                                </form>
                                          </Stack>
                                    </div>
                              </div>
                        </Stack>
                  </div>
            </Box>
      )
}