import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./index.scss";

export default function CustomLoader(props) {

    return (
        <div className="custom-loader">
                  <ClipLoader color={"#EAEAF7"} loading={props.isLoading} size={150} />
        </div>
    )
}