import React from "react";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { ThemeProvider } from "@mui/material";

import gridTheme from "../GridTheme";
import CustomPagination from '../../Pagination';
import CaseKPI from "../../CaseKPI/CaseKPI";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { flattenIdNomDict } from "../../../utils/referentielData";
import { useReferentiels } from "../../../services/referentiels/referentiels";
import { DataGridEyeIcon } from "../../Icons/DataGridIcons";
import { createDataGridRows } from "../../../utils/dataGrid";
import '../index.scss';


const columnWidth = 148;
const numeroColunmWidth = 79;
const columnStyleClass = 'grid-header';
const cellStyleClass = 'grid-cell-style';



export default function HistoryDataGrid(props) {
  // const { cases, filter, columnNames, fieldNames, loading, onSetCases } = props;
  const cases = props.cases;
  const filter = props.filter;
  const loading = props.loading;
  const columnNames = props.columnNames;
  const fieldNames = props.fieldNames;
  const referentielResults = useReferentiels(["type_rapport", "repondeur", "delai_reponse", "analyste"]);
  const isLoading = referentielResults.some((result) => result.isLoading);
  const showCase = React.useCallback(
    (id) => () => {
      console.log("Case: " + id);
    },
    [],
  );
  const createColumns = (fieldNames, columnNames) => {
    let columns = [{ field: 'id', hide: true }];
    for (let i = 0; i < fieldNames.length; i++) {
      let width = fieldNames[i] == 'numero' ? numeroColunmWidth : columnWidth;
      let column = {
        field: fieldNames[i],
        headerName: columnNames[i],
        width: width, headerClassName: columnStyleClass, cellClassName: cellStyleClass
      }
      if (fieldNames[i] == 'kpi_avancement') {
        column.renderCell = (params) => (
          <CaseKPI value={params.value} />
        );
      }
      if (fieldNames[i] == 'actions') {
        column.type = 'actions';
        column.headerName = 'Actions';
        column.width = numeroColunmWidth + 20;
        column.getActions = (params) => [
          <GridActionsCellItem
            icon={<DataGridEyeIcon />}
            label="Afficher"
            onClick={showCase(params.id)}
          />
        ]
      }
      columns.push(column);
    }
    return columns;
  };
  if (isLoading) return <CustomLoader isLoading={isLoading} />;
  const typeRapports = flattenIdNomDict(referentielResults[0].data);
  const repondeurs = flattenIdNomDict(referentielResults[1].data);
  const delaisReponses = flattenIdNomDict(referentielResults[2].data);
  const analystes = flattenIdNomDict(referentielResults[3].data);
  const rows = createDataGridRows("cases", cases, filter, { "types_rapports": typeRapports, "repondeurs": repondeurs, "delais": delaisReponses, "analystes": analystes });
  let columns = createColumns(fieldNames, columnNames);
  const data = { rows, columns }
  return (
    <ThemeProvider theme={gridTheme}>
      <div style={{ height: 555 }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }} >
            <DataGrid
              {...data}
              disableColumnSelector={true}
              loading={loading}
              pagination
              pageSize={8}
              rowsPerPageOptions={[8]}
              components={{
                Pagination: CustomPagination,
              }}
            />

          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}