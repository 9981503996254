import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import "../../index.scss";
import LabelGroupMenu from "../../../LabelMenu/LabelGroupMenu";
import { getTypeRapportDict } from "../../../../utils/case";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelDatePicker from "../../../LabelDatePicker/LabelDatePicker";

export default function Rapport(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const typeRapportDict = getTypeRapportDict();
  const widthPercentage = "31%";
  useEffect(() => { }, [values]);

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Le rapport</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"N° de dossier"}
                  name={"declarant.rapport.numero_dossier"}
                  value={getIn(values, "declarant.rapport.numero_dossier")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.rapport.numero_dossier") &&
                    Boolean(getIn(errors, "declarant.rapport.numero_dossier"))
                  }
                  helperText={
                    getIn(touched, "declarant.rapport.numero_dossier") &&
                    getIn(errors, "declarant.rapport.numero_dossier")
                  }
                  mandatory={true}
                  disabled
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelGroupMenu
                  title={"Type de rapport"}
                  name={"declarant.rapport.type_rapport_id"}
                  value={
                    getIn(values, "declarant.rapport.type_rapport_id") ?? 1
                  }
                  onChange={setFieldValue}
                  error={
                    getIn(touched, "declarant.rapport.type_rapport_id") &&
                    Boolean(getIn(errors, "declarant.rapport.type_rapport_id"))
                  }
                  helperText={
                    getIn(touched, "declarant.rapport.type_rapport_id") &&
                    getIn(errors, "declarant.rapport.type_rapport_id")
                  }
                  mandatory={true}
                  valuesDict={typeRapportDict}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Opérateur/trice de saisie"}
                  name={"declarant.rapport.operateur"}
                  value={getIn(values, "declarant.rapport.operateur")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.rapport.operateur") &&
                    Boolean(getIn(errors, "declarant.rapport.operateur"))
                  }
                  helperText={
                    getIn(touched, "declarant.rapport.operateur") &&
                    getIn(errors, "declarant.rapport.operateur")
                  }

                  disabled
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelDatePicker
                  title={"Date de saisie"}
                  name={"declarant.rapport.date_saisie"}
                  value={getIn(values, "declarant.rapport.date_saisie")}
                  onChange={setFieldValue}
                  error={
                    getIn(touched, "declarant.rapport.date_saisie") &&
                    Boolean(getIn(errors, "declarant.rapport.date_saisie"))
                  }
                  helperText={
                    getIn(touched, "declarant.rapport.date_saisie") &&
                    getIn(errors, "declarant.rapport.date_saisie")
                  }
                  mandatory={true}
                  disabled
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelDatePicker
                  title={"Date de réception"}
                  name={"declarant.rapport.date_reception"}
                  value={getIn(values, "declarant.rapport.date_reception")}
                  onChange={setFieldValue}
                  error={
                    getIn(touched, "declarant.rapport.date_reception") &&
                    Boolean(getIn(errors, "declarant.rapport.date_reception"))
                  }
                  helperText={
                    getIn(touched, "declarant.rapport.date_reception") &&
                    getIn(errors, "declarant.rapport.date_reception")
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Heure de réception"}
                  name={"declarant.rapport.heure_reception"}
                  value={getIn(values, "declarant.rapport.heure_reception")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "declarant.rapport.heure_reception") &&
                    Boolean(getIn(errors, "declarant.rapport.heure_reception"))
                  }
                  helperText={
                    getIn(touched, "declarant.rapport.heure_reception") &&
                    getIn(errors, "declarant.rapport.heure_reception")
                  }
                  type={"time"}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
