import React from "react";
import { List, ListItemButton } from "@mui/material";
import SaisieNavElement from "./SaisieNavElement";


const flexContainer = {
      display: 'inline-flex',
      flexDirection: 'row',
      padding: 0,
};

const listItem = {
      padding:0,
}



export default function SaisieNavBar(props) {
      const {steps, stepsDone, onClick, currentStep} = props;
      const [selectedIndex, setSelectedIndex] = React.useState(currentStep);

      const handleListItemClick = (event, index) => {
            setSelectedIndex(index);
            onClick(index);
      };

      return (
            <List component="nav"  style={flexContainer}>
                  {
                        steps.map((step, index) => (
                              <ListItemButton key={index} onClick={(event) => handleListItemClick(event, index)} style={listItem}>
                                    <SaisieNavElement active={currentStep === index} isDone={stepsDone[index]} isFirst={index===0} title={step} />
                              </ListItemButton>
                        ))
                  }
            </List>
      )
}

