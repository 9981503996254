import React from "react";
import "./index.scss"
import { IconButton, Box, Stack, Button } from "@mui/material";
import { PopupTrashIcon } from "../../Icons/Icons";
import { UserViewCloseIcon } from "../../Icons/Icons";

const style = {
    position: 'absolute',
    width: 585,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'

}

const PopupDelRefCas = (props) => {
    const {selectedRow, onClose, onDelete, title} = props;
    
    const deleteRow = (row) => {
        onDelete(row);
        onClose();
    }
    
    return (
       
            <Box sx={{ ...style }}>
                <div className="popups-delrefcas-container">
                    <Stack direction="column" spacing={0} style={{ alignItems: 'center' }}>
                        <div className="popups-delrefcas-header">
                            <IconButton style={{ padding: 0 }} onClick={onClose}>
                                <UserViewCloseIcon />
                            </IconButton>
                        </div>
                        <PopupTrashIcon />
                        <h3>{'Etes - vous sûr?'}</h3>
                        <h5>{'Voulez-vous vraiment supprimer ' + title +' ?'}</h5>
                        <div className="popups-delrefcas-buttons">
                            <Stack direction="row" spacing={0} style={{ alignItems: 'center' }}>

                                <div>
                                    <Button variant="contained" onClick={onClose} style={{
                                        background: '#E3E5E6',
                                        height: '45px',
                                        width: '144px',
                                        color: '#5E6366',
                                    }}>Annuler</Button>
                                </div>
                                <div className="popups-delrefcas-buttons-delete">
                                    <Button variant="contained" onClick={() => deleteRow(selectedRow)} style={{
                                        background: '#EB5757',
                                        color: '#FFFFFF',
                                        height: '45px',
                                        width: '144px',
                                    }}>Supprimer</Button>
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </Box>
            
       

    )
}
export default PopupDelRefCas;