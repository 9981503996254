import React from "react";
import "./index.scss"
import { IconButton, Box, Stack, Button } from "@mui/material";
import { PopupLogoutIcon } from "../../Icons/Icons";
import { UserViewCloseIcon } from "../../Icons/Icons";

const style = {
    position: 'absolute',
    width: 585,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'

}

const PopupLogout = (props) => {
    const { onClose, onLogout } = props;


    const logout = () => {
        onLogout();
    }

    return (

        <Box sx={{ ...style }}>
            <div className="popups-delrefcas-container">
                <Stack direction="column" spacing={0} style={{ alignItems: 'center' }}>
                    <div className="popups-delrefcas-header">
                        <IconButton style={{ padding: 0 }} onClick={onClose}>
                            <UserViewCloseIcon />
                        </IconButton>
                    </div>
                    <PopupLogoutIcon />
                    <h3>{'Êtes - vous sûr?'}</h3>
                    <h5>{'Voulez-vous vraiment se déconnecter ?'}</h5>
                    <div className="popups-delrefcas-buttons">
                        <Stack direction="row" spacing={0} style={{ alignItems: 'center' }}>

                            <div>
                                <Button variant="contained" onClick={onClose} style={{
                                    background: '#E3E5E6',
                                    height: '45px',
                                    width: '144px',
                                    color: '#5E6366',
                                }}>Annuler</Button>
                            </div>
                            <div className="popups-delrefcas-buttons-delete">
                                <Button variant="contained" onClick={logout} style={{
                                    background: '#EB5757',
                                    color: '#FFFFFF',
                                    height: '45px',
                                    width: '144px',
                                }}>Déconnexion</Button>
                            </div>
                        </Stack>
                    </div>
                </Stack>
            </div>
        </Box>



    )
}
export default PopupLogout;