import React from "react"
import { Stack } from "@mui/material";
import General from "../../../components/ViewCase/Scorpion/General/General";
import Declarant from "../../../components/ViewCase/Scorpion/Declarant/Declarant";
import Patient from "../../../components/ViewCase/Scorpion/Patient/Patient";
import Traitement from "../../../components/ViewCase/Scorpion/Traitement/Traitement";
import Hospitalisation from "../../../components/ViewCase/Scorpion/Hospitalisation/Hospitalisation";
import Reference from "../../../components/ViewCase/Scorpion/Reference/Reference";
import Evolution from "../../../components/ViewCase/Scorpion/Evolution/Evolution";
import '../index.scss';

export default function ViewScorpion(props) {
 
      return (
            <div className='view-case-common-container'>
                  <div className='view-case-common-component'>
                        <Stack direction="column" spacing={0}>
                              <div className='view-case-common-sub-header'>
                                    <div className='view-case-common-sub-header-title'>
                                          relevé scorpion
                                    </div>
                              </div>
                              <div className='view-case-common-body'>
                                    <Stack direction="column" spacing={4}>
                                          <General />
                                          <Declarant />
                                          <Patient />
                                          <Traitement />
                                          <Hospitalisation />
                                          <Reference />
                                          <Evolution />
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}