import React, { useContext, useEffect, useState } from "react";
import {
  Stack,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import { getProvinces, getCommunes, getRegionWithProvince, getProvince } from "../../../../services/cases/cases";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelPhone from "../../../LabelPhone/LabelPhone";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import LabelTextArea from "../../../LabelTextArea";

import "../../index.scss";
import { ValidatedContext } from "../../../../modules/AddCases/AddLaboCase/AddLaboCase";

const ageTransformation = {
  'Ans': 1,
  'Mois': 1 / 12.0,
  'Jours': 1 / 365.0
}

export default function Patient(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();

  const validated = useContext(ValidatedContext);
  const [provinces, setProvinces] = useState({});
  const [communes, setCommunes] = useState({});

  const widthPercentage = "31%";

  const handleTrancheAgeChange = (event) => {
    handleChange(event);
    setFieldValue("patient.age_chiffre", '');
    setFieldValue("patient.age_unite", 0);
  };

  const handleAgeChange = (event, champ_age) => {
    handleChange(event);
    let age_chiffre = getIn(values, "patient.age_chiffre");
    let age_unite = getIn(values, "patient.age_unite");
    if (champ_age === "chiffre") {
      age_chiffre = event.target.value
    }
    if (champ_age === "unite") {
      age_unite = event.target.value
    }
    if (age_unite === "Jours") {
      if (age_chiffre >= 0 && age_chiffre <= 31) {
        setFieldValue("patient.tranche_age", 1);
        return;
      }
      if (age_chiffre >= 32 && age_chiffre <= 365) {
        setFieldValue("patient.tranche_age", 2);
        return;
      }
    }


    let age = age_chiffre * ageTransformation[age_unite];
    let tranches = referentielResults[0].data;
    for (let trancheIndex in tranches) {
      let tranche = tranches[trancheIndex]
      if (tranche.unite_age !== "Ans") {
        continue
      }
      if (age >= tranche.debut_age && age <= tranche.fin_age) {
        setFieldValue("patient.tranche_age", tranche.id)
      }
    }
  }
  const onComuneProvinceChange = (event, name) => {
    handleChange(event);
    let communeId = getIn(values, "patient.commune_id");
    let provinceId = getIn(values, "patient.province_id");
    if (name === "commune") {
      communeId = event.target.value;
      getProvince(communeId).then((data) => {
        setFieldValue("patient.province_id", "" + data.province_id);
        setFieldValue("patient.region_id", "" + data.region_id);
      })
    } else {
      provinceId = event.target.value;
      onProvinceChange(event);
    }
  };

  const onRegionChange = (event) => {
    handleChange(event);
    let regionId = event.target.value;
    if (regionId === "") return;
    getProvinces(regionId).then((data) => {
      setProvinces(flattenIdNomDict(data));
      setFieldValue("patient.commune_id", "");
      setFieldValue("patient.province_id", "");
    }
    )
  };

  const onProvinceChange = (event) => {

    handleChange(event);
    let provinceId = event.target.value;
    if (provinceId === "") {
      return;
    }
    getCommunes(provinceId).then((data) => {
      setCommunes(flattenIdNomDict(data));
      setFieldValue("patient.commune_id", "");
    }
    )

    getRegionWithProvince(provinceId).then((data) => {
      setFieldValue("patient.region_id", "" + data);
    });
  };

  const referentielResults = useReferentiels([
    "groupe_age",
    "commune",
    "province",
    "region",
    "profession"
  ]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  useEffect(() => {

    setProvinces(isLoading ? {} : flattenIdNomDict(referentielResults[2].data));
    setCommunes(isLoading ? {} : flattenIdNomDict(referentielResults[1].data));
  }, [isLoading]);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const trancheAges = flattenIdNomDict(referentielResults[0].data);

  const regions = flattenIdNomDict(referentielResults[3].data);
  const professions = flattenIdNomDict(referentielResults[4].data);
  return values && (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Le patient</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={5}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Nom et prénom"}
                  name={"patient.nom_prenom"}
                  value={getIn(values, "patient.nom_prenom")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "patient.nom_prenom") &&
                    Boolean(getIn(errors, "patient.nom_prenom"))
                  }
                  helperText={
                    getIn(touched, "patient.nom_prenom") &&
                    getIn(errors, "patient.nom_prenom")
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelPhone
                  title={"Numéro de téléphone"}
                  name={"patient.telephone"}
                  value={getIn(values, "patient.telephone")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "patient.telephone") &&
                    Boolean(getIn(errors, "patient.telephone"))
                  }
                  helperText={
                    getIn(touched, "patient.telephone") &&
                    getIn(errors, "patient.telephone")
                  }
                  
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "50%", height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>Sexe:</label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={"patient.sexe"}
                        onChange={(event) =>
                          setFieldValue("patient.sexe", event.target.value)
                        }
                        value={getIn(values, "patient.sexe")}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Féminin"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Masculin"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Non précisé"
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                </FormControl>
              </div>
              <div style={{ width: widthPercentage, height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>
                        Milieu:
                      </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={"patient.milieu"}
                        onChange={(event) =>
                          setFieldValue("patient.milieu", event.target.value)
                        }
                        value={getIn(values, "patient.milieu")}
                        error={
                          getIn(touched, "patient.milieu") &&
                          Boolean(getIn(errors, "patient.milieu"))
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Rural"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Urbain"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Inconnu"
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(touched, "patient.milieu") &&
                      getIn(errors, "patient.milieu")}
                  </FormHelperText>
                </FormControl>
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Age"}
                  name={"patient.age_chiffre"}
                  value={getIn(values, "patient.age_chiffre")}
                  onChange={(e) => handleAgeChange(e, "chiffre")}
                  error={
                    getIn(touched, "patient.age_chiffre") &&
                    Boolean(getIn(errors, "patient.age_chiffre"))
                  }
                  helperText={
                    getIn(touched, "patient.age_chiffre") &&
                    getIn(errors, "patient.age_chiffre")
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Unité d'âge"}
                  name={"patient.age_unite"}
                  value={getIn(values, "patient.age_unite")}
                  onChange={(e) => handleAgeChange(e, "unite")}
                  error={
                    getIn(touched, "patient.age_unite") &&
                    Boolean(getIn(errors, "patient.age_unite"))
                  }
                  helperText={
                    getIn(touched, "patient.age_unite") &&
                    getIn(errors, "patient.age_unite")
                  }
                  valuesDict={{ 'Jours': 'Jours', 'Mois': 'Mois', 'Ans': 'Ans' }}
                />

              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Tranche d'âge"}
                  name={"patient.tranche_age"}
                  value={getIn(values, "patient.tranche_age")}
                  onChange={handleTrancheAgeChange}
                  error={
                    getIn(touched, "patient.tranche_age") &&
                    Boolean(getIn(errors, "patient.tranche_age"))
                  }
                  helperText={
                    getIn(touched, "patient.tranche_age") &&
                    getIn(errors, "patient.tranche_age")
                  }
                  valuesDict={trancheAges}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Commune"}
                  name={"patient.commune_id"}
                  value={getIn(values, "patient.commune_id")}
                  onChange={(e) => onComuneProvinceChange(e, "commune")}
                  error={
                    getIn(touched, "patient.commune_id") &&
                    Boolean(getIn(errors, "patient.commune_id"))
                  }
                  helperText={
                    getIn(touched, "patient.commune_id") &&
                    getIn(errors, "patient.commune_id")
                  }

                  valuesDict={communes}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Province"}
                  name={"patient.province_id"}
                  value={getIn(values, "patient.province_id")}
                  onChange={(e) => onComuneProvinceChange(e, "province")}
                  error={
                    getIn(touched, "patient.province_id") &&
                    Boolean(getIn(errors, "patient.province_id"))
                  }
                  helperText={
                    getIn(touched, "patient.province_id") &&
                    getIn(errors, "patient.province_id")
                  }

                  valuesDict={provinces}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Région"}
                  name={"patient.region_id"}
                  value={getIn(values, "patient.region_id")}
                  error={
                    getIn(touched, "patient.region_id") &&
                    Boolean(getIn(errors, "patient.region_id"))
                  }
                  helperText={
                    getIn(touched, "patient.region_id") &&
                    getIn(errors, "patient.region_id")
                  }
                  valuesDict={regions}
                  onChange={onRegionChange}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>

              <div style={{ width: widthPercentage, height: "59px" }}>
                <FormControl
                  disabled={validated}
                  component="fieldset"
                  style={{ height: "100%" }}
                >
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>Grossesse: </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={"patient.grossesse"}
                        onChange={(event) =>
                          setFieldValue("patient.grossesse", event.target.value)
                        }
                        value={getIn(values, "patient.grossesse")}
                        error={
                          getIn(touched, "patient.grossesse") &&
                          Boolean(getIn(errors, "patient.grossesse"))
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Oui"
                          disabled={
                            parseInt(getIn(values, "patient.sexe")) !== 0 || validated
                          }
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non"
                          disabled={
                            parseInt(getIn(values, "patient.sexe")) !== 0 || validated
                          }
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Inconnu"
                          disabled={
                            parseInt(getIn(values, "patient.sexe")) !== 0 || validated
                          }
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(touched, "patient.grossesse") &&
                      getIn(errors, "patient.grossesse")}
                  </FormHelperText>
                </FormControl>
              </div>

              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Nombre de semaines"}
                  name={"patient.nombre_semaines"}
                  value={getIn(values, "patient.nombre_semaines")}
                  disabled={parseInt(getIn(values, "patient.sexe")) !== 0 || parseInt(getIn(values, "patient.grossesse")) !== 1}
                  onChange={handleChange}
                  error={
                    getIn(touched, "patient.nombre_semaines") &&
                    Boolean(getIn(errors, "patient.nombre_semaines"))
                  }
                  helperText={
                    getIn(touched, "patient.nombre_semaines") &&
                    getIn(errors, "patient.nombre_semaines")
                  }
                />
              </div>
              <div style={{ width: widthPercentage, height: "59px" }}>
                <FormControl component="fieldset" style={{ height: "100%" }}>
                  <Stack direction="row" spacing={0} style={{ height: "100%" }}>
                    <div className="case-common-container-body-label">
                      <label>Allaitement: </label>
                    </div>
                    <div className="case-common-container-body-radio">
                      <RadioGroup
                        row
                        name={"patient.allaitement"}
                        onChange={(event) =>
                          setFieldValue(
                            "patient.allaitement",
                            event.target.value
                          )
                        }
                        value={getIn(values, "patient.allaitement")}
                        error={
                          getIn(touched, "patient.allaitement") &&
                          Boolean(getIn(errors, "patient.allaitement"))
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Oui"
                          disabled={
                            parseInt(getIn(values, "patient.sexe")) !== 0 || validated
                          }
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non"
                          disabled={
                            parseInt(getIn(values, "patient.sexe")) !== 0 || validated
                          }
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Inconnu"
                          disabled={
                            parseInt(getIn(values, "patient.sexe")) !== 0 || validated
                          }
                        />
                      </RadioGroup>
                    </div>
                  </Stack>
                  <FormHelperText
                    style={{ color: "#d32f2f", marginLeft: "14px" }}
                  >
                    {getIn(touched, "patient.allaitement") &&
                      getIn(errors, "patient.allaitement")}
                  </FormHelperText>
                </FormControl>
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>

              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Poids (Kg)"}
                  name={"patient.poids"}
                  value={getIn(values, "patient.poids")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "patient.poids") &&
                    Boolean(getIn(errors, "patient.poids"))
                  }
                  helperText={
                    getIn(touched, "patient.poids") &&
                    getIn(errors, "patient.poids")
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelMenu
                  title={"Profession"}
                  name={"patient.profession_id"}
                  value={getIn(values, "patient.profession_id")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "patient.profession_id") &&
                    Boolean(getIn(errors, "patient.profession_id"))
                  }
                  helperText={
                    getIn(touched, "patient.profession_id") &&
                    getIn(errors, "patient.profession_id")
                  }
                  valuesDict={professions}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Antécédents"}
                  name={"patient.antecedents"}
                  value={getIn(values, "patient.antecedents")}
                  onChange={handleChange}
                  error={
                    getIn(touched, "patient.antecedents") &&
                    Boolean(getIn(errors, "patient.antecedents"))
                  }
                  helperText={
                    getIn(touched, "patient.antecedents") &&
                    getIn(errors, "patient.antecedents")
                  }
                />
              </div>
            </Stack>
          </div>

        </Stack>
      </div>
    </div>
  );
}
