import { Component } from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import "./index.scss";

const linkStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    textAlign: "right",
    color: "#367bf5",
    textDecoration: "none",
};

class LabelPhone extends Component {


    render() {
        const {
            title,
            name,
            value,
            link,
            type,
            onChange,
            error,
            helperText,
            placeholder,
            inputBackground,
            mandatory,
            boldTitle,

            disabled
        } = this.props;
        const validated = this.context;

        let fontWeight = boldTitle === undefined ? 600 : 400;
        let isDisabled = disabled === undefined ? false : disabled;
        let selectedInputBackground =
            inputBackground === undefined ? "#FFFFFF" : inputBackground;
        if (isDisabled || validated) {
            selectedInputBackground = "#F1F1F1";
        }
        let redFlag = "";
        if (mandatory === true) {
            redFlag = <span style={{ color: "red" }}>&nbsp;*</span>;
        }
        let linkSection = 1;
        if (link) {
            linkSection = 6;
        }

        return (
            <div style={{ textAlign: "right" }}>
                <Grid container spacing={0.5} direction="column">
                    <Grid container spacing={0} direction="row">
                        <Grid item xs={12 - linkSection}>
                            <div>
                                <label
                                    className="label-input-label"
                                    style={{
                                        fontWeight: fontWeight,
                                    }}
                                >
                                    {title}
                                    {redFlag}
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={linkSection}>
                            <Link className="link" href="#" style={linkStyle}>
                                {link}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 0 }}>
                        <PhoneInput
                            className="number"
                            name={name}
                            disabled={isDisabled || validated}
                            placeholder={placeholder}
                            value={value}
                            error={error}
                            helperText={helperText}
                            onChange={onChange}
                            country={"ma"}
                            type={type}
                            style={{
                                background: selectedInputBackground,
                                boxSizing: "border-box",

                            }}
                            inputProps={{
                                style: {
                                    boxSizing: "border-box",
                                    height: "42px",
                                    paddingLeft: "5px"
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default LabelPhone;
