import * as yup from 'yup';

const nomOnlySchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
});

const whoArtSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      categorie: yup
            .string('Entrer une catégorie')
            .required("Le nom de la catégorie est obligatoire"),
});

const examenDemandeSchema = yup.object({
      nom_molecule: yup
            .string('Entrer un nom de molécule')
            .required("Le nom de molécule est obligatoire"),
});



const classeOnlySchema = yup.object({
      classe: yup
            .string('Entrer un nom de la classe')
            .required("Le nom de la classe est obligatoire"),
});

const ITSchema = yup.object({
      terme: yup
            .string('Entrer un terme')
            .required("Le terme est obligatoire"),
      soc_id: yup
            .number()
            .required("Le SOC est obligatoire")
});

const CategorieAlimentSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      classe_aliment_id: yup
            .number()
            .required("La classe aliment est obligatoire")
});

const EntreeNomSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      categorie: yup
            .string('Entrer une catégorie')
            .required("La catégorie est obligatoire"),
});

const DCISchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      substance_chimique_id: yup
            .number()
            .required("La substance chimique est obligatoire")
});


const EspeceAnimauxSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      groupe_animaux_id: yup
            .number()
            .required("Le groupe animal est obligatoire")
});

const GroupeAlimentSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      categorie_aliment_id: yup
            .number()
            .required("La catégorie aliment est obligatoire")
});


const GroupeChimiqueSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      groupe_therapeutique_id: yup
            .number()
            .required("Le groupe thérapeutique est obligatoire")
});

const GroupeTherapeutiqueSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      groupe_anatomique_id: yup
            .number()
            .required("Le groupe anatomique est obligatoire")
});

const MatiereActiveSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      classe_chimique_id: yup
            .number()
            .required("La classe chimique est obligatoire")
});

const PlanteNomFrancaisSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      plante_nom_scientifique_id: yup
            .number()
            .required("Le nom scientifique est obligatoire")
});

const PlanteNomScientifiqueSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      classe_plante_id: yup
            .number()
            .required("La classe plante est obligatoire")
});


const PlanteNomVernaculaireSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      plante_nom_francais_id: yup
            .number()
            .required("Le nom français est obligatoire")
});

const ProduitAlimentSchema = yup.object({
      nom_aliment: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      code_aliment: yup
            .string('Entrer un code')
            .required("Le code est obligatoire"),
      groupe_aliment_id: yup
            .number()
            .required("Le groupe aliment est obligatoire")
});

const ProduitCosmetiqueSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      sous_classe_produit_cosmetique_id: yup
            .number()
            .required("La sous classe produit cosmétique est obligatoire")
});

const ProduitGazSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      classe_gaz_id: yup
            .number()
            .required("La classe gaz est obligatoire")
});


const ProduitIndustrielSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      sous_classe_produit_industriel_id: yup
            .number()
            .required("La sous classe produit industriel est obligatoire")
});

const SousClasseProduitCosmetiqueSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      classe_produit_cosmetique_id: yup
            .number()
            .required("La classe est obligatoire")
});

const ClasseProduitCosmetiqueSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      code_produit_cosmetique_id: yup
            .number()
            .required("Le code est obligatoire")
});


const SousClasseProduitIndustrielSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      classe_produit_id: yup
            .number()
            .required("La classe est obligatoire")
});

const SubstanceChimiqueSchema = yup.object({
      nom: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      groupe_chimique_id: yup
            .number()
            .required("Le groupe chimique est obligatoire")
});

const ProduitEngraisSchema = yup.object({
      nom_commercial: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      matiere_active_id: yup
            .number()
            .required("La matière active est obligatoire"),
      classe_engrais_id: yup
            .number()
            .required("La classe engrais est obligatoire"),
      forme_id: yup
            .number()
            .required("La forme est obligatoire"),
      usage_official_id: yup
            .number()
            .required("L'usage officiel est obligatoire")
});

const ProduitMedicamentSchema = yup.object({
      nom_commercial: yup
            .string('Entrer un nom')
            .required("Le nom commercial est obligatoire"),
      veterinaire: yup
            .boolean()
            .default(false),
      dci_id: yup
            .number()
            .required("Le DCI est obligatoire"),
      groupe_anatomique_id: yup
            .number()
            .required("Le groupe anatomique est obligatoire"),
      code_atc_id: yup
            .number()
            .required("L'usage officiel est obligatoire"),
});


const ProduitPesticideSchema = yup.object({
      nom_commercial: yup
            .string('Entrer un nom')
            .required("Le nom est obligatoire"),
      matiere_active_id: yup
            .number()
            .required("La matière active est obligatoire"),
      usage_official_id: yup
            .number()
            .required("L'usage officiel est obligatoire"),
      forme_id: yup
            .number()
            .required("La forme est obligatoire"),
      classe_dangerosite_id: yup
            .number()
            .required("La classe de dangerosité est obligatoire"),
      type_pesticide: yup
            .string('Entrer un type')
            .required("Le type est obligatoire"),
});

const ScorpionCouleurSchema = yup.object({
      couleur: yup
            .string('Entrer un nom')
            .required("Le nom de couleur est obligatoire")
});





export { ClasseProduitCosmetiqueSchema, EntreeNomSchema, whoArtSchema, examenDemandeSchema, nomOnlySchema, classeOnlySchema, ScorpionCouleurSchema, ProduitPesticideSchema, ProduitMedicamentSchema, ProduitEngraisSchema, SubstanceChimiqueSchema, SousClasseProduitIndustrielSchema, SousClasseProduitCosmetiqueSchema, ProduitIndustrielSchema, ProduitGazSchema, ProduitCosmetiqueSchema, ProduitAlimentSchema, PlanteNomVernaculaireSchema, PlanteNomScientifiqueSchema, PlanteNomFrancaisSchema, MatiereActiveSchema, GroupeTherapeutiqueSchema, GroupeChimiqueSchema, CategorieAlimentSchema, ITSchema, DCISchema, EspeceAnimauxSchema, GroupeAlimentSchema }