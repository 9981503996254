import React from "react";
import { Stack, Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInput from "../../../LabelInput/LabelInput";
import LabelDatePicker from "../../../LabelDatePicker/LabelDatePicker";
import { erreurCircuitDict, causesErreurDict, erreurTypeDict } from "../../../../utils/erreurMedicamenteuse";
import '../../index.scss';



export default function Intoxication(props) {
      const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();
      const referentielResults = useReferentiels(['lieu_intoxication', 'circonstance_intoxication']);
      const widthPercentage = '31%';
      const isLoading = referentielResults.some(result => result.isLoading)
      if (isLoading)
            return (<CustomLoader isLoading={isLoading} />)

      const lieuxIntoxication = flattenIdNomDict(referentielResults[0].data);
      const circonstancesIntoxication = flattenIdNomDict(referentielResults[1].data);

      return (
            <div className="case-common-container">
                  <div className="case-common-container-title">
                        <label>L'intoxication</label>
                  </div>
                  <div className="case-common-container-body">
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelDatePicker title={"Date d'intoxication"}
                                                      name={"intoxication.intoxication.date_intoxication"}
                                                      value={getIn(values, "intoxication.intoxication.date_intoxication")}
                                                      onChange={setFieldValue}
                                                      error={getIn(touched, "intoxication.intoxication.date_intoxication") && Boolean(getIn(errors, "intoxication.intoxication.date_intoxication"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.date_intoxication") && getIn(errors, "intoxication.intoxication.date_intoxication")}
                                                />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Heure d'intoxication"}
                                                      name={"intoxication.intoxication.heure"}
                                                      value={getIn(values, "intoxication.intoxication.heure")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.heure") && Boolean(getIn(errors, "intoxication.intoxication.heure"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.heure") && getIn(errors, "intoxication.intoxication.heure")}
                                                      type={'time'} />
                                          </div>
                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ display: "flex" }}>
                                                <span className="label-input-label">Délai post exposition</span>
                                          </div>
                                          <div>
                                                <Stack direction="row" spacing={1}>
                                                      <div style={{ width: "30%" }}>
                                                            <LabelInput title={"(jour)"}
                                                                  name={"intoxication.intoxication.delai_intoxication_j"}
                                                                  value={getIn(values, "intoxication.intoxication.delai_intoxication_j")}
                                                                  onChange={handleChange}
                                                                  adornment={"J"}
                                                                  error={getIn(touched, "intoxication.intoxication.delai_intoxication_j") && Boolean(getIn(errors, "intoxication.intoxication.delai_intoxication_j"))}
                                                                  helperText={getIn(touched, "intoxication.intoxication.delai_intoxication_j") && getIn(errors, "intoxication.intoxication.delai_intoxication_j")}
                                                            />
                                                      </div>
                                                      <div style={{ width: "30%" }}>
                                                            <LabelInput title={"(heure)"}
                                                                  name={"intoxication.intoxication.delai_intoxication_h"}
                                                                  value={getIn(values, "intoxication.intoxication.delai_intoxication_h")}
                                                                  onChange={handleChange}
                                                                  adornment={"H"}
                                                                  error={getIn(touched, "intoxication.intoxication.delai_intoxication_h") && Boolean(getIn(errors, "intoxication.intoxication.delai_intoxication_h"))}
                                                                  helperText={getIn(touched, "intoxication.intoxication.delai_intoxication_h") && getIn(errors, "intoxication.intoxication.delai_intoxication_h")}
                                                            />
                                                      </div>
                                                      <div style={{ width: "30%" }}>
                                                            <LabelInput title={"(minute)"}
                                                                  name={"intoxication.intoxication.delai_intoxication_m"}
                                                                  value={getIn(values, "intoxication.intoxication.delai_intoxication_m")}
                                                                  onChange={handleChange}
                                                                  adornment={"M"}
                                                                  error={getIn(touched, "intoxication.intoxication.delai_intoxication_m") && Boolean(getIn(errors, "intoxication.intoxication.delai_intoxication_m"))}
                                                                  helperText={getIn(touched, "intoxication.intoxication.delai_intoxication_m") && getIn(errors, "intoxication.intoxication.delai_intoxication_m")}
                                                            />
                                                      </div>
                                                </Stack>
                                          </div>
                                    </Stack>
                              </div>


                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: '45%', height: '59px' }}>
                                                <FormControl component="fieldset" style={{ height: '100%' }}>
                                                      <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                            <div className="case-common-container-body-label">
                                                                  <label >Type d'intoxication: </label>
                                                            </div>
                                                            <div className="case-common-container-body-radio">
                                                                  <RadioGroup row name={"intoxication.intoxication.type_intoxication"}
                                                                        onChange={(event) => setFieldValue("intoxication.intoxication.type_intoxication", event.target.value)}

                                                                        value={getIn(values, "intoxication.intoxication.type_intoxication")}
                                                                        error={getIn(touched, "intoxication.intoxication.type_intoxication") && Boolean(getIn(errors, "intoxication.intoxication.type_intoxication"))}>
                                                                        <FormControlLabel value="0" control={<Radio />} label="Isolée" />
                                                                        <FormControlLabel value="1" control={<Radio />} label="Collective" />
                                                                        <FormControlLabel value="2" control={<Radio />} label="Inconnu" />

                                                                  </RadioGroup>
                                                            </div>
                                                      </Stack>
                                                      <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.intoxication.type_intoxication") && getIn(errors, "intoxication.intoxication.type_intoxication")}</FormHelperText>
                                                </FormControl>
                                          </div>
                                          {getIn(values, "intoxication.intoxication.type_intoxication") === "1" && (<div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Nombre de cas"}
                                                      name={"intoxication.intoxication.nombre_cas"}
                                                      value={getIn(values, "intoxication.intoxication.nombre_cas")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.nombre_cas") && Boolean(getIn(errors, "intoxication.intoxication.nombre_cas"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.nombre_cas") && getIn(errors, "intoxication.intoxication.nombre_cas")}
                                                />
                                          </div>)}

                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>

                                          <div style={{ width: widthPercentage, height: '59px' }}>
                                                <FormControl component="fieldset" style={{ height: '100%' }}>
                                                      <Stack direction="row" spacing={0} style={{ height: '100%' }}>

                                                            <div className="case-common-container-body-label">
                                                                  <label >Erreur médicamenteuse: </label>
                                                            </div>
                                                            <div className="case-common-container-body-radio">
                                                                  <RadioGroup row name={"intoxication.intoxication.erreur_medicamenteuse"}
                                                                        onChange={(event) => setFieldValue("intoxication.intoxication.erreur_medicamenteuse", event.target.value)}
                                                                        value={getIn(values, "intoxication.intoxication.erreur_medicamenteuse")}
                                                                        error={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse") && Boolean(getIn(errors, "intoxication.intoxication.erreur_medicamenteuse"))}>
                                                                        <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                                                        <FormControlLabel value="0" control={<Radio />} label="Non" />
                                                                  </RadioGroup>
                                                            </div>
                                                      </Stack>
                                                      <FormHelperText style={{ color: '#d32f2f', marginLeft: '14px' }}>{getIn(touched, "intoxication.intoxication.erreur_medicamenteuse") && getIn(errors, "intoxication.intoxication.erreur_medicamenteuse")}</FormHelperText>
                                                </FormControl>
                                          </div>
                                          {parseInt(getIn(values, "intoxication.intoxication.erreur_medicamenteuse")) === 1 && (<div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Erreur de Circuit"}
                                                      name={"intoxication.intoxication.erreur_medicamenteuse_erreur_circuit"}
                                                      value={getIn(values, "intoxication.intoxication.erreur_medicamenteuse_erreur_circuit")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_erreur_circuit") && Boolean(getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_erreur_circuit"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_erreur_circuit") && getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_erreur_circuit")}
                                                      valuesDict={erreurCircuitDict} />
                                          </div>)}
                                          {parseInt(getIn(values, "intoxication.intoxication.erreur_medicamenteuse")) === 1 && (<div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Type d'erreur"}
                                                      name={"intoxication.intoxication.erreur_medicamenteuse_type_erreur"}
                                                      value={getIn(values, "intoxication.intoxication.erreur_medicamenteuse_type_erreur")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_type_erreur") && Boolean(getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_type_erreur"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_type_erreur") && getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_type_erreur")}
                                                      valuesDict={erreurTypeDict} />
                                          </div>)}
                                    </Stack>
                              </div>
                              {parseInt(getIn(values, "intoxication.intoxication.erreur_medicamenteuse")) === 1 && (<div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Causes d'erreur"}
                                                      name={"intoxication.intoxication.erreur_medicamenteuse_cause_erreur"}
                                                      value={getIn(values, "intoxication.intoxication.erreur_medicamenteuse_cause_erreur")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_cause_erreur") && Boolean(getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_cause_erreur"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_cause_erreur") && getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_cause_erreur")}
                                                      valuesDict={causesErreurDict} />
                                          </div>
                                          <div style={{ width: "64%" }}>
                                                <LabelInput title={"Autre information"}
                                                      name={"intoxication.intoxication.erreur_medicamenteuse_autre_information"}
                                                      value={getIn(values, "intoxication.intoxication.erreur_medicamenteuse_autre_information")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_autre_information") && Boolean(getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_autre_information"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.erreur_medicamenteuse_autre_information") && getIn(errors, "intoxication.intoxication.erreur_medicamenteuse_autre_information")}
                                                />
                                          </div>
                                    </Stack>
                              </div>)}
                              <div>
                                    <Stack direction="row" spacing={3}>

                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Lieu d'intoxication"}
                                                      name={"intoxication.intoxication.lieu_intoxication_id"}
                                                      value={getIn(values, "intoxication.intoxication.lieu_intoxication_id")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.lieu_intoxication_id") && Boolean(getIn(errors, "intoxication.intoxication.lieu_intoxication_id"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.lieu_intoxication_id") && getIn(errors, "intoxication.intoxication.lieu_intoxication_id")}
                                                      valuesDict={lieuxIntoxication} />

                                          </div>
                                          {parseInt(getIn(values, "intoxication.intoxication.lieu_intoxication_id")) === 9 ? (
                                                <div style={{ width: widthPercentage }}>
                                                      <LabelInput title={"Si autre, précisez"}
                                                            name={"intoxication.intoxication.autre_lieu_intoxication"}
                                                            value={getIn(values, "intoxication.intoxication.autre_lieu_intoxication")}
                                                            onChange={handleChange}
                                                            error={getIn(touched, "intoxication.intoxication.autre_lieu_intoxication") && Boolean(getIn(errors, "intoxication.intoxication.autre_lieu_intoxication"))}
                                                            helperText={getIn(touched, "intoxication.intoxication.autre_lieu_intoxication") && getIn(errors, "intoxication.intoxication.autre_lieu_intoxication")}
                                                      />
                                                </div>
                                          ) : <div style={{ width: widthPercentage }} />}

                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Circonstance"}
                                                      name={"intoxication.intoxication.circonstance_intoxication_id"}
                                                      value={getIn(values, "intoxication.intoxication.circonstance_intoxication_id")}
                                                      onChange={handleChange}
                                                      error={getIn(touched, "intoxication.intoxication.circonstance_intoxication_id") && Boolean(getIn(errors, "intoxication.intoxication.circonstance_intoxication_id"))}
                                                      helperText={getIn(touched, "intoxication.intoxication.circonstance_intoxication_id") && getIn(errors, "intoxication.intoxication.circonstance_intoxication_id")}
                                                      valuesDict={circonstancesIntoxication} />
                                          </div>
                                          {parseInt(getIn(values, "intoxication.intoxication.circonstance_intoxication_id")) === 23 ? (
                                                <div style={{ width: widthPercentage }}>
                                                      <LabelInput title={"Si autre, précisez"}
                                                            name={"intoxication.intoxication.autre_circonstance_intoxication"}
                                                            value={getIn(values, "intoxication.intoxication.autre_circonstance_intoxication")}
                                                            onChange={handleChange}
                                                            error={getIn(touched, "intoxication.intoxication.autre_circonstance_intoxication") && Boolean(getIn(errors, "intoxication.intoxication.autre_circonstance_intoxication"))}
                                                            helperText={getIn(touched, "intoxication.intoxication.autre_circonstance_intoxication") && getIn(errors, "intoxication.intoxication.autre_circonstance_intoxication")}
                                                      />
                                                </div>
                                          ) : <div style={{ width: widthPercentage }} />}
                                    </Stack>
                              </div>
                        </Stack>
                  </div >
            </div >
      )

}
