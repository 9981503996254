import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { typeRapportFlatDict } from "../../../../utils/case";

import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Rapport(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  let rapport = selectedCase ? selectedCase : values.declarant?.rapport;

  let dateReception =
    rapport && new Date(rapport?.date_reception).toLocaleDateString();
  let dateSaisie =
    rapport && new Date(rapport?.date_saisie).toLocaleDateString();

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Le rapport</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="N° de dossier"
                  value={
                    rapport?.numero_dossier
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Type de rapport"
                  value={typeRapportFlatDict[rapport?.type_rapport_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Opérateur/trice de saisie"
                  value={rapport?.operateur}
                />
              </div>

            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Date saisie" value={dateSaisie} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Date réception" value={dateReception} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Heure" value={rapport?.heure_reception} />
              </div>

            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
