import React, { useContext } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import AccessibilityOutlinedIcon from "@mui/icons-material/AccessibilityOutlined";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";

import { ThemeProvider } from "@mui/material";
import { buttonsTheme } from "./ButtonsTheme";
import { ValidatedContext } from "../../modules/AddCases/AddLaboCase/AddLaboCase";
import { Edit } from "@material-ui/icons";

function CustomButton(props) {
  const { type, customBackground, disabled, onClick } = props;

  const validated = useContext(ValidatedContext);

  let icon = null;
  let title = null;
  let backgroundColor = customBackground === true ? "#EBEFF8" : "inherit";
  let fontWeight = customBackground === true ? "bold" : "inherit";

  switch (type) {
    case "add":
      icon = <AddIcon />;
      title = "Ajouter";
      break;
    case "save":
      icon = <SaveSharpIcon />;
      title = "Sauvegarder";
      break;
    case "print":
      icon = <PrintIcon />;
      title = "Imprimer";
      break;
    case "preview":
      icon = <VisibilityOutlinedIcon />;
      title = "Aperçu";
      break;
    case "download":
      icon = <SimCardDownloadIcon />;
      title = "Télécharger";
      break;
    case "permission":
      icon = <AccessibilityOutlinedIcon />;
      title = "Modifier les permissions";
      break;
    case "return":
      icon = <ReplyRoundedIcon />;
      title = "Retourner au cas";
      break;
    case "validation":
      icon = <ReplyRoundedIcon />;
      title = "Envoi pour validation";
      break;
    case "edit":
      icon = <Edit />;
      title = "Modifier";
      break;
    default:
      break;
  }
  if (props.title !== undefined) {
    title = props.title;
  }
  return (
    <ThemeProvider theme={buttonsTheme}>
      <Button
        variant="outlined"
        startIcon={icon}
        onClick={onClick}
        style={{ backgroundColor: backgroundColor, fontWeight: fontWeight }}
        disabled={disabled || validated}
      >
        {title}
      </Button>
    </ThemeProvider>
  );
}

export default CustomButton;
