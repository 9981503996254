import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import ViewCaseDataGrid from "../../components/Grids/ViewCaseDataGrid/ViewCaseDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { isAllowed } from "../../services/auth";
import { viewToxiCasesPermissions, viewLaboCasesPermissions } from "../../utils/permissions";
import { debounce } from "lodash";
import { toxicoColumnNames, toxicoFieldNames, laboColumnNames, laboFieldNames } from "../../utils/dataGrid";
import { getLaboCases, getToxiCases } from "../../services/cases/cases";
import "./index.scss";

import { useSnackbar } from "notistack";
import PreviewCaseModal from "../../components/Modal/PreviewCaseModal";
import { useHistory, useLocation } from "react-router-dom";

export default function ViewCases(props) {
  const { category, onEditMode } = props;
  const location = useLocation();
  const declarationId = location.state ? location.state.declarationId : "";

  const [cases, setCases] = useState([]);
  const [searchText, setSearchText] = useState(declarationId.toString() ?? "");
  const [loading, setLoading] = useState(false);
  const [previewCase, setPreviewCase] = useState(
    declarationId.toString() ?? ""
  );
  const [showModal, setShowModal] = useState(false);
  const [limit, setLimit] = useState(8);
  const [sort, setSort] = useState({
    sort_order: 0,
    sort_column: "date_saisie",
  });
  const [skip, setSkip] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const [query, setQuery] = useState("");

  const { enqueueSnackbar: showMessage } = useSnackbar();

  const history = useHistory();

  const permissions = [isAllowed([viewToxiCasesPermissions[3]]), isAllowed([viewToxiCasesPermissions[1]]), isAllowed([viewToxiCasesPermissions[2]]), isAllowed([viewLaboCasesPermissions[3]]), isAllowed([viewLaboCasesPermissions[1]]), isAllowed([viewLaboCasesPermissions[2]])]

  useEffect(() => {
    setLoading(true);
    if (category.toLowerCase() === "toxicovigilance") {
      getToxiCases(limit, skip, query, sort)
        .then((data) => {
          setCases(data.declarations);
          setTotalCases(data.nombre);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.toString(), { variant: "error" });
        });
    } else {
      getLaboCases(limit, skip, query, sort)
        .then((data) => {
          setCases(data.declarations);
          setTotalCases(data.nombre);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.toString(), { variant: "error" });
        });
    }
  }, [category, skip, limit, totalCases, query, sort]);

  useEffect(() => {
    setQuery(declarationId.toString());
    setSearchText(declarationId.toString());
  }, [declarationId]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setSearchText(e.target.value);
  };

  const editCase = (id) => {
    onEditMode(true);
    history.push({
      pathname: "/main/add-cases",
      state: { selectedCase: id.toString(), category: category },
    });
  };

  return (
    <div className="view-cases-container">
      <Stack direction="column" spacing={0} style={{ width: "100%" }}>
        <div className="view-cases-research">
          <Stack direction="row-reverse" spacing={0} style={{ width: "100%" }}>
            <OutlinedInput
              placeholder="Rechercher un cas…"
              onChange={debounce(handleSearch, 1000)}
              endAdornment={<SearchIcon htmlColor="#aaaaaa" />}
            />
          </Stack>
        </div>
        <div className="view-cases-datagrid">
          <ViewCaseDataGrid
            columnNames={
              category.toLowerCase() === "toxicovigilance"
                ? toxicoColumnNames
                : laboColumnNames
            }
            fieldNames={
              category.toLowerCase() === "toxicovigilance" ? toxicoFieldNames : laboFieldNames
            }
            permissions={
              category.toLowerCase() === "toxicovigilance" ? [permissions[0], permissions[1], permissions[2]] : [permissions[3], permissions[4], permissions[5]]
            }
            activTab={category}
            onSetSkip={setSkip}
            cases={cases}
            onSetSort={setSort}
            totalCases={totalCases}
            filter={searchText}
            loading={loading}
            onSetCases={setCases}
            preview={previewCase}
            onPreview={setPreviewCase}
            onShowModal={setShowModal}
            editCase={editCase}
          />
        </div>
        <PreviewCaseModal
          showModal={showModal}
          onShowModal={setShowModal}
          previewCase={previewCase}
          editCase={() => editCase(previewCase.toString())}
          category={category}
        />
      </Stack>
    </div>
  );
}
