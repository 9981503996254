import React from "react";
import LoginComponent from "../../components/Login/Login";
import "./index.scss";

export default function Login() {
  return (
    <>
      {/* <img src='/assets/imgs/login_left_side.png' width='100%' alt="" /> */}
      <LoginComponent />
    </>
  );
}
