/* eslint-disable default-case */
import moment from "moment";
import { getSecureAxiosInstance } from "../../utils/axiosInstances";

import {
  getBEFormatEchantillons,
  getBEFormatProduitTeste,
  getBEFormatProduitDosage,
  getBEFormatResultatRecherche,
} from "../../utils/divers/divers";



const getTotalCases = async () => {
  const { data } = await getSecureAxiosInstance().get(`common/nombre-cases`);
  return data;
};
const getToxiCases = async (limit, skip, query, sort) => {
  const { data } = await getSecureAxiosInstance().post(
    `toxico-case-management/get-declarations`,
    {
      limit: limit,
      skip: skip,
      sort_order: sort.sort_order,
      sort_column: sort.sort_column,
      query: query,
    }
  );
  return data;
};
const getLaboCases = async (limit, skip, query, sort) => {
  const { data } = await getSecureAxiosInstance().post(
    `labo-case-management/get-declarations`,
    {
      limit: limit,
      skip: skip,
      sort_order: sort.sort_order,
      sort_column: sort.sort_column,
      query: query,
    }
  );
  return data;
};

const getToxiDraftCases = async (limit, skip, query, sort) => {
  const { data } = await getSecureAxiosInstance().post(
    "toxico-case-management/brouillon-case",
    {
      limit: limit,
      skip: skip,
      sort_order: sort.sort_order,
      sort_column: sort.sort_column,
      query: query,
    }
  );
  return data;
};
const getLaboDraftCases = async (limit, skip, query, sort) => {
  const { data } = await getSecureAxiosInstance().post(
    "labo-case-management/brouillon-case",
    {
      limit: limit,
      skip: skip,
      sort_order: sort.sort_order,
      sort_column: sort.sort_column,
      query: query,
    }
  );
  return data;
};

const getToValidateToxiCases = async (limit, skip, query, sort) => {
  const { data } = await getSecureAxiosInstance().post(
    "toxico-case-management/tovalidate-case",
    {
      limit: limit,
      skip: skip,
      sort_order: sort.sort_order,
      sort_column: sort.sort_column,
      query: query,
    }
  );
  return data;
};
const getToValidateLaboCases = async (limit, skip, query, sort) => {
  const { data } = await getSecureAxiosInstance().post(
    "labo-case-management/tovalidate-case",
    {
      limit: limit,
      skip: skip,
      sort_order: sort.sort_order,
      sort_column: sort.sort_column,
      query: query,
    }
  );
  return data;
};

const getCasebyId = async (caseId, category) => {
  const { data } = await getSecureAxiosInstance().get(
    (category.toLowerCase() === "toxicovigilance"
      ? "toxico-case-management/get-declaration/"
      : "labo-case-management/get-declaration/") + caseId
  );
  return data;
};
const getLatestNotifications = async () => {
  const { data } = await getSecureAxiosInstance().get(
    "common/lastnotifications"
  );
  return data;
};

const getAllNotifications = async (number) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/allnotifications/" + number
  );
  return data;
};

const checkNotification = async (notificationId) => {
  const { data } = await getSecureAxiosInstance().post(
    "common/checknotification/" + notificationId
  );
  return data;
};

const getNumberLastNotifications = async () => {
  const { data } = await getSecureAxiosInstance().get(
    "common/number-lastnotifications"
  );
  return data;
};

const getLaboCategories = async (categorie) => {
  const { data } = await getSecureAxiosInstance().get(
    `/referentiels/entree-nom-by-categorie?categorie=${categorie}`
  );
  return data;
};

const getMeddraElements = async () => {

  const { data } = await getSecureAxiosInstance().get(
    `/common/medra-elements`
  );
  return data;
}

const extractData = async (min_date, max_date, categories, type) => {
  await getSecureAxiosInstance()
    .get(
      `${type === "labo"
        ? `labo-case-management/export-case?min_date=${min_date}&max_date=${max_date}&types_resultats=${categories}`
        : `toxico-case-management/export-case?min_date=${min_date}&max_date=${max_date}&toxiques=${categories}`
      }`,
      {
        method: "GET",
        responseType: "blob",
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `declarations.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
};

const addToxiCase = async () => {
  const { data } = await getSecureAxiosInstance().post(
    "toxico-case-management/create-declaration"
  );
  return data;
};
const toxiCaseValidation = async (declarationId) => {
  const { data } = await getSecureAxiosInstance().post(
    "toxico-case-management/validate-case/" + declarationId
  );
  return data;
};
const laboCaseValidation = async (declarationId) => {
  const { data } = await getSecureAxiosInstance().post(
    "labo-case-management/validate-case/" + declarationId
  );
  return data;
};

const addLaboCase = async () => {
  const { data } = await getSecureAxiosInstance().post(
    "labo-case-management/create-declaration-labo"
  );
  return data;
};

const getRegion = async (communeId, provinceId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-region/" + communeId + "/" + provinceId
  );
  return data;
};

const getProvince = async (communeId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-province/" + communeId
  );
  return data;
};

const getRegionWithProvince = async (provinceId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-region-with-province/" + provinceId
  );
  return data;
};


const getProvinces = async (regionId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-provinces/" + regionId
  );
  return data;
}

const getCommunes = async (provinceId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-communes/" + provinceId
  );
  return data;
}

const getProduitMedicamentDetails = async (dciId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-produit-medicament-details/" + dciId
  );
  return data;
};

const getProduitMedicamentList = async (nomProduit) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-produit-medicament-list/" + nomProduit
  );
  return data;
}


const getPlanteDetails = async (nomId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-plante-details/" + nomId
  );
  return data;
};

const getProduitPesticideDetails = async (produitId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-produit-pesticide-details/" + produitId
  );
  return data;
};

const getClasseMenagerDetails = async (classeId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-classe-menager-details/" + classeId
  );
  return data;
};

const getProduitEngraisDetails = async (produitId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-produit-engrais-details/" + produitId
  );
  return data;
};

const getFamilleDrogueDetails = async (familleId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-famille-drogue-details/" + familleId
  );
  return data;
};

const getProduitGazDetails = async (produitId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-produit-gaz-details/" + produitId
  );
  return data;
};

const getProduitIndustrielDetails = async (produitId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-produit-industriel-details/" + produitId
  );
  return data;
};


const getEspeceAnimaleDetails = async (especeId) => {
  const { data } = await getSecureAxiosInstance().get(
    "common/get-espece-animale-details/" + especeId
  );
  return data;
};

const getProduitCosmetiqueDetails = async (produitId) => {
  const { data } = await await getSecureAxiosInstance().get(
    "common/get-produit-cosmetique-details/" + produitId
  );
  return data;
};

const getProduitAlimentDetails = async (produitId) => {
  const { data } = await await getSecureAxiosInstance().get(
    "common/get-produit-aliment-details/" + produitId
  );
  return data;
};

const getMedDRASymptomes = async (termes) => {
  const { data } = await getSecureAxiosInstance().post(
    "common/meddra-symptomes",
    { "termes": termes }
  );
  return data;
}

const getCaseDrafts = async () => {
  const { data } = await getSecureAxiosInstance().get(
    "toxico-case-management/brouillon-case"
  );
  return data;
};


const validateDeclaration = async (declarationId, activTab) => {
  let endPoint = "";
  if (activTab.toLowerCase() === "toxicovigilance") {
    endPoint = "toxico-case-management/validate-declaration/" + declarationId
  } else {
    endPoint = "labo-case-management/validate-declaration/" + declarationId
  }
  const { data } = await getSecureAxiosInstance().post(endPoint);
  return data;
};
const rejectDeclaration = async (declarationId, activTab) => {
  let endPoint = "";
  if (activTab.toLowerCase() === "toxicovigilance") {
    endPoint = "toxico-case-management/unvalidate-declaration/" + declarationId
  } else {
    endPoint = "labo-case-management/unvalidate-declaration/" + declarationId
  }
  const { data } = await getSecureAxiosInstance().post(endPoint);
  return data;
};

const deleteCase = async (declarationId, category) => {
  const { data } = await getSecureAxiosInstance().post(
    (category.toLowerCase() === "toxicovigilance"
      ? "toxico-case-management/delete-declaration/"
      : "labo-case-management/delete-declaration/") + declarationId
  );

  return data;
};

const deleteDraftDeclaration = async (declarationId) => {
  // const { data } = await getSecureAxiosInstance().post(
  //   "toxico-case-management/unvalidate-declaration//" + declarationId
  // );
  // return data;
};

const addLaboStep = async (stepIndex, values) => {
  let payload = {};
  payload.declaration_labo_id = values.id;
  switch (stepIndex) {
    case 0:
      payload.step_name = "prescripteur";
      payload.step_data = {
        dossier: {
          ...values.prescripteur.dossier,
          heure_reception: values.prescripteur.dossier.heure_reception || null
        },
        prescripteur: {
          ...values.prescripteur.prescripteur,
          commune_id: values.prescripteur.prescripteur.commune_id || null,
          province_id: values.prescripteur.prescripteur.province_id || null,
          region_id: values.prescripteur.prescripteur.region_id || null,
          provenance_id: values.prescripteur.prescripteur.provenance_id || null,
          service_id: values.prescripteur.prescripteur.service_id || null
        },
      };
      payload.step_data.dossier.date_reception = moment(
        payload.step_data.dossier.date_reception
      ).format("YYYY-MM-DD");
      break;
    case 1:
      payload.step_name = "patient";
      payload.step_data = {
        nom_prenom: values.patient.nom_prenom,
        telephone: values.patient.telephone,
        sexe: values.patient.sexe,
        age_chiffre: values.patient.age_chiffre,
        age_unite: values.patient.age_unite,
        tranche_age: values.patient.tranche_age || null,
        commune_id: values.patient.commune_id || null,
        province_id: values.patient.province_id || null,
        region_id: values.patient.region_id || null,
        milieu: values.patient.milieu,
        poids: values.patient.poids || null,
        grossesse: values.patient.grossesse,
        nombre_semaines: values.patient.nombre_semaines,
        allaitement: values.patient.allaitement,
        profession_id: values.patient.profession_id || null,
        antecedents: values.patient.antecedents,
        historique: values.patient.historique,
      };
      if (values.patient.sexe !== "0") {
        payload.step_data.grossesse = 0;
        payload.step_data.nombre_semaines = 0;
        payload.step_data.allaitement = 0;
      }
      break;
    case 2:
      payload.step_name = "toxique";
      payload.step_data = {
        aliments: values.toxiques.aliments?.map(aliments => ({
          ...aliments,
          produit_aliment_id: aliments.produit_aliment_id || null,
          voie_intoxication_id: aliments.voie_intoxication_id || null
        })),
        autre: values.toxiques.autre?.map(autre => ({
          ...autre,
          classe_id: autre.classe_id || null,
          voie_intoxication_id: autre.voie_intoxication_id || null
        })),
        autre_animal_venimeux: values.toxiques.autre_animal_venimeux?.map(autre_animal_venimeux => ({
          ...autre_animal_venimeux,
          espece_id: autre_animal_venimeux.espece_id || null,
          voie_intoxication_id: autre_animal_venimeux.voie_intoxication_id || null
        })),
        cosmetique: values.toxiques.cosmetique?.map(cosmetique => ({
          ...cosmetique,
          presentation_id: cosmetique.presentation_id || null,
          voie_intoxication_id: cosmetique.voie_intoxication_id || null
        })),
        drogue: values.toxiques.drogue?.map(drogue => ({
          ...drogue,
          famille_drogue_id: drogue.famille_drogue_id || null,
          voie_intoxication_id: drogue.voie_intoxication_id || null
        })),
        gaz: values.toxiques.gaz?.map(gaz => ({
          ...gaz,
          produit_gaz_id: gaz.produit_gaz_id || null,
          source_id: gaz.source_id || null,
          voie_intoxication_id: gaz.voie_intoxication_id || null
        })),
        inconnu: values.toxiques.inconnu?.map(inconnu => ({
          ...inconnu,
          classe_id: inconnu.classe_id || null,
          voie_intoxication_id: inconnu.voie_intoxication_id || null
        })),
        medicaments: values.toxiques.medicaments?.map(medicaments => ({
          ...medicaments,
          produit_medicament_id: medicaments.produit_medicament_id || null,
          voie_intoxication_id: medicaments.voie_intoxication_id || null
        })),
        medicaments_veterinaires: values.toxiques.medicaments_veterinaires.map(medicaments_veterinaires => ({
          ...medicaments_veterinaires,
          produit_medicament_id: medicaments_veterinaires.produit_medicament_id || null,
          voie_intoxication_id: medicaments_veterinaires.voie_intoxication_id || null
        })),
        metaux_lourds: values.toxiques.metaux_lourds,
        pesticide: values.toxiques.pesticide?.map(pesticide => ({
          ...pesticide,
          usage_notificateur_id: pesticide.usage_notificateur_id || null,
          voie_intoxication_id: pesticide.voie_intoxication_id || null
        })),
        engrais: values.toxiques.engrais?.map(engrais => ({
          ...engrais,
          usage_notificateur_id: engrais.usage_notificateur_id || null,
          voie_intoxication_id: engrais.voie_intoxication_id || null
        })),
        plante: values.toxiques.plante?.map(plante => ({
          ...plante,
          partie_utilisee_id: plante.partie_utilisee_id || null,
          presentation_id: plante.presentation_id || null,
          mode_preparation_id: plante.mode_preparation_id || null,
          conseillee_media: plante.conseillee_media || null,
          voie_intoxication_id: plante.voie_intoxication_id || null,
        })),
        produit_industriel: values.toxiques.produit_industriel?.map(produit_industriel => ({
          ...produit_industriel,
          produit_industriel_id: produit_industriel.produit_industriel_id || null,
          presentation_id: produit_industriel.presentation_id || null,
          voie_intoxication_id: produit_industriel.conseillee_media || null,
        })),
        produit_menager: values.toxiques.produit_menager?.map(produit_menager => ({
          ...produit_menager,
          presentation_id: produit_menager.presentation_id || null,
          voie_intoxication_id: produit_menager.voie_intoxication_id || null,
        })),
        scorpion: values.toxiques.scorpion?.map(scorpion => ({
          ...scorpion,
          voie_intoxication_id: scorpion.voie_intoxication_id || null,
        })),
        serpent: values.toxiques.serpent,
      };
      break;
    case 3:
      payload.step_name = "intoxication";
      payload.step_data = {
        date_intoxication: moment(
          values.intoxication.intoxication.date_intoxication
        ).format("YYYY-MM-DD").replace("Invalid date", "") || null,
        heure: values.intoxication.intoxication.heure || null,
        delai_intoxication_j: values.intoxication.intoxication.delai_intoxication_j || null,
        delai_intoxication_h: values.intoxication.intoxication.delai_intoxication_h || null,
        delai_intoxication_m: values.intoxication.intoxication.delai_intoxication_m || null,
        lieu_intoxication_id: values.intoxication.intoxication.lieu_intoxication_id || null,
        autre_lieu_intoxication: values.intoxication.intoxication.autre_lieu_intoxication,
        type_risque_id: values.intoxication.intoxication.type_risque_id || null,
        type_intoxication: values.intoxication.intoxication.type_intoxication,
        nombre_cas: values.intoxication.intoxication.nombre_cas,
        relation_toxique_symptomologie:
          values.intoxication.symptomatologie.relation_toxique_symptomologie,
        symptomatique: values.intoxication.symptomatologie.symptomatique,
        gradation_initiale_id:
          values.intoxication.symptomatologie.gradation_initiale_id || null,
        terme_reaction_id: values.intoxication.symptomatologie.terme_reaction_id || null,
        termes: values.intoxication.symptomatologie.termes,

        relance: values.intoxication.evolution.relance,
        date_relance: values.intoxication.evolution.date_relance || null,
        hospitalisation: values.intoxication.evolution.hospitalisation,
        duree_hospitalisation:
          values.intoxication.evolution.duree_hospitalisation,
        sequelles: values.intoxication.evolution.sequelles,
        sequelles_details: values.intoxication.evolution.sequelles_details,
        historique: values.intoxication.evolution.historique,
        gradation_finale_id: values.intoxication.evolution.gradation_finale_id || null,
        evolution_id: values.intoxication.evolution.evolution_id || null,
        imputabilite: {
          imputabilite_resultat_id: values.intoxication.evolution.imputabilite.imputabilite || null,
          exposition_id: values.intoxication.evolution.imputabilite.exposition || null,
          symptomatologie_id: values.intoxication.evolution.imputabilite.symptomatologie || null,
          chronologie_id: values.intoxication.evolution.imputabilite.chronologie || null,
          caracterisation_causale_id: values.intoxication.evolution.imputabilite.caracterisation_causale || null,
          diagnostic_differentiel_id: values.intoxication.evolution.imputabilite.diagnostique_differentiel || null,
          lien_extrinseque_id: values.intoxication.evolution.imputabilite.lien_extrinseque || null,
        },
      };
      break;
    case 4:
      payload.step_name = "echantillon";
      payload.step_data = {
        heure_prelevement: values.echantillon.heure_prelevement,
        echantillon_examens_demandes: values.echantillon.examen_demande,
        echantillons_nature: getBEFormatEchantillons(
          values.echantillon.echantillons
        ),
      };
      break;
    case 5:
      payload.step_name = "resultat";
      payload.step_data = {
        LC: {
          recherche_dosage: values.resultat.LC.recherche_dosage,
          resultat_analyse: values.resultat.LC.resultat_analyse,
          produits_testes: getBEFormatProduitTeste(
            values.resultat.LC.produits_testes
          ),
        },
        PTRR: {
          resultat_analyse: values.resultat.PTRR.resultat_analyse,
          resultats_dosage: getBEFormatProduitDosage(
            values.resultat.PTRR.resultats_dosage
          ),
          resultats_recherche: getBEFormatResultatRecherche(
            values.resultat.PTRR.resultats_recherche
          ),
        },
        SAA: {
          resultat_analyse: values.resultat.SAA.resultat_analyse,
          resultats: getBEFormatProduitDosage(
            values.resultat.SAA.resultats
          ),
        },
        GC: {
          recherche_dosage: values.resultat.GC.recherche_dosage,
          resultat_analyse: values.resultat.GC.resultat_analyse,
          produits_testes: getBEFormatProduitTeste(
            values.resultat.GC.produits_testes
          ),
        },
      };
      break;
    case 6:
      payload.step_name = "analyste";
      payload.step_data = {
        analyste_id: values.analyste.analyste_id,
        observation: values.analyste.observation,
      };
      break;
  }
  const { result } = await getSecureAxiosInstance().post(
    "labo-case-management/save-step",
    payload
  );
  return result;
};

const addToxiStep = async (stepIndex, values) => {
  let payload = {};
  payload.declaration_id = values.id;
  switch (stepIndex) {
    case 0:
      payload.step_name = "declarant";
      payload.step_data = {
        rapport: {
          ...values.declarant.rapport,
          heure_reception: values.declarant.rapport.heure_reception || null
        },
        declarant: {
          ...values.declarant.declarant,
          commune_id: values.declarant.declarant.commune_id || null,
          province_id: values.declarant.declarant.province_id || null,
          region_id: values.declarant.declarant.region_id || null,
          provenance_id: values.declarant.declarant.provenance_id || null,
          service_id: values.declarant.declarant.service_id || null
        },
      };
      payload.step_data.rapport.date_reception = moment(
        payload.step_data.rapport.date_reception
      ).format("YYYY-MM-DD");
      break;
    case 1:
      payload.step_name = "patient";
      payload.step_data = {
        nom_prenom: values.patient.nom_prenom,
        telephone: values.patient.telephone,
        sexe: values.patient.sexe,
        age_chiffre: values.patient.age_chiffre,
        age_unite: values.patient.age_unite,
        tranche_age: values.patient.tranche_age || null,
        commune_id: values.patient.commune_id || null,
        province_id: values.patient.province_id || null,
        region_id: values.patient.region_id || null,
        milieu: values.patient.milieu,
        poids: values.patient.poids || null,
        grossesse: values.patient.grossesse,
        nombre_semaines: values.patient.nombre_semaines,
        allaitement: values.patient.allaitement,
        profession_id: values.patient.profession_id || null,
        antecedents: values.patient.antecedents,
        historique: values.patient.historique,
      };
      if (values.patient.sexe !== "0") {
        payload.step_data.grossesse = 0;
        payload.step_data.nombre_semaines = 0;
        payload.step_data.allaitement = 0;
      }
      break;
    case 2:
      payload.step_name = "toxique";
      payload.step_data = {
        aliments: values.toxiques.aliments?.map(aliments => ({
          ...aliments,
          produit_aliment_id: aliments.produit_aliment_id || null,
          voie_intoxication_id: aliments.voie_intoxication_id || null
        })),
        autre: values.toxiques.autre?.map(autre => ({
          ...autre,
          classe_id: autre.classe_id || null,
          voie_intoxication_id: autre.voie_intoxication_id || null
        })),
        autre_animal_venimeux: values.toxiques.autre_animal_venimeux?.map(autre_animal_venimeux => ({
          ...autre_animal_venimeux,
          espece_id: autre_animal_venimeux.espece_id || null,
          voie_intoxication_id: autre_animal_venimeux.voie_intoxication_id || null
        })),
        cosmetique: values.toxiques.cosmetique?.map(cosmetique => ({
          ...cosmetique,
          presentation_id: cosmetique.presentation_id || null,
          voie_intoxication_id: cosmetique.voie_intoxication_id || null
        })),
        drogue: values.toxiques.drogue?.map(drogue => ({
          ...drogue,
          famille_drogue_id: drogue.famille_drogue_id || null,
          voie_intoxication_id: drogue.voie_intoxication_id || null
        })),
        gaz: values.toxiques.gaz?.map(gaz => ({
          ...gaz,
          produit_gaz_id: gaz.produit_gaz_id || null,
          source_id: gaz.source_id || null,
          voie_intoxication_id: gaz.voie_intoxication_id || null
        })),
        inconnu: values.toxiques.inconnu?.map(inconnu => ({
          ...inconnu,
          classe_id: inconnu.classe_id || null,
          voie_intoxication_id: inconnu.voie_intoxication_id || null
        })),
        medicaments: values.toxiques.medicaments?.map(medicaments => ({
          ...medicaments,
          produit_medicament_id: medicaments.produit_medicament_id || null,
          forme_id: medicaments.forme_id || null,
          voie_intoxication_id: medicaments.voie_intoxication_id || null
        })),
        medicaments_veterinaires: values.toxiques.medicaments_veterinaires?.map(medicaments_veterinaires => ({
          ...medicaments_veterinaires,
          produit_medicament_id: medicaments_veterinaires.produit_medicament_id || null,
          forme_id: medicaments_veterinaires.forme_id || null,
          voie_intoxication_id: medicaments_veterinaires.voie_intoxication_id || null
        })),
        metaux_lourds: values.toxiques.metaux_lourds,
        pesticide: values.toxiques.pesticide?.map(pesticide => ({
          ...pesticide,
          usage_notificateur_id: pesticide.usage_notificateur_id || null,
          voie_intoxication_id: pesticide.voie_intoxication_id || null
        })),
        engrais: values.toxiques.engrais?.map(engrais => ({
          ...engrais,
          usage_notificateur_id: engrais.usage_notificateur_id || null,
          voie_intoxication_id: engrais.voie_intoxication_id || null
        })),
        plante: values.toxiques.plante?.map(plante => ({
          ...plante,
          partie_utilisee_id: plante.partie_utilisee_id || null,
          presentation_id: plante.presentation_id || null,
          mode_preparation_id: plante.mode_preparation_id || null,
          conseillee_media: plante.conseillee_media || null,
          voie_intoxication_id: plante.voie_intoxication_id || null,
        })),
        produit_industriel: values.toxiques.produit_industriel?.map(produit_industriel => ({
          ...produit_industriel,
          produit_industriel_id: produit_industriel.produit_industriel_id || null,
          presentation_id: produit_industriel.presentation_id || null,
          voie_intoxication_id: produit_industriel.conseillee_media || null,
        })),
        produit_menager: values.toxiques.produit_menager?.map(produit_menager => ({
          ...produit_menager,
          presentation_id: produit_menager.presentation_id || null,
          voie_intoxication_id: produit_menager.voie_intoxication_id || null,
        })),
        scorpion: values.toxiques.scorpion?.map(scorpion => ({
          ...scorpion,
          voie_intoxication_id: scorpion.voie_intoxication_id || null,
        })),
        serpent: values.toxiques.serpent,
      };
      break;
    case 3:
      payload.step_name = "intoxication";
      payload.step_data = {
        date_intoxication: moment(
          values.intoxication.intoxication.date_intoxication
        ).format("YYYY-MM-DD").replace("Invalid date", "") || null,
        heure: values.intoxication.intoxication.heure || null,
        delai_intoxication_j: values.intoxication.intoxication.delai_intoxication_j || null,
        delai_intoxication_h: values.intoxication.intoxication.delai_intoxication_h || null,
        delai_intoxication_m: values.intoxication.intoxication.delai_intoxication_m || null,
        type_intoxication: values.intoxication.intoxication.type_intoxication,
        nombre_cas: values.intoxication.intoxication.nombre_cas,
        relation_toxique_symptomologie:
          values.intoxication.symptomatologie.relation_toxique_symptomologie,
        terme_reaction_id: values.intoxication.symptomatologie.terme_reaction_id || null,
        termes: values.intoxication.symptomatologie.termes,
        symptomatique: values.intoxication.symptomatologie.symptomatique,
        relance: values.intoxication.evolution.relance,
        date_relance: values.intoxication.evolution.date_relance || null,
        hospitalisation: values.intoxication.evolution.hospitalisation,
        duree_hospitalisation:
          values.intoxication.evolution.duree_hospitalisation,
        sequelles: values.intoxication.evolution.sequelles,
        sequelles_details: values.intoxication.evolution.sequelles_details,
        historique: values.intoxication.evolution.historique,
        erreur_medicamenteuse:
          values.intoxication.intoxication.erreur_medicamenteuse,
        erreur_medicamenteuse_erreur_circuit: values.intoxication.intoxication.erreur_medicamenteuse_erreur_circuit,
        erreur_medicamenteuse_type_erreur: values.intoxication.intoxication.erreur_medicamenteuse_type_erreur,
        erreur_medicamenteuse_cause_erreur: values.intoxication.intoxication.erreur_medicamenteuse_cause_erreur,
        erreur_medicamenteuse_autre_information: values.intoxication.intoxication.erreur_medicamenteuse_autre_information,

        lieu_intoxication_id:
          values.intoxication.intoxication.lieu_intoxication_id || null,
        autre_lieu_intoxication: values.intoxication.intoxication.autre_lieu_intoxication,
        circonstance_intoxication_id: values.intoxication.intoxication.circonstance_intoxication_id || null,
        autre_circonstance_intoxication: values.intoxication.intoxication.autre_circonstance_intoxication,
        type_risque_id: values.intoxication.symptomatologie.type_risque_id || null,
        gradation_initiale_id:
          values.intoxication.symptomatologie.gradation_initiale_id || null,
        gradation_finale_id: values.intoxication.evolution.gradation_finale_id || null,
        evolution_id: values.intoxication.evolution.evolution_id || null,
        imputabilite: {
          imputabilite_resultat_id: values.intoxication.evolution.imputabilite.imputabilite || null,
          exposition_id: values.intoxication.evolution.imputabilite.exposition || null,
          symptomatologie_id: values.intoxication.evolution.imputabilite.symptomatologie || null,
          chronologie_id: values.intoxication.evolution.imputabilite.chronologie || null,
          caracterisation_causale_id: values.intoxication.evolution.imputabilite.caracterisation_causale || null,
          diagnostic_differentiel_id: values.intoxication.evolution.imputabilite.diagnostique_differentiel || null,
          lien_extrinseque_id: values.intoxication.evolution.imputabilite.lien_extrinseque || null,
        },
        traitement_avant: {
          precise: values.intoxication.traitement_avant.precise,
          abstention_therapeutique:
            values.intoxication.traitement_avant.abstention_therapeutique,
          antidotes: values.intoxication.traitement_avant.antidotes,
          bilan_biologique:
            values.intoxication.traitement_avant.bilan_biologique,
          bilan_radiologique:
            values.intoxication.traitement_avant.bilan_radiologique,
          bilan_toxicologique:
            values.intoxication.traitement_avant.bilan_toxicologique,
          resultat_bilan_toxicologique: values.intoxication.traitement_avant.resultat_bilan_toxicologique,
          consultation_specialisee:
            values.intoxication.traitement_avant.consultation_specialisee,
          decontaminations:
            values.intoxication.traitement_avant.decontaminations,
          epurations: values.intoxication.traitement_avant.epurations,
          orientation: values.intoxication.traitement_avant.orientation,
          surveillance: values.intoxication.traitement_avant.surveillance,
          traitement_symptomatique_id:
            values.intoxication.traitement_avant.traitement_symptomatique_id || null,
        },
        traitement_capm: {
          precise: values.intoxication.traitement_capm.precise,
          abstention_therapeutique:
            values.intoxication.traitement_capm.abstention_therapeutique,
          antidotes: values.intoxication.traitement_capm.antidotes,
          bilan_biologique:
            values.intoxication.traitement_capm.bilan_biologique,
          bilan_radiologique:
            values.intoxication.traitement_capm.bilan_radiologique,
          bilan_toxicologique:
            values.intoxication.traitement_capm.bilan_toxicologique,
          consultation_specialisee:
            values.intoxication.traitement_capm.consultation_specialisee,
          decontaminations:
            values.intoxication.traitement_capm.decontaminations,
          epurations: values.intoxication.traitement_capm.epurations,
          orientation: values.intoxication.traitement_capm.orientation,
          surveillance: values.intoxication.traitement_capm.surveillance,
          traitement_symptomatique_id:
            values.intoxication.traitement_capm.traitement_symptomatique_id || null,
          resultat_synthese:
            values.intoxication.traitement_capm.resultat_synthese,
        },
        traitement_effectue: {
          precise: values.intoxication.traitement_effectue.precise,
          abstention_therapeutique:
            values.intoxication.traitement_effectue.abstention_therapeutique,
          antidotes: values.intoxication.traitement_effectue.antidotes,
          bilan_biologique:
            values.intoxication.traitement_effectue.bilan_biologique,
          bilan_radiologique:
            values.intoxication.traitement_effectue.bilan_radiologique,
          bilan_toxicologique:
            values.intoxication.traitement_effectue.bilan_toxicologique,
          consultation_specialisee:
            values.intoxication.traitement_effectue.consultation_specialisee,
          decontaminations:
            values.intoxication.traitement_effectue.decontaminations,
          epurations: values.intoxication.traitement_effectue.epurations,
          orientation: values.intoxication.traitement_effectue.orientation,
          surveillance: values.intoxication.traitement_effectue.surveillance,
          traitement_symptomatique_id:
            values.intoxication.traitement_effectue.traitement_symptomatique_id || null,
          nombre_cas: values.intoxication.traitement_effectue.nombre_cas,
          resultat_synthese:
            values.intoxication.traitement_effectue.resultat_synthese,
        },
      };
      break;
    case 4:
      payload.step_name = "repondeur";
      payload.step_data = {
        repondeur_id: values.repondeur.repondeur_id || null,
        delai_reponse_id: values.repondeur.delai_reponse_id || null,
        signal: values.repondeur.signal || null,
      };
      break;
  }
  const { result } = await getSecureAxiosInstance().post(
    "toxico-case-management/save-step",
    payload
  );
  return result;
};

export {
  getTotalCases,
  getToxiCases,
  getLaboCases,
  getToxiDraftCases,
  laboCaseValidation,
  getLaboDraftCases,
  getCasebyId,
  extractData,
  getLaboCategories,
  getToValidateToxiCases,
  getToValidateLaboCases,
  toxiCaseValidation,
  validateDeclaration,
  rejectDeclaration,
  deleteCase,
  deleteDraftDeclaration,
  getLatestNotifications,
  getNumberLastNotifications,
  getAllNotifications,
  checkNotification,
  addToxiCase,
  addLaboCase,
  addToxiStep,
  addLaboStep,
  getRegion,
  getProvince,
  getProduitMedicamentDetails,
  getProduitMedicamentList,
  getProduitAlimentDetails,
  getProduitPesticideDetails,
  getProduitEngraisDetails,
  getFamilleDrogueDetails,
  getProduitGazDetails,
  getProduitIndustrielDetails,
  getEspeceAnimaleDetails,
  getProduitCosmetiqueDetails,
  getPlanteDetails,
  getClasseMenagerDetails,
  getProvinces,
  getCommunes,
  getRegionWithProvince,
  getMeddraElements,
  getMedDRASymptomes
};
