import React, { useEffect } from "react";
import {
  Select,
  Grid,
  MenuItem,
  FormHelperText,
  ListSubheader,
} from "@mui/material";
import "./index.scss";

const LabelGroupMenu = React.memo((props) => {
  const {
    title,
    name,
    value,
    onChange,
    helperText,
    error,
    valuesDict,
    mandatory,
    disabled,
  } = props;
  let isDisabled = disabled === undefined ? false : true;
  let fontWeight = props.boldTitle === undefined ? 600 : 400;
  let redFlag = "";
  if (mandatory == true) {
    redFlag = <span style={{ color: "red" }}>&nbsp;*</span>;
  }
  return (
    <div style={{ textAlign: "right" }}>
      <Grid container spacing={0.5} direction="column">
        <Grid container spacing={0} direction="row">
          <Grid item xs={12}>
            <div>
              <label
                className="label-input-label"
                style={{
                  fontWeight: fontWeight,
                }}
              >
                {title}
                {redFlag}
              </label>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: 0 }}>
          <Select
            disabled={isDisabled}
            name={name}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            error={error}
            inputProps={{
              style: {
                boxSizing: "border-box",
                height: "42px",
              },
            }}
            style={{
              height: "42px",
              width: "100%",
              textAlign: "left",
              backgroundColor: "#FFFFFF",
            }}
          >

            <ListSubheader
              style={{ fontWeight: "bold", fontSize: "large", color: "purple" }}
            >
              Passif
            </ListSubheader>
            <MenuItem value={5} style={{ marginLeft: "15px" }}>
              Autre
            </MenuItem>
            <MenuItem value={6} style={{ marginLeft: "15px" }}>
              Consultation sur place
            </MenuItem>
            <MenuItem value={7} style={{ marginLeft: "15px" }}>
              Courrier
            </MenuItem>
            <MenuItem value={8} style={{ marginLeft: "15px" }}>
              Dossier d'hospitalisation
            </MenuItem>
            <MenuItem value={9} style={{ marginLeft: "15px" }}>
              Fiche de déclaration d'un cas overdose
            </MenuItem>
            <MenuItem value={10} style={{ marginLeft: "15px" }}>
              Registre
            </MenuItem>
            <MenuItem value={11} style={{ marginLeft: "15px" }}>
              Réponse téléphonique
            </MenuItem>
            <MenuItem value={12} style={{ marginLeft: "15px" }}>
              Site Web
            </MenuItem>
            <ListSubheader
              style={{ fontWeight: "bold", fontSize: "large", color: "purple" }}
            >
              Actif
            </ListSubheader>

            <MenuItem value={1} style={{ marginLeft: "15px" }}>
              Autre
            </MenuItem>
            <MenuItem value={2} style={{ marginLeft: "15px" }}>
              Etudes
            </MenuItem>
            <MenuItem value={3} style={{ marginLeft: "15px" }}>
              Littérature
            </MenuItem>
            <MenuItem value={4} style={{ marginLeft: "15px" }}>
              Média
            </MenuItem>
            {/* {
                                          groups.map((group, index) => {
                                                <ListSubheader key={group} style={{fontWeight:"bold", fontSize:"large", color:'purple'}}>{group}</ListSubheader>
                                                {
                                                      Object.keys(valuesDict[group]).map((item, subIndex) => (
                                                            <MenuItem style={{marginLeft:'15px'}} key={item} value={item}>{valuesDict[group][item]}</MenuItem>
                                                      ))  
                                                }
                                          })
                                    } */}
          </Select>
          <FormHelperText style={{ color: "#d32f2f", marginLeft: "14px" }}>
            {helperText}
          </FormHelperText>
        </Grid>
      </Grid>
    </div>
  );
});

export default LabelGroupMenu;
