import React from "react";
import { Stack } from "@mui/material";
import "../../index.scss";
import LabelInput from "../../../LabelInput/LabelInput";
import { useFormikContext } from "formik";

export default function Patient(props) {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const widthPercentage = "31%";

  return (
    <div className="case-common-container">
      <div className="case-common-container-title">
        <label>Patient</label>
      </div>
      <div className="case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Sexe masculin"}
                  name={"patient.sexe_masculin"}
                  onChange={handleChange}
                  error={
                    touched?.patient?.sexe_masculin &&
                    Boolean(errors?.patient?.sexe_masculin)
                  }
                  helperText={
                    touched?.patient?.sexe_masculin &&
                    errors?.patient?.sexe_masculin
                  }
                  mandatory={true}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Sexe féminin"}
                  name={"patient.sexe_feminin"}
                  onChange={handleChange}
                  error={
                    touched?.patient?.sexe_feminin &&
                    Boolean(errors?.patient?.sexe_feminin)
                  }
                  helperText={
                    touched?.patient?.sexe_feminin &&
                    errors?.patient?.sexe_feminin
                  }
                  mandatory={true}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Age < 15"}
                  name={"patient.age_less_15"}
                  onChange={handleChange}
                  error={
                    touched?.patient?.age_less_15 &&
                    Boolean(errors?.patient?.age_less_15)
                  }
                  helperText={
                    touched?.patient?.age_less_15 &&
                    errors?.patient?.age_less_15
                  }
                  mandatory={true}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInput
                  title={"Age > 15"}
                  name={"patient.age_more_15"}
                  onChange={handleChange}
                  error={
                    touched?.patient?.age_more_15 &&
                    Boolean(errors?.patient?.age_more_15)
                  }
                  helperText={
                    touched?.patient?.age_more_15 &&
                    errors?.patient?.age_more_15
                  }
                  mandatory={true}
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
