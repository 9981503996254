import React from "react";
import { Stack } from "@mui/material";
import CaseHeader from "../Common/CaseHeader";
import Declarant from "./Declarant";
import Patient from "./Patient";
import Toxique from "../Common/Toxique";
import Intoxication from "./Intoxication";
import Repondeur from "./Repondeur";

import "../index.scss";


export default function ViewToxiCase(props) {
  return (
    <div id="apercu" className="view-case-common-container">
      <Stack direction="column" spacing={0}>
        <CaseHeader type="toxicovigilance" />
        <Declarant />
        <Patient />
        <Toxique />
        <Intoxication />
        <Repondeur />
      </Stack>
    </div>
  );
}
