import React from "react";
import { Stack } from "@mui/material";
import {
      Chart as ChartJS,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { tauxRemplissageDesFiches } from "../../utils/dashboardData";
import './index.scss';


ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
);

export default function RemplissageFiche(props) {
      const { data } = props;
      const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: false,
              },
            },
          };
      return (
            <div className="graph-container">
                  <Stack direction='column' spacing={0}>
                        <div className="graph-title">
                              <label>Taux de remplissage des fiches (Qualité)</label>
                        </div>
                        <div className="graph-body">
                              <Bar
                                    data={tauxRemplissageDesFiches}
                                    width={260}
                                    options={options}
                              />
                        </div>
                  </Stack>
            </div>
      )

}