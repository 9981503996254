import React, { useContext, useEffect } from "react";
import { Stack, List, Divider, ListItemButton } from "@mui/material";
import { ReferentielContext } from "../../contextes/ReferentielContext";
import { getTableHeader } from "../../utils/referentielData";
import "./index.scss";

export default function TablesMenu(props) {
  const tables = props.tables;
  const notificationTable = props.notificationTable;

  const { handleTableClick } = useContext(ReferentielContext);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    handleTableClick(tables[index]);
  };

  useEffect(() => {
    setSelectedIndex(notificationTable, 0);
  }, [tables]);

  return (
    <Stack spacing={3} sx={{ width: "100%", maxWidth: 249 }}>
      <label className="table-label-title">Tables</label>
      <div className="table-nav">
        <List component="nav">
          {tables.map((table, index) => (
            <React.Fragment key={index}>
              <Divider />
              <ListItemButton
                style={{
                  padding: 0,
                }}
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <Stack direction="row" className="table-button-container">
                  <div className="table-button-left-side">
                    {getTableHeader(table)}
                  </div>
                </Stack>
              </ListItemButton>
            </React.Fragment>
          ))}
          <Divider />
        </List>
      </div>
    </Stack>
  );
}
