import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Declarant(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  const declarant = selectedCase
    ? selectedCase.declarant
    : values.declarant?.declarant;

  const referentielResults = useReferentiels([
    "declarant_type",
    "commune",
    "province",
    "provenance",
    "region",
    "service"
  ]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;
  const declarantTypes = declarant && flattenIdNomDict(referentielResults[0].data);
  const communes = declarant && flattenIdNomDict(referentielResults[1].data);
  const provinces = declarant && flattenIdNomDict(referentielResults[2].data);
  const provenances = declarant && flattenIdNomDict(referentielResults[3].data);
  const regions = declarant && flattenIdNomDict(referentielResults[4].data);
  const services = declarant && flattenIdNomDict(referentielResults[5].data);

  const widthPercentage = "31%";

  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Le déclarant</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Type du déclarant"
                  value={
                    declarant && declarantTypes[declarant?.declarant_type_id]
                  }
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo title="Nom du déclarant" value={declarant?.nom} />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Téléphone"
                  value={declarant?.telephone}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Commune"
                  value={declarant && communes[declarant?.commune_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Province"
                  value={declarant && provinces[declarant?.province_id]}
                />
              </div>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Région"
                  value={declarant && regions[declarant?.region_id]}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Provenance"
                  value={declarant && provenances[declarant?.provenance_id]}
                />
              </div>
              {declarant && [1, 2, 3].includes(parseInt(declarant?.provenance_id)) && (<div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Service"
                  value={declarant && services[declarant?.service_id]}
                />
              </div>)}
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Dénomination sociale"
                  value={declarant?.denomination}
                />
              </div>

            </Stack>
          </div>
        </Stack>
      </div >
    </div >
  );
}
