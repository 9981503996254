import React from "react";
import { useMutation } from "react-query";
import { Stack, IconButton, Divider, Button } from "@mui/material";
import { NewUserAddIcon, UserViewCloseIcon } from "../Icons/Icons";
import { useFormik } from 'formik';
import { flattenIdNomDict } from "../../utils/referentielData";
import { getRolesDict } from "../../utils/rolesManagement";
import LabelInput from "../LabelInput/LabelInput";
import LabelMenu from "../LabelMenu/LabelMenu";
import LabelMultipleMenu from "../LabelMenu/LabelMultipleMenu";
import { newUserValidationSchema } from "../../utils/validationSchemas/others/formValidationSchemas";
import { addUser } from "../../services/users/users";
import CustomLoader from "../CustomLoader/CustomLoader";
import './index.scss';
import { useReferentiels } from "../../services/referentiels/referentiels";
import LabelPhone from "../LabelPhone/LabelPhone";



export default function NewUser(props) {
      const { onClose, onSuccess, onError } = props;
      const referentielResults = useReferentiels(['region', 'vigilance']);
      const [respVigilanceSelected, setRespVigilanceSelected] = React.useState(false);


      const mutation = useMutation(values => {
            addUser(values).then(() => {
                  onSuccess();
                  onClose();
            }).catch((error) => {
                  onError();
                  onClose();
            })
            // },{
            //       onSuccess:()=>{
            //             onSuccess();
            //             onClose();
            //       },
            //       onError: ()=>{
            //             onError();
            //             onClose();
            //       },
      })
      const formik = useFormik({
            initialValues: {
                  nom_prenom: '',
                  email: '',
                  telephone: '',
                  region_id: '',
                  role_id: '',
                  vigilances: []
            },
            validationSchema: newUserValidationSchema,
            onSubmit: (values) => {
                  mutation.mutate(values)
            },
      });
      const handleRoleChanged = (e) => {
            formik.handleChange(e);
            setRespVigilanceSelected(e.target.value === "5");
      }
      const isLoading = referentielResults.some(result => result.isLoading)
      if (isLoading) {
            return (<CustomLoader isLoading={isLoading} />)
      }
      const rolesDict = getRolesDict();
      const regions = flattenIdNomDict(referentielResults[0].data);
      const vigilances = flattenIdNomDict(referentielResults[1].data);
      const inputBackground = '#FFFFFF';
      return (
            <div className="new-user-container">
                  <Stack direction="column" spacing={0}>
                        <div className="new-user-header">
                              <Stack direction="row" spacing={0} style={{ alignItems: 'center' }}>
                                    <div>
                                          <NewUserAddIcon />
                                    </div>
                                    <div className="new-user-header-title">
                                          <label>Nouvel utilisateur</label>
                                    </div>
                                    <div className="new-user-close">
                                          <IconButton style={{ padding: 0 }} onClick={onClose}>
                                                <UserViewCloseIcon />
                                          </IconButton>
                                    </div>
                              </Stack>
                        </div>
                        <Divider />
                        <form onSubmit={formik.handleSubmit}>
                              <div className="new-user-body">
                                    <Stack direction="row" spacing={6}>
                                          <div style={{ width: '100%' }} >
                                                <Stack direction="column" spacing={4}>
                                                      <LabelInput title={'Nom et prénom'} name={"nom_prenom"} value={formik.values.nom_prenom}
                                                            onChange={formik.handleChange}
                                                            error={formik.touched.nom_prenom && Boolean(formik.errors.nom_prenom)}
                                                            helperText={formik.touched.nom_prenom && formik.errors.nom_prenom}
                                                            inputBackground={inputBackground} />

                                                      <LabelPhone title={'Téléphone'} name={"telephone"} value={formik.values.telephone}
                                                            onChange={formik.handleChange}
                                                            inputBackground={inputBackground} />
                                                      <LabelMenu title='Rôle' name={"role_id"} value={formik.values.role_id}
                                                            onChange={(e) => handleRoleChanged(e)}
                                                            error={formik.touched.role_id && Boolean(formik.errors.role_id)}
                                                            helperText={formik.touched.role_id && formik.errors.role_id}
                                                            valuesDict={rolesDict} />
                                                </Stack>
                                          </div>
                                          <div style={{ width: '100%' }}>
                                                <Stack direction="column" spacing={4}>
                                                      <LabelInput title={'Adresse email'} name={"email"} value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                                            helperText={formik.touched.email && formik.errors.email}
                                                            inputBackground={inputBackground} />
                                                      <LabelMenu title='Région' name={"region_id"} value={formik.values.region_id}
                                                            onChange={formik.handleChange}
                                                            valuesDict={regions} />
                                                      {respVigilanceSelected === true &&
                                                            <LabelMultipleMenu title='Vigilances' name={"vigilances"} value={formik.values.vigilances}
                                                                  onChange={formik.setFieldValue}
                                                                  valuesDict={vigilances} />
                                                      }
                                                </Stack>
                                          </div>
                                    </Stack>
                              </div>
                              <div className="new-user-footer">
                                    <div style={{ paddingRight: '34px', paddingTop: '16px' }}>
                                          <Stack
                                                direction="row-reverse"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                          >
                                                <Button variant="contained" type="submit" style={{
                                                      background: '#367BF5',
                                                      height: '32px',
                                                      textTransform: 'none'
                                                }}>Ajouter</Button>

                                                <Button onClick={onClose} style={{
                                                      color: '#4F566B',
                                                      height: '32px',
                                                      textTransform: 'none'
                                                }}>Annuler</Button>

                                          </Stack>
                                    </div>

                              </div>
                        </form>
                  </Stack>

            </div>
      )
}