import { SvgIcon } from "@mui/material";

const DataGridFiltreIcon = (props) => {
      return (
          <SvgIcon {...props}  width="12" height="12" viewBox="0 0 12 12" >
              <path d="M6.84408 11.1946C6.94068 11.1472 7.02209 11.0738 7.07909 10.9825C7.1361 10.8913 7.16644 10.7859 7.16667 10.6783V7.57501C7.16667 7.42218 7.22792 7.27518 7.33758 7.16668L10.9082 3.63518C11.1269 3.41876 11.25 3.12476 11.25 2.81851V1.32751C11.2497 1.25127 11.2344 1.17583 11.2049 1.10552C11.1754 1.03522 11.1323 0.971415 11.0781 0.917774C11.0239 0.864133 10.9597 0.821704 10.8891 0.792917C10.8185 0.76413 10.7429 0.74955 10.6667 0.750011H1.33333C1.01075 0.750011 0.75 1.00784 0.75 1.32751V2.81851C0.75 3.12476 0.873083 3.41876 1.09183 3.63518L4.66242 7.16668C4.71652 7.22004 4.75948 7.28361 4.78882 7.35371C4.81816 7.4238 4.83329 7.49902 4.83333 7.57501V11.2558C4.83333 11.6846 5.2895 11.9634 5.67742 11.7715L6.84408 11.1946Z" fill="#C2BFBF" />
          </SvgIcon>
      )
  }
  
  const DataGridEyeIcon = (props) => {
      return (
        <SvgIcon {...props} width="22" height="22" viewBox="0 0 22 22" fontSize="medium"  >
              <rect width="22" height="22" rx="11" fill="#8CB2F2" />
              <path d="M11.0004 15.29C14.6456 15.29 17.6004 11 17.6004 11C17.6004 11 14.6456 6.70996 11.0004 6.70996C7.35521 6.70996 4.40039 11 4.40039 11C4.40039 11 7.35521 15.29 11.0004 15.29Z" stroke="white" strokeWidth="2" strokeLinejoin="round" fill="#8CB2F2" />
              <path d="M11.0004 12.7879C11.438 12.7879 11.8577 12.5996 12.1671 12.2643C12.4765 11.9291 12.6504 11.4745 12.6504 11.0004C12.6504 10.5263 12.4765 10.0717 12.1671 9.73644C11.8577 9.40122 11.438 9.21289 11.0004 9.21289C10.5628 9.21289 10.1431 9.40122 9.83366 9.73644C9.52423 10.0717 9.35039 10.5263 9.35039 11.0004C9.35039 11.4745 9.52423 11.9291 9.83366 12.2643C10.1431 12.5996 10.5628 12.7879 11.0004 12.7879Z" stroke="white" strokeWidth="2" strokeLinejoin="round" fill="#8CB2F2" />
          </SvgIcon>
  
      )
  }
  
  const DataGridValideIcon = (props) => {
      return (
        <SvgIcon {...props}  width="22" height="22" viewBox="0 0 22 22" fontSize="medium" >
              <path d="M22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 0 13.9174 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11ZM16.5413 6.83375C16.443 6.73588 16.3261 6.65881 16.1974 6.60717C16.0687 6.55553 15.9309 6.53037 15.7923 6.53319C15.6537 6.53602 15.5171 6.56677 15.3906 6.62361C15.2641 6.68045 15.1504 6.76221 15.0562 6.864L10.2809 12.9484L7.403 10.0691C7.20751 9.88696 6.94895 9.7878 6.68178 9.79251C6.41462 9.79722 6.15971 9.90545 5.97077 10.0944C5.78183 10.2833 5.6736 10.5382 5.66888 10.8054C5.66417 11.0726 5.76334 11.3311 5.9455 11.5266L9.58375 15.1662C9.68176 15.2641 9.79848 15.3412 9.92693 15.3929C10.0554 15.4447 10.1929 15.47 10.3314 15.4675C10.4699 15.4649 10.6064 15.4345 10.7328 15.378C10.8593 15.3215 10.9731 15.2401 11.0674 15.1388L16.5564 8.2775C16.7435 8.08294 16.8469 7.82275 16.8443 7.55282C16.8417 7.28288 16.7334 7.02471 16.5426 6.83375H16.5413Z" fill="#90E790" />
          </SvgIcon>
      )
  }
  
  const DataGridEditIcon = (props) => {
      return (
        <SvgIcon {...props}  width="22" height="22" viewBox="0 0 22 22" fontSize="medium">
              <rect width="22" height="22" rx="11" fill="#FFB660" />
              <path d="M5.7571 16.7192C5.66027 16.7191 5.56453 16.6987 5.47598 16.6596C5.38742 16.6204 5.30799 16.5632 5.24273 16.4916C5.17628 16.4207 5.12558 16.3365 5.09395 16.2446C5.06231 16.1527 5.05046 16.0551 5.05917 15.9583L5.23016 14.0775L13.1293 6.17903L15.5979 8.64758L7.70083 16.5454L5.82061 16.7164C5.7995 16.7184 5.7783 16.7193 5.7571 16.7192ZM16.0906 8.15401L13.6228 5.68546L15.1031 4.20475C15.1679 4.13984 15.2449 4.08835 15.3296 4.05322C15.4143 4.01808 15.5051 4 15.5968 4C15.6886 4 15.7794 4.01808 15.8641 4.05322C15.9488 4.08835 16.0258 4.13984 16.0906 4.20475L17.5709 5.68546C17.6358 5.7503 17.6873 5.82729 17.7224 5.91204C17.7576 5.99679 17.7756 6.08764 17.7756 6.17938C17.7756 6.27112 17.7576 6.36197 17.7224 6.44672C17.6873 6.53147 17.6358 6.60846 17.5709 6.6733L16.0913 8.15331L16.0906 8.15401Z" fill="white" />
          </SvgIcon>
  
      )
  }
  
  const DataGridDeleteIcon = (props) => {
      return (
        <SvgIcon {...props}  width="22" height="22" viewBox="0 0 22 22" fontSize="medium" >
              <rect width="22" height="22" rx="11" fill="#FF6760" />
              <path d="M7.224 17.3248C7.24 17.7024 7.5504 18 7.928 18H14.5056C14.8832 18 15.1952 17.7024 15.2096 17.3248L15.68 7.408H6.7536L7.224 17.3248ZM12.704 9.872C12.704 9.7136 12.832 9.5856 12.9904 9.5856H13.448C13.6064 9.5856 13.7344 9.7152 13.7344 9.872V15.5376C13.7344 15.696 13.6048 15.824 13.448 15.824H12.9904C12.832 15.824 12.704 15.696 12.704 15.5376V9.872ZM10.7024 9.872C10.7024 9.7136 10.8304 9.5856 10.9888 9.5856H11.4464C11.6048 9.5856 11.7328 9.7152 11.7328 9.872V15.5376C11.7328 15.696 11.6048 15.824 11.4464 15.824H10.9888C10.8304 15.824 10.7024 15.696 10.7024 15.5376V9.872ZM8.6992 9.872C8.6992 9.7136 8.8272 9.5856 8.984 9.5856H9.4416C9.6 9.5856 9.728 9.7152 9.728 9.872V15.5376C9.728 15.696 9.5984 15.824 9.4416 15.824H8.984C8.8256 15.824 8.6992 15.696 8.6992 15.5376V9.872ZM15.992 4.7216H12.9584V4.1472C12.9584 4.0656 12.8928 4 12.8112 4H9.624C9.5424 4 9.4768 4.0656 9.4768 4.1472V4.7216H6.4416C6.1984 4.7216 6 4.9184 6 5.1632V6.552H16.4336V5.1632C16.4336 4.92 16.2368 4.7216 15.992 4.7216Z" fill="white" />
          </SvgIcon>
  
      )
  }
  
  const DataGridCancelIcon = (props) => {
      return (
        <SvgIcon {...props}  width="22" height="22" viewBox="0 0 22 22" fontSize="medium" >
              <path d="M22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 0 13.9174 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11ZM7.36175 6.38825C7.23266 6.25916 7.05757 6.18663 6.875 6.18663C6.69243 6.18663 6.51734 6.25916 6.38825 6.38825C6.25916 6.51734 6.18663 6.69243 6.18663 6.875C6.18663 7.05757 6.25916 7.23266 6.38825 7.36175L10.0279 11L6.38825 14.6383C6.32433 14.7022 6.27362 14.7781 6.23903 14.8616C6.20444 14.9451 6.18663 15.0346 6.18663 15.125C6.18663 15.2154 6.20444 15.3049 6.23903 15.3884C6.27362 15.4719 6.32433 15.5478 6.38825 15.6117C6.51734 15.7408 6.69243 15.8134 6.875 15.8134C6.9654 15.8134 7.05491 15.7956 7.13843 15.761C7.22194 15.7264 7.29783 15.6757 7.36175 15.6117L11 11.9721L14.6383 15.6117C14.7022 15.6757 14.7781 15.7264 14.8616 15.761C14.9451 15.7956 15.0346 15.8134 15.125 15.8134C15.2154 15.8134 15.3049 15.7956 15.3884 15.761C15.4719 15.7264 15.5478 15.6757 15.6117 15.6117C15.6757 15.5478 15.7264 15.4719 15.761 15.3884C15.7956 15.3049 15.8134 15.2154 15.8134 15.125C15.8134 15.0346 15.7956 14.9451 15.761 14.8616C15.7264 14.7781 15.6757 14.7022 15.6117 14.6383L11.9721 11L15.6117 7.36175C15.6757 7.29783 15.7264 7.22194 15.761 7.13843C15.7956 7.05491 15.8134 6.9654 15.8134 6.875C15.8134 6.7846 15.7956 6.69509 15.761 6.61157C15.7264 6.52806 15.6757 6.45217 15.6117 6.38825C15.5478 6.32433 15.4719 6.27362 15.3884 6.23903C15.3049 6.20444 15.2154 6.18663 15.125 6.18663C15.0346 6.18663 14.9451 6.20444 14.8616 6.23903C14.7781 6.27362 14.7022 6.32433 14.6383 6.38825L11 10.0279L7.36175 6.38825Z" fill="#FF6760" />
          </SvgIcon>
      )
  }

  export {
      DataGridFiltreIcon,
      DataGridEyeIcon,
      DataGridValideIcon,
      DataGridEditIcon,
      DataGridDeleteIcon,
      DataGridCancelIcon
  }