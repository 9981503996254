import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdNomCommercialDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getProduitMedicamentDetails } from "../../../../services/cases/cases";

export default function Medicament(props) {
  const { medicament, preview } = props;
  const [medicamentDetails, setMedicamentDetails] = useState(null);

  const referentielResults = useReferentiels([
    "produit_medicament",
    //"usage_medicament",
    "voie_intoxication",
    "forme"
  ]);
  useEffect(() => {
    getProduitMedicamentDetails(medicament.produit_medicament_id).then((data) => {
      setMedicamentDetails({
        "dci": data.dci,
        //"forme": data.forme,
        "groupe_anatomique": data.groupe_anatomique,
        "code_atc": data.code_atc
      });
    });
  }, [props]);
  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading || medicamentDetails === null) return <CustomLoader isLoading={isLoading} />;

  const produitsMedicaments = flattenIdNomCommercialDict(referentielResults[0].data);
  //const usagesMedicaments = flattenIdNomDict(referentielResults[1].data);
  const voix = flattenIdNomDict(referentielResults[1].data);
  const formes = flattenIdNomDict(referentielResults[2].data);

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Médicament</label>
      </div>
      <div className="view-case-common-container-body">
        {parseInt(medicament.connu_inconnu) !== 0 ? (
          <Stack direction="column" spacing={4}>
            <div>
              <Stack direction="row" spacing={3}>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Nom commercial"
                    value={produitsMedicaments[medicament.produit_medicament_id]}
                  />
                </div>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo title="DCI" value={medicamentDetails.dci} />
                </div>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Groupe Anatomique"
                    value={medicamentDetails.groupe_anatomique}
                  />
                </div>
              </Stack>
            </div>
            <div>
              <Stack direction="row" spacing={3}>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Forme médicament"
                    value={formes[parseInt(medicament.forme_id)]}
                  />
                </div>
                <div style={{ width: "66%" }}>
                  <LabelInfo
                    title="Code ATC"
                    value={medicamentDetails.code_atc}
                  />
                </div>

              </Stack>
            </div>


            <div>
              <Stack direction="row" spacing={3}>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo title="Dosage" value={medicament.posologie} />
                </div>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Doses administrées"
                    value={medicament.quantite}
                  />
                </div>
                <div style={{ width: widthPercentage }}>
                  <LabelInfo
                    title="Voie d'intoxication"
                    value={
                      voix[medicament.voie_intoxication_id]
                    }
                  />
                </div>
              </Stack>
            </div>
          </Stack>
        ) : (
          <div style={{ width: widthPercentage, fontWeight: 500 }}>
            Medicament Inconnu
          </div>
        )}
      </div>
    </div>
  );
}
