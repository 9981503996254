import React from "react";
import { Stack } from "@mui/material";
import Dossier from "../../../components/Case/Labo/Dossier/Dossier";

import "../index.scss";
import Prescripteur from "../../../components/Case/Labo/Prescripteur/Prescripteur";

export default function AddLaboPrescripteur(props) {
  return (
    <div className="add-case-body">
      <Stack direction="column" spacing={4}>
        <Dossier />
        <Prescripteur />
      </Stack>
    </div>
  );
}
