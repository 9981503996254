import React from "react";
import { Avatar, Stack } from "@mui/material";
import './index.scss';
import { getAvatar } from "../../../utils/divers/divers";


export default function NomPrenomAvatar(props) {
      const { nom_prenom, avatar } = props;
      let avatarComponent = getAvatar(avatar);
      
      return (
            <div>
                  <Stack direction="row">
                        <div className="nom-prenom-avatar">
                              <div className="header-right-side-user-img">
                                    <Avatar sx={{ width: 40, height: 40 }} src={avatarComponent} />
                              </div>
                        </div>
                        <div className="nom-prenom-label">
                              <label>{nom_prenom}</label>
                        </div>
                  </Stack>
            </div>
      )

}