import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useMutation, useQueryClient } from "react-query";
import { Stack } from "@mui/material";
import "./index.scss";
import ToggleSwitches from "../ToggleSwitches";
import RoleName from "../RoleName/RoleName";
import { RolePermissionsContext } from '../../contextes/UserPermissionsContext';
import { editPermission, useRolesAndPermissions } from "../../services/roles/roles";
import CustomLoader from "../CustomLoader/CustomLoader";
import { flattenIdNomDict } from "../../utils/referentielData";
import { useSnackbar } from 'notistack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: "center",
    backgroundColor: "#E8E8E8",
    color: "#25282B",
    fontSize: 14,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    height: '45px'
    // lineHeight: "20"

  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontSize: 14,
    color: "#25282B",
    fontSize: 14,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    height: '49px',
    paddingTop: 0,
    paddingBottom: 0
  }

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    boxShadow: "0 4px 2px -2px gray",
    textAlign: "center",
    border: 0
  }
}));



export default function UserPermission(props) {
  const { updateable, role } = React.useContext(RolePermissionsContext)
  const rolesAndPermissions = useRolesAndPermissions();
  const queryClient = useQueryClient();
  const { enqueueSnackbar: showMessage } = useSnackbar();

  const createData = (Activite, Droit) => {
    return { Activite, Droit };
  }

  const editPermissionMutation = useMutation(values => {
    editPermission(values)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('roles');
      showMessage("Permission modifiée avec succès!", { variant: 'success' });
    },
    onError: (error) => {
      showMessage("Erreur lors de la modification de la permission " + error.toString(), { variant: 'error' });
    },
  });

  const handleToggleChange = (event, editIds, name) => {
    let value = event.target.checked ? 1 : 0;
    let values = {
      "permission_id": editIds[0],
      "role_id": editIds[1],
    };
    switch (name) {
      case 'droit':
        values.droit = value;
        break;
      default:
        break;
    }

    editPermissionMutation.mutate(values);
  }

  const createPermissionRow = (title, permissionList, editIds, updateable) => {
    const droit = permissionList[0];
    const row = createData(title, <ToggleSwitches updateable={updateable} checked={droit} handleChange={(event) => handleToggleChange(event, editIds, 'droit')} />);
    return row;
  }

  const createRows = (permissions, permissionTitlesDict, updateable) => {
    const rows = [];

    for (const permission in permissions) {
      let permissionDetails = permissions[permission];
      let permissionTitle = permissionTitlesDict[permissionDetails.permission_id];
      let permissionArray = [permissionDetails.droit];
      let editIds = [permissionDetails.permission_id, permissionDetails.role_id];
      rows.push(createPermissionRow(permissionTitle, permissionArray, editIds, updateable));
    }
    return rows;
  }
  const isLoading = rolesAndPermissions.some(result => result.isLoading)
  if (isLoading) {
    return (<CustomLoader isLoading={isLoading} />)
  }
  const roles = rolesAndPermissions[0].data;
  const permissions = flattenIdNomDict(rolesAndPermissions[1].data);
  const rolePermissions = (roles.filter(x => x.id === parseInt(role)))[0]?.role_permissions;
  const rows = createRows(rolePermissions, permissions, updateable);

  return (
    <div className="user-permission-container">
      <Stack direction="column" spacing={0} style={{ display: 'inline-block' }}>
        <div>
          <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
            <div className="user-permission-container-title">
              <label>Permissions</label>
            </div>
            <div className="user-permission-container-role">
              <RoleName type={role} />
            </div>
          </Stack>
        </div>
        <div className="user-permission-table-container">
          <TableContainer component={Paper} >
            <Table stickyHeader sx={{ minWidth: 630 }} style={{ overflow: 'auto' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="right">
                    <div style={{ textAlign: 'left' }}>
                      <p>Activité</p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div className="user-permission-header">
                      <p>Autorisé</p>
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center" style={{ textAlign: 'left' }}>{row.Activite}</StyledTableCell>
                    <StyledTableCell align="center">{row.Droit}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </div>

  );
}
