import React from "react";
import { Box } from "@mui/system";
import { Stack, IconButton, Button, Divider } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useFormik } from 'formik';
import LabelInput from "../../LabelInput/LabelInput";
import { UserViewCloseIcon, NewUserAddIcon } from "../../Icons/Icons";
import {whoArtSchema} from "../../../utils/validationSchemas/referentiel/referentiel"; 
import '../index.scss';


const style = {
      position: 'absolute',
      width: 621,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'

}



export default function WHOArtModal(props) {
      const {selectedRow,onEdit,onClose}  = props;
      const inputBackground = '#FFFFFF';
      const formik = useFormik({
            initialValues: {
                  id:selectedRow?.id,
                  nom: selectedRow?.nom,
                  categorie:selectedRow?.categorie
            },
            validationSchema: whoArtSchema,
            onSubmit: (values) => {
                  onEdit(values);
            },
      });
      
      return (
            <Box sx={{ ...style }}>
                  <div>
                        <Stack direction="column" spacing={0} >
                              <div>
                                    <div className="referentiel-modal-container">
                                          <Stack direction="column" spacing={0}>
                                                <div className="referentiel-modal-header">
                                                      <Stack direction="row" spacing={0} style={{ alignItems: 'center', width:'100%' }}>
                                                            <div>
                                                                  <ModeEditIcon style={{color:"#367BF5"}} />
                                                            </div>
                                                            <div className="referentiel-modal-header-title">
                                                                  <label>Modifier un élément</label>
                                                            </div>
                                                            
                                                            <div className="referentiel-modal-close" >
                                                                  <IconButton style={{ padding: 0 }} onClick={onClose}>
                                                                        <UserViewCloseIcon />
                                                                  </IconButton>
                                                            </div>
                                                            
                                                      </Stack>
                                                </div>
                                                <Divider />
                                                <form onSubmit={formik.handleSubmit}>
                                                      <div className="referentiel-modal-body">
                                                            <Stack direction="row" spacing={6}>
                                                                  <div style={{ width: '100%' }} >
                                                                        <Stack direction="column" spacing={4}>
                                                                              <div>
                                                                                    <LabelInput title={'Nom:'} name={"nom"} value={formik.values.nom}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.nom && Boolean(formik.errors.nom)}
                                                                                          helperText={formik.touched.nom && formik.errors.nom}
                                                                                          inputBackground={inputBackground}  />  
                                                                              </div>
                                                                              <div>
                                                                                    <LabelInput title={'Categorie:'} name={"categorie"} value={formik.values.categorie}
                                                                                          onChange={formik.handleChange}
                                                                                          error={formik.touched.categorie && Boolean(formik.errors.categorie)}
                                                                                          helperText={formik.touched.categorie && formik.errors.categorie}
                                                                                          inputBackground={inputBackground}  />  
                                                                              </div>
                                                                        </Stack>
                                                                  </div>
                                                            </Stack>
                                                      </div>
                                                      <div className="referentiel-modal-footer">
                                                            <div style={{ paddingRight: '34px', paddingTop: '16px' }}>
                                                                  <Stack
                                                                        direction="row-reverse"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        spacing={2}
                                                                  >
                                                                        <Button variant="contained" type="submit" style={{
                                                                              background: '#367BF5',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Modifier</Button>

                                                                        <Button onClick={onClose} style={{
                                                                              color: '#4F566B',
                                                                              height: '32px',
                                                                              textTransform: 'none'
                                                                        }}>Annuler</Button>

                                                                  </Stack>
                                                            </div>

                                                      </div>
                                                </form>
                                          </Stack>
                                    </div>
                              </div>
                        </Stack>
                  </div>
            </Box>
      )
}