import React from "react"
import { Stack } from "@mui/material";
import Dossier from "../../../components/ViewCase/Labo/Dossier/Dossier";
import PrescripteurComponent from "../../../components/ViewCase/Labo/Prescripteur/Prescripteur";
import '../index.scss';

export default function Prescripteur(props) {


      return (
            <div className='view-case-common-container'>
                  <div className='view-case-common-component'>
                        <Stack direction="column" spacing={0}>
                              <div className='view-case-common-sub-header'>
                                    <div className='view-case-common-sub-header-title'>
                                          information sur le prescripteur
                                    </div>
                              </div>
                              <div className='view-case-common-body'>
                                    <Stack direction="column" spacing={3}>
                                          <Dossier />
                                          <PrescripteurComponent />
                                    </Stack>
                              </div>
                        </Stack>
                  </div>
            </div>
      )
}