import React, { useContext } from "react";
import {
  Select,
  Grid,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import "./index.scss";
import { ValidatedContext } from "../../modules/AddCases/AddLaboCase/AddLaboCase";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function LabelMultipleMenu(props) {
  const {
    title,
    name,
    onChange,
    helperText,
    error,
    valuesDict,
    mandatory,
    disabled,
    initialValues,
  } = props;
  const validated = useContext(ValidatedContext);

  const [value, setValue] = React.useState(
    initialValues == null ? [] : initialValues.map((x) => valuesDict[x])
  );
  let dictValues = Object.values(valuesDict);
  let inverseValuesDict = {};
  for (let key in valuesDict) {
    let val = valuesDict[key];
    inverseValuesDict[val] = key;
  }
  let isDisabled = disabled === undefined ? false : disabled;
  let fontWeight = props.boldTitle === undefined ? 600 : 400;
  let redFlag = "";

  if (mandatory === true) {
    redFlag = <span style={{ color: "red" }}>&nbsp;*</span>;
  }
  const handleChange = (event) => {
    const newValues = event.target.value;
    setValue(newValues);
    const values = newValues.map((x) => inverseValuesDict[x]);
    onChange(name, values);
  };
  return (
    <div style={{ textAlign: "right" }}>
      <Grid container spacing={0.5} direction="column">
        <Grid container spacing={0} direction="row">
          <Grid item xs={12}>
            <div>
              <label
                className="label-input-label"
                style={{
                  fontWeight: fontWeight,
                }}
              >
                {title}
                {redFlag}
              </label>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: 0 }}>
          <Select
            multiple
            disabled={isDisabled || validated}
            name={name}
            value={value}
            onChange={handleChange}
            error={error}
            helperText={helperText}
            inputProps={{
              style: {
                boxSizing: "border-box",
                height: "42px",
              },
            }}
            style={{
              height: "42px",
              width: "100%",
              textAlign: "left",
            }}
            renderValue={(selected) => {
              return selected.join(", ");
            }}
            MenuProps={MenuProps}
          >
            {dictValues.map((elt, index) => (
              <MenuItem key={index} value={elt}>
                <Checkbox checked={value.indexOf(elt) > -1} />
                <ListItemText primary={elt} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: "#d32f2f", marginLeft: "14px" }}>
            {helperText}
          </FormHelperText>
        </Grid>
      </Grid>
    </div>
  );
}
