import React from "react";
import { Stack, Grid } from "@mui/material";
import ToxiqueCard from "../../components/ToxiqueCard/ToxiqueCard";
import './index.scss';


export default function ExtractLaboMenu(props) {
      const groups = ['LC', 'GC', 'PTRR', 'SAA'];
      const onClick = props.onClick;
      return (
            <Stack spacing={1} sx={{ width: '100%', maxWidth: 400 }}>
                  <label className="extract-menu-title">
                        Catégories
                  </label>
                  <div className="extract-menu-container">
                        <div className="extract-menu-sub-menu">
                              <Stack spacing={1} sx={{ width: '100%' }}>
                                    <div style={{ width: '50%' }}>
                                          <div className="extract-menu-sub-title" >
                                                <label>Pôles</label>
                                          </div>
                                    </div>
                                    <div >
                                          <Grid container spacing={1}>
                                                {groups.map((laboType, index) => (
                                                      <Grid key={index} item xs={6}>
                                                            <ToxiqueCard type={laboType} onChangeHandler={() => onClick(laboType)} />
                                                      </Grid>
                                                ))}
                                          </Grid>
                                    </div>

                              </Stack>
                        </div>
                  </div>
            </Stack>
      )
}