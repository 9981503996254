const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const veterinaireDict = { 0: "Non", 1: "Oui" };
const typeDossier = { 1: "STP", 2: "Toxico" };

const entreeNomDict = {
  "produits_testes": "Produits testés",
  "resultats_dosage": "Résultats Dosage",
  "resultats_recherche": "Résultats recherche",
  "resultats": "Résultats Métaux lourds"

}

const createDataGridRows = (table, data, filter, refs = {}) => {
  let rows = [];
  if (data === undefined || data === null) return;
  for (var i = 0; i < data.length; i++) {
    let d = data[i];
    let newRow = {};
    for (const property in d) {
      if (property === "veterinaire") {
        newRow[property] = veterinaireDict[d[property]];
      } else if (property === "type_dossier") {
        newRow[property] = typeDossier[d[property]];
      } else if (table === "entree_nom" && property === "categorie") {
        newRow[property] = entreeNomDict[d[property]];
      } else if (table === "cases" && property === "repondeur_id") {
        newRow[property] = refs['repondeurs'][d[property]];
      } else if (table === "cases" && property === "type_rapport_id") {
        newRow[property] = refs['types_rapports'][d[property]];
      } else if (table === "cases" && property === "delai_reponse_id") {
        newRow[property] = refs['delais'][d[property]];
      } else if (table === "cases" && property === "analyste_id") {
        newRow[property] = refs['analystes'][d[property]];
      }
      else newRow[property] = d[property];
    }
    rows.push(newRow);
  }

  const searchRegex = new RegExp(escapeRegExp(filter), "i");
  const filteredRows = rows.filter((row) => {
    return Object.keys(row).some((field) => {
      if (row[field] === null || row[field] === undefined) {
        return false;
      } else {
        return searchRegex.test(row[field].toString());
      }
    });
  });

  return filteredRows;
};

const toxicoColumnNames = [
  "Numero Dossier",
  "Type de rapport",
  "Date de saisie",
  "Date de réception",
  "Répondeur",
  "Délai réponse",
  "Actions",
];
const toxicoFieldNames = [
  "numero_dossier",
  "type_rapport_id",
  "date_saisie",
  "date_reception",
  "repondeur_id",
  "delai_reponse_id",
  "actions",
];
const laboColumnNames = [
  "Numero Dossier",
  "Type de dossier",
  "Date de saisie",
  "Date de réception",
  "Analyste",
  "Actions",
];

const laboFieldNames = [
  "numero_dossier",
  "type_dossier",
  "date_saisie",
  "date_reception",
  "analyste_id",
  "actions",
];

export { createDataGridRows, toxicoColumnNames, toxicoFieldNames, laboColumnNames, laboFieldNames };
