import * as Yup from 'yup';
import { phoneRegExp } from '../../divers/divers';

const CHAMP_OBLIGATOIRE = "Ce Champ est Obligatoire";

const SCORPION_FORMIK_VALIDATION_SCHEMA = Yup.object().shape({
      general: Yup.object().shape({
            province: Yup.string().required(CHAMP_OBLIGATOIRE),
            mois: Yup.number().typeError('Mois doit être un nombre').integer('Mois doit être un entier').required(CHAMP_OBLIGATOIRE),
            annee: Yup.number().typeError('Année doit être un nombre').integer('Année doit être un entier').required(CHAMP_OBLIGATOIRE),
            label: Yup.string().required(CHAMP_OBLIGATOIRE),
      }),
      declarant: Yup.object().shape({
            numero_dossier: Yup.string().required(CHAMP_OBLIGATOIRE),
            date_saisie: Yup.date().required(CHAMP_OBLIGATOIRE),
            date_reception: Yup.date().required(CHAMP_OBLIGATOIRE),
            heure_reception: Yup.string().required(CHAMP_OBLIGATOIRE),
            commune: Yup.string().required(CHAMP_OBLIGATOIRE),
            province: Yup.string().required(CHAMP_OBLIGATOIRE),
            region: Yup.string().required(CHAMP_OBLIGATOIRE)
      }),
      patient: Yup.object().shape({
            sexe_masculin: Yup.number().typeError('Ce champ doit être un nombre').integer('Ce champ doit être un entier').required(CHAMP_OBLIGATOIRE),
            sexe_feminin: Yup.number().typeError('Ce champ doit être un nombre').integer('Ce champ doit être un entier').required(CHAMP_OBLIGATOIRE),
            age_less_15: Yup.number().typeError('Ce champ doit être un nombre').integer('Ce champ doit être un entier').required(CHAMP_OBLIGATOIRE),
            age_more_15: Yup.number().typeError('Ce champ doit être un nombre').integer('Ce champ doit être un entier').required(CHAMP_OBLIGATOIRE),
      }),
      traitement: Yup.object().shape({
            t0: Yup.string().required(CHAMP_OBLIGATOIRE),
            t1: Yup.string().required(CHAMP_OBLIGATOIRE),
      }),
      hospitalisation: Yup.object().shape({
            mis_observation: Yup.string().required(CHAMP_OBLIGATOIRE),
            hospitalisation: Yup.string().required(CHAMP_OBLIGATOIRE),
      }),
      reference: Yup.object().shape({
            r0: Yup.string().required(CHAMP_OBLIGATOIRE),
            r1: Yup.string().required(CHAMP_OBLIGATOIRE),
            r2: Yup.string().required(CHAMP_OBLIGATOIRE),
      }),
      evolution: Yup.object().shape({
            e1: Yup.string().required(CHAMP_OBLIGATOIRE),
            e2: Yup.string().required(CHAMP_OBLIGATOIRE),
      }),
})

const MEDICAMENT_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number().required(CHAMP_OBLIGATOIRE),
            produit_medicament_id: Yup.string().nullable(),
            dci: Yup.string(),
            forme_id: Yup.string().nullable(),
            groupe_anatomique: Yup.string(),
            code_atc: Yup.string(),
            posologie: Yup.string(),
            quantite: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const ALIMENT_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number().required(CHAMP_OBLIGATOIRE),
            nom_commercial: Yup.string(),
            produit_aliment_id: Yup.string().nullable(),
            groupe_aliment: Yup.string(),
            categorie_aliment: Yup.string(),
            classe_aliment: Yup.string(),
            code_aliment: Yup.string(),
            prelevement: Yup.string(),
            resultat_prelevement: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const GAZ_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number().required(CHAMP_OBLIGATOIRE),
            nom: Yup.string(),
            produit_gaz_id: Yup.string().nullable(),
            classe_gaz: Yup.string(),
            code: Yup.string(),
            source_id: Yup.string().nullable(),
            autre_source: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const PRODUIT_INDUSTRIEL_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number().required(CHAMP_OBLIGATOIRE),
            nom_commercial: Yup.string(),
            produit_industriel_id: Yup.string().nullable(),
            sous_classe: Yup.string(),
            classe: Yup.string(),
            code: Yup.string(),
            dose_administree: Yup.string(),
            presentation_id: Yup.string().nullable(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const DROGUE_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number().required(CHAMP_OBLIGATOIRE),
            nom: Yup.string(),
            famille_drogue_id: Yup.string().nullable(),
            code: Yup.string(),
            dose_administree: Yup.string(),
            dose_toxique: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const MEDICAMENT_VETERINAIRE_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number().required(CHAMP_OBLIGATOIRE),
            produit_medicament_id: Yup.string().nullable(),
            dci: Yup.string(),
            forme_id: Yup.string().nullable(),
            groupe_anatomique: Yup.string(),
            code_atc: Yup.string(),
            posologie: Yup.string(),
            quantite: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const PESTICIDE_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            produit_pesticide_id: Yup.string().required(CHAMP_OBLIGATOIRE),
            matiere_active: Yup.string().required(CHAMP_OBLIGATOIRE),
            classe_chimique: Yup.string(),
            type_pesticide: Yup.string(),
            classe_dangerosite: Yup.string(),
            forme_id: Yup.string(),
            dosage: Yup.string(),
            usage_official: Yup.string(),
            usage_notificateur_id: Yup.string().nullable(),
            usage_notificateur_autre: Yup.string(),
            quantite: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const ENGRAIS_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            produit_engrais_id: Yup.string().required(CHAMP_OBLIGATOIRE),
            matiere_active: Yup.string().required(CHAMP_OBLIGATOIRE),
            groupe_chimique: Yup.string(),
            classe_dangerosite: Yup.string(),
            forme_id: Yup.string(),
            dosage: Yup.string(),
            usage_official: Yup.string(),
            usage_notificateur_id: Yup.string().nullable(),
            usage_notificateur_autre: Yup.string(),
            quantite: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const TOXIQUE_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            nom_commercial: Yup.string().required(CHAMP_OBLIGATOIRE),
            dci: Yup.string().required(CHAMP_OBLIGATOIRE),
            groupe_therapeutique: Yup.string(),
            forme_medicament: Yup.string(),
            groupe_chimique: Yup.string(),
            groupe_atc: Yup.string(),
            substance_chimique: Yup.string(),
            dosage: Yup.string(),
            quantite: Yup.string(),
            voie_intoxication_id: Yup.string()
      })
);

const AUTRE_ANIMAL_VENIMEUX_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            nom: Yup.string().required(CHAMP_OBLIGATOIRE),
            espece_id: Yup.string().nullable(),
            groupe: Yup.string(),
            code: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const AUTRE_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            nom: Yup.string().required(CHAMP_OBLIGATOIRE),
            classe_id: Yup.string().nullable(),
            composition: Yup.string(),
            presentation: Yup.string(),
            dose_administree: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const INCONNU_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            nom: Yup.string().required(CHAMP_OBLIGATOIRE),
            classe_id: Yup.string().nullable(),
            composition: Yup.string(),
            presentation: Yup.string(),
            dose_administree: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const SCORPION_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            couleur_id: Yup.string().required(CHAMP_OBLIGATOIRE),
            site_piqure: Yup.string().max(400, "Ne pas dépasser 400 caractères"),
            voie_intoxication_id: Yup.string().nullable(),
            classe: Yup.string().nullable()
      })
);

const SERPENT_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            espece_serpent_id: Yup.string().required(CHAMP_OBLIGATOIRE),
            code: Yup.string(),
            malade_refere: Yup.string(),
            voie_intoxication_id: Yup.string().nullable(),
            siege_morsure: Yup.string(),

            date_morsure: Yup.date().nullable(),
            heure_morsure: Yup.string().nullable(),
            date_admission: Yup.date().nullable(),
            heure_admission: Yup.string().nullable(),
            grade_syndrome_viperin: Yup.string(),
            grade_syndrome_cobraique: Yup.string(),
            premiers_gestes: Yup.string(),
            traitement_symptomatique: Yup.string(),
            type_serum_administre: Yup.string(),
            delai_administration: Yup.string(),
            nombre_ampoules_administrees: Yup.number(),
            effets_indesirables: Yup.string(),
            duree_hospitalisation: Yup.number(),
            evolution_gradation: Yup.string(),
      })
);

const PLANTE_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            nom_vernaculaire_id: Yup.string().required(CHAMP_OBLIGATOIRE),
            nom_scientifique: Yup.string(),
            nom_francais: Yup.string(),
            code: Yup.string(),
            partie_utilisee_id: Yup.string().nullable(),
            presentation_id: Yup.string().nullable(),
            dose_administree: Yup.string(),
            mode_preparation_id: Yup.string().nullable(),
            conseillee_media: Yup.string().nullable(),
            voie_intoxication_id: Yup.string().nullable()
      })
);

const PRODUIT_MENAGER_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            nom: Yup.string().required(CHAMP_OBLIGATOIRE),
            classe_id: Yup.string().required(CHAMP_OBLIGATOIRE),
            code: Yup.string(),
            presentation_id: Yup.string().nullable(),
            dose_administree: Yup.string(),
            voie_intoxication_id: Yup.string().nullable()
      })
);


const PRODUIT_COSMETIQUE_VALIDATION_SCHEMA = Yup.array().of(
      Yup.object().shape({
            type: Yup.string().required(CHAMP_OBLIGATOIRE),
            index: Yup.number().required(CHAMP_OBLIGATOIRE),
            title: Yup.string().required(CHAMP_OBLIGATOIRE),
            connu_inconnu: Yup.number(),
            nom_commercial: Yup.string().required(CHAMP_OBLIGATOIRE),
            produit_cosmetique_id: Yup.string().required(CHAMP_OBLIGATOIRE),
            sous_classe: Yup.string(),
            classe: Yup.string(),
            code: Yup.string(),
            marque: Yup.string(),
            lieu_achat: Yup.string(),
            dose_administree: Yup.string(),
            presentation_id: Yup.string().nullable(),
            voie_intoxication_id: Yup.string().nullable()
      })
);


const ECHANTILLON_VALIDATION_SCHEMA = Yup.object().shape({
      checked: Yup.boolean(),
      conformite: Yup.string(),
      probleme: Yup.string(),
      lc: Yup.boolean(),
      gc: Yup.boolean(),
      ptrr: Yup.boolean(),
      saa: Yup.boolean(),
});

const DOSAGE_VALIDATION_SCHEMA = Yup.object().shape({
      sign: Yup.string(),
      value: Yup.number(),
      unit: Yup.string(),
})

const RESULTAT_VALIDATION_SCHEMA = Yup.object().shape({
      checked: Yup.boolean(),
      produit: Yup.string(),
      molecules: Yup.array().of(Yup.string()),
      dosages: Yup.array().of(DOSAGE_VALIDATION_SCHEMA),
});

const RESULTAT_DOSAGE_VALIDATION_SCHEMA = Yup.object().shape({
      checked: Yup.boolean(),
      analyse: Yup.string(),
      dosage: DOSAGE_VALIDATION_SCHEMA,
});

const RESULTAT_RECHERCHE_VALIDATION_SCHEMA = Yup.object().shape({
      checked: Yup.boolean(),
      analyse: Yup.string(),
      result: Yup.boolean(),
});

const LABO_FORMIK_VALIDATION_SCHEMA = [
      Yup.object().shape({
            //Prescripteur
            prescripteur: Yup.object().shape({
                  dossier: Yup.object().shape({
                        type_dossier: Yup.number().required(CHAMP_OBLIGATOIRE),
                        numero_dossier: Yup.string().required(CHAMP_OBLIGATOIRE),
                        date_saisie: Yup.date().required(CHAMP_OBLIGATOIRE),
                        date_reception: Yup.date().nullable(),
                        heure_reception: Yup.string().nullable(),
                  }),
                  prescripteur: Yup.object().shape({
                        prescripteur_type_id: Yup.number().required(CHAMP_OBLIGATOIRE),
                        nom: Yup.string().nullable(),
                        commune_id: Yup.number().nullable(),
                        province_id: Yup.number().nullable(),
                        region_id: Yup.number().nullable(),

                        provenance_id: Yup.string().nullable(),
                        service_id: Yup.string().nullable(),

                  })
            })
      }),
      Yup.object().shape({
            //Patient
            patient: Yup.object().shape({
                  nom_prenom: Yup.string().nullable(),
                  telephone: Yup.string().nullable()
                        .matches(phoneRegExp, 'Numéro de téléphone invalide')
                        .min(0, "Le numéro de téléphone doit avoir 10 chiffres")
                        .max(10, "Le numéro de téléphone doit avoir 10 chiffres"),
                  sexe: Yup.number().nullable(),
                  age_chiffre: Yup.number().typeError('Age doit être un nombre').integer('Age doit être un nombre entier').min(0, 'Age doit être un nombre positif').nullable(),
                  age_unite: Yup.string().nullable(),
                  tranche_age: Yup.string().nullable(),
                  region_id: Yup.string().nullable(),
                  commune_id: Yup.string().nullable(),
                  province_id: Yup.string().nullable(),
                  milieu: Yup.number().nullable(),
                  poids: Yup.number().typeError('Poids doit être un nombre').min(0, 'Poids doit être positif').nullable(),
                  grossesse: Yup.string().nullable(),
                  nombre_semaines: Yup.number().typeError('Nombre de semaines doit être un nombre').nullable(),
                  allaitement: Yup.number().nullable(),
                  profession_id: Yup.string().nullable(),
                  antecedents: Yup.string().nullable(),
                  historique: Yup.string().nullable(),
            })
      }),
      Yup.object().shape({
            //Toxiques
            toxiques: Yup.object().shape({
                  medicaments: MEDICAMENT_VALIDATION_SCHEMA,
                  produit_menager: PRODUIT_MENAGER_VALIDATION_SCHEMA,
                  cosmetique: PRODUIT_COSMETIQUE_VALIDATION_SCHEMA,
                  aliments: ALIMENT_VALIDATION_SCHEMA,
                  plante: PLANTE_VALIDATION_SCHEMA,
                  gaz: GAZ_VALIDATION_SCHEMA,
                  drogue: DROGUE_VALIDATION_SCHEMA,
                  pesticide: PESTICIDE_VALIDATION_SCHEMA,
                  engrais: ENGRAIS_VALIDATION_SCHEMA,
                  metaux_lourds: TOXIQUE_VALIDATION_SCHEMA,
                  autre_animal_venimeux: AUTRE_ANIMAL_VENIMEUX_VALIDATION_SCHEMA,
                  medicaments_veterinaires: MEDICAMENT_VETERINAIRE_VALIDATION_SCHEMA,
                  produit_industriel: PRODUIT_INDUSTRIEL_VALIDATION_SCHEMA,
                  scorpion: SCORPION_VALIDATION_SCHEMA,
                  serpent: SERPENT_VALIDATION_SCHEMA,
                  inconnu: INCONNU_VALIDATION_SCHEMA,
                  autre: AUTRE_VALIDATION_SCHEMA
            })
      }),
      Yup.object().shape({
            //Intoxication
            intoxication: Yup.object().shape({
                  intoxication: Yup.object().shape({
                        date_intoxication: Yup.date().nullable(),
                        heure: Yup.string().nullable(),
                        delai_intoxication_j: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        delai_intoxication_h: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        delai_intoxication_m: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        lieu_intoxication_id: Yup.string().nullable(),
                        autre_lieu_intoxication: Yup.string().nullable(),
                        type_risque_id: Yup.string().nullable(),
                        type_intoxication: Yup.string().nullable(),
                        nombre_cas: Yup.number().typeError('Nombre cas doit être un nombre').integer('Nombre cas doit être un nombre entier').min(0, 'Nombre cas doit être un nombre positif').nullable(),
                  }),
                  symptomatologie: Yup.object().shape({
                        symptomatique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        terme_reaction_id: Yup.string().nullable(),
                        termes: Yup.array(),
                        relation_toxique_symptomologie: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        type_risque_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        gradation_initiale_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),
                  }),
                  evolution: Yup.object().shape({
                        relance: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        date_relance: Yup.date().nullable(),
                        hospitalisation: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        duree_hospitalisation: Yup.number('La durée doit être un nombre en jour').nullable(),
                        gradation_finale_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        evolution_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),

                        sequelles: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        sequelles_details: Yup.string().nullable(),
                        imputabilite: Yup.object().shape({
                              exposition: Yup.string().nullable(),
                              symptomatologie: Yup.string().nullable(),
                              chronologie: Yup.string().nullable(),
                              caracterisation_causale: Yup.string().nullable(),
                              diagnostique_differentiel: Yup.string().nullable(),
                              lien_extrinseque: Yup.string().nullable(),
                              imputabilite: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        }),
                        historique: Yup.string().nullable(),
                  }),
            })
      }),
      Yup.object().shape({
            //Echantillon
            echantillon: Yup.object().shape({
                  heure_prelevement: Yup.string().nullable(),
                  echantillons: Yup.object().shape({
                        sang: ECHANTILLON_VALIDATION_SCHEMA,
                        urines: ECHANTILLON_VALIDATION_SCHEMA,
                        lg: ECHANTILLON_VALIDATION_SCHEMA,
                        lcr: ECHANTILLON_VALIDATION_SCHEMA,
                        produit: Yup.object().shape({
                              checked: Yup.boolean(),
                              name: Yup.string(),
                              conformite: Yup.string(),
                              probleme: Yup.string(),
                              lc: Yup.boolean(),
                              gc: Yup.boolean(),
                              ptrr: Yup.boolean(),
                              saa: Yup.boolean(),
                        }),
                        autres: Yup.object().shape({
                              checked: Yup.boolean(),
                              name: Yup.string(),
                              conformite: Yup.string(),
                              probleme: Yup.string(),
                              lc: Yup.boolean(),
                              gc: Yup.boolean(),
                              ptrr: Yup.boolean(),
                              saa: Yup.boolean(),
                        })
                  }),
                  examen_demande: Yup.array().of(
                        Yup.number()
                  ).min(1, "Au moins un examen doit être choisi")
            })
      }),
      Yup.object().shape({
            //Resultat
            resultat: Yup.object().shape({
                  LC: Yup.object().shape({
                        recherche_dosage: Yup.string().required(CHAMP_OBLIGATOIRE),
                        resultat_analyse: Yup.string().nullable(),
                        produits_testes: Yup.array().of(RESULTAT_VALIDATION_SCHEMA),
                  }),
                  PTRR: Yup.object().shape({
                        resultat_analyse: Yup.string().required(CHAMP_OBLIGATOIRE),
                        resultats_dosage: Yup.array(RESULTAT_DOSAGE_VALIDATION_SCHEMA),
                        resultats_recherche: Yup.array(RESULTAT_RECHERCHE_VALIDATION_SCHEMA),
                  }),
                  SAA: Yup.object().shape({
                        resultat_analyse: Yup.string().required(CHAMP_OBLIGATOIRE),
                        resultats: Yup.array(RESULTAT_DOSAGE_VALIDATION_SCHEMA),
                  }),
                  GC: Yup.object().shape({
                        recherche_dosage: Yup.string().required(CHAMP_OBLIGATOIRE),
                        resultat_analyse: Yup.string().nullable(),
                        produits_testes: Yup.array().of(RESULTAT_VALIDATION_SCHEMA),
                  }),
            })
      }),
      Yup.object().shape({
            //Analyste
            analyste: Yup.object().shape({
                  analyste_id: Yup.number().required(CHAMP_OBLIGATOIRE),
                  observation: Yup.string().nullable()
            })
      }),

]


const TOXI_FORMIK_VALIDATION_SCHEMA = [
      Yup.object().shape({
            //Déclarant
            declarant: Yup.object().shape({
                  rapport: Yup.object().shape({
                        numero_dossier: Yup.string().required(CHAMP_OBLIGATOIRE),
                        date_saisie: Yup.date().required(CHAMP_OBLIGATOIRE),
                        date_reception: Yup.date(),
                        heure_reception: Yup.string().nullable(),
                        type_rapport_id: Yup.number().required(CHAMP_OBLIGATOIRE),
                        operateur: Yup.string().nullable(),
                  }),
                  declarant: Yup.object().shape({
                        declarant_type_id: Yup.string().required(CHAMP_OBLIGATOIRE),
                        nom: Yup.string(),
                        telephone: Yup.string().nullable()
                              .matches(phoneRegExp, 'Numéro de téléphone invalide')
                              .min(0, "Le numéro de téléphone doit avoir 10 chiffres")
                              .max(10, "Le numéro de téléphone doit avoir 10 chiffres"),
                        commune_id: Yup.string().nullable(),
                        province_id: Yup.string().nullable(),
                        region_id: Yup.string().nullable(),
                        provenance_id: Yup.string().nullable(),
                        service_id: Yup.string().nullable(),
                        denomination: Yup.string().nullable(),

                  }),

            })
      }),
      Yup.object().shape({
            //Patient
            patient: Yup.object().shape({
                  nom_prenom: Yup.string(),
                  telephone: Yup.string().nullable()
                        .matches(phoneRegExp, 'Numéro de téléphone invalide')
                        .min(0, "Le numéro de téléphone doit avoir 10 chiffres")
                        .max(10, "Le numéro de téléphone doit avoir 10 chiffres"),
                  sexe: Yup.number(),
                  age_chiffre: Yup.number().typeError('Age doit être un nombre').integer('Age doit être un nombre entier').min(0, 'Age doit être un nombre positif').nullable(),
                  age_unite: Yup.string().nullable(),
                  tranche_age: Yup.string().nullable(),
                  region_id: Yup.string().nullable(),
                  commune_id: Yup.string().nullable(),
                  province_id: Yup.string().nullable(),
                  milieu: Yup.number(),
                  poids: Yup.number().typeError('Poids doit être un nombre').min(0, 'Poids doit être positif').nullable(),
                  grossesse: Yup.string(),
                  nombre_semaines: Yup.number().typeError('Nombre de semaines doit être un nombre'),
                  allaitement: Yup.number(),
                  profession_id: Yup.string().nullable(),
                  antecedents: Yup.string(),
                  historique: Yup.string(),
            })
      }),
      Yup.object().shape({
            //Toxiques
            toxiques: Yup.object().shape({
                  medicaments: MEDICAMENT_VALIDATION_SCHEMA,
                  produit_menager: PRODUIT_MENAGER_VALIDATION_SCHEMA,
                  cosmetique: PRODUIT_COSMETIQUE_VALIDATION_SCHEMA,
                  aliments: ALIMENT_VALIDATION_SCHEMA,
                  plante: PLANTE_VALIDATION_SCHEMA,
                  gaz: GAZ_VALIDATION_SCHEMA,
                  drogue: DROGUE_VALIDATION_SCHEMA,
                  pesticide: PESTICIDE_VALIDATION_SCHEMA,
                  engrais: ENGRAIS_VALIDATION_SCHEMA,
                  metaux_lourds: TOXIQUE_VALIDATION_SCHEMA,
                  autre_animal_venimeux: AUTRE_ANIMAL_VENIMEUX_VALIDATION_SCHEMA,
                  medicaments_veterinaires: MEDICAMENT_VETERINAIRE_VALIDATION_SCHEMA,
                  produit_industriel: PRODUIT_INDUSTRIEL_VALIDATION_SCHEMA,
                  scorpion: SCORPION_VALIDATION_SCHEMA,
                  serpent: SERPENT_VALIDATION_SCHEMA,
                  inconnu: INCONNU_VALIDATION_SCHEMA,
                  autre: AUTRE_VALIDATION_SCHEMA
            })
      }),
      Yup.object().shape({
            //Intoxication
            intoxication: Yup.object().shape({
                  intoxication: Yup.object().shape({
                        date_intoxication: Yup.date().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        heure: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        delai_intoxication_j: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        delai_intoxication_h: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        delai_intoxication_m: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        type_intoxication: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        nombre_cas: Yup.number().typeError('Nombre cas doit être un nombre').integer('Nombre cas doit être un nombre entier').min(0, 'Nombre cas doit être un nombre positif').nullable(),
                        lieu_intoxication_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        autre_lieu_intoxication: Yup.string().nullable(),
                        circonstance_intoxication_id: Yup.number().nullable(),
                        autre_circonstance_intoxication: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        erreur_medicamenteuse: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        erreur_medicamenteuse_erreur_circuit: Yup.string().nullable(),
                        erreur_medicamenteuse_type_erreur: Yup.string().nullable(),
                        erreur_medicamenteuse_cause_erreur: Yup.string().nullable(),
                        erreur_medicamenteuse_autre_information: Yup.string().nullable(),
                  }),
                  symptomatologie: Yup.object().shape({
                        symptomatique: Yup.string(),//.required(CHAMP_OBLIGATOIRE),
                        terme_reaction_id: Yup.string().nullable(),
                        termes: Yup.array(),
                        relation_toxique_symptomologie: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        type_risque_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        gradation_initiale_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),
                  }),
                  traitement_avant: Yup.object().shape({
                        precise: Yup.string().nullable(),
                        abstention_therapeutique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        orientation: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),

                        decontaminations: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        traitement_symptomatique_id: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        epurations: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        antidotes: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        consultation_specialisee: Yup.string().nullable(),

                        surveillance: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_radiologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_biologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_toxicologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        resultat_bilan_toxicologique: Yup.string().nullable(),
                  }),
                  traitement_capm: Yup.object().shape({
                        precise: Yup.string().nullable(),
                        abstention_therapeutique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        orientation: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),

                        decontaminations: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        traitement_symptomatique_id: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        epurations: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        antidotes: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        consultation_specialisee: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),


                        surveillance: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_radiologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_biologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_toxicologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        resultat_synthese: Yup.string().nullable()
                  }),
                  traitement_effectue: Yup.object().shape({
                        precise: Yup.string().nullable(),
                        abstention_therapeutique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        orientation: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),

                        decontaminations: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        traitement_symptomatique_id: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        epurations: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        antidotes: Yup.array().min(0, CHAMP_OBLIGATOIRE),
                        consultation_specialisee: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),

                        surveillance: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_radiologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_biologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        bilan_toxicologique: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        nombre_cas: Yup.string().nullable(),
                        resultat_synthese: Yup.string().nullable(),
                  }),
                  evolution: Yup.object().shape({
                        relance: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        date_relance: Yup.date().nullable(),
                        hospitalisation: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        duree_hospitalisation: Yup.number('La durée doit être un nombre en jour').nullable(),
                        gradation_finale_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        evolution_id: Yup.number().nullable(),//.required(CHAMP_OBLIGATOIRE),

                        sequelles: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        sequelles_details: Yup.string().nullable(),
                        imputabilite: Yup.object().shape({
                              exposition: Yup.string().nullable(),
                              symptomatologie: Yup.string().nullable(),
                              chronologie: Yup.string().nullable(),
                              caracterisation_causale: Yup.string().nullable(),
                              diagnostique_differentiel: Yup.string().nullable(),
                              lien_extrinseque: Yup.string().nullable(),
                              imputabilite: Yup.string().nullable(),//.required(CHAMP_OBLIGATOIRE),
                        }),
                        historique: Yup.string().nullable(),
                  }),

            }),
      }),
      Yup.object().shape({
            repondeur: Yup.object().shape({
                  repondeur_id: Yup.number().nullable(),
                  delai_reponse_id: Yup.number().nullable(),
                  signal: Yup.string().nullable(),
            }),
      })
]



export { SCORPION_FORMIK_VALIDATION_SCHEMA, LABO_FORMIK_VALIDATION_SCHEMA, TOXI_FORMIK_VALIDATION_SCHEMA };
