import { useQueries, useQuery } from "react-query";
import { getSecureAxiosInstance } from "../../utils/axiosInstances";



const useRolesAndPermissions = () => {
      return useQueries(
            ['roles', 'permissions'].map((table)=>{
                  return {
                        queryKey: table,
                        queryFn: () => getTable(table),
                  }
            })
      )
}

const getTable = async (table) => {
      const { data } = await getSecureAxiosInstance().get(`role-management/${table}`);
      return data;
}

const editPermission = async (values) => {
      const { data } = await getSecureAxiosInstance().patch(`role-management/edit-role-permission`, values);
      return data;
}
export {useRolesAndPermissions, editPermission}