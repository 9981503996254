import React from 'react';

const NotFound = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>404 - Page Not Found</h1>
            <p style={styles.message}>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '100px'
    },
    heading: {
        fontSize: '48px',
        color: '#FF5733'
    },
    message: {
        fontSize: '24px',
        color: '#333'
    }
};

export default NotFound;
