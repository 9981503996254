import React from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import { AddCase, ViewCase, UpdateRef, ExtractData, Dashboard, UserManagement } from "../Icons/Icons";

import { navButtonStyle, navTitleStyle, navDiv } from './style.js';

import './index.scss';


function CustomNav(props) {
      let navLocalButtonStyle = { ...navButtonStyle };
      let navLocalTitleStyle = { ...navTitleStyle };
      let icon, title = null;
      let iconColor = "#BDBDBD";
      if (props.active) {
            navLocalButtonStyle.background = '#367BF5';
            navLocalButtonStyle.borderLeftColor = '#367BF5';
            iconColor = '#fff';
            navLocalTitleStyle.fontWeight = 'bold';
      }
      switch (props.type) {
            case 'add-case':
                  icon = <AddCase width="20" height="20" viewBox="0 0 20 20" style={{ fill: iconColor }} />;
                  title = 'Ajouter un cas';
                  break;
            case 'view-case':
                  icon = <ViewCase width="20" height="20" viewBox="0 0 25 25" style={{ fill: iconColor }} border="1.5px solid #BDBDBD" />;
                  title = 'Consulter un cas';
                  break;
            case 'update-ref':
                  icon = <UpdateRef width="20" height="14" viewBox="0 0 25 25" style={{ fill: iconColor }} />
                  title = 'Référentiels';
                  break;
            case 'extract-data':
                  icon = <ExtractData width="20" height="14" viewBox="0 0 15 20" style={{ fill: iconColor, marginTop: 3 }} />;
                  title = 'Extraction des données';
                  break;
            case 'dashboard':
                  icon = <Dashboard width="20" height="14" viewBox="0 0 15 20" style={{ fill: iconColor, marginTop: 3 }} />;
                  title = 'Dashboard / Rapport';
                  break;
            case 'permissions':
                  icon = <UserManagement width="15" height="15" viewBox="0 0 20 20" style={{ fill: iconColor, marginTop: 3 }} />;
                  title = 'Gestion des utilisateurs';
                  break;
            default:
                  break;
      }
      return (
            <Stack direction="column" spacing={2} style={{ alignItems: 'center', width: '85px' }}>
                  <div style={{
                        width: '85px',
                        borderLeft: 'solid 3px',
                        borderLeftColor: navLocalButtonStyle.borderLeftColor
                  }}>
                        <IconButton style={navLocalButtonStyle}>
                              {icon}
                        </IconButton>
                  </div>
                  <div className='left-nav-bar-hidden-div' style={{ margin: 0 }}>
                        <Typography style={navLocalTitleStyle}>
                              {title}
                        </Typography>
                  </div>
            </Stack>
      )
}

export default CustomNav;