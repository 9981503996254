import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";

export default function Inconnu(props) {
    const { inconnu, preview } = props;


    const referentielResults = useReferentiels([
        "classe_chimique",
        "voie_intoxication",
    ]);

    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading) return <CustomLoader isLoading={isLoading} />;

    const classes = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);

    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Inconnu</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(inconnu.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom"
                                        value={inconnu.nom}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Classse" value={classes[inconnu.classe_id]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Composition"
                                        value={inconnu.composition}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Présentation"
                                        value={
                                            inconnu.presentation
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Doses administrées"
                                        value={
                                            inconnu.dose_administree
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[inconnu.voie_intoxication_id]
                                        }
                                    />
                                </div>

                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Toxique Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
