import React, { useState, useContext } from "react";
import { createTheme } from "@mui/material/styles";
import { Grid, ThemeProvider, TextField } from "@mui/material";
import frLocale from "date-fns/locale/fr";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import DatePicker from "@mui/lab/DatePicker";
import { ValidatedContext } from "../../modules/AddCases/AddLaboCase/AddLaboCase";

import "./index.scss";

const labelDatePickerTheme = createTheme();
labelDatePickerTheme.components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        height: "44px",
        background: "#FFFFFF",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "8px",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "20px",
        color: "#09101D",
        opacity: "0.8",
      },
    },
  },
};

export default function LabelDatePicker(props) {
  const {
    title,
    name,
    mandatory,
    boldTitle,
    disabled,
    onChange,
    error,
    helperText,
    value,
  } = props;
  const [newValue, setNewValue] = useState(value === "" ? null : value);

  const validated = useContext(ValidatedContext);

  let fontWeight = boldTitle === undefined ? 600 : 400;
  let isDisabled = disabled === undefined ? false : true;
  let redFlag =
    mandatory === true ? <span style={{ color: "red" }}>&nbsp;*</span> : "";

  return (
    <Grid
      container
      spacing={0.5}
      direction="column"
      style={{ marginLeft: "-8px" }}
    >
      <Grid item style={{ padding: 0 }}>
        <div style={{ paddingLeft: 4 }}>
          <label
            className="label-date-picker-label"
            style={{
              fontWeight: fontWeight,
            }}
          >
            {title}
            {redFlag}
          </label>
        </div>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={DateAdapter} locale={frLocale}>
          <ThemeProvider theme={labelDatePickerTheme}>
            <DatePicker
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ height: "42px", width: "100%" }}
                />
              )}
              name={name}
              value={newValue}
              disableFuture
              error={error}
              helperText={helperText}
              disabled={isDisabled || validated}
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setNewValue(newValue);
                onChange(name, moment(newValue).format("YYYY-MM-DD"));
              }}
              inputProps={{
                style: {
                  boxSizing: "border-box",
                },
              }}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
