import React from "react";
import { IconButton } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';

import { ThemeProvider } from "@mui/material";
import {buttonsTheme} from "./ButtonsTheme";


export default function SmallButton(props){
      const {type, onClick} = props;
      let icon = null;
     
      switch(type){
            case 'search':
                  icon = <SearchIcon />;
                  break;
            case 'load':
                  icon = <CachedIcon />;
                  break;
            default:
                  break;
      };
     
      return (
            <ThemeProvider theme={buttonsTheme}>
                  <IconButton size="large" onClick={onClick} style={{
                        backgroundColor:'#EAECF4',
                        borderRadius: '8px',
                        width:'43px',
                        height:'43px'
                  }}>
                        {icon}
                  </IconButton>
            </ThemeProvider>
      );
}

