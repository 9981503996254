import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { getFEFormatResultat } from "../../../../utils/divers/divers";
import { getReferentiel } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function AbsorptionAtomique(props) {
  const value = useFormikContext();
  const [entreeNom, setEntreeNom] = useState({});
  const [absorptionAtomique, setAbsorptionAtomique] = useState({});
  const selectedCase = useContext(CaseContext);
  useEffect(() => {
    getReferentiel("entree_nom").then((data) => {
      let flattenData = flattenIdNomDict(data);
      setEntreeNom(flattenData);
      const { values } = value || {};
      const resultatLabo = selectedCase
        ? getFEFormatResultat(selectedCase?.resultats_labo, flattenData) : values.resultat;
      setAbsorptionAtomique(resultatLabo?.SAA);
    })
  }, []);

  const widthPercentage = "45%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Métaux Lourds</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Résultat d'analyse"
                  value={
                    parseInt(absorptionAtomique?.resultat_analyse) === 1
                      ? "Positif"
                      : "Negatif"
                  }
                />
              </div>
            </Stack>
          </div>
          <div className="view-case-common-container-body-label">
            <label>Résultats</label>
          </div>
          <div>
            {absorptionAtomique?.resultats && absorptionAtomique?.resultats
              .filter((res) => res.checked === true)
              .map((result) => (
                <Stack direction="row" spacing={1}>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo title="Analyse" value={result.analyse} />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Dosage"
                      value={
                        result.dosage.sign +
                        result.dosage.value +
                        result.dosage.unit
                      }
                    />
                  </div>
                </Stack>
              ))}
          </div>
        </Stack>
      </div>
    </div>
  );
}
