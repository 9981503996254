import React, { useEffect } from "react";
import { Stack, Divider } from "@mui/material";
import ReferentielDataContainer from "../../components/Grids/ReferentielDataContainer/ReferentielDataContainer";
import TablesMenu from "../../components/TablesMenu/TablesMenu";
import { ReferentielContext } from "../../contextes/ReferentielContext";
import { getTablesByCategory } from "../../utils/referentielData";
import "./index.scss";

export default function ReferentielManagement(props) {
  const { category, notificationTable } = props;
  const [tables, setTables] = React.useState([]);
  const [table, setTable] = React.useState(notificationTable ?? "");

  const handleTableClick = (newTable) => {
    setTable(newTable);
  };

  useEffect(() => {
    let newTables = getTablesByCategory(category);
    setTables(newTables);
    setTable(newTables[0]);
  }, [category]);

  return (
    <div className="referentiel-management-container-root">
      <div className="referentiel-management-container">
        <ReferentielContext.Provider value={{ table, handleTableClick }}>
          <div className="referentiel-management-main">
            <Stack direction="row" spacing={0}>
              <div className="referentiel-management-tables-menu">
                <TablesMenu
                  notificationTable={notificationTable}
                  tables={tables}
                />
              </div>
              <div className="referentiel-management-divider">
                <Divider orientation="vertical" flexItem />
              </div>
              <div className="referentiel-management-data-grid">
                <ReferentielDataContainer table={table} />
              </div>
            </Stack>
          </div>
        </ReferentielContext.Provider>
      </div>
    </div>
  );
}
