import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict, flattenIdNomCommercialDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { getProduitPesticideDetails } from "../../../../services/cases/cases";

export default function Pesticide(props) {
    const { pesticide, preview } = props;
    const [pesticideDetails, setPesticideDetails] = useState(null);

    const referentielResults = useReferentiels([
        "matiere_active",
        "voie_intoxication",
        "forme",
        "classe_chimique",
        "classe_dangerosite",
        "usage_pesticide",
        "produit_pesticide"
    ]);
    useEffect(() => {
        getProduitPesticideDetails(pesticide.produit_pesticide_id).then((data) => {
            setPesticideDetails({
                "matiere_active": data.matiere_active,
                "classe_chimique": data.classe_chimique,
                "classe_dangerosite": data.classe_dangerosite,
                "forme_id": data.forme_id,
                "usage_official": data.usage_official,
                "type_pesticide": data.type_pesticide,
            });
        });
    }, [props]);
    const isLoading = referentielResults.some((result) => result.isLoading);
    if (isLoading || pesticideDetails === null) return <CustomLoader isLoading={isLoading} />;
    const matieresActives = flattenIdNomDict(referentielResults[0].data);
    const voix = flattenIdNomDict(referentielResults[1].data);
    const formes = flattenIdNomDict(referentielResults[2].data);
    const classesChimiques = flattenIdNomDict(referentielResults[3].data);
    const classesDangerosites = flattenIdNomDict(referentielResults[4].data);
    const usagesPesticides = flattenIdNomDict(referentielResults[5].data);
    const produitsPesticides = flattenIdNomCommercialDict(referentielResults[6].data);


    const widthPercentage = "31%";
    return (
        <div className="view-case-common-container">
            <div className="view-case-common-container-title">
                <label>Pesticide</label>
            </div>
            <div className="view-case-common-container-body">
                {parseInt(pesticide.connu_inconnu) !== 0 ? (
                    <Stack direction="column" spacing={4}>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Nom commercial"
                                        value={produitsPesticides[pesticide.produit_pesticide_id]}
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo title="Matière active" value={matieresActives[pesticideDetails.matiere_active]} />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Classe chimique"
                                        value={classesChimiques[pesticideDetails.classe_chimique]}
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Classe dangérosité"
                                        value={
                                            classesDangerosites[pesticideDetails.classe_dangerosite]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Forme"
                                        value={
                                            formes[pesticideDetails.forme_id]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Usage officiel"
                                        value={
                                            pesticideDetails.usage_official
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Dosage"
                                        value={
                                            pesticide.dosage
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Usage selon notificateur"
                                        value={
                                            usagesPesticides[pesticide.usage_notificateur_id]
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Usage (Autre)"
                                        value={
                                            pesticide.usage_notificateur_autre
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <Stack direction="row" spacing={3}>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Type"
                                        value={
                                            pesticide.type_pesticide
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Quantité prise"
                                        value={
                                            pesticide.quantite
                                        }
                                    />
                                </div>
                                <div style={{ width: widthPercentage }}>
                                    <LabelInfo
                                        title="Voie d'intoxication"
                                        value={
                                            voix[pesticide.voie_intoxication_id]
                                        }
                                    />
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                ) : (
                    <div style={{ width: widthPercentage, fontWeight: 500 }}>
                        Pesticide Inconnu
                    </div>
                )}
            </div>
        </div>
    );
}
