import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Traitement(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);
  let traitement;

  let title = "Traitement ";
  switch (props.type) {
    case "capm":
      title = title + "conseille par le CAPM";
      traitement = selectedCase ? selectedCase.intoxication?.traitement_capm : values.intoxication?.traitement_capm;
      break;
    case "avant":
      title = title + "avant l'appel";
      traitement = selectedCase ? selectedCase.intoxication?.traitement_avant : values.intoxication?.traitement_avant;
      break;
    case "effectue":
      title = title + "effectué";
      traitement = selectedCase ? selectedCase.intoxication?.traitement_effectue : values.intoxication?.traitement_effectue;
      break;
    default:
      title = "";
      traitement = null;
      break;
  }




  const referentielResults = useReferentiels([
    "decontamination",
    "traitement_symptomatique",
    "epuration",
    "antidotes",
  ]);

  const isLoading = referentielResults.some((result) => result.isLoading);
  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const decontaminations = flattenIdNomDict(referentielResults[0].data);
  const traitementSymptomatiques = flattenIdNomDict(referentielResults[1].data);
  const epurations = flattenIdNomDict(referentielResults[2].data);
  const antidotes = flattenIdNomDict(referentielResults[3].data);
  const widthPercentage = "31%";

  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>{title}</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Précisé:"
                  value={
                    parseInt(traitement?.precise) === 1 ? "Oui" : "Non"
                  }
                />
              </div>
            </Stack>
          </div>
          {parseInt(traitement?.precise) === 1 &&
            <>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: widthPercentage }}>

                    <LabelInfo
                      title="Orientation"
                      value={parseInt(traitement?.orientation) === 1 ? "Oui" : "Non"}
                    />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Abstention thérapeutique"
                      value={
                        parseInt(traitement?.abstention_therapeutique) === 1 ? "Oui" : "Non"
                      }
                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: "80%" }}>
                    <LabelInfo
                      title="Décontamination"
                      value={traitement && traitement.decontaminations.map((x) => decontaminations[x]).join(", ")}
                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>

                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Traitement symptomatique"
                      value={
                        traitement &&
                        traitementSymptomatiques[
                        traitement.traitement_symptomatique_id
                        ]
                      }
                    />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Consultation spécialisée"
                      value={

                        traitement?.consultation_specialisee
                      }
                    />
                  </div>

                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Epuration"
                      value={traitement && traitement.epurations.map((x) => epurations[x]).join(", ")}
                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: "80%" }}>
                    <LabelInfo title="Antidotes" value={traitement && traitement.antidotes.map((x) => antidotes[x]).join(", ")} />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>


                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Surveillance"
                      value={parseInt(traitement?.surveillance) === 1 ? "Oui" : "Non"}
                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Bilan radiologique"
                      value={parseInt(traitement?.bilan_radiologique) === 1 ? "Oui" : "Non"}
                    />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Bilan biologique"
                      value={parseInt(traitement?.bilan_biologique) === 1 ? "Oui" : "Non"}
                    />
                  </div>
                  <div style={{ width: widthPercentage }}>
                    <LabelInfo
                      title="Bilan toxicologique"
                      value={parseInt(traitement?.bilan_toxicologique) === 1 ? "Oui" : "Non"}
                    />
                  </div>
                </Stack>
              </div>
              <div>
                <Stack direction="row" spacing={3}>
                  <div style={{ width: "96%" }}>
                    <LabelInfo
                      title="Résultats Bilan toxicologique"
                      value={traitement?.resultat_bilan_toxicologique}
                    />
                  </div>
                </Stack>
              </div>
              {
                traitement?.nombre_cas && (
                  <div>
                    <Stack direction="row" spacing={3}>

                      <div style={{ width: widthPercentage }}>
                        <LabelInfo
                          title="Numéro du cas laboratoire"
                          value={traitement?.nombre_cas}
                        />
                      </div>

                    </Stack>
                  </div>
                )
              }
              {
                traitement?.resultat_synthese && (
                  <div>
                    <Stack direction="row" spacing={3}>

                      <div style={{ width: "80%" }}>
                        <LabelInfo
                          title="Résultats laboratoire"
                          value={traitement?.resultat_synthese}
                        />
                      </div>

                    </Stack>
                  </div>
                )
              }
            </>}
        </Stack>
      </div>
    </div>
  );
}
