import React from "react";
import { Stack } from "@mui/material";
import General from "../../../components/Case/Scorpion/General/General";
import Declarant from "../../../components/Case/Scorpion/Declarant/Declarant";
import Patient from "../../../components/Case/Scorpion/Patient/Patient";
import Traitement from "../../../components/Case/Scorpion/Traitement/Traitement";
import Hospitalisation from "../../../components/Case/Scorpion/Hospitalisation/Hospitalisation";
import Reference from "../../../components/Case/Scorpion/Reference/Reference";
import Evolution from "../../../components/Case/Scorpion/Evolution/Evolution";
import '../index.scss';




export default function AddScorpionMain(props) {

      return (
            <div className="add-case-body">
                  <Stack direction="column" spacing={5}>
                        <General />
                        <Declarant />
                        <Patient />
                        <Traitement />
                        <Hospitalisation />
                        <Reference />
                        <Evolution /> 
                  </Stack>
            </div>

      )
}