import React, { useContext } from "react";
import { Button } from "@mui/material";

import { ThemeProvider } from "@mui/material";
import { buttonsTheme } from "./ButtonsTheme";
import { ValidatedContext } from "../../modules/AddCases/AddLaboCase/AddLaboCase";

function GridButton(props) {
  const validated = useContext(ValidatedContext);

  const { onClick } = props;
  let backgroundColor = "#EAECF4";

  return (
    <ThemeProvider theme={buttonsTheme}>
      <Button
        disabled={validated}
        variant="outlined"
        onClick={onClick}
        style={{
          backgroundColor: backgroundColor,
          fontSize: "30px",
          border: 0,
          borderRadius: "5px",
          color: "#828282",
          minWidth: "20px",
        }}
      >
        +
      </Button>
    </ThemeProvider>
  );
}

export default GridButton;
