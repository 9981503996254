import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { useReferentiels } from "../../../../services/referentiels/referentiels";
import { flattenIdNomDict } from "../../../../utils/referentielData";

import CustomLoader from "../../../CustomLoader/CustomLoader";
import LabelInfo from "../../../LabelInfo/LabelInfo";
import "../../index.scss";
import { CaseContext } from "../../../Modal/PreviewCaseModal";

export default function Repondeur(props) {
  const value = useFormikContext();
  const { values } = value || {};
  const selectedCase = useContext(CaseContext);

  const repondeur = selectedCase ? selectedCase : values.repondeur;

  const referentielResults = useReferentiels(["repondeur", "delai_reponse"]);
  const isLoading = referentielResults.some((result) => result.isLoading);

  if (isLoading) return <CustomLoader isLoading={isLoading} />;

  const repondeurs = flattenIdNomDict(referentielResults[0].data);
  const delaiReponse = flattenIdNomDict(referentielResults[1].data);

  const widthPercentage = "31%";
  return (
    <div className="view-case-common-container">
      <div className="view-case-common-container-title">
        <label>Le répondeur</label>
      </div>
      <div className="view-case-common-container-body">
        <Stack direction="column" spacing={4}>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Répondeur"
                  value={repondeur && repondeurs[repondeur.repondeur_id]}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: widthPercentage }}>
                <LabelInfo
                  title="Délai de réponse"
                  value={repondeur && delaiReponse[repondeur.delai_reponse_id]}
                />
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="row" spacing={3}>
              <div style={{ width: "80%" }}>
                <LabelInfo
                  title="Considérez-vous ce toxique comme un signal ?"
                  value={repondeur && (
                    parseInt(repondeur.signal) === 1
                      ? "Oui"
                      : parseInt(repondeur.signal) === 0
                        ? "Non"
                        : undefined)
                  }
                />
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
