import React, { useState, useRef } from "react";
import { Stack } from "@mui/material";
import { printPdf } from "../../utils/divers/divers";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import DashKPIS from "../../components/DashKPI/DashKPIS";
import { useSnackbar } from 'notistack';
import { getDashboardData } from "../../services/dashboard/dashboard";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import './index.scss';



export default function Report(props) {
      const { category } = props;
      const [isLoading, setIsLoading] = useState(false);
      const [pdfLoading, setPdfLoading] = useState(false);
      const [startDate, setStartDate] = useState((new Date()).toISOString().slice(0, 10));
      const [endDate, setEndDate] = useState((new Date()).toISOString().slice(0, 10));
      const [dateType, setDateType] = useState("date_reception");
      const [data, setData] = useState(null);
      const { enqueueSnackbar: showMessage } = useSnackbar();
      const printRef = useRef();


      const onStartDateChange = (name, newValue) => {
            setStartDate(newValue);
      }

      const onEndDateChange = (name, newValue) => {
            setEndDate(newValue);
      }

      const onDateTypeChange = (newValue) => {
            setDateType(newValue);
      }

      const handlePreviewClick = () => {
            setIsLoading(true);
            getDashboardData(category, dateType, startDate, endDate).then(data => {
                  setData(data);
                  setIsLoading(false);
            })
                  .catch(() => showMessage('Accès refusé. Veuillez réessayer', { variant: 'error' }))
      }

      const handleDownloadClick = async () => {
            printPdf("dash-kpis", "Dashboard_" + category + ".pdf", setPdfLoading)
      }

      return (
            <div className="report-container">
                  <div className="report-body">
                        <Stack direction="column" spacing={1}>
                              <div className="report-elt">
                                    <DashboardFilter
                                          onStartDateChange={onStartDateChange}
                                          onEndDateChange={onEndDateChange}
                                          onDateTypeChange={onDateTypeChange}
                                          startDate={startDate}
                                          endDate={endDate}
                                          dateType={dateType}
                                          onPreview={handlePreviewClick}
                                          onDownload={handleDownloadClick}
                                          disabled={pdfLoading} />
                              </div>
                              <div ref={printRef} className="report-elt">
                                    {isLoading === true && <CustomLoader isLoading={isLoading} />}
                                    {isLoading === false && data != null && <DashKPIS data={data} />}
                              </div>
                        </Stack>
                  </div>
            </div>
      )

}