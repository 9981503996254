import React  from "react";
import { Stack, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { useFormikContext } from "formik";
import LabelMenu from "../../../LabelMenu/LabelMenu";
import { getTypeDeclarantDict, getMonthsDict, getYearsDict } from "../../../../utils/case";
import LabelInput from "../../../LabelInput/LabelInput";
import '../../index.scss';



export default function General(props) {
      const {values, touched, errors, handleChange, setFieldValue} = useFormikContext();
      const typeDeclarantDict = getTypeDeclarantDict();
      const widthPercentage = '31%';
      return (
            <div className="case-common-container" style={{ border:0,boxShadow:'none' }}>

                  <div className="case-common-container-body" style={{marginLeft:0}}>
                        <Stack direction="column" spacing={4}>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Province médicale"} 
                                                      name={"general.province"} 
                                                      onChange={handleChange}
                                                      error={touched?.general?.province && Boolean(errors?.general?.province)}
                                                      helperText={touched?.general?.province && errors?.general?.province}
                                                      mandatory={true} valuesDict={typeDeclarantDict} />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelMenu title={"Mois"} 
                                                name={"general.mois"} 
                                                onChange={handleChange}
                                                error={touched?.general?.mois && Boolean(errors?.general?.mois)}
                                                helperText={touched?.general?.mois && errors?.general?.mois}
                                                mandatory={true} valuesDict={getMonthsDict()} />
                                          </div>
                                          <div style={{ width: widthPercentage }}>
                                                <LabelInput title={"Année"} 
                                                name={"general.annee"} 
                                                onChange={handleChange}
                                                error={touched?.general?.annee && Boolean(errors?.general?.annee)}
                                                helperText={touched?.general?.annee && errors?.general?.annee}
                                                mandatory={true} />
                                          </div>
                                    </Stack>
                              </div>
                              <div>
                                    <Stack direction="row" spacing={3}>
                                          <div style={{ width: '45%', height: '59px' }}>
                                                <FormControl component="fieldset" style={{ height: '100%' }}>
                                                      <Stack direction="row" spacing={0} style={{ height: '100%' }}>
                                                            <div className="case-common-container-body-label">
                                                                  <label >Label: <span style={{ color: 'red' }}>*</span></label>
                                                            </div>
                                                            <div className="case-common-container-body-radio">
                                                                  <RadioGroup row name={"general.label"} 
                                                                        onChange={(event)=>setFieldValue("general.label", event.target.value)}
                                                                        value={values.general.label}
                                                                  >
                                                                        <FormControlLabel value="chu" control={<Radio />} label="CHU" />
                                                                        <FormControlLabel value="autre" control={<Radio />} label="Autre" />
                                                                  </RadioGroup>
                                                                  
                                                            </div>
                                                      </Stack>
                                                </FormControl>
                                          </div>
                                    </Stack>
                              </div>

                        </Stack>
                  </div>
            </div>
      )
}